import { LanguageStructure } from 'types/language';

const es: LanguageStructure = {
    shared: {
        loading: 'Cargando...',
        yes: 'Sí',
        no: 'No',
        add: 'Agregar',
        submit: 'Enviar',
        cancel: 'Cancelar',
        resident: 'Residente',
    },
    error: {
        emptyValue: '¡Éste es un campo requerido!',
        userNotFound:
            'La Cuenta proporcionada no existe. Favor de verificar e intentar nuevamente.',
        missingCredentials:
            '¡Necesita proporcionar un Nombre de Usuario y Contraseña!',
        crendentialsMismatch:
            '¡Sus credenciales no coinciden! Por favor, intente nuevamente.',
        tokenExpired:
            'Lo sentimos, pero su token a expirado. Por favor, reinicie su sesión.',
        inactiveUser:
            'Lo sentimos, pero su cuenta ha sido desactivada. Por favor, contacte a su Supervisor.',
        pageDenied:
            'Lo sentimos, pero no está autorizado para acceder a esta página.',
        actionDenied:
            'Lo sentimos, pero no está autorizado para realizar esta acción.',
        userLogout: '¡Hubo un error al intentar cerra su sesión!',
        missingUserId: 'Necesitas proporcionar un ID de Usuario.',
        missingRoleId: 'Necesitas proporcionar un ID de Rol.',
        onlyManagers:
            'Lo sentimos, pero esta acción es exclusiva para Administradores',
        onlyCaregivers:
            'Lo sentimos, pero esta acción es exclusiva para Cuidadores',
    },
    login: {
        pageTitle: 'Login',
        content:
            'Bienvenido a AllieHealth. Por favor inicie sesión proporcionando su nombre de usuario y contraseña.',
        username: 'Nombre de Usuario',
        password: 'Contraseña',
        signIn: 'Iniciar Sesión',
        signOut: 'Cerrar Sesión',
    },
    navigator: {
        home: 'Inicio',
        residents: 'Residentes',
        myRewards: 'Mis Ganancias',
        userProfile: 'Perfil',
        userCompany: 'Empresa',
        settings: 'Configuración',
        signIn: 'Iniciar Sesión',
        signOut: 'Cerrar Sesión',
        language: 'English',
        goodMorning: 'Buenos Días',
        goodAfternoon: 'Buenas Tardes',
        goodEvening: 'Buenas Noches',
        supportEmail: 'support@alliehealth.com',
        privacy: 'Privacidad',
        integratePointClickCare: 'Integrada con PointClickCare',
    },
    footer: {
        label: 'AllieHealth es una marca registrada',
    },
    pageNotFound: {
        pageTitle: 'Página no encontrada',
        title: '¡Oh no!',
        content: 'La página que buscas no se encontró.',
        goToHome: 'Ir al Inicio',
    },
    home: {
        pageTitle: 'Inicio',
        date: 'Fecha',
        branch: 'Sucursal',
        shift: 'Turno',
        zone: 'Zona',
        addTask: 'Agregar Tarea',
        serviceProvided: 'Servicio Prestado',
        taskNotes: 'Notas de la Tarea',
        noTasks: 'No hay tareas para la Fecha, Turno y Zona seleccionados',
        missingParamsForAddedTask:
            '¡Necesitas seleccionar un Residente y un Servicio para Agregar la Tarea!',
        createTaskSuccess: '¡La tarea ha sido Creada satisfactoriamente!',
        updateTaskSuccess: '¡La tarea ha sido Completada satisfactoriamente!',
        downloadCsvSuccess:
            '¡El archivo CSV file se ha descargado satisfactoriamente!',
    },
    residents: {
        pageTitle: 'Residentes',
        pageDetailsTitle: 'Residente',
        searchLabel: 'Nombre/Zona/Habitación del Residente',
        searchPlaceholder: 'Buscar por Nombre, Zona o Habitación',
        status: 'Estatus',
        focus: 'Enfoque',
        level: 'Nivel',
        transfer: 'Transferir',
        one: '1 Persona',
        two: '2 Personas',
        healthIncident: 'Salud/Incidente',
        roomMaintenance: 'Mantenimiento de Cuarto',
        supply: 'Suministro',
        intakeRecord: 'Registro de Adminsión',
        record: 'Registro',
        dressing: 'Vestir',
        toileting: 'Ir al baño',
        bathing: 'Bañar',
        hygiene: 'Higiene',
        walking: 'Caminar',
        diet: 'Dieta',
        dining: 'Alimentos',
        personalInfo: 'Información Personal',
        noResidents: 'No hay Residentes registrados en esta Sucursal',
        noMatch: '¡Ningún Residente coincide con el filtro!',
        reportDialogTitle: 'Crear Reporte de {XXX}',
        report: 'Reporte',
        reportPlaceholder:
            'Por favor, proporcione una breve descripción del reporte.',
        createShiftNotesSuccess:
            '¡El Reporte ha sido creado satisfactoriamente!',
        updateShiftNotesSuccess:
            '¡El Reporte ha sido actualizado satisfactoriamente!',
    },
    privacyPolicy: {
        pageTitle: 'Privacy Policy',
        pageDetailsTitle: 'Privacy Policy',
    },
    myRewards: {
        pageTitle: 'Mis Ganancias',
        totalPoints: 'Total de Puntos Adquiridos',
        taskDate: 'Fecha de la Tarea',
        taskType: 'Tipo de Tarea',
        taskName: 'Tarea',
        attendedResident: 'Residente atendido',
        earnedOn: 'Obtenidos el',
        earnedAt: 'a la(s)',
        earnedPoints: 'Puntos obtenidos',
    },
    comingSoon: {
        title: 'Próximamente',
        content:
            'Estamos trabajando duro para crear algo fantástico para ti. ¡Te mereces lo mejor!',
    },
    companies: {
        pageTitle: 'Empresas',
    },
    branches: {
        pageTitle: 'Sucursales',
    },
    users: {
        pageTitle: 'Usuarios',
    },
    userCompany: {
        pageTitle: 'Empresa',
        companyName: 'Nombre de la Empresa',
        branchName: 'Nombre de la Sucursal',
    },
    userProfile: {
        pageTitle: 'Perfil',
        firstName: 'Nombre',
        lastName: 'Apellido(s)',
        birthDate: 'Fecha de Nacimiento',
        role: 'Rol',
        username: 'Usuario',
        password: 'Contraseña',
        email: 'Email',
        phoneNumber: 'Número Telefónico',
        enabledGoogleOauth: 'Habilitar acceso via Google',
        status: 'Estatus',
    },
};

export default es;
