import { LocationState } from 'types/redux';

import actionTypes from 'redux/actionTypes';

const history = (state: LocationState = {} as LocationState, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_UPDATE: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default history;
