import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAnalytics } from 'analytics';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { Languages } from 'types/language';
import { Route as RouteType } from 'types/routes';

import { pxToRem } from 'components/theme/typography';
import {
    BASE_PATH,
    HOME_PATH,
    MENU_MAP,
    MOBILE_BOTTOM_MENU_HEIGHT,
    MOBILE_HEADER_HEIGHT,
    OPERATIONS_SNAPSHOTS_PATH,
    SUB_PAGES_MENU_MAP,
    USERS_MANAGEMENT_PATH,
    USERS_MANAGEMENT_USERS_PATH,
} from 'constants/app';
import { routesList } from 'lib/routes';

const mobileHeight = MOBILE_HEADER_HEIGHT + MOBILE_BOTTOM_MENU_HEIGHT;

const ContainerStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: { xs: pxToRem(MOBILE_HEADER_HEIGHT), lg: 0 },
        mb: { xs: pxToRem(MOBILE_BOTTOM_MENU_HEIGHT), lg: 0 },
        width: '100%',
        height: { xs: `calc(100vh - ${pxToRem(mobileHeight)})`, lg: '100%' },
        overflowY: 'auto',
    })
);

type Props = {
    languageId: Languages;
    roleLevelId: number;
};

const Routing = (props: Props) => {
    const { languageId, roleLevelId } = props;
    const location = useLocation();

    const { pageViewed } = useAnalytics();

    useEffect(() => {
        pageViewed(location.pathname);
    }, [location]);

    return (
        <ContainerStyle>
            <Routes>
                {routesList().map((route: RouteType) => {
                    const { path, Component, Wrapper } = route;

                    if (path === BASE_PATH) {
                        const homePath =
                            roleLevelId <= 4
                                ? `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}`
                                : `${BASE_PATH}${HOME_PATH}`;
                        return (
                            <Route
                                key={path}
                                path={path}
                                element={<Navigate to={homePath} replace />}
                            />
                        );
                    }

                    if (
                        roleLevelId > 4 &&
                        (path === MENU_MAP.operationsSnapshots ||
                            path === MENU_MAP.performanceSnapshots ||
                            path === MENU_MAP.userManagementUsersList ||
                            path === MENU_MAP.userManagementInvitations ||
                            path === MENU_MAP.managerTaskList ||
                            path === SUB_PAGES_MENU_MAP.managerResidentDetails)
                    ) {
                        return (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <Navigate
                                        to={`${BASE_PATH}${HOME_PATH}`}
                                        replace
                                    />
                                }
                            />
                        );
                    }

                    if (path === `${BASE_PATH}${USERS_MANAGEMENT_PATH}`) {
                        const newPath = `${BASE_PATH}${USERS_MANAGEMENT_PATH}${USERS_MANAGEMENT_USERS_PATH}`;
                        return (
                            <Route
                                key={path}
                                path={path}
                                element={<Navigate to={newPath} replace />}
                            />
                        );
                    }

                    return (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <Wrapper>
                                    <Component />
                                </Wrapper>
                            }
                        />
                    );
                })}
            </Routes>
        </ContainerStyle>
    );
};

const mapStateToProps = ({ language, session }) => {
    const { id: languageId } = language;

    const {
        sessionData: { roleLevelId },
    } = session;

    return {
        languageId,
        roleLevelId,
    };
};

export default connect(mapStateToProps)(Routing);
