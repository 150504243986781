import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import html from 'react-inner-html';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LanguageStructure } from 'types/language';

import allieHealthImage from 'assets/allie_health.jpg';
import { pxToRem } from 'components/theme/typography';
import { BASE_PATH, HOME_PATH, OPERATIONS_SNAPSHOTS_PATH } from 'constants/app';
import PageStructure from 'pages/PageStructure';

const useStyles = makeStyles({
    image: {
        padding: `${pxToRem(8)} 0`,
        width: pxToRem(248),
    },
});

const ContentStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pt: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    })
);

const GoHomeButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(16),
    })
);

type Props = {
    dictionary: LanguageStructure;
    roleLevelId: number;
};

const PageNotFound = (props: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {
        dictionary: { pageNotFound },
        roleLevelId,
    } = props;

    const handleGoHomeClick = () => {
        navigate(
            roleLevelId <= 4
                ? `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}`
                : `${BASE_PATH}${HOME_PATH}`
        );
    };

    return (
        <PageStructure>
            <Typography variant="h2">{pageNotFound.title}</Typography>
            <ContentStyle>
                <Typography variant="h4" {...html(pageNotFound.content)} />
                <GoHomeButton
                    variant="contained"
                    color="primary"
                    onClick={handleGoHomeClick}
                >
                    {pageNotFound.goToHome}
                </GoHomeButton>
                <Box
                    component="img"
                    src={allieHealthImage}
                    className={classes.image}
                />
            </ContentStyle>
        </PageStructure>
    );
};

const mapStateToProps = ({ language, session }) => {
    const { dictionary } = language;
    const {
        sessionData: { roleLevelId },
    } = session;

    return {
        dictionary,
        roleLevelId,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedPageNotFound: any = connect(mapStateToProps)(PageNotFound);

export default ConnectedPageNotFound;
