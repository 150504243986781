import { SelectStructure } from 'types/inputs';

export const AVATAR_WIDTH = 64;
export const TASKS_DONE_AVATAR_WIDTH = AVATAR_WIDTH / 2.29;
export const AVATAR_CONTAINER_WIDTH = AVATAR_WIDTH + 16;
export const BUILDING_STATUS_WIDTH = 96;
export const SORT_BUTTON_WIDTH = 48;

export const RESIDENTS_BUILDING_STATUSES = ['In Building', 'Out of Building'];

export const RESIDENTS_BUILDING_STATUSES_OPTIONS: SelectStructure[] =
    RESIDENTS_BUILDING_STATUSES.map((status) => ({
        label: status,
        value: status,
    }));
