import { Box, Icon, Link, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
    DashboardDialogData,
    DashboardDialogType,
    IncompletedOverdueTaskData,
} from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomAvatar, CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { SUB_PAGES_MENU_MAP } from 'constants/app';
import { AVATAR_TYPES } from 'constants/shared';
import { formatDateTimeFullUS, formatDateTimeShortUS } from 'lib/common';
import { formatPotentialContactsReportContent } from 'lib/dashboard';
import {
    SeeDetails,
    SeeDetailsIcon,
} from 'pages/Dashboards/components/SharedComponents';
import { updatePriorParentPage } from 'redux/actions/session';

const SubtitleContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        pb: pxToRem(16),
        display: 'flex',
        width: '100%',
    })
);

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'flex-start',
        '&:first-of-type': {
            mt: pxToRem(24),
        },
        '&:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ResidentData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-end',
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        fontSize: { lg: pxToRem(16) },
        fontWeight: 'bold',
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
    })
);

const ReportedByLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
    })
);

const ReportContent = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        px: pxToRem(16),
        py: pxToRem(8),
        borderRadius: theme.shape.borderRadiusSm,
        color: theme.palette.grey[600],
        backgroundColor: theme.palette.grey[200],
        fontSize: { lg: pxToRem(14) },
    })
);

const ContactContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
    })
);

const ContactLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.common.black,
        fontSize: { lg: pxToRem(12) },
        '&:not(:first-of-type)': {
            ml: pxToRem(4),
        },
    })
);

const PhonNumberLink = styled(Link)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        fontSize: { lg: pxToRem(12) },
    })
);

const TaskGroupName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.error.main,
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'bold',
    })
);

const DataRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        p: pxToRem(8),
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadiusSm,
        width: '100$',
    })
);

const TaskName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% - ${pxToRem(192)})`,
    })
);

const TaskTimestamp = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
        width: pxToRem(192),
    })
);

type Props = {
    data: DashboardDialogData;
    dialogType?: DashboardDialogType;
    dictionary: LanguageStructure;
    open: boolean;
    timezone: string;
    title: string;
    dispatchUpdatePriorParentPage: (parentPage: string) => void;
    onClose: () => void;
};

const DetailsDialog = (props: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {
        data,
        dialogType,
        dictionary: { dashboards, shared },
        open,
        timezone,
        title,
        dispatchUpdatePriorParentPage,
        onClose,
    } = props;

    const handleShowDetails = () => {
        // Store the current parent page.
        dispatchUpdatePriorParentPage(pathname);

        const url =
            dialogType === 'overdue'
                ? SUB_PAGES_MENU_MAP.dashboardOverdueTasks
                : SUB_PAGES_MENU_MAP.dashboardIncompletedTasks;

        navigate(url);
    };

    if (!dialogType) {
        return null;
    }

    return (
        <CustomDialog
            closeable
            open={open}
            title={title}
            width={pxToRem(600)}
            maxHeight={`calc(100vh - ${pxToRem(160)})`}
            showDividers
            // subtitle={
            //   <>
            //     {['incompleted', 'overdue'].includes(dialogType) && (
            //       <SubtitleContainer>
            //         <SeeDetails variant="caption" onClick={handleShowDetails}>
            //           {shared.seeDetails}
            //           <SeeDetailsIcon />
            //         </SeeDetails>
            //       </SubtitleContainer>
            //     )}
            //   </>
            // }
            content={
                <>
                    {data.map((item) => {
                        const {
                            caregiverName = '',
                            reportId,
                            reportTimestamp = '',
                            reportContent = '',
                            residentId = '',
                            residentFirstName = '',
                            residentLastName = '',
                            residentName = '',
                            residentPhoto = '',
                            roomNumber = '',
                            tasks: tasksGroups,
                            id,
                            resident = {},
                            caregiver = {},
                            report = {},
                        } = item;
                        const residentFullName =
                            residentName ||
                            resident?.fullName ||
                            `${residentFirstName} ${residentLastName}`;
                        const timestampFormatted = [
                            'fallen',
                            'potential',
                        ].includes(dialogType)
                            ? formatDateTimeFullUS(
                                  reportTimestamp || report.timestamp,
                                  timezone
                              )
                            : null;
                        const reportedBy =
                            ['fallen', 'potential'].includes(dialogType) &&
                            timestampFormatted
                                ? dashboards.fallenResidentsReportBy
                                      .replace(
                                          '{XX}',
                                          caregiverName || caregiver?.fullName
                                      )
                                      .replace('{YY}', timestampFormatted)
                                : '';
                        // reportContentData is only currently used in fallen or potential dialogs and breaks for other dialogs
                        const reportContentData =
                            dialogType === 'fallen' ||
                            dialogType === 'potential'
                                ? reportContent ||
                                  formatPotentialContactsReportContent(
                                      report.content,
                                      dashboards
                                  )
                                : null;
                        const potentialContactName =
                            resident?.contact?.fullName ||
                            `${dashboards.contactName} ${shared.notDefined}`;
                        const potentialContactRelationship =
                            resident?.contact?.relationship ||
                            `${dashboards.contactRelationship} ${shared.notDefined}`;
                        const potentialContactData = `${potentialContactName} (${potentialContactRelationship})`;
                        let key = residentId;

                        if (dialogType === 'fallen') {
                            key = reportId;
                        } else if (dialogType === 'potential') {
                            key = id;
                        }

                        return (
                            <Row key={key}>
                                <AvatarContainer>
                                    <CustomAvatar
                                        avatarType={AVATAR_TYPES.resident}
                                        firstName={
                                            residentFirstName ||
                                            resident?.firstName
                                        }
                                        lastName={
                                            residentLastName ||
                                            resident?.lastName
                                        }
                                        photo={residentPhoto || resident?.photo}
                                        size={48}
                                    />
                                </AvatarContainer>
                                <DataContainer>
                                    <RouterLink to={`resident-details/${key}`}>
                                        <DataContainer>
                                            <ResidentData>
                                                <ResidentName>
                                                    {residentFullName}
                                                </ResidentName>
                                                <RoomNumber>
                                                    {dashboards.room}{' '}
                                                    {roomNumber ||
                                                        resident?.roomNumber}
                                                </RoomNumber>
                                            </ResidentData>
                                        </DataContainer>
                                    </RouterLink>
                                    {dialogType === 'fallen' && (
                                        <>
                                            <ReportedByLabel>
                                                {reportedBy}
                                            </ReportedByLabel>
                                            <ReportContent>
                                                {reportContentData}
                                            </ReportContent>
                                        </>
                                    )}
                                    {['incompleted', 'overdue'].includes(
                                        dialogType
                                    ) &&
                                        tasksGroups &&
                                        Object.keys(tasksGroups).map(
                                            (taskGroup) => {
                                                const tasks: IncompletedOverdueTaskData[] =
                                                    tasksGroups[taskGroup];
                                                return (
                                                    <>
                                                        {dialogType ===
                                                            'incompleted' && (
                                                            <TaskGroupName
                                                                key={taskGroup}
                                                            >
                                                                {taskGroup}
                                                            </TaskGroupName>
                                                        )}
                                                        {tasks.map((task) => {
                                                            const timestamp = `${task.taskDate} ${task.taskTime}`;
                                                            const dateTimeFormatted =
                                                                formatDateTimeShortUS(
                                                                    timestamp,
                                                                    timezone
                                                                );
                                                            return (
                                                                <DataRow
                                                                    key={
                                                                        task.taskId
                                                                    }
                                                                >
                                                                    <TaskName>
                                                                        {
                                                                            task.taskName
                                                                        }
                                                                    </TaskName>
                                                                    <TaskTimestamp>
                                                                        {
                                                                            dateTimeFormatted
                                                                        }
                                                                    </TaskTimestamp>
                                                                </DataRow>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            }
                                        )}
                                    {dialogType === 'potential' && (
                                        <>
                                            <ReportContent>
                                                {reportContentData}
                                            </ReportContent>
                                            <ContactContainer>
                                                <ContactLabel>
                                                    {dashboards.contact}:
                                                </ContactLabel>
                                                {resident?.contact
                                                    ?.mainPhone ? (
                                                    <PhonNumberLink
                                                        href={`tel: ${resident.contact.mainPhone}`}
                                                    >
                                                        {
                                                            resident.contact
                                                                .mainPhone
                                                        }
                                                    </PhonNumberLink>
                                                ) : (
                                                    <ContactLabel>
                                                        {`${dashboards.phoneNumber} ${shared.notDefined}`}{' '}
                                                        /
                                                    </ContactLabel>
                                                )}
                                                <ContactLabel>
                                                    {potentialContactData}
                                                </ContactLabel>
                                            </ContactContainer>
                                        </>
                                    )}
                                </DataContainer>
                            </Row>
                        );
                    })}
                </>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdatePriorParentPage: (parentPage: string) =>
        dispatch(updatePriorParentPage(parentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsDialog);
