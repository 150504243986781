import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    selectedTab: number;
    value: number;
};

const TabPanel = (props: Props) => {
    const { children, selectedTab, value } = props;

    return (
        <Box component="div" role="tabpanel" hidden={selectedTab !== value}>
            {children}
        </Box>
    );
};

export default TabPanel;
