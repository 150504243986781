import { merge } from 'lodash';

import AppBar from 'components/theme/overrides/AppBar';
import Autocomplete from 'components/theme/overrides/Autocomplete';
import BottomNavigation from 'components/theme/overrides/BottomNavigation';
import Button from 'components/theme/overrides/Button';
import Dialog from 'components/theme/overrides/Dialog';
import Link from 'components/theme/overrides/Link';
import List from 'components/theme/overrides/List';
import Menu from 'components/theme/overrides/Menu';
import Pagination from 'components/theme/overrides/Pagination';
import Select from 'components/theme/overrides/Select';
import Switch from 'components/theme/overrides/Switch';
import Table from 'components/theme/overrides/Table';
import Tabs from 'components/theme/overrides/Tabs';
import TextField from 'components/theme/overrides/TextField';

export default function ComponentOverrides(theme) {
    return merge(
        AppBar(theme),
        Autocomplete(theme),
        BottomNavigation(theme),
        Button(theme),
        Dialog(),
        Link(),
        List(theme),
        Menu(theme),
        Pagination(theme),
        Select(),
        Switch(theme),
        Table(theme),
        Tabs(theme),
        TextField(theme)
    );
}
