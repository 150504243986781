import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
    viewBox?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const BadgeMostAboveAndBeyond = ({
    className,
    size,
    viewBox,
}: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            d="M20.6833 3.67836C22.6091 1.67218 25.8198 1.67218 27.7456 3.67836V3.67836C28.9436 4.92635 30.7125 5.44895 32.3961 5.05152V5.05152C35.089 4.41586 37.7811 6.184 38.3224 8.89743V8.89743C38.6617 10.5982 39.8721 12.0213 41.5042 12.6076V12.6076C44.1177 13.5466 45.4325 16.4866 44.4312 19.0767V19.0767C43.804 20.6992 44.0633 22.5552 45.1201 23.9368V23.9368C46.8141 26.1515 46.3656 29.3362 44.116 30.9837V30.9837C42.7145 32.0102 41.9522 33.7198 42.1011 35.4506V35.4506C42.3386 38.2094 40.254 40.667 37.4895 40.8238V40.8238C35.7607 40.9218 34.2042 41.9364 33.4042 43.4721V43.4721C32.1238 45.9299 29.0438 46.8549 26.6381 45.479V45.479C25.1364 44.6202 23.2924 44.6202 21.7907 45.479V45.479C19.3851 46.8549 16.305 45.9299 15.0246 43.4721V43.4721C14.2246 41.9364 12.6681 40.9218 10.9394 40.8238V40.8238C8.17481 40.667 6.09028 38.2094 6.32772 35.4506V35.4506C6.47668 33.7198 5.71434 32.0102 4.31282 30.9837V30.9837C2.0633 29.3362 1.61472 26.1515 3.30879 23.9368V23.9368C4.3656 22.5552 4.62482 20.6992 3.99764 19.0767V19.0767C2.99635 16.4866 4.31119 13.5466 6.92461 12.6076V12.6076C8.55673 12.0213 9.76717 10.5982 10.1064 8.89743V8.89743C10.6477 6.184 13.3398 4.41586 16.0327 5.05152V5.05152C17.7164 5.44895 19.4853 4.92635 20.6833 3.67836V3.67836Z"
            fill="url(#paint0_linear_7_175)"
        />
        <path
            d="M7.78369 24.4232C7.78369 15.2332 15.2337 7.7832 24.4237 7.7832V7.7832C33.6136 7.7832 41.0636 15.2332 41.0636 24.4232V24.7654C41.0636 33.9554 33.6136 41.4053 24.4237 41.4053V41.4053C15.2337 41.4053 7.78369 33.9554 7.78369 24.7654V24.4232Z"
            fill="black"
            fillOpacity="0.04"
        />
        <path
            d="M39.4543 24.4232V24.7654C39.4543 33.0666 32.7249 39.7961 24.4237 39.7961C16.1224 39.7961 9.39297 33.0666 9.39297 24.7654V24.4232C9.39297 16.1219 16.1224 9.39248 24.4237 9.39248C32.7249 9.39248 39.4543 16.1219 39.4543 24.4232Z"
            stroke="white"
            strokeOpacity="0.3"
            strokeWidth="3.21855"
        />
        <g clipPath="url(#clip0_7_175)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1578 26.8936C20.2411 27.0014 20.2782 27.1379 20.2609 27.273C20.2437 27.4081 20.1736 27.5308 20.066 27.6143C19.8158 27.8068 19.6238 28.0649 19.5112 28.3598C19.3987 28.6548 19.3699 28.9751 19.4282 29.2854C19.7385 29.3437 20.0589 29.315 20.3538 29.2024C20.6487 29.0899 20.9068 28.8979 21.0993 28.6477C21.1402 28.593 21.1915 28.5469 21.2504 28.5123C21.3092 28.4776 21.3743 28.455 21.442 28.4458C21.5097 28.4366 21.5785 28.4409 21.6445 28.4586C21.7104 28.4762 21.7722 28.5069 21.8262 28.5487C21.8802 28.5905 21.9254 28.6426 21.959 28.702C21.9926 28.7615 22.0141 28.827 22.0221 28.8948C22.0302 28.9626 22.0246 29.0314 22.0058 29.097C21.987 29.1626 21.9553 29.2239 21.9126 29.2772C21.5612 29.7329 21.0752 30.0665 20.5235 30.2304C19.9718 30.3944 19.3825 30.3804 18.8392 30.1905C18.7661 30.165 18.6997 30.1234 18.645 30.0686C18.5903 30.0139 18.5486 29.9475 18.5231 29.8744C18.3331 29.3311 18.3191 28.7418 18.483 28.1901C18.647 27.6384 18.9806 27.1524 19.4365 26.801C19.5443 26.7176 19.6809 26.6804 19.8162 26.6976C19.9515 26.7149 20.0744 26.7851 20.1578 26.8929V26.8936Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9439 26.2282C21.3931 26.8029 21.9108 27.3205 22.4854 27.7697V30.514C22.4854 30.7978 22.7158 31.0282 22.9997 31.0282C23.5329 31.0284 24.0587 30.9041 24.5355 30.6654C25.0122 30.4267 25.4267 30.0801 25.7461 29.6531C26.0654 29.2261 26.2808 28.7306 26.375 28.2058C26.4693 27.681 26.4398 27.1415 26.2891 26.6301C27.4709 25.7885 28.4292 24.6709 29.0806 23.3744C29.7319 22.078 30.0565 20.642 30.0262 19.1914C30.0236 19.0586 29.9696 18.9319 29.8757 18.838C29.7817 18.744 29.6551 18.6901 29.5222 18.6874C28.0715 18.657 26.6354 18.9816 25.3388 19.6329C24.0423 20.2843 22.9245 21.2426 22.0829 22.4246C21.5715 22.2739 21.0321 22.2446 20.5074 22.3389C19.9827 22.4332 19.4872 22.6486 19.0603 22.9679C18.6334 23.2872 18.2869 23.7017 18.0482 24.1784C17.8095 24.6551 17.6853 25.1809 17.6854 25.714C17.6861 25.9978 17.9165 26.2282 18.2004 26.2282H20.9439ZM25.914 24.1711C26.2777 24.1711 26.6265 24.0266 26.8837 23.7694C27.1409 23.5122 27.2854 23.1634 27.2854 22.7997C27.2854 22.4359 27.1409 22.0871 26.8837 21.8299C26.6265 21.5727 26.2777 21.4282 25.914 21.4282C25.5503 21.4282 25.2014 21.5727 24.9442 21.8299C24.687 22.0871 24.5426 22.4359 24.5426 22.7997C24.5426 23.1634 24.687 23.5122 24.9442 23.7694C25.2014 24.0266 25.5503 24.1711 25.914 24.1711Z"
                fill="white"
            />
        </g>
        <path
            d="M41.1108 24.621C41.1108 33.915 33.6523 41.4492 24.4519 41.4492C15.2515 41.4492 7.79311 33.915 7.79311 24.621C7.79311 15.327 15.2515 7.79276 24.4519 7.79276C33.6523 7.79276 41.1108 15.327 41.1108 24.621ZM11.268 24.621C11.268 31.9763 17.1707 37.939 24.4519 37.939C31.7332 37.939 37.6358 31.9763 37.6358 24.621C37.6358 17.2657 31.7332 11.303 24.4519 11.303C17.1707 11.303 11.268 17.2657 11.268 24.621Z"
            fill="url(#paint1_linear_7_175)"
            fillOpacity="0.5"
        />
        <defs>
            <linearGradient
                id="paint0_linear_7_175"
                x1="8.64252"
                y1="4.49478"
                x2="34.9703"
                y2="45.9931"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B5D3D6" />
                <stop offset="1" stopColor="#0CA3B1" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_7_175"
                x1="24.4519"
                y1="7.79276"
                x2="24.4519"
                y2="41.4492"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="white" />
                <stop offset="0.0001" stopColor="white" stopOpacity="0.81" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <clipPath id="clip0_7_175">
                <rect
                    width="13.7143"
                    height="13.7143"
                    fill="white"
                    transform="translate(17 18)"
                />
            </clipPath>
        </defs>
    </Base>
);
