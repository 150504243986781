import { Avatar, Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';

import celebrateEmoji from 'assets/celebrate_emoji.png';
import noProfilePhoto from 'assets/no_profile_photo.png';
import { pxToRem } from 'components/theme/typography';
import { RESIDENTS_PHOTO_PATH } from 'constants/app';
import {
    AVATAR_CONTAINER_WIDTH,
    AVATAR_WIDTH,
    TASKS_DONE_AVATAR_WIDTH,
} from 'constants/residents';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pr: pxToRem(16),
        position: 'relative',
        width: pxToRem(AVATAR_CONTAINER_WIDTH),
        '& .MuiBadge-root.BaseBadge-root': {
            position: 'absolute',
            top: pxToRem(8),
            right: pxToRem(24),
            '& .MuiBadge-badge.MuiBadge-colorSecondary': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.main,
            },
        },
    })
);

const ResidentAvatar = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        backgroundColor: theme.palette.common.white,
        width: pxToRem(AVATAR_WIDTH),
        height: pxToRem(AVATAR_WIDTH),
    })
);

const TasksDoneAvatar = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(7),
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        right: pxToRem(7),
        width: pxToRem(TASKS_DONE_AVATAR_WIDTH),
        height: pxToRem(TASKS_DONE_AVATAR_WIDTH),
        boxShadow: `0 ${pxToRem(4)} ${pxToRem(4)} ${alpha(
            theme.palette.grey[300],
            0.12
        )}}`,
    })
);

type Props = {
    residentName: string;
    residentPhoto: string | null;
    totalTasks: number;
    totalTasksDone: number;
};

const ResidentPhotoContainer = (props: Props) => {
    const { residentName, residentPhoto, totalTasks, totalTasksDone } = props;
    const photo = residentPhoto
        ? `${RESIDENTS_PHOTO_PATH}/${residentPhoto}`
        : noProfilePhoto;

    return (
        <RootStyle>
            <ResidentAvatar alt={residentName} src={photo} />
            {totalTasks === totalTasksDone && totalTasks > 0 && (
                <TasksDoneAvatar alt="Task Done" src={celebrateEmoji} />
            )}
        </RootStyle>
    );
};

export default ResidentPhotoContainer;
