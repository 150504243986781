import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import watch from 'redux-watch';

import { CaregiverAppFilters } from 'types/filters';

import { CAREGIVER_FILTERS } from 'constants/localStorage';
import History from 'helpers/History';
import reducers from 'redux/reducers';

const logger = createLogger({ collapsed: true });
const middlewares = [routerMiddleware(History), ReduxThunk, logger];

const storedCargiverFilters: CaregiverAppFilters = JSON.parse(
    localStorage.getItem(CAREGIVER_FILTERS) as string
);
console.log(storedCargiverFilters);
const store = createStore(
    reducers,
    storedCargiverFilters
        ? { filters: { caregiverApp: storedCargiverFilters } }
        : {},
    composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares))
);

const zoneWatcher = watch(store.getState, 'filters.caregiverApp');
store.subscribe(
    zoneWatcher((caregiverAppFilters: CaregiverAppFilters) => {
        localStorage.setItem(
            CAREGIVER_FILTERS,
            JSON.stringify(caregiverAppFilters)
        );
    })
);

export default store;
