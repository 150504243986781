import { api } from 'api';

import { DashboardReadParams, ResidentWeightResponse } from 'types/dashboard';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, getToken } from 'lib/common';

export const useResidentWeightQuery = (jsonParams: DashboardReadParams) => {
    const params = formatApiParams(jsonParams);
    return useErrorHandledQuery(
        ['resident-weight', jsonParams],
        async (): Promise<ResidentWeightResponse> =>
            (
                await api.get('/dashboard/resident-weights', {
                    headers: {
                        authorization: getToken(),
                    },
                    params,
                })
            ).data.response,
        { staleTime: 300000 }
    );
};
