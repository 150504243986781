import { api } from 'api';

import { ShiftNotesCategoryResponse } from 'types/ShiftNotesCategories';

import { getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { throwError } from 'redux/actions/messages';
import 'redux/reducers/shiftNotesCategories';

/**
 * Fetches the list of Report Categories.
 * @param jsonParams the JSON object with the params for fetching the Report Categories list.
 */
export const readShiftNotesCategories =
    (actionType = actionTypes.SHIFT_NOTES_CATEGORY_READ_LIST) =>
    async (dispatch) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert =
                actionType === actionTypes.SHIFT_NOTES_CATEGORY_READ_LIST;

            // Enable the loading process.
            dispatch(
                loading(
                    actionTypes.SHIFT_NOTES_CATEGORY_LOADING,
                    true,
                    shouldHideAlert
                )
            );

            // Get the token.
            const token = getToken();

            // Fetch the Report Categories List.
            const shiftNotesCategoriesList: ShiftNotesCategoryResponse[] = (
                await api.get('/shift-notes-category', {
                    headers: {
                        authorization: token,
                    },
                })
            ).data.response;

            dispatch({
                type: actionType,
                payload: shiftNotesCategoriesList,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SHIFT_NOTES_CATEGORY_LOADING, false));
        }
    };
