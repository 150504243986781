export const ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];

// eslint-disable-next-line no-shadow
export const enum ALIGNMENT_TYPES {
    center = 'center',
    inherit = 'inherit',
    justify = 'justify',
    left = 'left',
    right = 'right',
}

// eslint-disable-next-line no-shadow
export const enum SORT_DIRECTIONS {
    asc = 'asc',
    desc = 'desc',
}
