import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import CustomSimpleDialog from 'components/Custom/CustomSimpleDialog';
import { pxToRem } from 'components/theme/typography';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    points?: number;
};

const CheckedInModal = ({ isOpen, onClose, points }: Props) => (
    <CustomSimpleDialog
        subtitle={
            <Typography
                sx={{
                    fontSize: { xs: pxToRem(24), lg: pxToRem(32) },
                    fontWeight: 700,
                    lineHeight: 1.16,
                    color: 'primary.dark',
                    backgroundColor: 'grey.200',
                    borderRadius: '50%',
                    width: pxToRem(112),
                    height: pxToRem(112),
                    display: 'grid',
                    placeContent: 'center',
                    mx: 'auto',
                    mt: pxToRem(-24),
                }}
            >
                {points}
            </Typography>
        }
        content={
            <Box
                sx={{
                    maxWidth: { xs: pxToRem(279), lg: pxToRem(400) },
                }}
            >
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: { xs: pxToRem(20) },
                        lineHeight: 1.6,
                        fontWeight: 700,
                        color: '#2C2D3B',
                        mb: pxToRem(8),
                    }}
                >
                    {points} points earned!
                </Typography>
                <Typography
                    sx={{
                        textAlign: 'center',
                        color: '#6F6F79',
                    }}
                >
                    Thank you for documenting your great work. You are AMAZING.{' '}
                    <Box
                        component="span"
                        sx={{
                            display: { xs: 'block', lg: 'inline' },
                        }}
                    >
                        We appreciate you.
                    </Box>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    fullWidth
                    sx={{
                        mt: pxToRem(24),
                        p: pxToRem(12),
                        fontSize: pxToRem(14),
                    }}
                >
                    Done
                </Button>
            </Box>
        }
        open={isOpen}
        onClose={onClose}
        closeable
    />
);

export default CheckedInModal;
