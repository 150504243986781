import { Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { DashboardNotesResponse } from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { CheckedRow, Column, Row, SortDirection } from 'types/table';

import { CustomTable } from 'components/Custom';
import { DASHBOARD_NOTES_COLUMNS } from 'constants/dashboards';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/table';
import { formatNotesColumns, formatNotesRows } from 'lib/dashboard';
import { SortRows } from 'lib/table';
import CardBaseStructure from 'pages/Dashboards/components/CardBaseStructure';

type Props = {
    dictionary: LanguageStructure;
    notes: DashboardNotesResponse[];
    timezone: string;
};

const NotesCard = (props: Props) => {
    const {
        dictionary: { dashboards },
        notes = [],
        timezone,
    } = props;
    const [columns, setColumns] = useState<Column[]>([]);
    const [rows, setRows] = useState<Row[]>([]);
    const [page, setPage] = useState<number>();
    const [rowsPerPage, setRowsPerPage] = useState<number>();
    const [rowsToShow, setRowsToShow] = useState<Row[]>([]);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortDirection, setSortDirection] = useState<
        SortDirection | undefined
    >();
    const [rowsCheckedStatus, setRowsCheckedStatus] = useState<CheckedRow>({});
    const initialPage = 1;

    const updateRowsToShow = (
        newRows: Row[],
        newPage: number,
        newRowsPerPage: number
    ) => {
        const newPageValue = newPage - 1;
        const sliceStartIndex = newPageValue * newRowsPerPage;
        const sliceEndIndex = (newPageValue + 1) * newRowsPerPage;
        const newRowsToShow = newRows.slice(sliceStartIndex, sliceEndIndex);
        setRowsToShow(newRowsToShow);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setPage(initialPage);
        setRowsPerPage(newRowsPerPage);
    };

    const handleSortChange = (
        newSortBy?: string,
        newSortDirection?: SortDirection
    ) => {
        const newRows = SortRows(rows, newSortBy, newSortDirection);
        setSortBy(newSortBy);
        setSortDirection(newSortDirection);

        if (!isUndefined(page) && !isUndefined(rowsPerPage)) {
            updateRowsToShow(newRows, page, rowsPerPage);
        }
    };

    useEffect(() => {
        setPage(initialPage);
        setRowsPerPage(ROWS_PER_PAGE_OPTIONS[0]);
    }, []);

    useEffect(() => {
        setColumns(
            formatNotesColumns(DASHBOARD_NOTES_COLUMNS, timezone, dashboards)
        );
        setRows(formatNotesRows(notes));
    }, [notes]);

    useEffect(() => {
        if (
            rows !== undefined &&
            !isUndefined(page) &&
            !isUndefined(rowsPerPage)
        ) {
            updateRowsToShow(rows, page, rowsPerPage);
        }
    }, [page, rows, rowsPerPage]);

    return (
        <CardBaseStructure cardType="notes" title={dashboards.notes}>
            {rowsToShow.length > 0 ? (
                <CustomTable
                    rows={rowsToShow}
                    columns={columns}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    totalRows={rows.length}
                    usePagination
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSortChange={handleSortChange}
                    rowsCheckedStatus={rowsCheckedStatus}
                    setRowsCheckedStatus={setRowsCheckedStatus}
                />
            ) : (
                <Typography variant="body2">
                    There are no registered notes for the provided date range.
                </Typography>
            )}
        </CardBaseStructure>
    );
};

const mapStateToProps = ({ language, dashboards, session }: ReduxStore) => {
    const { notes } = dashboards;
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        notes,
        timezone,
    };
};

export default connect(mapStateToProps)(NotesCard);
