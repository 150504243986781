import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { omit } from 'lodash';
import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';

import { DashboardKeyServicesResponse } from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomDialog } from 'components/Custom';
import TabPanel from 'components/Shared/TabPanel';
import { pxToRem } from 'components/theme/typography';
import KeyServicesTabContent from 'pages/Dashboards/components/KeyServicesTabContent';

const TabContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
    })
);

const TabStyle = styled(Tab)(({ theme }) =>
    theme.unstable_sx({
        width: '50%',
    })
);

type Props = {
    data: DashboardKeyServicesResponse;
    dictionary: LanguageStructure;
    open: boolean;
    timezone: string;
    title: string;
    onClose: () => void;
};

const KeyServicesDialog = (props: Props) => {
    const {
        data,
        dictionary: { dashboards },
        open,
        timezone,
        title,
        onClose,
    } = props;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const newData = omit(data, ['totalKeyServices']);
    const totalMissed =
        data.missed.mealTotals +
        data.missed.showerTotals +
        data.missed.toiletTotals;
    const totalRefused =
        data.refused.mealTotals +
        data.refused.showerTotals +
        data.refused.toiletTotals;
    const dialogData = Object.keys(newData).map(
        (serviceCode) => data[serviceCode].data
    );

    const handleTabChange = (event: SyntheticEvent, newTabIndex: number) => {
        setTabIndex(newTabIndex);
    };

    return (
        <CustomDialog
            closeable
            open={open}
            title={title}
            width={pxToRem(600)}
            maxHeight={`calc(100vh - ${pxToRem(160)})`}
            showDividers
            content={
                <>
                    <TabContainer>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <TabStyle
                                id="missed"
                                label={`${dashboards.missedTab} (${totalMissed})`}
                            />
                            <TabStyle
                                id="refused"
                                label={`${dashboards.refusedTab} (${totalRefused})`}
                            />
                        </Tabs>
                    </TabContainer>
                    <TabPanel value={0} selectedTab={tabIndex}>
                        <KeyServicesTabContent serviceData={dialogData[0]} />
                    </TabPanel>
                    <TabPanel value={1} selectedTab={tabIndex}>
                        <KeyServicesTabContent serviceData={dialogData[1]} />
                    </TabPanel>
                </>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        timezone,
    };
};

export default connect(mapStateToProps)(KeyServicesDialog);
