import { pxToRem } from 'components/theme/typography';

export default function Dialog() {
    return {
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root.MuiDialog-paper': {
                            margin: pxToRem(16),
                            '& .MuiTypography-root.MuiDialogTitle-root': {
                                padding: pxToRem(16),
                            },
                            '& .MuiDialogContent-root': {
                                padding: pxToRem(16),
                                paddingTop: 0,
                            },
                        },
                    },
                },
            },
        },
    };
}
