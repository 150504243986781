import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    onToggleRejectDialog: () => void;
    onConfirmTask: () => void;
};

export const TaskOptions = (props: Props) => {
    const { onToggleRejectDialog, onConfirmTask } = props;

    const styles = {
        height: pxToRem(54),
        width: pxToRem(54),
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CheckCircleOutlined sx={styles} onClick={onConfirmTask} />
            <CancelOutlined sx={styles} onClick={onToggleRejectDialog} />
        </Box>
    );
};
