import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';

const RootStyle = styled(Card)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
    })
);

type Props = {
    dictionary: LanguageStructure;
    hideCard?: boolean;
    useFilters?: boolean;
};

const NoData = (props: Props) => {
    const {
        dictionary: { shared },
        hideCard,
        useFilters,
    } = props;
    const cardStyle = hideCard ? { boxShadow: 'none' } : {};

    return (
        <RootStyle sx={cardStyle}>
            <Typography variant="body2">
                {useFilters ? shared.noDataForFilters : shared.noData}
            </Typography>
        </RootStyle>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

export default connect(mapStateToProps)(NoData);
