import { Box, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

import {
    TYPE_COLOR,
    TYPE_COMPLETED,
    TYPE_OTHER_INCOMPLETE,
    TYPE_REFUSED,
    TYPE_STR,
    TYPE_UNDOCUMENTED,
} from './constants';

export const ChartSegment = ({
    percent,
    count,
    type,
    widthThreshold,
    showPercent = false,
}) => {
    let value = null;
    if (percent > widthThreshold) {
        value = showPercent ? `${Math.round(percent * 100)}%` : count;
    }

    return (
        <Tooltip
            title={`${TYPE_STR[type]} ${+(percent * 100).toFixed(
                1
            )}% (${count})`}
            placement="bottom"
            arrow
        >
            <Box
                sx={{
                    backgroundColor: TYPE_COLOR[type],
                    color: '#fff',
                    width: percent,
                    fontSize: pxToRem(12),
                    padding: pxToRem(4),
                    textAlign: 'center',
                    '&:first-of-type': {
                        borderTopLeftRadius: pxToRem(6),
                        borderBottomLeftRadius: pxToRem(6),
                    },
                    '&:last-of-type': {
                        borderTopRightRadius: pxToRem(6),
                        borderBottomRightRadius: pxToRem(6),
                    },
                }}
            >
                {value}
            </Box>
        </Tooltip>
    );
};

export const ChartLegendItem = ({ type, text }) => (
    <Box
        sx={{
            display: 'flex',
            gap: pxToRem(4),
            alignItems: 'center',
        }}
    >
        <Box
            sx={{
                borderRadius: pxToRem(8),
                minWidth: pxToRem(8),
                height: pxToRem(8),
                backgroundColor: TYPE_COLOR[type],
            }}
        />
        <Box
            sx={{
                color: '#85858E',
                textAlign: 'left',
                fontSize: pxToRem(12),
            }}
        >
            {text}
        </Box>
    </Box>
);

export const Chart = ({
    completed,
    refused,
    otherIncomplete,
    undocumented,
    showLegend = true,
    widthThreshold = 0.08,
    showPercent = false,
}) => {
    const isMobile = useMediaQuery('(max-width:500px)');

    const total = completed + refused + otherIncomplete + undocumented;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: pxToRem(12),
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {completed > 0 && (
                    <ChartSegment
                        percent={completed / total}
                        count={completed}
                        type={TYPE_COMPLETED}
                        widthThreshold={widthThreshold}
                        showPercent={showPercent}
                    />
                )}
                {otherIncomplete > 0 && (
                    <ChartSegment
                        percent={otherIncomplete / total}
                        count={otherIncomplete}
                        type={TYPE_OTHER_INCOMPLETE}
                        widthThreshold={widthThreshold}
                        showPercent={showPercent}
                    />
                )}
                {refused > 0 && (
                    <ChartSegment
                        percent={refused / total}
                        count={refused}
                        type={TYPE_REFUSED}
                        widthThreshold={widthThreshold}
                        showPercent={showPercent}
                    />
                )}
                {undocumented > 0 && (
                    <ChartSegment
                        percent={undocumented / total}
                        count={undocumented}
                        type={TYPE_UNDOCUMENTED}
                        widthThreshold={widthThreshold}
                        showPercent={showPercent}
                    />
                )}
            </Box>
            {showLegend && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: pxToRem(12),
                    }}
                >
                    <ChartLegendItem
                        type={TYPE_COMPLETED}
                        text={`Completed ${+((completed / total) * 100).toFixed(
                            1
                        )}% (${completed})`}
                    />
                    <ChartLegendItem
                        type={TYPE_REFUSED}
                        text={`Refused ${+((refused / total) * 100).toFixed(
                            1
                        )}% (${refused})`}
                    />
                    <ChartLegendItem
                        type={TYPE_OTHER_INCOMPLETE}
                        text={`Other Incomplete ${+(
                            (otherIncomplete / total) *
                            100
                        ).toFixed(1)}% (${otherIncomplete})`}
                    />
                    <ChartLegendItem
                        type={TYPE_UNDOCUMENTED}
                        text={`Undocumented ${+(
                            (undocumented / total) *
                            100
                        ).toFixed(1)}% (${undocumented})`}
                    />
                </Box>
            )}
        </Box>
    );
};
