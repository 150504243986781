import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const UserInnerCancelIcon = ({ className, size }: Props) => (
    <Base className={className} size={size}>
        <path
            d="M12.835 2c-2.62 0-4.75 2.13-4.75 4.75 0 2.57 2.01 4.65 4.63 4.74.08-.01.16-.01.22 0h.07a4.738 4.738 0 0 0 4.58-4.74c0-2.62-2.13-4.75-4.75-4.75ZM17.915 14.15c-2.79-1.86-7.34-1.86-10.15 0-1.27.85-1.97 2-1.97 3.23s.7 2.37 1.96 3.21c1.4.94 3.24 1.41 5.08 1.41 1.84 0 3.68-.47 5.08-1.41 1.26-.85 1.96-1.99 1.96-3.23-.01-1.23-.7-2.37-1.96-3.21Zm-3.14 4.11c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-.88-.88-.88.88c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l.88-.88-.88-.88a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l.88.88.88-.88c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-.88.88.88.88Z"
            fill="currentColor"
        />
    </Base>
);
