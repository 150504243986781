import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
    viewBox?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const TrashIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            fill="currentColor"
            d="M32.105 7.845c-2.415-.24-4.83-.42-7.26-.555v-.015l-.33-1.95c-.225-1.38-.555-3.45-4.065-3.45h-3.93c-3.495 0-3.825 1.98-4.065 3.435l-.315 1.92c-1.395.09-2.79.18-4.185.315l-3.06.3c-.63.06-1.08.615-1.02 1.23.06.615.6 1.065 1.23 1.005l3.06-.3c7.86-.78 15.78-.48 23.73.315h.12c.57 0 1.065-.435 1.125-1.02a1.15 1.15 0 0 0-1.035-1.23ZM29.345 12.21a1.896 1.896 0 0 0-1.365-.585H9.02c-.51 0-1.02.21-1.365.585s-.54.885-.51 1.41l.93 15.39c.165 2.28.375 5.13 5.61 5.13h9.63c5.235 0 5.445-2.835 5.61-5.13l.93-15.375c.03-.54-.165-1.05-.51-1.425ZM20.99 26.625h-4.995c-.615 0-1.125-.51-1.125-1.125s.51-1.125 1.125-1.125h4.995c.615 0 1.125.51 1.125 1.125s-.51 1.125-1.125 1.125Zm1.26-6h-7.5c-.615 0-1.125-.51-1.125-1.125s.51-1.125 1.125-1.125h7.5c.615 0 1.125.51 1.125 1.125s-.51 1.125-1.125 1.125Z"
        />
    </Base>
);
