import { Box } from '@mui/material';
import React from 'react';

import { useRewardsDetailsQuery } from 'api/queries/caregiverRewards';
import { pxToRem } from 'components/theme/typography';

import ThanksItem from './ThanksItem';

type Props = {
    userId: number;
};

const ThanksSection = ({ userId }: Props) => {
    const {
        isLoading: rewardsDetailsIsLoading,
        isError: rewardsDetailsIsError,
        data: rewardsDetailsData,
        error: rewardsDetailsError,
        refetch: rewardsDetailsRefetch,
    } = useRewardsDetailsQuery(userId);

    if (rewardsDetailsIsLoading || !rewardsDetailsData) {
        return null;
    }

    if (rewardsDetailsData.shiftType === 'NEVER') {
        return null;
    }

    return (
        <Box
            sx={{
                marginTop: {
                    xs: pxToRem(24),
                    lg: pxToRem(32),
                },
                marginRight: pxToRem(10),
                marginLeft: pxToRem(10),
                marginBottom: pxToRem(24),
            }}
        >
            <Box
                sx={{
                    marginBottom: pxToRem(16),
                    marginTop: pxToRem(10),
                    color: '#2C2D3B',
                    fontWeight: 700,
                    fontSize: pxToRem(14),
                }}
            >
                Thanks from the community
            </Box>
            <Box
                sx={{
                    gap: pxToRem(12),
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {rewardsDetailsData.unscheduledTasksData.unscheduledTasksCount >
                    0 && (
                    <ThanksItem
                        thanksType={'UNSCHEDULED_TASKS'}
                        contentValues={{
                            residentsCount:
                                rewardsDetailsData.unscheduledTasksData
                                    .residentsCount,
                            unscheduledTasksCount:
                                rewardsDetailsData.unscheduledTasksData
                                    .unscheduledTasksCount,
                            isThisShift:
                                rewardsDetailsData.shiftType === 'CURRENT',
                        }}
                    />
                )}
                {rewardsDetailsData.residentsServed > 0 && (
                    <ThanksItem
                        thanksType={'RESIDENTS'}
                        contentValues={{
                            residentsCount: rewardsDetailsData.residentsServed,
                        }}
                    />
                )}
                {rewardsDetailsData.residentNotesData.residentNotesCount >
                    0 && (
                    <ThanksItem
                        thanksType={'NOTES'}
                        contentValues={{
                            residentsCount:
                                rewardsDetailsData.residentNotesData
                                    .residentsCount,
                            notesCount:
                                rewardsDetailsData.residentNotesData
                                    .residentNotesCount,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ThanksSection;
