import { ExpandCircleDown as ExpandCircleDownIcon } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import momentTz from 'moment-timezone';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import {
    ResidentShiftNotesCreateUpdateParams,
    ResidentShiftNotesReadParams,
    ResidentShiftNotesResponse,
} from 'types/residentShiftNotes';
import { StoredSession } from 'types/session';

import DeleteConfirmationDialog from 'components/Shared/DeleteConfirmationDialog';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import { getPeriodOfTwoPreviousShifts } from 'lib/dailyTaskRecords';
import ShiftNoteCard from 'pages/Residents/Details/components/ShiftNoteCard';
import ShiftNoteDialog from 'pages/Residents/Details/components/ShiftNoteDialog';
import {
    deleteResidentShiftNote,
    readResidentShiftNotes,
    updateResidentShiftNote,
} from 'redux/actions/residentShiftNotes';

const useStyles = makeStyles({
    collapsed: {
        height: 0,
    },
    collapseIcon: {
        transform: 'rotate(180deg)',
    },
});

const TitleStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
    })
) as typeof Typography;

const SectionStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        transition: 'height 0.15s ease-out',
        overflow: 'hidden',
    })
);

const ExpandCollapseIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'relative',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    })
);

const ExpandCollapseIcon = styled(ExpandCircleDownIcon)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(6),
        color: theme.palette.app.green.main,
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        fontSize: pxToRem(32),
        position: 'absolute',
        top: pxToRem(-32),
        boxShadow: `0 0 ${pxToRem(8)} ${alpha(theme.palette.grey[500], 0.5)}`,
    })
);

const DividerStyle = styled(Divider)(({ theme }) =>
    theme.unstable_sx({
        my: pxToRem(16),
    })
);

type Props = {
    dictionary: LanguageStructure;
    residentId: number;
    residentShiftNotesList: ResidentShiftNotesResponse[];
    timezone: string;
    dispatchReadResidentShiftNotes: (
        params: ResidentShiftNotesReadParams
    ) => void;
    dispatchUpdateResidentShiftNotes: (
        residentShiftNoteId: number,
        params: ResidentShiftNotesCreateUpdateParams
    ) => void;
    dispatchDeleteResidentShiftNote: (residentShiftNoteId: number) => void;
};

const ShiftNotes = (props: Props) => {
    const classes = useStyles();
    const {
        dictionary: { residents: residentsDictionary, shared },
        residentId,
        residentShiftNotesList,
        timezone,
        dispatchReadResidentShiftNotes,
        dispatchUpdateResidentShiftNotes,
        dispatchDeleteResidentShiftNote,
    } = props;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] =
        useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [currentShiftNoteId, setCurrentShiftNoteId] = useState<number>(0);
    const [currentReport, setCurrentReport] = useState<string>('');
    const [currentShiftNoteCategoryId, setCurrentShiftNoteCategoryId] =
        useState<number>(0);

    // Get the user's ID and his/her Role Level ID.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { userId = 0, roleLevelId = 0 } = storedSession?.sessionData || {};
    const isManager = roleLevelId === 4;
    const isCaregiver = roleLevelId === 5;
    const isAllowed = isManager || isCaregiver;

    const updateShiftNotesList = async () => {
        const { startPeriod, endPeriod } =
            getPeriodOfTwoPreviousShifts(timezone);

        // Define the params for fething the Resident's Shift Notes.
        const shiftNotesParams = {
            residentId: Number(residentId),
            startPeriod,
            endPeriod,
        };
        await dispatchReadResidentShiftNotes(shiftNotesParams);
    };

    const getShiftNoteData = (shiftNote: ResidentShiftNotesResponse) => {
        const {
            residentShiftNoteId: key,
            caregiver,
            shiftNoteCategoryId,
            shiftNoteCategory,
            report,
            userId: caregiverId,
            reportedAtUtc,
        } = shiftNote;
        const elapsedTimeLabel = momentTz.utc(reportedAtUtc).fromNow();

        return {
            caregiver,
            caregiverId,
            categoryId: shiftNoteCategoryId,
            category: shiftNoteCategory,
            elapsedTimeLabel,
            key,
            report,
        };
    };

    const handleCollapsibleSectionToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleDialogClose = () => {
        setIsShiftNoteDialogOpen(false);
        setIsDeleteDialogOpen(false);
    };

    const handleEdit =
        (shiftNoteId: number, categoryId: number, reportContent: string) =>
        () => {
            // If the user is not allowed, exit the function.
            if (!isAllowed) {
                return;
            }

            setCurrentShiftNoteId(shiftNoteId);
            setCurrentShiftNoteCategoryId(categoryId);
            setCurrentReport(reportContent);
            setIsShiftNoteDialogOpen(true);
        };

    const handleShiftNoteSubmit = async (
        report: string,
        newShiftNoteCategory: number
    ) => {
        // Define the params to submit into the API.
        const params: ResidentShiftNotesCreateUpdateParams = {
            residentId,
            userId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
            dateTime: momentTz
                .tz(new Date(), timezone)
                .format('YYYY-MM-DD HH:mm:ss'),
        };

        // Trigger the action for Updating the Resident's Shift Note.
        await dispatchUpdateResidentShiftNotes(currentShiftNoteId, params);

        // Updates the Resident's Shift Notes.
        updateShiftNotesList();
    };

    const handleDelete = (shiftNoteId: number) => () => {
        // If the user is not allowed, exit the function.
        if (!isAllowed) {
            return;
        }

        setCurrentShiftNoteId(shiftNoteId);
        setIsDeleteDialogOpen(true);
    };

    const handleShiftNoteDelete = async () => {
        // Trigger the action for Deleting the Resident's Shift Note.
        await dispatchDeleteResidentShiftNote(currentShiftNoteId);

        // Updates the Resident's Shift Notes.
        updateShiftNotesList();
    };

    if (!residentShiftNotesList?.length) {
        return null;
    }

    const complementaryShiftNotes = cloneDeep(residentShiftNotesList);
    const firstShiftNote = complementaryShiftNotes.shift();
    const firstShiftNoteData = getShiftNoteData(
        firstShiftNote as ResidentShiftNotesResponse
    );

    return (
        <>
            <TitleStyle variant="h2">
                {residentsDictionary.shiftNotesTitle}
            </TitleStyle>
            <SectionStyle>
                <ShiftNoteCard
                    caregiver={firstShiftNoteData.caregiver}
                    caregiverId={firstShiftNoteData.caregiverId}
                    category={firstShiftNoteData.category}
                    elapsedTimeLabel={firstShiftNoteData.elapsedTimeLabel}
                    report={firstShiftNoteData.report}
                    onEdit={handleEdit(
                        firstShiftNoteData.key,
                        firstShiftNoteData.categoryId,
                        firstShiftNoteData.report
                    )}
                    onDelete={handleDelete(firstShiftNoteData.key)}
                />
            </SectionStyle>
            <SectionStyle className={isCollapsed ? classes.collapsed : ''}>
                {complementaryShiftNotes.map((shiftNote) => {
                    const {
                        caregiver,
                        caregiverId,
                        category,
                        categoryId,
                        elapsedTimeLabel,
                        key,
                        report,
                    } = getShiftNoteData(shiftNote);

                    return (
                        <ShiftNoteCard
                            key={key}
                            caregiver={caregiver}
                            caregiverId={caregiverId}
                            category={category}
                            elapsedTimeLabel={elapsedTimeLabel}
                            report={report}
                            onEdit={handleEdit(key, categoryId, report)}
                            onDelete={handleDelete(key)}
                        />
                    );
                })}
            </SectionStyle>
            {residentShiftNotesList.length > 1 && (
                <ExpandCollapseIconContainer>
                    <ExpandCollapseIcon
                        className={isCollapsed ? '' : classes.collapseIcon}
                        onClick={handleCollapsibleSectionToggle}
                    />
                </ExpandCollapseIconContainer>
            )}
            <DividerStyle />
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                reportContent={currentReport}
                shiftNoteCategoryId={currentShiftNoteCategoryId.toString()}
                onClose={handleDialogClose}
                onSubmit={handleShiftNoteSubmit}
            />
            <DeleteConfirmationDialog
                content={residentsDictionary.ShiftNotesDelete}
                isOpen={isDeleteDialogOpen}
                onClose={handleDialogClose}
                onDelete={handleShiftNoteDelete}
            />
        </>
    );
};

const mapStateToProps = (
    { language, residentShiftNotes, session }: ReduxStore
) => {
    const { dictionary } = language;
    const { residentShiftNotesList } = residentShiftNotes;
    const { timezone } = session;

    return {
        dictionary,
        residentShiftNotesList,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadResidentShiftNotes: (params: ResidentShiftNotesReadParams) =>
        dispatch(readResidentShiftNotes(params)),
    dispatchUpdateResidentShiftNotes: (
        residentShiftNoteId: number,
        params: ResidentShiftNotesCreateUpdateParams
    ) => dispatch(updateResidentShiftNote(residentShiftNoteId, params)),
    dispatchDeleteResidentShiftNote: (residentShiftNoteId: number) =>
        dispatch(deleteResidentShiftNote(residentShiftNoteId)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedShiftNotes: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftNotes);

export default ConnectedShiftNotes;
