import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import { DASHBOARD_CARD_TITLE_HEIGHT } from 'constants/dashboards';

export const CardStyle = styled(Card)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: theme.shape.borderRadiusMd,
        width: '100%',
        boxShadow: 'none',
    })
);

export const Subtitle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(11) },
    })
);

export const ResidentData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
    })
);

export const ResidentNameLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'bold',
        color: theme.palette.common.black,
        textAlign: 'left',
    })
);

export const RoomLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(12) },
        color: theme.palette.app.orange.main,
    })
);

export const CardFooter = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'absolute',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        bottom: 0,
        width: '100%',
        height: pxToRem(100),
        backgroundImage: `linear-gradient(
      ${alpha(theme.palette.common.white, 0.8)},
      ${theme.palette.common.white}
    )`,
    })
);

export const ActionLabelContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(24),
        pt: pxToRem(8),
        pb: pxToRem(24),
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        width: '100%',
    })
);

export const ActionLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(11) },
        textDecoration: 'underline',
        cursor: 'pointer',
    })
);

export const SeeDetails = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        color: theme.palette.app.green.main,
        fontSize: { lg: pxToRem(11) },
        fontWeight: 'bold',
        width: pxToRem(88),
    })
);

export const SeeDetailsIcon = styled(ArrowForwardIcon)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        fontSize: pxToRem(14),
    })
);
