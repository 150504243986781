import { Box, Typography } from '@mui/material';
import { parse } from 'date-fns';
import { camelCase } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ResidentDashboardHeader } from './components/ResidentDashboardHeader';
import { ResidentDashboardModule } from './components/ResidentDashboardModule';
import { AssistLevelRowContent } from './components/ResidentDashboardModule/AssistLevelRowContent';
import { DefaultRowContent } from './components/ResidentDashboardModule/DefaultRowContent';
import { FallRowContent } from './components/ResidentDashboardModule/FallRowContent';
import { MedAndInfectionRowContent } from './components/ResidentDashboardModule/MedAndInfectionRowContent';
import { NotesModule } from './components/ResidentDashboardModule/NotesModule';
import { SeeAllButton } from './components/ResidentDashboardModule/SeeAllButton';
import TasksRow from './components/ResidentDashboardModule/TasksRow';
import { VisitRowContent } from './components/ResidentDashboardModule/VisitRowContent';
import { WeightRowContent } from './components/ResidentDashboardModule/WeightRowContent';
import TasksChart from './components/TasksChart';
import { TasksDialog } from './components/TasksDialog';
import TimelineChart from './components/TimelineChart';
import WeightChart from './components/WeightChart';
import { useResidentDashboardDetailsQuery } from 'api/queries/dashboard/residentDashboardDetails';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

import {
    ASSESSMENT_MODULES,
    ASSIST_CHANGES_ID,
    EATING_ID,
    FALL_ID,
    HIGHEST_MODULE_ORDER,
    INCONTINENCE_CARE_ID,
    INFECTION_POTENTIAL_UTI_ID,
    MED_CHANGES_ID,
    NOTES_MODULES,
    PRN_TASKS_ID,
    REFUSED_TASKS_ID,
    RESIDENT_NOTES_ID,
    SHOWER_ID,
    TASKS_MODULES,
    TASK_NOTES_ID,
    VISIT_ID,
    WEIGHT_LOSS_ID,
} from './constants';
import {
    calculateKeyServicesCount,
    createGroupedPRNTasks,
    createRefusedTasksPerKeyService,
    createTasksPerDate,
} from './helpers';

export const Resident = () => {
    const { residentId } = useParams() || {};

    const {
        isLoading: residentDetailsIsLoading,
        isError: residentDetailsIsError,
        data: residentDetailsData,
    } = useResidentDashboardDetailsQuery(
        residentId ? parseInt(residentId, 10) : null
    );

    const [isTasksDialogOpen, setIsTasksDialogOpen] = useState(false);
    const [tasksDialogData, setTasksDialogData] = useState<TasksDialogData>({
        type: 'refused',
        data: [],
    });

    const toggleTasksDialog = () => setIsTasksDialogOpen((prev) => !prev);

    if (residentDetailsIsLoading) {
        return <Loading />;
    }

    if (residentDetailsIsError || !residentDetailsData) {
        return null;
    }

    // Map module with corresponding row data
    const moduleRowData = {
        [VISIT_ID]: residentDetailsData?.visits,
        [SHOWER_ID]: residentDetailsData?.incompleteShowerTasks,
        [INCONTINENCE_CARE_ID]:
            residentDetailsData?.incompleteIncontinenceTasks,
        [FALL_ID]: residentDetailsData?.falls,
        [ASSIST_CHANGES_ID]: residentDetailsData?.assistLevelChanges,
        [EATING_ID]: residentDetailsData?.incompleteEatingTasks,
        [WEIGHT_LOSS_ID]: residentDetailsData?.weightChanges,
        [INFECTION_POTENTIAL_UTI_ID]:
            residentDetailsData?.potentialInfectionChanges,
        [MED_CHANGES_ID]: residentDetailsData?.medicationChanges,
        [RESIDENT_NOTES_ID]: residentDetailsData?.residentNotes.residentNotes,
        [TASK_NOTES_ID]: residentDetailsData?.taskNotes.taskNotes,
        [REFUSED_TASKS_ID]: residentDetailsData?.refusedTasks.tasks,
        [PRN_TASKS_ID]: residentDetailsData?.unscheduledTasks.tasks,
    };

    // Map module with corresponding row component
    const moduleRowComponents = {
        [VISIT_ID]: VisitRowContent,
        [SHOWER_ID]: DefaultRowContent,
        [INCONTINENCE_CARE_ID]: DefaultRowContent,
        [FALL_ID]: FallRowContent,
        [ASSIST_CHANGES_ID]: AssistLevelRowContent,
        [EATING_ID]: DefaultRowContent,
        [WEIGHT_LOSS_ID]: WeightRowContent,
        [INFECTION_POTENTIAL_UTI_ID]: MedAndInfectionRowContent,
        [MED_CHANGES_ID]: MedAndInfectionRowContent,
    };

    // Adds API data to each module
    const assessmentModulesWithAPIData = ASSESSMENT_MODULES.map(
        (assessmentModule) => {
            const moduleName = assessmentModule.id;
            const moduleData = moduleRowData[moduleName];
            const isVisits = moduleName === VISIT_ID;
            const isFalls = moduleName === FALL_ID;
            const isAssistChanges = moduleName === ASSIST_CHANGES_ID;
            const isPotentialInfectionChanges =
                moduleName === INFECTION_POTENTIAL_UTI_ID;
            const isMedicationChanges = moduleName === MED_CHANGES_ID;
            const isIncontinenceCare = moduleName === INCONTINENCE_CARE_ID;
            const isShower = moduleName === SHOWER_ID;
            const isEating = moduleName === EATING_ID;
            const isWeight = moduleName === WEIGHT_LOSS_ID;

            const getRowData = () => {
                if (isVisits) {
                    const sortedVisits =
                        moduleData?.visits.length > 0
                            ? moduleData?.visits.sort((a: Visit, b: Visit) => {
                                  if (a.endDate && b.endDate) {
                                      return (
                                          new Date(b.endDate).getTime() -
                                          new Date(a.endDate).getTime()
                                      );
                                  }
                                  if (a.endDate && !b.endDate) {
                                      return -1;
                                  }
                                  if (!a.endDate && b.endDate) {
                                      return 1;
                                  }
                                  return (
                                      new Date(b.startDate).getTime() -
                                      new Date(a.startDate).getTime()
                                  );
                              })
                            : [];

                    return sortedVisits;
                }
                if (isFalls) {
                    const sortedFalls =
                        moduleData?.falls.length > 0
                            ? moduleData?.falls.sort(
                                  (a: Fall, b: Fall) =>
                                      new Date(b.datetime).getTime() -
                                      new Date(a.datetime).getTime()
                              )
                            : [];
                    return sortedFalls;
                }
                if (isAssistChanges) {
                    const sortedAssistChanges =
                        moduleData?.assistChanges.length > 0
                            ? moduleData?.assistChanges.sort(
                                  (
                                      a: AssistLevelChanges,
                                      b: AssistLevelChanges
                                  ) => b.taskCount - a.taskCount
                              )
                            : [];
                    return sortedAssistChanges;
                }
                if (isPotentialInfectionChanges || isMedicationChanges) {
                    const sortedMedicationChanges =
                        moduleData?.medicationChanges.length > 0
                            ? moduleData?.medicationChanges.sort(
                                  (a: MedicationChange, b: MedicationChange) =>
                                      new Date(b.datetime).getTime() -
                                      new Date(a.datetime).getTime()
                              )
                            : [];

                    return sortedMedicationChanges;
                }
                if (isWeight) return moduleData?.weightMeasures;
                if (isIncontinenceCare || isShower || isEating) {
                    const sortedTasks =
                        moduleData?.tasks.length > 0
                            ? moduleData?.tasks.sort(
                                  (a: IncompleteTask, b: IncompleteTask) =>
                                      new Date(b.shiftDayDate).getTime() -
                                      new Date(a.shiftDayDate).getTime()
                              )
                            : [];
                    return sortedTasks;
                }
                return [];
            };

            const completeTasksCount = moduleData?.completedTasksCount || 0;
            const totalTaskCount = moduleData?.totalTaskCount || 0;
            const status = moduleData?.needsAttention
                ? 'needs_attention'
                : 'good';
            const hasTasksCount = isIncontinenceCare || isShower || isEating;

            return {
                ...assessmentModule,
                status,
                order: moduleData?.order,
                ...(hasTasksCount && {
                    completeTasksCount,
                    totalTaskCount,
                }),
                ...(isVisits && { visitsCount: moduleData?.visits.length }),
                ...(isFalls && { fallsCount: moduleData?.falls.length }),
                ...(isAssistChanges && {
                    assistLevelChangesCount: moduleData?.assistChanges.length,
                }),
                ...((isPotentialInfectionChanges || isMedicationChanges) && {
                    medicationChangesCount:
                        moduleData?.medicationChanges.length,
                }),
                ...(isWeight && {
                    weightAboveThresholdCount:
                        moduleData?.weightMeasures.filter(
                            (weightMeasure: WeightMeasure) =>
                                weightMeasure.isAboveThreshold
                        ).length,
                    latestMeasurementDate: moduleData?.latestMeasurementDate,
                    hasWeightChart: true,
                }),
                rowData: getRowData(),
                RowComponent: moduleRowComponents[moduleName],
            };
        }
    );

    const notesModulesWithAPIData = NOTES_MODULES.map((notesModule) => ({
        ...notesModule,
        order: residentDetailsData?.[camelCase(notesModule.id)].order,
        rowData: moduleRowData[notesModule.id]?.sort(
            (a: ResidentNote | TaskNote, b: ResidentNote | TaskNote) =>
                b.note.length - a.note.length
        ),
    }));

    const refusedTasks: RefusedTask[] =
        moduleRowData[TASKS_MODULES[0].id] || [];

    const prnTasks: PRNTask[] = moduleRowData[TASKS_MODULES[1].id] || [];

    const getTimelineDates = (
        hasFalls: boolean,
        hasAssistLevelChanges: boolean
    ) => {
        if (hasFalls) {
            const dates = residentDetailsData?.falls.falls.map((fall) =>
                parse(fall.datetime, 'yyyy-MM-dd HH:mm:ss', new Date())
            );
            return dates || [];
        }
        if (hasAssistLevelChanges) {
            const dates =
                residentDetailsData?.assistLevelChanges.assistTasks.map(
                    (task) => parse(task.shiftDayDate, 'yyyy-MM-dd', new Date())
                );
            return dates || [];
        }
        return [];
    };

    const hasRefusedTasks = refusedTasks.length > 0;
    const hasPrnTasks = prnTasks.length > 0;
    const assistLevelChangesTasks =
        residentDetailsData?.assistLevelChanges.assistTasks || [];

    const checkIsAssistLevelChangesWithTasks = (id: string): boolean => {
        const isAssistChanges = id === ASSIST_CHANGES_ID;
        const hasTasks =
            (residentDetailsData?.assistLevelChanges.assistTasks.length || 0) >
            0;

        return isAssistChanges && hasTasks;
    };

    const prnTasksOrder = residentDetailsData?.unscheduledTasks.order;
    const refusedTasksOrder = residentDetailsData?.refusedTasks.order;

    return (
        <PageStructure>
            <Box m={{ xs: pxToRem(12), md: pxToRem(24) }}>
                {residentDetailsData && (
                    <ResidentDashboardHeader
                        {...residentDetailsData.resident}
                        needsAttentionSummary={
                            residentDetailsData.needsAttentionSummary
                        }
                    />
                )}
                <Box
                    display={{ xs: 'flex', xl: 'grid' }}
                    flexDirection={{ xs: 'column', xl: 'unset' }}
                    gridTemplateColumns={{ xl: 'repeat(3, 1fr)' }}
                    rowGap={4}
                    columnGap={3}
                    mt={{ xs: pxToRem(18), xl: pxToRem(32) }}
                    mb={{ xs: pxToRem(40), xl: 0 }}
                >
                    {assessmentModulesWithAPIData.map(
                        ({
                            id,
                            title,
                            subtitle,
                            timeRange,
                            hasTimeline,
                            status,
                            completeTasksCount,
                            totalTaskCount,
                            rowData,
                            RowComponent,
                            visitsCount,
                            fallsCount,
                            assistLevelChangesCount,
                            medicationChangesCount,
                            weightAboveThresholdCount,
                            latestMeasurementDate,
                            order,
                            hasWeightChart,
                        }) => {
                            let assessmentModuleChart: React.ReactElement | null =
                                null;

                            if (hasTimeline) {
                                if (fallsCount || assistLevelChangesCount) {
                                    assessmentModuleChart = (
                                        <TimelineChart
                                            dates={getTimelineDates(
                                                fallsCount > 0,
                                                assistLevelChangesCount > 0
                                            )}
                                            dateRange={30}
                                        />
                                    );
                                }
                            } else if (hasWeightChart) {
                                assessmentModuleChart = (
                                    <WeightChart
                                        weightData={
                                            residentDetailsData?.weightChanges
                                                .measurements || []
                                        }
                                    />
                                );
                            }

                            return (
                                <ResidentDashboardModule
                                    key={id}
                                    title={title}
                                    subtitle={subtitle}
                                    timeRange={timeRange}
                                    hasTimeline={hasTimeline}
                                    status={status}
                                    completeTasksCount={completeTasksCount}
                                    totalTaskCount={totalTaskCount}
                                    visitsCount={visitsCount}
                                    fallsCount={fallsCount}
                                    assistLevelChangesCount={
                                        assistLevelChangesCount
                                    }
                                    medicationChangesCount={
                                        medicationChangesCount
                                    }
                                    weightAboveThresholdCount={
                                        weightAboveThresholdCount
                                    }
                                    latestMeasurementDate={
                                        latestMeasurementDate
                                    }
                                    rowData={rowData}
                                    order={order}
                                    RowComponent={RowComponent}
                                    hasWeightChart={hasWeightChart}
                                    Chart={assessmentModuleChart}
                                    SeeAllButton={
                                        checkIsAssistLevelChangesWithTasks(
                                            id
                                        ) && (
                                            <SeeAllButton
                                                onClick={() => {
                                                    setTasksDialogData({
                                                        type: 'assist',
                                                        data: assistLevelChangesTasks,
                                                    });
                                                    toggleTasksDialog();
                                                }}
                                            />
                                        )
                                    }
                                />
                            );
                        }
                    )}
                    {/* REFUSED TASKS */}
                    <ResidentDashboardModule
                        key={TASKS_MODULES[0].id}
                        title={TASKS_MODULES[0].title}
                        subtitle={TASKS_MODULES[0].subtitle}
                        timeRange={TASKS_MODULES[0].timeRange}
                        rowData={createRefusedTasksPerKeyService(refusedTasks)}
                        RowComponent={TasksRow}
                        order={refusedTasksOrder || HIGHEST_MODULE_ORDER}
                        SeeAllButton={
                            hasRefusedTasks && (
                                <SeeAllButton
                                    onClick={() => {
                                        setTasksDialogData({
                                            type: 'refused',
                                            data: refusedTasks,
                                        });
                                        toggleTasksDialog();
                                    }}
                                />
                            )
                        }
                        Chart={
                            hasRefusedTasks ? (
                                <TasksChart
                                    refusedTasksData={createTasksPerDate(
                                        refusedTasks
                                    )}
                                />
                            ) : null
                        }
                        hasChart={hasRefusedTasks}
                        tasksOrServicesCount={calculateKeyServicesCount(
                            refusedTasks
                        )}
                    />
                    {/* PRN TASKS */}
                    <ResidentDashboardModule
                        key={TASKS_MODULES[1].id}
                        title={TASKS_MODULES[1].title}
                        subtitle={TASKS_MODULES[1].subtitle}
                        timeRange={TASKS_MODULES[1].timeRange}
                        rowData={createGroupedPRNTasks(prnTasks)}
                        RowComponent={TasksRow}
                        order={prnTasksOrder || HIGHEST_MODULE_ORDER}
                        SeeAllButton={
                            hasPrnTasks && (
                                <SeeAllButton
                                    onClick={() => {
                                        setTasksDialogData({
                                            type: 'prn',
                                            data: prnTasks,
                                        });
                                        toggleTasksDialog();
                                    }}
                                />
                            )
                        }
                        Chart={
                            hasPrnTasks ? (
                                <TasksChart
                                    prnTasksData={createTasksPerDate(prnTasks)}
                                />
                            ) : null
                        }
                        hasChart={hasPrnTasks}
                        tasksOrServicesCount={prnTasks.length}
                    />
                    {notesModulesWithAPIData.map(
                        ({ id, title, rowData, order }) => (
                            <NotesModule
                                key={id}
                                title={title}
                                rowData={rowData}
                                isNotesModule
                                order={order}
                            />
                        )
                    )}
                </Box>
            </Box>
            <TasksDialog
                isOpen={isTasksDialogOpen}
                onClose={toggleTasksDialog}
                tasksDialogData={tasksDialogData}
            />
        </PageStructure>
    );
};

export const ResidentDashboard = AccessControl(Resident);
