import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import React from 'react';
import {
    BsFillArrowDownCircleFill as DownArrow,
    BsFillArrowUpCircleFill as UpArrow,
} from 'react-icons/bs';
import { Link as RouterLink } from 'react-router-dom';

import { DashboardReadParams } from 'types/dashboard';

import { useResidentWeightQuery } from 'api/queries/dashboard/residentWeight';
import { CustomAvatar, CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'flex-start',
        '&:first-of-type': {
            mt: pxToRem(24),
        },
        '&:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ResidentData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-end',
        flexGrow: 1,
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        fontSize: { lg: pxToRem(16) },
        fontWeight: 'bold',
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
    })
);

const DataRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        p: pxToRem(8),
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadiusSm,
        width: '100$',
    })
);

const TaskName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
    })
);

const TaskTimestamp = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
    })
);

export const RefusedTasksDialog = (props) => {
    const { data, open, onClose } = props;

    return (
        <CustomDialog
            closeable
            open={open}
            title="Key Services Refused Tasks By Resident"
            width={pxToRem(600)}
            maxHeight={`calc(100vh - ${pxToRem(160)})`}
            showDividers
            content={
                <>
                    {data &&
                        data.map(
                            (
                                {
                                    residentFirstName,
                                    residentLastName,
                                    residentName,
                                    residentPhoto,
                                    roomNumber,
                                    residentId,
                                    tasks,
                                }
                            ) => (
                                <Row key={residentId}>
                                    <AvatarContainer>
                                        <CustomAvatar
                                            avatarType={AVATAR_TYPES.resident}
                                            firstName={residentFirstName}
                                            lastName={residentLastName}
                                            photo={residentPhoto}
                                            size={48}
                                        />
                                    </AvatarContainer>
                                    <DataContainer>
                                        <RouterLink
                                            to={`resident-details/${residentId}`}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                }}
                                            >
                                                <ResidentData>
                                                    <ResidentName>
                                                        {residentName}
                                                    </ResidentName>
                                                    <RoomNumber>
                                                        {`Room ${roomNumber}`}
                                                    </RoomNumber>
                                                </ResidentData>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        color:
                                                            tasks.length >= 3
                                                                ? 'error.main'
                                                                : 'app.orange.main',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box>{`${
                                                        tasks.length
                                                    } task${
                                                        tasks.length > 1
                                                            ? 's'
                                                            : ''
                                                    }`}</Box>
                                                </Box>
                                            </Box>
                                        </RouterLink>
                                        {tasks.map(
                                            (
                                                {
                                                    taskDate,
                                                    taskId,
                                                    taskKeyService,
                                                    taskName,
                                                    taskTime,
                                                }
                                            ) => (
                                                <DataRow key={taskId}>
                                                    <TaskName>
                                                        {taskName}
                                                    </TaskName>
                                                    <TaskTimestamp>
                                                        {moment(
                                                            `${taskDate} ${taskTime}`
                                                        ).format(
                                                            'MM/DD/YYYY h:mm A'
                                                        )}
                                                    </TaskTimestamp>
                                                </DataRow>
                                            )
                                        )}
                                    </DataContainer>
                                </Row>
                            )
                        )}
                </>
            }
            onClose={onClose}
        />
    );
};
