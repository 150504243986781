import { hideAlert } from 'redux/actions/messages';

const loading =
    (type: string, payload: boolean, shouldHideAlert = true) =>
    (dispatch) => {
        // TODO: Check how to hide the error Alerts but keeping the Success Alerts.
        if (payload && shouldHideAlert) {
            dispatch(hideAlert());
        }

        dispatch({
            type,
            payload,
        });
    };

export default loading;
