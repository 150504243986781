import { Box } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import isDeepEqual from 'fast-deep-equal/react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { BiNote } from 'react-icons/bi';
import { FaTasks } from 'react-icons/fa';
import { connect } from 'react-redux';

import {
    DashboardCountersResponse,
    DashboardDialogData,
    DashboardDialogType,
    DashboardFallenResidentsResponse,
    DashboardFilters,
    DashboardIncompleteByCaregiversResponse,
    DashboardIncompletedOverdueByResidentsResponse,
    DashboardKeyServicesResponse,
    DashboardPotentialContactsResponse,
    DashboardReadParams,
    DashboardUnscheduledTasksResponse,
    UnscheduledTasksByResident,
} from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import PerformanceDetailsDialog from './components/PerformanceDetailsDialog';
import PotentialContactsCard from './components/PotentialContactsCard';
import { RefusedTasksCard } from './components/RefusedTasksCard';
import { RefusedTasksDialog } from './components/RefusedTasksDialog';
import { ResidentWeights } from './components/ResidentWeights';
import UnscheduledTasksCard from './components/UnscheduledTasksCard';
import { UnscheduledTasksSummaryCard } from './components/UnscheduledTasksSummaryCard';
import { UnscheduledTasksSummaryDialog } from './components/UnscheduledTasksSummaryDialog';
import { WeightDetailsDialog } from './components/WeightDetailsDialog';
import {
    PortfolioInnerCheckIcon,
    UserFallingIcon,
    UserInnerCancelIcon,
    UserInnerCheckIcon,
} from 'components/Svg';
import { pxToRem } from 'components/theme/typography';
import { DATE_FORMAT } from 'constants/app';
import {
    DASHBOARD_FIRST_BLOCK_COLUMN_SPACE_WIDTH,
    DASHBOARD_FIRST_BLOCK_COLUMN_WIDTH,
    DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH,
} from 'constants/dashboards';
import AccessControl from 'helpers/AccessControl';
import CounterCard from 'pages/Dashboards/components/CounterCard';
import DetailsDialog from 'pages/Dashboards/components/DetailsDialog';
import KeyServicesCard from 'pages/Dashboards/components/KeyServicesCard';
import KeyServicesDialog from 'pages/Dashboards/components/KeyServicesDialog';
import NotesCard from 'pages/Dashboards/components/NotesCard';
import PerformanceCard from 'pages/Dashboards/components/PerformanceCard';
import ResidentsCard from 'pages/Dashboards/components/ResidentsCard';
import TasksCard from 'pages/Dashboards/components/TasksCard';
import TasksChartCard from 'pages/Dashboards/components/TasksChartCard';
import PageStructure from 'pages/PageStructure';
import {
    readCounters,
    readIncompletedByResidents,
    readNotes,
    readOverdueByResidents,
    readPerformance,
    readUnscheduledTasks,
} from 'redux/actions/dashboards';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    orange: {
        color: theme.palette.app.orange.main,
    },
    green: {
        color: theme.palette.app.green.main,
    },
}));

const DataBlock = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    })
);

const FirstBlockColumn = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: {
            xs: '100%',
            s: pxToRem(DASHBOARD_FIRST_BLOCK_COLUMN_WIDTH),
            md: pxToRem(DASHBOARD_FIRST_BLOCK_COLUMN_WIDTH),
            lg: pxToRem(DASHBOARD_FIRST_BLOCK_COLUMN_WIDTH),
        },
    })
);

const FirstBlockLastColumn = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: {
            xs: '100%',
            lg: '100%',
            xl: `calc(100% - ${pxToRem(
                DASHBOARD_FIRST_BLOCK_COLUMN_WIDTH * 2 +
                    DASHBOARD_FIRST_BLOCK_COLUMN_SPACE_WIDTH * 2
            )})`,
        },
        height: { lg: pxToRem(616) },
    })
);

const SecondBlockColumn = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: {
            xs: '100%',
            s: `calc((100% - ${pxToRem(
                DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH
            )}) / 2)`,
            md: `calc((100% - ${pxToRem(
                DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH
            )}) / 2)`,
            lg: `calc((100% - ${pxToRem(
                DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH
            )}) / 2)`,
        },
    })
);

const ThirdBlockColumn = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: {
            xs: '100%',
            s: `calc((100% - ${pxToRem(
                DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH
            )}) / 3)`,
            md: `calc((100% - ${pxToRem(
                DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH
            )}) / 3)`,
            lg: `calc((100% - ${pxToRem(
                DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH
            )}) / 3)`,
        },
    })
);

type Props = {
    counters: DashboardCountersResponse;
    dictionary: LanguageStructure;
    fallenResidents: DashboardFallenResidentsResponse[];
    filters: DashboardFilters;
    incompletedByCaregivers: DashboardIncompleteByCaregiversResponse;
    incompletedByResidents: DashboardIncompletedOverdueByResidentsResponse;
    keyServices: DashboardKeyServicesResponse;
    overdueByCaregivers: DashboardIncompleteByCaregiversResponse;
    overdueByResidents: DashboardIncompletedOverdueByResidentsResponse;
    potentialContacts: DashboardPotentialContactsResponse[];
    unscheduledTasks: DashboardUnscheduledTasksResponse[];
    dispatchReadCounters: (params: DashboardReadParams) => void;
    dispatchReadIncompletedByResidents: (params: DashboardReadParams) => void;
    dispatchReadNotes: (params: DashboardReadParams) => void;
    dispatchReadUnscheduledTasks: (params: DashboardReadParams) => void;
    dispatchReadOverdueByResidents: (params: DashboardReadParams) => void;
    dispatchReadPerformance: (params: DashboardReadParams) => void;
};

const Dashboards = (props: Props) => {
    const classes = useStyles();
    const { palette } = useTheme();
    const {
        counters: {
            percentageCompleted = 0,
            percentageConfirmed = 0,
            totalCaregivers: activeCaregivers = 0,
            unscheduledTasksCount = 0,
            residentShiftNotesCount = 0,
        },
        dictionary: { dashboards, shared },
        fallenResidents,
        filters,
        incompletedByCaregivers: {
            caregivers: incompletedTasksByCaregivers = [],
            totalCaregivers: totalCaregiversWithIncompletedTasks,
        },
        incompletedByResidents: {
            residents: incompletedTasksByResidents,
            totalResidents: totalResidentsWithIncompletedTasks,
        },
        keyServices,
        overdueByCaregivers: {
            caregivers: overdueTasksByCaregivers = [],
            totalCaregivers: totalCaregiversWithOverdueTasks,
        },
        overdueByResidents: {
            residents: overdueTasksByResidents,
            totalResidents: totalResidentsWithOverdueTasks,
        },
        unscheduledTasks,
        potentialContacts,
        dispatchReadCounters,
        dispatchReadIncompletedByResidents,
        dispatchReadNotes,
        dispatchReadUnscheduledTasks,
        dispatchReadOverdueByResidents,
        dispatchReadPerformance,
    } = props;
    const [dialogData, setDialogData] = useState<DashboardDialogData>([]);
    const [dialogType, setDialogType] = useState<DashboardDialogType>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [keyServicesDialogOpen, setKeyServicesDialogOpen] =
        useState<boolean>(false);
    const [performanceDetailsDialogOpen, setPerformanceDetailsDialogOpen] =
        useState<boolean>(false);
    const [weightDetailsDialogOpen, setWeightDetailsDialogOpen] =
        useState<boolean>(false);
    const [refusedTasksDialogOpen, setRefusedTasksDialogOpen] =
        useState<boolean>(false);
    const [unscheduledSummaryDialogOpen, setUnscheduledSummaryDialogOpen] =
        useState<boolean>(false);

    let responseConfirmed = 0;

    if (percentageConfirmed) {
        responseConfirmed = Number.isInteger(percentageConfirmed)
            ? percentageConfirmed
            : Number(percentageConfirmed.toFixed(2));
    }

    let serviceCompleted = 0;

    if (percentageCompleted) {
        serviceCompleted = Number.isInteger(percentageCompleted)
            ? percentageCompleted
            : Number(percentageCompleted.toFixed(2));
    }

    const totalFallen = Array.from(
        new Set(fallenResidents.map((resident) => resident.residentId))
    ).length;
    const totalFallenRecords = fallenResidents.length;
    const totalPotentialContactsRecords = potentialContacts.length;
    const fallenSubtitle = dashboards.totalRecordsInPeriod.replace(
        '{XX}',
        totalFallenRecords.toString()
    );
    const potentialContactsSubtitle = dashboards.totalRecordsInPeriod.replace(
        '{XX}',
        totalPotentialContactsRecords.toString()
    );
    const { totalKeyServices } = keyServices;
    const tasksByCaregivers = [
        {
            color: palette.charts.overdue,
            data: overdueTasksByCaregivers,
            label: dashboards.overdueTasks,
        },
        {
            color: palette.charts.incomplete,
            data: incompletedTasksByCaregivers,
            label: dashboards.incompleteTasks,
        },
    ];

    const handleDialogOpen = (
        newData: DashboardDialogData,
        newType: DashboardDialogType,
        newTitle: string
    ) => {
        setDialogData(newData);
        setDialogType(newType);
        setDialogTitle(newTitle);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleKeyServicesDialogOpen = () => setKeyServicesDialogOpen(true);

    const handleKeyServicesDialogClose = () => setKeyServicesDialogOpen(false);

    const togglePerformanceDetailsDialogOpen = () =>
        setPerformanceDetailsDialogOpen((prevState) => !prevState);

    const toggleWeightDetailsDialogOpen = () =>
        setWeightDetailsDialogOpen((prevState) => !prevState);

    const toggleRefusedTasksDialogOpen = () =>
        setRefusedTasksDialogOpen((prevState) => !prevState);

    const toggleUnscheduledSummaryDialogOpen = () =>
        setUnscheduledSummaryDialogOpen((prevState) => !prevState);

    // to reduce the number of renders we are using useRef and comparing the object values directly
    const filtersRef = useRef(filters);

    if (!isDeepEqual(filtersRef.current, filters)) {
        filtersRef.current = filters;
    }

    useEffect(() => {
        if (
            !isEmpty(filters) &&
            filters.branchId &&
            !isEmpty(filters.dateRange)
        ) {
            const {
                branchId,
                dateRange: { startDate, endDate },
            } = filters;
            const startPeriod = `${moment(startDate).format(
                DATE_FORMAT
            )} 00:00:00`;
            const endPeriod = `${moment(endDate).format(DATE_FORMAT)} 23:59:59`;
            const params: DashboardReadParams = {
                branchId,
                startPeriod,
                endPeriod,
            };
            dispatchReadCounters(params);
            dispatchReadIncompletedByResidents(params);
            dispatchReadNotes(params);
            dispatchReadUnscheduledTasks(params);
            dispatchReadOverdueByResidents(params);
            dispatchReadPerformance(params);
        }
    }, [filtersRef.current]);

    const keyServiceRefusedTasksByResident =
        incompletedTasksByResidents
            ?.filter((resident) => {
                const keyServiceTasks =
                    resident.tasks['Resident Refused']?.filter(
                        (task) => !!task.taskKeyService
                    ) || [];

                return keyServiceTasks.length > 0;
            })
            .map((resident) => {
                const keyServiceTasks =
                    resident.tasks['Resident Refused']?.filter(
                        (task) => !!task.taskKeyService
                    ) || [];

                // group key service types together and then sort by date desc
                keyServiceTasks.sort((a, b) => {
                    if (a.taskKeyService > b.taskKeyService) {
                        return 1;
                    }
                    if (a.taskKeyService < b.taskKeyService) {
                        return -1;
                    }

                    const aTaskDate = moment(`${a.taskDate} ${a.taskTime}`);
                    const bTaskDate = moment(`${b.taskDate} ${b.taskTime}`);

                    if (aTaskDate.isBefore(bTaskDate)) {
                        return 1;
                    }

                    if (aTaskDate.isSameOrAfter(bTaskDate)) {
                        return -1;
                    }

                    return 0;
                });

                return {
                    ...resident,
                    tasks: keyServiceTasks,
                };
            }) || [];

    const unscheduledTasksByResident = {};
    unscheduledTasks.forEach(
        ({
            residentId,
            companyCareplanName,
            taskTimestamp,
            residentFirstName,
            residentLastName,
            residentName,
            residentPhoto,
            room,
            caregiverName,
            userId,
        }) => {
            if (!unscheduledTasksByResident[residentId]) {
                unscheduledTasksByResident[residentId] = {
                    residentId,
                    residentFirstName,
                    residentLastName,
                    residentName,
                    residentPhoto,
                    room,
                    tasks: [],
                };
            }

            unscheduledTasksByResident[residentId].tasks.push({
                companyCareplanName,
                taskTimestamp,
                caregiverName,
                userId,
            });
        }
    );

    const residentUnscheduledTasks = Object.values(
        unscheduledTasksByResident
    ).map((resident: UnscheduledTasksByResident) => {
        // group tasks types together and then sort by date desc
        const tasks = resident.tasks.sort((a, b) => {
            if (a.companyCareplanName > b.companyCareplanName) {
                return 1;
            }
            if (a.companyCareplanName < b.companyCareplanName) {
                return -1;
            }

            const aTaskDate = moment.utc(a.taskTimestamp);
            const bTaskDate = moment.utc(b.taskTimestamp);

            if (aTaskDate.isBefore(bTaskDate)) {
                return 1;
            }

            if (aTaskDate.isSameOrAfter(bTaskDate)) {
                return -1;
            }

            return 0;
        });

        return {
            ...resident,
            tasks,
        };
    });

    return (
        <PageStructure>
            <DataBlock>
                <FirstBlockColumn>
                    <CounterCard
                        type="percentage"
                        label={dashboards.responseConfirmed}
                        value={responseConfirmed}
                        icon={
                            <UserInnerCancelIcon className={classes.orange} />
                        }
                        iconBackgroundColor={alpha(
                            palette.app.orange.main,
                            0.16
                        )}
                    />
                    <CounterCard
                        type="percentage"
                        label={dashboards.serviceCompleted}
                        value={serviceCompleted}
                        icon={
                            <PortfolioInnerCheckIcon
                                className={classes.green}
                            />
                        }
                        iconBackgroundColor={alpha(
                            palette.app.green.main,
                            0.16
                        )}
                    />
                    <RefusedTasksCard
                        onShowAllClick={toggleRefusedTasksDialogOpen}
                        data={keyServiceRefusedTasksByResident}
                    />
                    {/* <ResidentsCard
            data={fallenResidents}
            title={dashboards.fallenResidents}
            subtitle={fallenSubtitle}
            showAllTotalRecords={totalFallen}
            onShowAllClick={handleDialogOpen}
          /> */}
                </FirstBlockColumn>
                <FirstBlockColumn>
                    <CounterCard
                        type="numeric"
                        label={dashboards.prnTasksAdded}
                        value={unscheduledTasksCount}
                        icon={<FaTasks className={classes.orange} />}
                        iconBackgroundColor={alpha(
                            palette.app.orange.main,
                            0.16
                        )}
                    />
                    <CounterCard
                        type="numeric"
                        label={dashboards.residentNotesSubmitted}
                        value={residentShiftNotesCount}
                        icon={<BiNote size={25} className={classes.green} />}
                        iconBackgroundColor={alpha(
                            palette.app.green.main,
                            0.16
                        )}
                    />
                    <UnscheduledTasksSummaryCard
                        onShowAllClick={toggleUnscheduledSummaryDialogOpen}
                        data={residentUnscheduledTasks}
                    />
                    {/* <PotentialContactsCard
            data={potentialContacts}
            title={dashboards.potentialContacts}
            subtitle={potentialContactsSubtitle}
            showAllTotalRecords={totalPotentialContactsRecords}
            onShowAllClick={handleDialogOpen}
          /> */}
                </FirstBlockColumn>
                <FirstBlockLastColumn>
                    <PerformanceCard
                        onExpand={togglePerformanceDetailsDialogOpen}
                    />
                </FirstBlockLastColumn>
            </DataBlock>
            <DataBlock>
                <ThirdBlockColumn>
                    {filters?.dateRange?.startDate &&
                        filters?.dateRange?.endDate &&
                        filters?.branchId && (
                            <ResidentWeights
                                onShowAllClick={toggleWeightDetailsDialogOpen}
                                params={{
                                    branchId: filters.branchId,
                                    startPeriod: `${moment(
                                        filters.dateRange.startDate
                                    ).format(DATE_FORMAT)} 00:00:00`,
                                    endPeriod: `${moment(
                                        filters.dateRange.endDate
                                    ).format(DATE_FORMAT)} 00:00:00`,
                                }}
                            />
                        )}
                </ThirdBlockColumn>
                <ThirdBlockColumn>
                    <TasksCard
                        cardType="incompleted"
                        data={incompletedTasksByResidents}
                        title={dashboards.incompleteTasks}
                        subtitle={dashboards.byResidents}
                        showAllTotalRecords={totalResidentsWithIncompletedTasks}
                        onShowAllClick={handleDialogOpen}
                    />
                </ThirdBlockColumn>
                <ThirdBlockColumn>
                    <TasksCard
                        cardType="overdue"
                        data={overdueTasksByResidents}
                        title={dashboards.undocumentedTasks}
                        subtitle={dashboards.byResidents}
                        showAllTotalRecords={totalResidentsWithOverdueTasks}
                        onShowAllClick={handleDialogOpen}
                    />
                </ThirdBlockColumn>
                {/* <SecondBlockColumn>
          <KeyServicesCard
            data={keyServices}
            showAllTotalRecords={totalKeyServices}
            onShowAllClick={handleKeyServicesDialogOpen}
        /> */}
            </DataBlock>
            {/* <DataBlock>
        <SecondBlockColumn>
          <TasksCard
            cardType="incompleted"
            data={incompletedTasksByResidents}
            title={dashboards.incompleteTasks}
            subtitle={dashboards.byResidents}
            showAllTotalRecords={totalResidentsWithIncompletedTasks}
            onShowAllClick={handleDialogOpen}
          />
        </SecondBlockColumn>
        <SecondBlockColumn>
          <TasksChartCard
            title={dashboards.chartCaregiverTasks}
            subtitle={dashboards.byCaregivers}
            tasksByCaregivers={tasksByCaregivers}
          />
        </SecondBlockColumn>
      </DataBlock> */}
            <DataBlock>
                <NotesCard />
            </DataBlock>
            <DataBlock>
                <UnscheduledTasksCard />
            </DataBlock>
            {dialogOpen && (
                <DetailsDialog
                    data={dialogData}
                    dialogType={dialogType}
                    open={dialogOpen}
                    title={dialogTitle}
                    onClose={handleDialogClose}
                />
            )}
            {keyServicesDialogOpen && (
                <KeyServicesDialog
                    data={keyServices}
                    open={keyServicesDialogOpen}
                    title={dashboards.keyServices}
                    onClose={handleKeyServicesDialogClose}
                />
            )}
            {performanceDetailsDialogOpen && (
                <PerformanceDetailsDialog
                    open={performanceDetailsDialogOpen}
                    onClose={togglePerformanceDetailsDialogOpen}
                />
            )}
            {weightDetailsDialogOpen &&
                filters?.dateRange?.startDate &&
                filters?.dateRange?.endDate &&
                filters?.branchId && (
                    <WeightDetailsDialog
                        open={weightDetailsDialogOpen}
                        onClose={toggleWeightDetailsDialogOpen}
                        params={{
                            branchId: filters.branchId,
                            startPeriod: `${moment(
                                filters.dateRange.startDate
                            ).format(DATE_FORMAT)} 00:00:00`,
                            endPeriod: `${moment(
                                filters.dateRange.endDate
                            ).format(DATE_FORMAT)} 00:00:00`,
                        }}
                    />
                )}
            {refusedTasksDialogOpen && (
                <RefusedTasksDialog
                    open={refusedTasksDialogOpen}
                    onClose={toggleRefusedTasksDialogOpen}
                    data={keyServiceRefusedTasksByResident}
                />
            )}
            {unscheduledSummaryDialogOpen && (
                <UnscheduledTasksSummaryDialog
                    open={unscheduledSummaryDialogOpen}
                    onClose={toggleUnscheduledSummaryDialogOpen}
                    data={residentUnscheduledTasks}
                />
            )}
        </PageStructure>
    );
};

const mapStateToProps = ({ dashboards, language }: ReduxStore) => {
    const {
        counters,
        fallenResidents,
        filters,
        incompletedByCaregivers,
        incompletedByResidents,
        keyServices,
        overdueByResidents,
        overdueByCaregivers,
        potentialContacts,
        unscheduledTasks,
    } = dashboards;
    const { dictionary } = language;

    return {
        counters,
        dictionary,
        fallenResidents,
        filters,
        incompletedByCaregivers,
        incompletedByResidents,
        keyServices,
        overdueByCaregivers,
        overdueByResidents,
        potentialContacts,
        unscheduledTasks,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadCounters: (params: DashboardReadParams) =>
        dispatch(readCounters(params)),
    dispatchReadIncompletedByResidents: (params: DashboardReadParams) =>
        dispatch(readIncompletedByResidents(params)),
    dispatchReadNotes: (params: DashboardReadParams) =>
        dispatch(readNotes(params)),
    dispatchReadUnscheduledTasks: (params: DashboardReadParams) =>
        dispatch(readUnscheduledTasks(params)),
    dispatchReadOverdueByResidents: (params: DashboardReadParams) =>
        dispatch(readOverdueByResidents(params)),
    dispatchReadPerformance: (params: DashboardReadParams) =>
        dispatch(readPerformance(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedDashboards: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(Dashboards)
);

export default ConnectedDashboards;
