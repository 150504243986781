import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
    viewBox?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const BadgeMostCommunicate = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            d="M20.6833 3.67836C22.6091 1.67218 25.8198 1.67218 27.7456 3.67836V3.67836C28.9436 4.92635 30.7125 5.44895 32.3961 5.05152V5.05152C35.089 4.41586 37.7811 6.184 38.3224 8.89743V8.89743C38.6617 10.5982 39.8721 12.0213 41.5042 12.6076V12.6076C44.1177 13.5466 45.4325 16.4866 44.4312 19.0767V19.0767C43.804 20.6992 44.0633 22.5552 45.1201 23.9368V23.9368C46.8141 26.1515 46.3656 29.3362 44.116 30.9837V30.9837C42.7145 32.0102 41.9522 33.7198 42.1011 35.4506V35.4506C42.3386 38.2094 40.254 40.667 37.4895 40.8238V40.8238C35.7607 40.9218 34.2042 41.9364 33.4042 43.4721V43.4721C32.1238 45.9299 29.0438 46.8549 26.6381 45.479V45.479C25.1364 44.6202 23.2924 44.6202 21.7907 45.479V45.479C19.3851 46.8549 16.305 45.9299 15.0246 43.4721V43.4721C14.2246 41.9364 12.6681 40.9218 10.9394 40.8238V40.8238C8.17481 40.667 6.09028 38.2094 6.32772 35.4506V35.4506C6.47668 33.7198 5.71434 32.0102 4.31282 30.9837V30.9837C2.0633 29.3362 1.61472 26.1515 3.30879 23.9368V23.9368C4.3656 22.5552 4.62482 20.6992 3.99764 19.0767V19.0767C2.99635 16.4866 4.31119 13.5466 6.92461 12.6076V12.6076C8.55673 12.0213 9.76717 10.5982 10.1064 8.89743V8.89743C10.6477 6.184 13.3398 4.41586 16.0327 5.05152V5.05152C17.7164 5.44895 19.4853 4.92635 20.6833 3.67836V3.67836Z"
            fill="url(#paint0_linear_7_175)"
        />
        <path
            d="M7.78369 24.4232C7.78369 15.2332 15.2337 7.7832 24.4237 7.7832V7.7832C33.6136 7.7832 41.0636 15.2332 41.0636 24.4232V24.7654C41.0636 33.9554 33.6136 41.4053 24.4237 41.4053V41.4053C15.2337 41.4053 7.78369 33.9554 7.78369 24.7654V24.4232Z"
            fill="black"
            fillOpacity="0.04"
        />
        <path
            d="M39.4543 24.4232V24.7654C39.4543 33.0666 32.7249 39.7961 24.4237 39.7961C16.1224 39.7961 9.39297 33.0666 9.39297 24.7654V24.4232C9.39297 16.1219 16.1224 9.39248 24.4237 9.39248C32.7249 9.39248 39.4543 16.1219 39.4543 24.4232Z"
            stroke="white"
            strokeOpacity="0.3"
            strokeWidth="3.21855"
        />
        <path
            d="M27.5543 27.6171L27.7771 29.4229C27.8343 29.8971 27.3257 30.2286 26.92 29.9829L24.9429 28.8057C24.8057 28.7257 24.7714 28.5543 24.8457 28.4171C25.1314 27.8914 25.2857 27.2971 25.2857 26.7029C25.2857 24.6114 23.4914 22.9086 21.2857 22.9086C20.8343 22.9086 20.3943 22.9771 19.9829 23.1143C19.7714 23.1829 19.5657 22.9886 19.6171 22.7714C20.1371 20.6914 22.1371 19.1429 24.5257 19.1429C27.3143 19.1429 29.5714 21.2514 29.5714 23.8514C29.5714 25.3943 28.7771 26.76 27.5543 27.6171Z"
            fill="white"
        />
        <path
            d="M24.4286 26.7029C24.4286 27.3829 24.1771 28.0114 23.7543 28.5086C23.1886 29.1943 22.2914 29.6343 21.2857 29.6343L19.7943 30.52C19.5429 30.6743 19.2229 30.4629 19.2571 30.1714L19.4 29.0457C18.6343 28.5143 18.1429 27.6629 18.1429 26.7029C18.1429 25.6971 18.68 24.8114 19.5029 24.2857C20.0114 23.9543 20.6229 23.7657 21.2857 23.7657C23.0229 23.7657 24.4286 25.08 24.4286 26.7029Z"
            fill="white"
        />
        <path
            d="M41.1108 24.621C41.1108 33.915 33.6523 41.4492 24.4519 41.4492C15.2515 41.4492 7.79311 33.915 7.79311 24.621C7.79311 15.327 15.2515 7.79276 24.4519 7.79276C33.6523 7.79276 41.1108 15.327 41.1108 24.621ZM11.268 24.621C11.268 31.9763 17.1707 37.939 24.4519 37.939C31.7332 37.939 37.6358 31.9763 37.6358 24.621C37.6358 17.2657 31.7332 11.303 24.4519 11.303C17.1707 11.303 11.268 17.2657 11.268 24.621Z"
            fill="url(#paint1_linear_7_175)"
            fillOpacity="0.5"
        />
        <defs>
            <linearGradient
                id="paint0_linear_7_175"
                x1="8.64252"
                y1="4.49478"
                x2="34.9703"
                y2="45.9931"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B5D3D6" />
                <stop offset="1" stopColor="#0CA3B1" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_7_175"
                x1="24.4519"
                y1="7.79276"
                x2="24.4519"
                y2="41.4492"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="white" />
                <stop offset="0.0001" stopColor="white" stopOpacity="0.81" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
        </defs>
    </Base>
);
