import emailjs from '@emailjs/browser';
import { Box, Button, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { encode as base64Encode } from 'base-64';
import moment from 'moment';
import React, { MutableRefObject, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import {
    InvitationCreateUpdateParams,
    InvitationResponse,
} from 'types/signupInvitations';

import { CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import {
    DATE_TIME_FORMAT,
    EMAIL_JS_INVITATION_TEMPLATE_ID,
    EMAIL_JS_PUBLIC_KEY,
    EMAIL_JS_SERVICE_ID,
} from 'constants/app';
import { PROFILE } from 'constants/localStorage';
import { NOTIFICATION_TYPE } from 'constants/shared';
import { INVITATION_STATUS_MAP, InvitationStatus } from 'constants/users';
import { formatDateTimeFullUS } from 'lib/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    completed: {
        color: theme.palette.app.green.main,
        backgroundColor: alpha(theme.palette.app.green.main, 0.05),
    },
    canceled: {
        color: theme.palette.app.orange.main,
        backgroundColor: alpha(theme.palette.app.orange.main, 0.05),
    },
    pending: {
        color: theme.palette.socialMedia.google.yellow,
        backgroundColor: alpha(theme.palette.socialMedia.google.yellow, 0.05),
    },
}));

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    })
);

const SingleDataWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '49%',
    })
);

const SingleDataText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(14) },
    })
);

const StatusChip = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        m: 0,
        px: pxToRem(8),
        display: 'flex',
        borderRadius: theme.shape.borderRadiusSm,
        fontSize: { lg: pxToRem(10) },
        textAlign: 'center',
        textTransform: 'uppercase',
        width: pxToRem(72),
    })
);

const FooterWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    })
);

const TernaryButtonWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(32),
        display: 'flex',
    })
);

const MainButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        '& >:not(:first-of-type)': {
            ml: pxToRem(8),
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    isOpen: boolean;
    invitation?: InvitationResponse;
    timezone: string;
    onCancelInvitation: () => void;
    onClose: () => void;
    onReSendInvitation: (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => void;
};

const InvitationDetailDialog = (props: Props) => {
    const classes = useStyles();
    const formRef = useRef() as MutableRefObject<HTMLFormElement>;
    const { register, setValue } = useForm();
    const {
        dictionary: {
            shared,
            userManagementInvitationsList,
            userManagementUsersList,
        },
        isOpen,
        invitation,
        timezone,
        onCancelInvitation,
        onClose,
        onReSendInvitation,
    } = props;

    if (!isOpen || !invitation) {
        return null;
    }

    const {
        firstName,
        lastName,
        email,
        invitationStatusId,
        creationTimestamp,
    } = invitation;
    const invitedToFullName = `${firstName} ${lastName}`;
    const creationDateTime = moment(creationTimestamp).format(DATE_TIME_FORMAT);
    const creationFullDateTime = formatDateTimeFullUS(
        creationDateTime,
        timezone
    );

    // Get the role id from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { company } = storedSession?.sessionData || {};

    let className = 'pending';

    if (invitationStatusId === InvitationStatus.completed) {
        className = 'completed';
    } else if (invitationStatusId === InvitationStatus.canceled) {
        className = 'canceled';
    }

    const statusLabel =
        userManagementInvitationsList[INVITATION_STATUS_MAP[className]];

    const handleReSendInvitation = () => {
        const signUpLinkParams = {
            email,
        };
        const encodedLink = base64Encode(JSON.stringify(signUpLinkParams));

        // Add the Link Code to the Form that will be submitted through emailjs.
        setValue('link_code', encodedLink);
        emailjs
            .sendForm(
                EMAIL_JS_SERVICE_ID,
                EMAIL_JS_INVITATION_TEMPLATE_ID,
                formRef.current,
                EMAIL_JS_PUBLIC_KEY
            )
            .then(
                (result) => {
                    // Show a Success notification.
                    onReSendInvitation(
                        userManagementUsersList.invitationSuccess,
                        userManagementUsersList.invitationNotificationSuccess,
                        NOTIFICATION_TYPE.success
                    );
                },
                (error) => {
                    // Show an Error notification.
                    onReSendInvitation(
                        userManagementUsersList.invitationError,
                        userManagementUsersList.invitationNotificationError,
                        NOTIFICATION_TYPE.error
                    );
                }
            );
    };

    return (
        <CustomDialog
            id="invitation_details"
            closeable
            open={isOpen}
            title={userManagementInvitationsList.invitationDetails}
            width={pxToRem(604)}
            showFooterDivider
            content={
                <>
                    <Row>
                        <SingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userManagementInvitationsList.invitedTo}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {invitedToFullName}
                            </SingleDataText>
                        </SingleDataWrapper>
                        <SingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userManagementInvitationsList.email}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {email}
                            </SingleDataText>
                        </SingleDataWrapper>
                    </Row>
                    <Row>
                        <SingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userManagementInvitationsList.invitationDate}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {creationFullDateTime}
                            </SingleDataText>
                        </SingleDataWrapper>
                        <SingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userManagementInvitationsList.status}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                <StatusChip className={classes[className]}>
                                    {statusLabel}
                                </StatusChip>
                            </SingleDataText>
                        </SingleDataWrapper>
                    </Row>
                    <form ref={formRef} style={{ display: 'none' }}>
                        <input value={company} {...register('company_name')} />
                        <input
                            value={invitedToFullName}
                            {...register('to_name')}
                        />
                        <input value={email} {...register('to_email')} />
                        <input value="" {...register('link_code')} />
                        <input type="submit" />
                    </form>
                </>
            }
            actions={
                <FooterWrapper>
                    {invitationStatusId === InvitationStatus.pending && (
                        <TernaryButtonWrapper>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={onCancelInvitation}
                            >
                                {userManagementInvitationsList.cancelInvitation}
                            </Button>
                        </TernaryButtonWrapper>
                    )}
                    <MainButtonsWrapper>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            {shared.close}
                        </Button>
                        {invitationStatusId === InvitationStatus.pending && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleReSendInvitation}
                            >
                                {userManagementInvitationsList.reSendInvitation}
                            </Button>
                        )}
                    </MainButtonsWrapper>
                </FooterWrapper>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        timezone,
    };
};

export default connect(mapStateToProps)(InvitationDetailDialog);
