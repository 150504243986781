import { api } from 'api';

import { DailyTasksReadParams, DailyTasksResponse } from 'types/dailyTasks';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, getToken } from 'lib/common';

export const useDailyTasks = (jsonParams: DailyTasksReadParams) => {
    const params = formatApiParams(jsonParams);

    return useErrorHandledQuery<DailyTasksResponse[]>(
        ['dailyTasks', jsonParams],
        async (): Promise<DailyTasksResponse[]> => {
            const { data } = await api.get('/tasks', {
                headers: { authorization: getToken() },
                params,
            });
            return data.response;
        },
        {
            enabled: !!jsonParams.branchId && !!jsonParams.date,
            staleTime: 300000,
        }
    );
};
