import { Box, Button, useMediaQuery } from '@mui/material';
import momentTz from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';

import { AddUnscheduledTask, DailyTasksCreateParams } from 'types/dailyTasks';
import { MessageProps } from 'types/messages';
import { ReduxStore } from 'types/redux';
import { ResidentShiftNotesCreateUpdateParams } from 'types/residentShiftNotes';

import {
    useRewardsDetailsQuery,
    useRewardsHistoryQuery,
    useRewardsSummaryQuery,
} from 'api/queries/caregiverRewards';
import { useDailyTasks } from 'api/queries/tasks/dailyTasks';
import notificationSound from 'assets/notification-sound.mp3';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';
import { useAppDispatch } from 'constants/redux';
import ShiftNoteDialog from 'pages/Residents/Details/components/ShiftNoteDialog';
import { createDailyTask } from 'redux/actions/dailyTasks';
import { showAlert } from 'redux/actions/messages';
import { createResidentShiftNotes } from 'redux/actions/residentShiftNotes';
import { toggleResidentParty } from 'redux/actions/residents';

import UnscheduledTaskDialog from './UnscheduledTaskDialog';

type Props = {
    residentId: number;
    date: string;
};

// just define the onsubmits for each item

export const AddedInfoActions = (props: Props) => {
    const { residentId, date } = props;

    const [isUnscheduledTaskDialogOpen, setIsUnscheduledTaskDialogOpen] =
        useState<boolean>(false);
    const toggleIsUnscheduledTaskDialogOpen = () =>
        setIsUnscheduledTaskDialogOpen((currentValue) => !currentValue);

    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] =
        useState<boolean>(false);
    const toggleIsShiftNoteDialogOpen = () =>
        setIsShiftNoteDialogOpen((currentValue) => !currentValue);

    const [playCoinSound] = useSound(notificationSound);

    const isMobile = useMediaQuery('(max-width:1024px)');

    const dispatch = useAppDispatch();
    const {
        sessionData: { userId: caregiverId, branchId },
        timezone,
    } = useSelector((state: ReduxStore) => state.session);

    const { refetch: rewardsDetailsRefetch } =
        useRewardsDetailsQuery(caregiverId);
    const { refetch: rewardsSummaryRefetch } =
        useRewardsSummaryQuery(caregiverId);
    const { refetch: rewardsHistoryRefetch } =
        useRewardsHistoryQuery(caregiverId);

    const { refetch: dailyTasksRefetch } = useDailyTasks({
        branchId: Number(branchId),
        date,
    });

    const { missingParamsForAddedTask: missingParamsForAddedTaskTxt } =
        useSelector((state: ReduxStore) => state.language.dictionary.home);

    if (!timezone) {
        throw new Error('no timezone specified');
    }

    const handleUnscheduledTaskSubmit = async (
        formValues: AddUnscheduledTask
    ) => {
        const { companyCarePlanId } = formValues;

        // Check if is missing any form value.
        const isFormIncomplete = !companyCarePlanId;

        // If there is any missing value, throw an error.
        if (isFormIncomplete) {
            // Set the message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: missingParamsForAddedTaskTxt,
                alertSeverity: 'error',
                status: 400,
            };

            dispatch(showAlert(message));
        } else if (!isFormIncomplete) {
            // Define the params to send to the API.
            const params: DailyTasksCreateParams = {
                companyCarePlanId,
                userId: caregiverId,
                residentId,
                taskType: 'Added',
            };

            if (!branchId) {
                throw new Error('no branch specified');
            }

            // Trigger the action for creating the Added Task.
            await dispatch(createDailyTask(params));

            dailyTasksRefetch();
            rewardsDetailsRefetch();
            rewardsSummaryRefetch();
            rewardsHistoryRefetch();
            dispatch(toggleResidentParty());
            playCoinSound();
        }
    };

    const handleShiftNoteSubmit = async (
        report: string,
        newShiftNoteCategory: number
    ) => {
        // Define the params to submit into the API.
        const params: ResidentShiftNotesCreateUpdateParams = {
            residentId,
            userId: caregiverId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
            dateTime: momentTz
                .tz(new Date(), timezone)
                .format('YYYY-MM-DD HH:mm:ss'),
        };

        // Trigger the action for Creating the Resident Info Report.
        await dispatch(createResidentShiftNotes(params));
        rewardsDetailsRefetch();
        rewardsSummaryRefetch();
        rewardsHistoryRefetch();
        dispatch(toggleResidentParty());
        playCoinSound();
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? pxToRem(0) : pxToRem(8),
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        width: '100%',
                        padding: pxToRem(12),
                        backgroundColor: 'app.green.main',
                        borderColor: 'app.green.light',
                        color: '#fff',
                        marginBottom: pxToRem(8),
                        fontSize: pxToRem(14),
                        fontWeight: 600,
                        '&:hover': {
                            borderColor: 'grey.500',
                            backgroundColor: 'app.green.light',
                        },
                    }}
                    onClick={toggleIsUnscheduledTaskDialogOpen}
                >
                    <Box
                        sx={{
                            marginRight: pxToRem(8),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    Add Unscheduled/PRN Task
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        width: '100%',
                        padding: pxToRem(12),
                        backgroundColor: 'app.green.main',
                        borderColor: 'app.green.light',
                        color: '#fff',
                        marginBottom: pxToRem(8),
                        fontSize: pxToRem(14),
                        fontWeight: 600,
                        '&:hover': {
                            borderColor: 'grey.500',
                            backgroundColor: 'app.green.light',
                        },
                    }}
                    onClick={toggleIsShiftNoteDialogOpen}
                >
                    <Box
                        sx={{
                            marginRight: pxToRem(8),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    Add Resident Note
                </Button>
            </Box>
            <UnscheduledTaskDialog
                isOpen={isUnscheduledTaskDialogOpen}
                dialogType="Create"
                onSubmit={handleUnscheduledTaskSubmit}
                onClose={toggleIsUnscheduledTaskDialogOpen}
            />
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                onClose={toggleIsShiftNoteDialogOpen}
                onSubmit={handleShiftNoteSubmit}
            />
        </>
    );
};
