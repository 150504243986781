import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGeolocation } from 'contexts/GeolocationContext';
import React from 'react';

import GeolocationPrompt from '../../../assets/geolocation-prompt.svg';
import { pxToRem } from 'components/theme/typography';
import { SIDE_MENU_WIDTH } from 'constants/app';

const OutsideGeofence = () => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const { updatePosition } = useGeolocation();

    const Container = styled(Box)(({ theme }) =>
        theme.unstable_sx({
            padding: pxToRem(16),
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            maxWidth: pxToRem(1024),
            ...(isMobile
                ? { alignItems: 'flex-start' }
                : {
                      position: 'absolute',
                      top: '50%',
                      left: `calc(50% + ${SIDE_MENU_WIDTH / 2}px)`,
                      transform: 'translate(-50%, -50%)',
                      alignItems: 'center',
                  }),
        })
    );

    const Title = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#2C2D3B',
            fontSize: {
                xs: pxToRem(24),
                lg: pxToRem(40),
            },

            lineHeight: pxToRem(34),
            fontWeight: 700,
            marginBottom: pxToRem(16),
            ...(isMobile ? {} : {}),
        })
    );

    const Subtitle = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#6F6F79',
            fontSize: {
                xs: pxToRem(14),
                lg: pxToRem(16),
            },
            maxWidth: 460,
            lineHeight: 1.5,
            fontWeight: 400,
            marginBottom: pxToRem(16),
            textAlign: 'center',
            ...(isMobile ? { textAlign: 'flex-start' } : {}),
        })
    );

    const RefreshButton = styled(Button)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            borderRadius: pxToRem(4),
            p: `${pxToRem(8)} ${pxToRem(16)}`,
            fontWeight: 700,
            fontSize: pxToRem(14),
            color: '##fff !important',
            backgroundColor: '#006B75 !important',
            marginBottom: pxToRem(8),
            alignSelf: 'flex-start',
            '&:disabled': {
                backgroundColor: '#61A3A9 !important',
            },
            ...(isMobile ? { maxWidth: 460 } : {}),
        })
    );

    return (
        <Container>
            <img
                style={{
                    width: pxToRem(isMobile ? 80 : 120),
                    marginBottom: pxToRem(24),
                }}
                src={GeolocationPrompt}
                alt="icon"
            />

            <Title>Outside of Community</Title>
            <Subtitle>It looks like you not near your community.</Subtitle>
            <Subtitle>This page will be available once you go back.</Subtitle>
            <RefreshButton onClick={updatePosition}>Refresh</RefreshButton>
        </Container>
    );
};

export default OutsideGeofence;
