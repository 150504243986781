import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const ClipboardIcon = ({ className, size }: Props) => (
    <Base className={className} size={size}>
        <path
            d="M15.1 2h-4.7c-1.04 0-1.89.84-1.89 1.88v.94c0 1.04.84 1.88 1.88 1.88h4.71c1.04 0 1.88-.84 1.88-1.88v-.94C16.99 2.84 16.14 2 15.1 2Z"
            fill="currentColor"
        />
        <path
            d="M17.99 4.82c0 1.59-1.3 2.89-2.89 2.89h-4.7c-1.59 0-2.89-1.3-2.89-2.89 0-.56-.6-.91-1.1-.65a4.472 4.472 0 0 0-2.37 3.95v9.41c0 2.46 2.01 4.47 4.47 4.47h8.48c2.46 0 4.47-2.01 4.47-4.47V8.12c0-1.71-.96-3.2-2.37-3.95-.5-.26-1.1.09-1.1.65Zm-1.9 7.91-4 4c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-1.5-1.5a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l.97.97 3.47-3.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06Z"
            fill="currentColor"
        />
    </Base>
);
