import { alpha } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

export default function Switch(theme) {
    const { palette } = theme;
    return {
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: pxToRem(8),
                    width: pxToRem(72),
                    height: pxToRem(34),
                    '& .MuiSwitch-switchBase': {
                        margin: pxToRem(1),
                        padding: 0,
                        transform: `translateX(${pxToRem(0)})`,
                        '&.Mui-checked': {
                            backgroundColor: palette.app.green.main,
                            color: palette.common.white,
                            transform: `translateX(${pxToRem(38)})`,
                            '& .MuiSwitch-thumb': {
                                backgroundColor: palette.app.green.main,
                                '&:before': {
                                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                        '#fff'
                                    )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>')`,
                                },
                            },
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: alpha(
                                    palette.app.green.main,
                                    0.5
                                ),
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        backgroundColor: palette.app.orange.main,
                        width: pxToRem(32),
                        height: pxToRem(32),
                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            left: 0,
                            top: 0,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                '#fff'
                            )}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"/></svg>')`,
                        },
                    },
                    '& .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: alpha(palette.app.orange.main, 0.5),
                        borderRadius: 20 / 2,
                    },
                },
            },
        },
    };
}
