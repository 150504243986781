import { dictionaryList, languageOptions } from 'languages';

import { LanguageOptions } from 'types/language';
import { Action, LanguageState } from 'types/redux';

import { LANGUAGE_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const language = (
    state: LanguageState = LANGUAGE_DEFAULT_STATE,
    action: Action
) => {
    switch (action.type) {
        case actionTypes.LANGUAGE_TOGGLE: {
            const langIndex: number = languageOptions.findIndex(
                (opt) => opt.id === action.payload.languageId
            );
            const newLanguage: LanguageOptions = languageOptions[langIndex];
            return {
                id: newLanguage.id,
                dictionary: dictionaryList[newLanguage.id],
                label: newLanguage.label,
                totalLanguages: languageOptions.length,
            };
        }
        default:
            return state;
    }
};

export default language;
