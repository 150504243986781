import { ResidentShiftNotesState } from 'types/redux';

import { RESIDENT_SHIFT_NOTESS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const residentShiftNotes = (
    state: ResidentShiftNotesState = RESIDENT_SHIFT_NOTESS_DEFAULT_STATE,
    action
) => {
    switch (action.type) {
        case actionTypes.RESIDENT_SHIFT_NOTES_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.RESIDENT_SHIFT_NOTES_READ_LIST: {
            return {
                ...state,
                residentShiftNotesList: action.payload,
            };
        }
        case actionTypes.RESIDENT_SHIFT_NOTES_DELETE: {
            const residentShiftNotesList = state.residentShiftNotesList
                ? state.residentShiftNotesList.filter(
                      (residentShiftNote) =>
                          residentShiftNote.residentShiftNoteId ===
                          action.payload
                  )
                : [];

            return {
                ...state,
                residentShiftNotesList,
            };
        }
        default:
            return state;
    }
};

export default residentShiftNotes;
