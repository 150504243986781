import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
};

export const GroomingIcon = ({ className, size }: Props) => (
    <Base className={className} size={size} viewBox="0 0 16 16">
        <g clipPath="url(#clip0_5924_38189)">
            <path
                d="M14.3956 8.81631C14.2321 8.6903 14.0447 8.59889 13.8447 8.54763C13.6448 8.49638 13.4365 8.48636 13.2325 8.51818C14.4062 7.33318 15 6.15506 15 5.00006C15 3.34568 13.6694 2.00006 12.0337 2.00006C11.5998 1.99733 11.1704 2.08964 10.7759 2.27049C10.3814 2.45134 10.0312 2.71636 9.75 3.04693C9.46879 2.71636 9.11863 2.45134 8.7241 2.27049C8.32958 2.08964 7.90025 1.99733 7.46625 2.00006C5.83063 2.00006 4.5 3.34568 4.5 5.00006C4.5 5.68756 4.7025 6.35568 5.12875 7.06256C4.77965 7.151 4.46106 7.33244 4.20687 7.58756L2.79312 9.00006H1C0.734784 9.00006 0.48043 9.10542 0.292893 9.29295C0.105357 9.48049 0 9.73484 0 10.0001L0 12.5001C0 12.7653 0.105357 13.0196 0.292893 13.2072C0.48043 13.3947 0.734784 13.5001 1 13.5001H7.5C7.54088 13.5001 7.58161 13.495 7.62125 13.4851L11.6213 12.4851C11.6467 12.479 11.6716 12.4706 11.6956 12.4601L14.125 11.4263L14.1525 11.4138C14.386 11.2971 14.5859 11.123 14.7335 10.9077C14.881 10.6924 14.9713 10.443 14.9959 10.1832C15.0205 9.92335 14.9785 9.66153 14.8739 9.42239C14.7693 9.18325 14.6056 8.97467 14.3981 8.81631H14.3956ZM13.7144 10.5132L11.3394 11.5244L7.4375 12.5001H3.5V9.70693L4.91437 8.29318C5.0069 8.19992 5.11703 8.12598 5.23839 8.07566C5.35974 8.02534 5.48988 7.99964 5.62125 8.00006H8.75C8.94891 8.00006 9.13968 8.07908 9.28033 8.21973C9.42098 8.36038 9.5 8.55115 9.5 8.75006C9.5 8.94897 9.42098 9.13974 9.28033 9.28039C9.13968 9.42104 8.94891 9.50006 8.75 9.50006H7C6.86739 9.50006 6.74021 9.55274 6.64645 9.6465C6.55268 9.74027 6.5 9.86745 6.5 10.0001C6.5 10.1327 6.55268 10.2598 6.64645 10.3536C6.74021 10.4474 6.86739 10.5001 7 10.5001H9C9.03763 10.5 9.07514 10.4958 9.11187 10.4876L13.2994 9.52443L13.3188 9.51943C13.4466 9.48395 13.583 9.49699 13.7018 9.55605C13.8206 9.61511 13.9134 9.71601 13.9622 9.83935C14.0111 9.96269 14.0126 10.0997 13.9665 10.2241C13.9204 10.3485 13.8299 10.4515 13.7125 10.5132H13.7144Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_5924_38189">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </Base>
);
