import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    taskStatusId: number;
    onToggleRejectDialog: () => void;
};

export const TaskOptions = (props: Props) => {
    const { taskStatusId, onToggleRejectDialog } = props;

    const isRejected =
        taskStatusId === 4 ||
        taskStatusId === 5 ||
        taskStatusId === 6 ||
        taskStatusId === 7 ||
        taskStatusId === 8;

    const baseStyles = {
        height: pxToRem(54),
        width: pxToRem(54),
    };

    const completeIconStyles = {
        ...baseStyles,
        ...(isRejected ? { color: 'grey.300' } : {}),
        ...(!isRejected ? { color: 'app.green.main' } : {}),
    };

    const rejectIconStyles = {
        ...baseStyles,
        ...(!isRejected ? { color: 'grey.300' } : {}),
        ...(isRejected ? { color: 'error.main' } : {}),
    };

    const completeOnClick = !isRejected ? onToggleRejectDialog : undefined;
    const rejectOnClick = isRejected ? onToggleRejectDialog : undefined;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CheckCircleOutlined
                sx={completeIconStyles}
                onClick={completeOnClick}
            />
            <CancelOutlined sx={rejectIconStyles} onClick={rejectOnClick} />
        </Box>
    );
};
