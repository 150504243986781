import {
    VisibilityOff as HidePasswordIcon,
    Visibility as ShowPasswordIcon,
} from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { decode as base64Decode } from 'base-64';
import { cloneDeep, isEmpty, isNil, omit } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { NotificationDialogProps } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import {
    PasswordFormData,
    UserFormErrors,
    UserUpdatePasswordParams,
} from 'types/users';

import { CustomTextField } from 'components/Custom';
import AllieHealthLogo from 'components/Shared/AllieHealthLogo';
import NotificationDialog from 'components/Shared/NotificationDialog';
import { pxToRem } from 'components/theme/typography';
import { BASE_PATH, DATE_TIME_FORMAT, LOGIN_PATH } from 'constants/app';
import { PASSWORD_REGEX } from 'constants/regex';
import {
    INITIAL_NOTIFICATION_DIALOG_STATE,
    NOTIFICATION_TYPE,
} from 'constants/shared';
import AccessControl from 'helpers/AccessControl';
import { calculateElapsedTime } from 'lib/common';
import PageStructure from 'pages/PageStructure';
import { readUserIdByEmail, updatePassword } from 'redux/actions/users';

const MainContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    })
);

const MessageContainer = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(14), lg: pxToRem(14) },
        lineHeight: { xs: pxToRem(14), lg: pxToRem(14) },
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'center',
    })
);

const TopSection = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(48),
        mb: pxToRem(48),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    })
);

const Title = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(24), lg: pxToRem(20) },
        lineHeight: { xs: pxToRem(24), lg: pxToRem(20) },
        fontWeight: 'bold',
    })
);

const Instructions = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        m: 0,
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(14), lg: pxToRem(14) },
        lineHeight: { xs: pxToRem(14), lg: pxToRem(14) },
        color: theme.palette.grey[600],
        fontWeight: 'normal',
    })
);

const FormWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    })
);

const CancelButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
    })
);

type Props = {
    dictionary: LanguageStructure;
    userId?: number;
    dispatchReadUserIdByEmail: (email: string) => void;
    dispatchUpdatePassword: (
        userId: number,
        jsonParams: UserUpdatePasswordParams
    ) => void;
};

const PasswordForm = (props: Props) => {
    const navigate = useNavigate();
    const { encodedLink } = useParams();
    const {
        dictionary: {
            passwordReset,
            shared,
            signupForm,
            userManagement,
            userProfile,
        },
        userId,
        dispatchReadUserIdByEmail,
        dispatchUpdatePassword,
    } = props;
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [errorMessageComplement, setErrorMessageComplement] =
        useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [form, setForm] = useState<PasswordFormData>({} as PasswordFormData);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] =
        useState<boolean>(false);
    const [errors, setErrors] = useState<UserFormErrors>({});
    const [notificationData, setNotificationData] =
        useState<NotificationDialogProps>(INITIAL_NOTIFICATION_DIALOG_STATE);
    const [disableButtons, setDisableButtons] = useState<boolean>(false);

    const togglePasswordView = (field: string) => () => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const validateForm = (hasErrors?: boolean) => {
        const validRequired = !!(form.password && form.confirmPassword);
        const validForm: boolean =
            !hasErrors && isEmpty(errors) && validRequired;
        setIsFormValid(validForm);
    };

    const passwordValidation = (field: string, fieldValue: string) => {
        // DON'T REMOVE THESE FIRST THREE LINES.
        // Initialize the last index for each time they need to be validated. Otherwise the validation could fail.
        PASSWORD_REGEX.lastIndex = 0;

        // Validate the field.
        const validPassword = PASSWORD_REGEX.test(fieldValue);

        // Verify the field type.
        const isConfirmation = field === 'confirmPassword';

        if (isConfirmation && fieldValue !== form.password) {
            setErrors({
                ...errors,
                [field]: signupForm.passwordMismatch,
            });
            return;
        }

        if (!validPassword) {
            setErrors({
                ...errors,
                [field]: signupForm.passwordError,
            });
            return;
        }

        const errorsClone = cloneDeep(errors);
        delete errorsClone[field];
        setErrors(errorsClone);
    };

    const handleFormFieldChange = (field: string) => (newValue: string) => {
        passwordValidation(field, newValue as string);

        setForm({
            ...form,
            [field]: newValue,
        });
    };

    const handleCancelClick = () => {
        navigate(`${BASE_PATH}${LOGIN_PATH}`);
    };

    const handleSubmitClick = async () => {
        // Do nothing if the button was triggered but the form is invalid.
        if (!isFormValid || !userId) {
            return;
        }

        // Set the params to send to the API.
        const jsonParams: UserUpdatePasswordParams = omit(form, [
            'confirmPassword',
        ]);

        setNotificationData({
            closable: false,
            isOpen: true,
            title: signupForm.processingTitle,
            message: signupForm.processingMessage,
            type: NOTIFICATION_TYPE.info,
        });

        setDisableButtons(true);
        setSuccessMessage('');

        try {
            // Complete the Sign-up process.
            await dispatchUpdatePassword(userId, jsonParams);

            setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);
            setSuccessMessage(
                `${passwordReset.notificationMessageSuccess} ${userManagement.gotoLogin}`
            );
        } catch (error) {
            setDisableButtons(false);
            setNotificationData({
                isOpen: true,
                title: passwordReset.pageDetailsTitle,
                message: passwordReset.notificationMessageError,
                type: NOTIFICATION_TYPE.info,
            });
        }
    };

    const handleCloseNotification = () => {
        if (notificationData.type !== NOTIFICATION_TYPE.error) {
            const formClone = cloneDeep(form);
            formClone.password = '';
            formClone.confirmPassword = '';
            setForm(formClone);
        }

        setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);
    };

    useEffect(() => {
        if (!encodedLink) {
            setErrorMessage(
                `${passwordReset.thankYou} ${userManagement.inexistentCode}`
            );
            setErrorMessageComplement('');
        } else if (encodedLink) {
            try {
                // Decode the link code.
                const decodedLink = JSON.parse(base64Decode(encodedLink));

                // If the decoded link hasn't the "email" or "timestamp" properties, throw an error. Otherwise, look for the invitation.
                if (!decodedLink.email || !decodedLink.timestamp) {
                    setErrorMessage(
                        `${passwordReset.thankYou} ${passwordReset.codeInvalid}`
                    );
                    setErrorMessageComplement('');
                } else {
                    const { email, timestamp, self } = decodedLink;
                    const linkDateTime =
                        moment(timestamp).format(DATE_TIME_FORMAT);
                    const currentDateTime = moment().format(DATE_TIME_FORMAT);
                    const { weeks, days, hours, minutes } =
                        calculateElapsedTime(linkDateTime, currentDateTime);
                    const isLinkExpired =
                        weeks > 0 ||
                        days > 0 ||
                        hours > 1 ||
                        (hours === 1 && minutes > 0);

                    if (isLinkExpired) {
                        setErrorMessage(
                            `${passwordReset.thankYou} ${passwordReset.codeExpired}`
                        );
                        setErrorMessageComplement(
                            !self
                                ? passwordReset.codeExpiredManager
                                : passwordReset.codeExpiredSelf
                        );
                    } else {
                        setForm({
                            password: '',
                            confirmPassword: '',
                        });
                        dispatchReadUserIdByEmail(email);
                    }
                }
            } catch (error) {
                // Show an error message if the decoding process fails.
                setErrorMessage(
                    `${passwordReset.thankYou} ${passwordReset.code}`
                );
            }
        }
    }, [encodedLink, dispatchReadUserIdByEmail]);

    useEffect(() => {
        if (errorMessage) {
            setSuccessMessage('');
        } else if (successMessage) {
            setErrorMessage('');
        }
    }, [errorMessage, successMessage]);

    return (
        <PageStructure>
            <MainContainer>
                <AllieHealthLogo />
                {!!errorMessage && (
                    <TopSection>
                        <MessageContainer>
                            {errorMessage}{' '}
                            {errorMessageComplement && errorMessageComplement}
                        </MessageContainer>
                        <MessageContainer>
                            {userManagement.gotoLogin}
                        </MessageContainer>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelClick}
                        >
                            {shared.goToLoginPage}
                        </Button>
                    </TopSection>
                )}
                {!!successMessage && (
                    <TopSection>
                        <MessageContainer>{successMessage}</MessageContainer>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelClick}
                        >
                            {shared.goToLoginPage}
                        </Button>
                    </TopSection>
                )}
                {!errorMessage && !successMessage && (
                    <>
                        <TopSection>
                            <Title>{passwordReset.pageDetailsTitle}</Title>
                            <Instructions>
                                {passwordReset.instructions}
                            </Instructions>
                        </TopSection>
                        {!isNil(form) && !isEmpty(form) && (
                            <FormWrapper>
                                <CustomTextField
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={userProfile.password}
                                    value={form.password}
                                    customErrorMessage={errors.password}
                                    fullWidth
                                    required
                                    endAdornment={
                                        <IconButton
                                            onClick={togglePasswordView(
                                                'password'
                                            )}
                                        >
                                            {showPassword ? (
                                                <HidePasswordIcon />
                                            ) : (
                                                <ShowPasswordIcon />
                                            )}
                                        </IconButton>
                                    }
                                    onBlur={validateForm}
                                    onChange={handleFormFieldChange('password')}
                                />
                                <CustomTextField
                                    id="confirmPassword"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    label={userProfile.confirmPassword}
                                    value={form.confirmPassword}
                                    customErrorMessage={errors.confirmPassword}
                                    fullWidth
                                    required
                                    endAdornment={
                                        <IconButton
                                            onClick={togglePasswordView(
                                                'passwordConfirm'
                                            )}
                                        >
                                            {showConfirmPassword ? (
                                                <HidePasswordIcon />
                                            ) : (
                                                <ShowPasswordIcon />
                                            )}
                                        </IconButton>
                                    }
                                    onBlur={validateForm}
                                    onChange={handleFormFieldChange(
                                        'confirmPassword'
                                    )}
                                />
                                <ButtonsWrapper>
                                    <CancelButton
                                        variant="outlined"
                                        color="secondary"
                                        disabled={disableButtons}
                                        onClick={handleCancelClick}
                                    >
                                        {shared.cancel}
                                    </CancelButton>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !isFormValid || disableButtons
                                        }
                                        onClick={handleSubmitClick}
                                    >
                                        {userManagement.resetPassword}
                                    </Button>
                                </ButtonsWrapper>
                                <NotificationDialog
                                    closable={notificationData.closable}
                                    isOpen={notificationData.isOpen}
                                    message={notificationData.message}
                                    title={notificationData.title}
                                    type={notificationData.type}
                                    onClose={handleCloseNotification}
                                />
                            </FormWrapper>
                        )}
                    </>
                )}
            </MainContainer>
        </PageStructure>
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { userId } = users;

    return {
        dictionary,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUserIdByEmail: (email: string) =>
        dispatch(readUserIdByEmail(email)),
    dispatchUpdatePassword: (
        userId: number,
        jsonParams: UserUpdatePasswordParams
    ) => dispatch(updatePassword(userId, jsonParams, true)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedPasswordForm: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(PasswordForm)
);

export default ConnectedPasswordForm;
