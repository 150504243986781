import { Capacitor } from '@capacitor/core';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    AndroidSettings,
    IOSSettings,
    NativeSettings,
} from 'capacitor-native-settings';
import { useGeolocation } from 'contexts/GeolocationContext';
import React from 'react';

import GeolocationDisabled from '../../../assets/geolocation-disabled.svg';
import GeolocationPrompt from '../../../assets/geolocation-prompt.svg';
import Location1Android from '../../../assets/location_1_android.png';
import Location1iOS from '../../../assets/location_1_ios.png';
import Location2Android from '../../../assets/location_2_android.png';
import Location2iOS from '../../../assets/location_2_ios.png';
import Location3Android from '../../../assets/location_3_android.png';
import Precise1Android from '../../../assets/precise_1_android.png';
import Precise1iOS from '../../../assets/precise_1_ios.png';
import { pxToRem } from 'components/theme/typography';
import { SIDE_MENU_WIDTH } from 'constants/app';

const isNativePlatform = Capacitor.isNativePlatform();
const platform = Capacitor.getPlatform() as 'android' | 'ios' | 'web';

const InstructionImage = ({ src, alt = 'image' }) => (
    <img
        style={{
            marginBottom: pxToRem(24),
        }}
        src={src}
        alt={alt}
    />
);

const GeolocationInfo = () => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const { permissionStatus, updatePosition, isPositionAccurate } =
        useGeolocation();

    const isPermissionDenied = () => {
        if (Capacitor.getPlatform() === 'android') {
            return permissionStatus?.coarseLocation === 'denied';
        }

        return permissionStatus?.location === 'denied';
    };

    // Components

    const Container = styled(Box)(({ theme }) =>
        theme.unstable_sx({
            padding: pxToRem(16),
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            maxWidth: pxToRem(1024),
            ...(isMobile
                ? { alignItems: 'flex-start' }
                : {
                      position: 'absolute',
                      top: '50%',
                      left: `calc(50% + ${SIDE_MENU_WIDTH / 2}px)`,
                      transform: 'translate(-50%, -50%)',
                      alignItems: 'center',
                  }),
        })
    );

    const Title = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#2C2D3B',
            fontSize: {
                xs: pxToRem(24),
                lg: pxToRem(40),
            },

            lineHeight: pxToRem(34),
            fontWeight: 700,
            marginBottom: pxToRem(16),
            ...(isMobile ? {} : {}),
        })
    );

    const Subtitle = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#6F6F79',
            fontSize: {
                xs: pxToRem(14),
                lg: pxToRem(16),
            },
            maxWidth: 460,
            lineHeight: 1.5,
            fontWeight: 400,
            marginBottom: pxToRem(16),
            textAlign: 'center',
            ...(isMobile ? { textAlign: 'flex-start' } : {}),
        })
    );

    const ActionButton = styled(Button)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            borderRadius: pxToRem(4),
            p: `${pxToRem(8)} ${pxToRem(16)}`,
            fontWeight: 700,
            fontSize: pxToRem(14),
            color: '##fff !important',
            backgroundColor: '#006B75 !important',
            marginBottom: pxToRem(8),
            alignSelf: 'flex-start',
            '&:disabled': {
                backgroundColor: '#61A3A9 !important',
            },
            ...(isMobile ? { maxWidth: 460 } : {}),
        })
    );

    // Application States

    if (permissionStatus?.location.includes('prompt')) {
        return (
            <Container>
                <img
                    style={{
                        width: pxToRem(isMobile ? 80 : 120),
                        marginBottom: pxToRem(24),
                    }}
                    src={GeolocationPrompt}
                    alt="icon"
                />

                <Title>Activate Geolocation</Title>
                <Subtitle>
                    Geolocation enables essential features such as emergency
                    response, location-based reminders, safe zones. Activate now
                    to unlock your access to the app.
                </Subtitle>
                <ActionButton onClick={updatePosition}>Activate</ActionButton>
            </Container>
        );
    }
    if (isPermissionDenied()) {
        return (
            <Container>
                <img
                    style={{
                        width: pxToRem(isMobile ? 80 : 120),
                        marginBottom: pxToRem(24),
                    }}
                    src={GeolocationDisabled}
                    alt="icon"
                />

                <Title>Disabled Geolocation</Title>
                <Subtitle>
                    {`Activating geolocation is important to make sure that you're here in our community.`}
                </Subtitle>
                <Subtitle>
                    {`If you have accidentally deactivated your geolocation, don't worry! You can manually activate geolocation on your phone settings. Follow these steps:`}
                </Subtitle>

                {/* iOS Steps */}
                {platform === 'ios' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location1iOS} />
                        <Subtitle>{`3. Select "While Using the App"`}</Subtitle>
                        <InstructionImage src={Location2iOS} />
                    </>
                )}

                {platform === 'android' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Permissions"`}</Subtitle>
                        <InstructionImage src={Location1Android} />
                        <Subtitle>{`3. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location2Android} />
                        <Subtitle>{`4. Select "Allow only while using the app"`}</Subtitle>
                        <InstructionImage src={Location3Android} />
                    </>
                )}

                {isNativePlatform && (
                    <ActionButton
                        onClick={() => {
                            NativeSettings.open({
                                optionAndroid:
                                    AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App,
                            });
                        }}
                    >
                        Go to Settings
                    </ActionButton>
                )}
            </Container>
        );
    }
    if (!isPositionAccurate) {
        return (
            <Container>
                <img
                    style={{
                        width: pxToRem(isMobile ? 80 : 120),
                        marginBottom: pxToRem(24),
                    }}
                    src={GeolocationDisabled}
                    alt="icon"
                />

                <Title>Inaccurate Geolocation</Title>
                <Subtitle>
                    {`Please enable the "Precise Location" option for AllieHealth in your system settings. Follow these steps:`}
                </Subtitle>
                {platform === 'ios' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location1iOS} />
                        <Subtitle>{`3. Turn on "Precise Location"`}</Subtitle>
                        <InstructionImage src={Precise1iOS} />
                    </>
                )}
                {platform === 'android' && (
                    <>
                        <Subtitle>
                            {`1. Read steps below, then click on the "Go to Settings" button on the bottom of this page`}
                        </Subtitle>
                        <Subtitle>{`2. Select "Permissions"`}</Subtitle>
                        <InstructionImage src={Location1Android} />
                        <Subtitle>{`3. Select "Location"`}</Subtitle>
                        <InstructionImage src={Location2Android} />
                        <Subtitle>{`4. Turn on "Use precise location"`}</Subtitle>
                        <InstructionImage src={Precise1Android} />
                    </>
                )}
                {isNativePlatform && (
                    <ActionButton
                        onClick={() => {
                            NativeSettings.open({
                                optionAndroid:
                                    AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App,
                            });
                        }}
                    >
                        Go to Settings
                    </ActionButton>
                )}
            </Container>
        );
    }

    return null;
};

export default GeolocationInfo;
