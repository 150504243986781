import {
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import {
    Box,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { cloneDeep, isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { SelectStructure, SelectValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { CheckedRow, Column, Row, SortDirection } from 'types/table';

import { CustomCheckbox, CustomRowsPerPageSelector } from 'components/Custom';
import BodyRow from 'components/Custom/CustomTable/BodyRow';
import ColumnHeader from 'components/Custom/CustomTable/ColumnHeader';
import { pxToRem } from 'components/theme/typography';
import {
    ALIGNMENT_TYPES,
    ROWS_PER_PAGE_OPTIONS,
    SORT_DIRECTIONS,
} from 'constants/table';
import { updateCaregiver } from 'redux/actions/dashboards';

const RootStyle = styled(Paper)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'none',
    })
);

const ExpandButtonCell = styled(TableCell)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(32),
    })
);

export const CheckboxColumn = styled(TableCell)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(32),
        minWidth: 'unset',
    })
);

const PaginationControlContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
    })
);

const PaginationBlock = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '50%',
        '&:last-of-type': {
            justifyContent: 'flex-end',
        },
    })
);

const PaginationBlockLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(14) },
    })
);

type Props = {
    columns: Column[];
    dictionary: LanguageStructure;
    maxHeight?: string | number;
    minHeight?: string | number;
    page?: number;
    rows: Row[];
    rowsPerPage?: number;
    sortBy?: string;
    sortDirection?: SortDirection;
    totalRows?: number;
    useCheckBoxes?: boolean;
    usePagination?: boolean;
    onPageChange?: (newPage: number) => void;
    onRowsPerPageChange?: (newRowsPerPage: number) => void;
    onRowsSelectChange?: (selectedRows: number[]) => void;
    onSortChange?: (
        newSortBy?: string,
        newSortDirection?: SortDirection
    ) => void;
    rowsCheckedStatus: CheckedRow;
    setRowsCheckedStatus: (checkedRow: CheckedRow) => void;
    usersList: any;
    dispatchUpdateCaregiver: (
        dailyTaskRecordId: number,
        jsonParams: { user_id: number },
        callbackSuccess: () => void
    ) => void;
};

const UnconnectedCustomTable = (props: Props) => {
    const {
        columns,
        dictionary: { shared, dashboards },
        maxHeight = pxToRem(496),
        minHeight = pxToRem(240),
        page = 1,
        rows,
        rowsPerPage = ROWS_PER_PAGE_OPTIONS[0],
        sortBy,
        sortDirection,
        totalRows = rows.length,
        useCheckBoxes,
        usePagination,
        onPageChange,
        onRowsPerPageChange,
        onRowsSelectChange,
        onSortChange,
        rowsCheckedStatus,
        setRowsCheckedStatus,
        usersList,
        dispatchUpdateCaregiver,
    } = props;
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState<
        SelectStructure[]
    >([]);
    const [selectedRowsPerPage, setSelectedRowsPerPage] =
        useState<SelectValue>();
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isSelectAllPartial, setIsSelectAllPartial] =
        useState<boolean>(false);
    const count = totalRows;
    const totalPages = Math.ceil(count / rowsPerPage);
    const tableContainerStyles = {
        maxHeight,
        minHeight,
    };
    const startRecord = (page - 1) * rowsPerPage + 1;
    const endRecordBlock = page * rowsPerPage;
    const endRecord = endRecordBlock > totalRows ? totalRows : endRecordBlock;
    const paginationBlockData = shared.paginationBlockData
        .replace('{XX}', startRecord.toString())
        .replace('{YY}', endRecord.toString())
        .replace('{ZZ}', totalRows.toString());
    const hasDetails = !isUndefined(rows[0]?.rowDetails);

    const toggleSelectAll = (newCheckedStatus: boolean) => {
        const objectValues = !isEmpty(rowsCheckedStatus)
            ? cloneDeep(rowsCheckedStatus)
            : rows.reduce(
                  (acum, row) => ({ ...acum, [row.id as number]: false }),
                  {}
              );
        const newSelectedRows = Object.keys(objectValues).reduce(
            (acum, key) => ({ ...acum, [key]: newCheckedStatus }),
            {}
        );
        setRowsCheckedStatus(newSelectedRows);
    };

    const handleCheckAllChange = (newCheckedStatus: boolean) => {
        setSelectAll(newCheckedStatus);
        toggleSelectAll(newCheckedStatus);
        setIsSelectAllPartial(false);
    };

    const handleRowCheckedChange = (
        rowId: number,
        newCheckedStatus: boolean
    ) => {
        const newSelectedRows = cloneDeep(rowsCheckedStatus);
        newSelectedRows[rowId] = newCheckedStatus;

        const isPartialSelection =
            Object.values(newSelectedRows).filter((value) => value).length > 0;

        setRowsCheckedStatus(newSelectedRows);
        setIsSelectAllPartial(isPartialSelection);
        setSelectAll(!isPartialSelection);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlePageChange = (event: unknown, newPage: number) => {
        if (onPageChange) {
            onPageChange(newPage);
        }
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        if (onRowsPerPageChange) {
            onRowsPerPageChange(newRowsPerPage);
        }
    };

    const handleSortClick = (
        columnId: string,
        initialDirection: SortDirection
    ) => {
        let newSortDirection: SortDirection | undefined;
        const nextSortDirection: SortDirection =
            initialDirection === SORT_DIRECTIONS.asc
                ? SORT_DIRECTIONS.desc
                : SORT_DIRECTIONS.asc;

        if (sortBy !== columnId || !sortDirection) {
            newSortDirection = initialDirection;
        } else if (sortBy === columnId) {
            newSortDirection =
                sortDirection === initialDirection
                    ? nextSortDirection
                    : undefined;
        }

        const newSortBy: string | undefined = newSortDirection
            ? columnId
            : undefined;

        if (onSortChange) {
            onSortChange(newSortBy, newSortDirection);
        }
    };

    useEffect(() => {
        const selectOptions: SelectStructure[] = ROWS_PER_PAGE_OPTIONS.map(
            (option) => ({
                label: option.toString(),
                value: option.toString(),
            })
        );
        setRowsPerPageOptions(selectOptions);
        setSelectedRowsPerPage(selectOptions[0].value);
    }, []);

    useEffect(() => {
        if (useCheckBoxes && rows.length && isEmpty(rowsCheckedStatus)) {
            toggleSelectAll(false);
        }

        if (
            useCheckBoxes &&
            onRowsSelectChange &&
            !isEmpty(rowsCheckedStatus)
        ) {
            const selectedRows = Object.keys(rowsCheckedStatus)
                .filter((key) => rowsCheckedStatus[key])
                .map((rowId) => Number(rowId));
            onRowsSelectChange(selectedRows);
        }
    }, [rows, rowsCheckedStatus, useCheckBoxes]);

    useEffect(() => {
        setSelectedRowsPerPage(rowsPerPage.toString());
    }, [rowsPerPage]);

    return (
        <RootStyle>
            <TableContainer sx={tableContainerStyles}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {useCheckBoxes && (
                                <CheckboxColumn
                                    id="checkbox"
                                    align={ALIGNMENT_TYPES.center}
                                >
                                    <CustomCheckbox
                                        checked={selectAll}
                                        isPartial={isSelectAllPartial}
                                        onChange={handleCheckAllChange}
                                    />
                                </CheckboxColumn>
                            )}
                            {columns.map((column) => {
                                const {
                                    align = ALIGNMENT_TYPES.left,
                                    id,
                                    initialSortDirection = SORT_DIRECTIONS.asc,
                                    isSortable = false,
                                    minWidth = 'inherit',
                                    width = 'auto',
                                } = column;
                                const cellStyles = {
                                    width,
                                    minWidth,
                                };

                                return (
                                    <TableCell
                                        key={id}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        <ColumnHeader
                                            column={column}
                                            initialSortDirection={
                                                initialSortDirection
                                            }
                                            isSortable={isSortable}
                                            sortBy={sortBy}
                                            sortDirection={sortDirection}
                                            onSortChange={handleSortClick}
                                        />
                                    </TableCell>
                                );
                            })}
                            {hasDetails && <ExpandButtonCell />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <BodyRow
                                key={row.id as string}
                                row={row}
                                columns={columns}
                                isRowSelected={
                                    rowsCheckedStatus[row.id as number]
                                }
                                useCheckBoxes={useCheckBoxes}
                                onRowCheckedChange={handleRowCheckedChange}
                                dashboards={dashboards}
                                usersList={usersList}
                                dispatchUpdateCaregiver={
                                    dispatchUpdateCaregiver
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControlContainer>
                <PaginationBlock>
                    <PaginationBlockLabel>
                        {paginationBlockData}
                    </PaginationBlockLabel>
                </PaginationBlock>
                {usePagination && selectedRowsPerPage && (
                    <PaginationBlock>
                        <PaginationBlockLabel>
                            {shared.paginationRowsPerPage}
                        </PaginationBlockLabel>
                        <CustomRowsPerPageSelector
                            id="rowsPerPageSelector"
                            options={rowsPerPageOptions}
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                        />
                    </PaginationBlock>
                )}
            </PaginationControlContainer>
            {usePagination && (
                <Pagination
                    count={totalPages}
                    page={page}
                    boundaryCount={5}
                    renderItem={(item) => {
                        if (totalPages === 1) {
                            return null;
                        }

                        return (
                            <PaginationItem
                                components={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                }}
                                {...item}
                            />
                        );
                    }}
                    onChange={handlePageChange}
                />
            )}
        </RootStyle>
    );
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateCaregiver: (
        dailyTaskRecordId: number,
        jsonParams: { user_id: number },
        callbackSuccess: () => void
    ) =>
        dispatch(
            updateCaregiver(dailyTaskRecordId, jsonParams, callbackSuccess)
        ),
});

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { usersList } = users;

    return {
        dictionary,
        usersList,
    };
};

export const CustomTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(UnconnectedCustomTable);
