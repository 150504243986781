export const TYPE_COMPLETED = 'TYPE_COMPLETED';
export const TYPE_OTHER_INCOMPLETE = 'TYPE_OTHER_INCOMPLETE';
export const TYPE_REFUSED = 'TYPE_REFUSED';
export const TYPE_UNDOCUMENTED = 'TYPE_UNDOCUMENTED';

export const TYPE_COLOR = {
    [TYPE_COMPLETED]: '#006B75',
    [TYPE_OTHER_INCOMPLETE]: '#478E94',
    [TYPE_REFUSED]: '#FA9C7A',
    [TYPE_UNDOCUMENTED]: '#DEDEE0',
};

export const TYPE_STR = {
    [TYPE_COMPLETED]: 'Completed',
    [TYPE_OTHER_INCOMPLETE]: 'Other Incomplete',
    [TYPE_REFUSED]: 'Refused',
    [TYPE_UNDOCUMENTED]: 'Undocumented',
};
