import { cloneDeep, padStart } from 'lodash';
import momentTz from 'moment-timezone';

import {
    DailyTasksCurrentDateAndShift,
    DailyTasksShiftPeriod,
} from 'types/dailyTasks';

import { SHIFTS_TIMES } from 'constants/home';
import { formatDate, getDateTimeTz24, getDateTz } from 'lib/common';

export const getCurrentDateAndShift = (
    timezone: string
): DailyTasksCurrentDateAndShift => {
    const currentDateTz = getDateTz(timezone);
    const currentDateTimeTz = getDateTimeTz24(timezone);
    const currentDate = new Date(currentDateTimeTz);
    const currentTime = currentDate.getTime();
    const nextDate = cloneDeep(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    const nextDateFormatted = formatDate(nextDate);
    const amStart = padStart(SHIFTS_TIMES.AM.start.toString(), 2, '0');
    const amEnd = padStart(SHIFTS_TIMES.AM.end.toString(), 2, '0');
    const midStart = padStart(SHIFTS_TIMES.MID.start.toString(), 2, '0');
    const midEnd = padStart(SHIFTS_TIMES.MID.end.toString(), 2, '0');
    const pmStart = padStart(SHIFTS_TIMES.PM.start.toString(), 2, '0');
    const pmEnd = padStart(SHIFTS_TIMES.PM.end.toString(), 2, '0');
    const amShift = new Date(
        momentTz
            .tz(`${currentDateTz} ${amStart}:00`, timezone)
            .format('YYYY-MM-DD HH:mm')
    ).getTime();
    const midShift = new Date(
        momentTz
            .tz(`${currentDateTz} ${midStart}:00`, timezone)
            .format('YYYY-MM-DD HH:mm')
    ).getTime();
    const pmShift = new Date(
        momentTz
            .tz(`${currentDateTz} ${pmStart}:00`, timezone)
            .format('YYYY-MM-DD HH:mm')
    ).getTime();
    const currentShiftPeriod: DailyTasksShiftPeriod = {
        start: `${currentDateTz} ${pmStart}:00:00`,
        end: `${nextDateFormatted} ${pmEnd}:59:59`,
    };
    let currentShift = 'PM';

    if (currentTime >= amShift && currentTime < midShift) {
        currentShift = 'AM';
        currentShiftPeriod.start = `${currentDateTz} ${amStart}:00:00`;
        currentShiftPeriod.end = `${currentDateTz} ${amEnd}:59:59`;
    } else if (currentTime >= midShift && currentTime < pmShift) {
        currentShift = 'MID';
        currentShiftPeriod.start = `${currentDateTz} ${midStart}:00:00`;
        currentShiftPeriod.end = `${currentDateTz} ${midEnd}:59:59`;
    }

    return {
        currentDate,
        currentShift,
        currentShiftPeriod,
    };
};

export const getPeriodOfTwoPreviousShifts = (timezone: string) => {
    const { currentDate } = getCurrentDateAndShift(timezone);
    const currentHour = currentDate.getHours();
    const previousDate = cloneDeep(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    const currentDateFormatted = formatDate(currentDate);
    const previousDateFormatted = formatDate(previousDate);

    // Set the period of the two previous shifts from Overnight PM shift.
    if (currentHour < SHIFTS_TIMES.AM.start) {
        // Set the start time from the beginning of the AM shift and the end time until the ending of the current shift (PM overnight)
        const startTime = `${padStart(
            SHIFTS_TIMES.AM.start.toString(),
            2,
            '0'
        )}:00:00`;
        const endTime = `${padStart(
            SHIFTS_TIMES.PM.end.toString(),
            2,
            '0'
        )}:59:59`;
        return {
            startPeriod: `${previousDateFormatted} ${startTime}`,
            endPeriod: `${currentDateFormatted} ${endTime}`,
        };
    }

    // Set the period of the two previous shifts from AM shift.
    if (
        currentHour >= SHIFTS_TIMES.AM.start &&
        currentHour < SHIFTS_TIMES.MID.start
    ) {
        // Set the start time from the beginning of the MID shift and the end time until the ending of the current shift (AM).
        const startTime = `${padStart(
            SHIFTS_TIMES.MID.start.toString(),
            2,
            '0'
        )}:00:00`;
        const endTime = `${padStart(
            SHIFTS_TIMES.AM.end.toString(),
            2,
            '0'
        )}:59:59`;
        return {
            startPeriod: `${previousDateFormatted} ${startTime}`,
            endPeriod: `${currentDateFormatted} ${endTime}`,
        };
    }

    // Set the period of the two previous shifts from MID shift.
    if (
        currentHour >= SHIFTS_TIMES.MID.start &&
        currentHour < SHIFTS_TIMES.PM.start
    ) {
        // Set the start time from the beginning of the PM shift and the end time until the ending of the current shift (MID)
        const startTime = `${padStart(
            SHIFTS_TIMES.PM.start.toString(),
            2,
            '0'
        )}:00:00`;
        const endTime = `${padStart(
            SHIFTS_TIMES.MID.end.toString(),
            2,
            '0'
        )}:59:59`;
        return {
            startPeriod: `${previousDateFormatted} ${startTime}`,
            endPeriod: `${currentDateFormatted} ${endTime}`,
        };
    }

    // Set the start time from the beginning of the AM shift and the end time until the ending of the current shift (PM)
    const startTime = `${padStart(
        SHIFTS_TIMES.AM.start.toString(),
        2,
        '0'
    )}:00:00`;
    const endTime = `${padStart(currentHour.toString(), 2, '0')}:59:59`;
    return {
        startPeriod: `${currentDateFormatted} ${startTime}`,
        endPeriod: `${currentDateFormatted} ${endTime}`,
    };
};
