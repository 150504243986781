import {
    VisibilityOff as HidePasswordIcon,
    Visibility as ShowPasswordIcon,
    Person as UserIcon,
} from '@mui/icons-material';
import { Box, Button, Card, IconButton, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { gapi } from 'api';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdLock } from 'react-icons/md';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { LanguageStructure } from 'types/language';
import {
    GoogleProfileData,
    GoogleUserData,
    SessionReadParams,
} from 'types/session';

import { CustomTextField } from 'components/Custom';
import AllieHealthLogo from 'components/Shared/AllieHealthLogo';
import { pxToRem } from 'components/theme/typography';
import { LOGIN_INITIAL_VALUES } from 'constants/login';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';
import { signIn } from 'redux/actions/session';

const MainContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    })
);

const SubtitleLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
    })
);

const VersionLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: 12,
    })
);

const FormContainer = styled(Card)(({ theme }) =>
    theme.unstable_sx({
        my: pxToRem(16),
        p: { xs: pxToRem(8), md: pxToRem(16) },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: { xs: '100%', md: pxToRem(400) },
    })
);

const ForgotPasswordLink = styled(Link)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
        fontSize: pxToRem(11),
        width: '100%',
    })
);

const SignInButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(216),
    })
);

type Props = {
    dictionary: LanguageStructure;
    dispatchSignIn: (
        params: SessionReadParams,
        redirectUrl: null | string,
        onError: null | (() => void)
    ) => void;
};

const Login = (props: Props) => {
    const {
        dictionary: { login, shared },
        dispatchSignIn,
    } = props;
    const [form, setForm] = useState<SessionReadParams>(LOGIN_INITIAL_VALUES);
    const [user, setUser] = useState<any>();
    const [profile, setProfile] = useState<GoogleProfileData>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const { trackEvent, showNewMessage } = useIntercom();
    const [signInErrorCount, setSignInErrorCount] = useState(0);

    const handleTextFieldChange = (field: string) => (newValue: string) => {
        setForm({
            ...form,
            [field]: newValue,
        });
    };

    const onAuthChange = (googleResponse) => {
        if (!isEmpty(googleResponse)) {
            const {
                id,
                authToken: accessToken,
                idToken,
                email,
                name: fullName,
                photoUrl: image,
            } = googleResponse;
            const userInfo = {
                id,
                accessToken,
                idToken,
                email,
                fullName,
                image,
            };
        }
    };

    const togglePasswordView = () => {
        setShowPassword(!showPassword);
    };

    const handleSignInError = () => {
        setSignInErrorCount(signInErrorCount + 1);

        if (signInErrorCount === 2) {
            trackEvent('failed-login', {
                guessed_username: form.username,
            });
        }
    };

    const handleSignInClick = () => {
        dispatchSignIn(form, searchParams.get('redirect'), handleSignInError);
    };

    const handlePasswordReset = () => {
        trackEvent('reset-password', {
            guessed_username: form.username,
        });
        showNewMessage('Hello - I forgot my password');
    };

    const handleGoogleSignInClick = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.error('Sign In Failed', error),
        flow: 'implicit',
    });

    const handleGoogleSignOutClick = () => {
        googleLogout();
    };

    const validateGoogleUser = async (userData: GoogleUserData) => {
        try {
            const googleResponse: GoogleProfileData = (
                await gapi.get(
                    `userinfo?access_token=${userData?.access_token}`,
                    {
                        headers: {
                            authorization: `Bearer ${userData?.access_token}`,
                            accept: 'application/json',
                        },
                    }
                )
            ).data;
            setProfile(googleResponse);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (user) {
            validateGoogleUser(user);
        }
    }, [user]);

    return (
        <PageStructure>
            <MainContainer>
                <AllieHealthLogo />
                <Typography variant="h1">{login.content}</Typography>
                <SubtitleLabel variant="body1">
                    {login.subContent}
                </SubtitleLabel>
                <FormContainer>
                    <CustomTextField
                        id="username"
                        label={login.username as string}
                        value={form.username}
                        fullWidth
                        startAdornment={<UserIcon />}
                        onChange={handleTextFieldChange('username')}
                        inputProps={{ autoCapitalize: 'none' }}
                    />
                    <CustomTextField
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        label={login.password}
                        value={form.password}
                        fullWidth
                        startAdornment={<MdLock size={22} />}
                        endAdornment={
                            <IconButton onClick={togglePasswordView}>
                                {showPassword ? (
                                    <HidePasswordIcon />
                                ) : (
                                    <ShowPasswordIcon />
                                )}
                            </IconButton>
                        }
                        onChange={handleTextFieldChange('password')}
                        inputProps={{ autoCapitalize: 'none' }}
                    />
                    {signInErrorCount > 0 && (
                        <ForgotPasswordLink
                            onClick={handlePasswordReset}
                            underline="none"
                        >
                            {shared.forgotPassword}
                        </ForgotPasswordLink>
                    )}
                    <SignInButton
                        variant="contained"
                        color="primary"
                        disabled={!form.username && !form.password}
                        onClick={handleSignInClick}
                    >
                        {login.signIn}
                    </SignInButton>
                </FormContainer>
                <VersionLabel>V1.0.4-demo</VersionLabel>
            </MainContainer>
        </PageStructure>
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSignIn: (
        params: SessionReadParams,
        redirectUrl: null | string,
        onError: null | (() => void)
    ) => dispatch(signIn(params, redirectUrl, onError)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedLogin: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(Login)
);

export default ConnectedLogin;
