import { Box, Button } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';
import { ResidentDailyTasksResponse } from 'types/residents';

import { CustomMobileDialog, CustomRadioGroupOptions } from 'components/Custom';
import TranscriptionTextField from 'components/Shared/TranscriptionTextField';
import { pxToRem } from 'components/theme/typography';
import {
    EDIT_TASK_DIALOG_ANALYTICS,
    REJECT_TASK_DIALOG_ANALYTICS,
} from 'constants/analytics';
import { DIALOG_TYPE } from 'constants/shared';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (selectedValue: number, notes: string) => void;
    dialogType: DIALOG_TYPE;
    selectedRecord?: ResidentDailyTasksResponse;
    taskNotes?: string;
};

const EditTaskStatusDialog = (props: Props) => {
    const { isOpen, onClose, onSubmit, dialogType, selectedRecord, taskNotes } =
        props;

    const { taskStatusesList } = useSelector(
        (state: ReduxStore) => state.taskStatuses
    );

    const [selectedValue, setSelectedValue] = useState('');
    const [notes, setNotes] = useState<string>('');
    const [showActivateMicConfirmation, setShowActivateMicConfirmation] =
        useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) return;

        if (isReject) {
            setNotes(taskNotes || '');
            setSelectedValue('');
            return;
        }

        if (selectedRecord) {
            setNotes(taskNotes || selectedRecord.caregiverNotes);
            setSelectedValue(selectedRecord.taskStatusId.toString());
        }
    }, [isOpen]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleNoteChange = (newNote: string) => setNotes(newNote);

    const handleClose = () => {
        setShowActivateMicConfirmation(false);
        onClose();
        setSelectedValue('');
        setNotes('');
    };

    const handleSubmit = () => {
        onSubmit(Number(selectedValue), notes);
        handleClose();
    };

    const isReject = dialogType === DIALOG_TYPE.reject;

    // ASCENT HACK: hard code the reject task statuses
    const editTaskStatusStatuses = taskStatusesList.filter(
        (taskStatus) =>
            !(
                taskStatus.id === 1 ||
                (isReject && taskStatus.id === 2) ||
                taskStatus.id === 3 ||
                taskStatus.id === 8
            )
    );

    const {
        editTaskStatusCancelButton: editTaskStatusCancelButtonTxt,
        editTaskStatusDialogTitle: editTaskStatusDialogTitleTxt,
        editTaskStatusRejectDialogTitle: editTaskStatusRejectDialogTitleTxt,
        editTaskStatusSubmitButton: editTaskStatusSubmitButtonTxt,
        addTaskNoteFieldPlaceholder: addTaskNoteFieldPlaceholderTxt,
        addTaskNoteFieldTitle: addTaskNoteFieldTitleTxt,
    } = useSelector((state: ReduxStore) => state.language.dictionary.shared);

    const dialogTitle = isReject
        ? editTaskStatusRejectDialogTitleTxt
        : editTaskStatusDialogTitleTxt;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={showActivateMicConfirmation ? '' : dialogTitle}
            width="100%"
            onClose={handleClose}
            content={
                <>
                    {!showActivateMicConfirmation && (
                        <RadioGroup
                            name={
                                isReject
                                    ? 'choose-reject-reason'
                                    : 'choose-status'
                            }
                            defaultValue={
                                isReject ? '' : selectedRecord?.taskStatusId
                            }
                            onChange={handleRadioChange}
                            value={selectedValue}
                            sx={{
                                display: 'grid',
                                gap: pxToRem(12),
                                m: 0,
                                mb: pxToRem(16),
                            }}
                        >
                            {editTaskStatusStatuses.map(({ id, name }) => (
                                <CustomRadioGroupOptions
                                    key={id}
                                    value={id.toString()}
                                    label={name}
                                    selectedValue={selectedValue}
                                />
                            ))}
                        </RadioGroup>
                    )}
                    {selectedValue && (
                        <TranscriptionTextField
                            label={addTaskNoteFieldTitleTxt}
                            placeholder={addTaskNoteFieldPlaceholderTxt}
                            showActivateMicConfirmation={
                                showActivateMicConfirmation
                            }
                            text={notes}
                            analyticsIdText={
                                isReject
                                    ? REJECT_TASK_DIALOG_ANALYTICS.TRANSCRIPTION_BUTTON
                                    : EDIT_TASK_DIALOG_ANALYTICS.TRANSCRIPTION_BUTTON
                            }
                            onChange={handleNoteChange}
                            toggleShowActivateMicConfirmation={(
                                show: boolean
                            ) => setShowActivateMicConfirmation(show)}
                        />
                    )}
                    {!showActivateMicConfirmation && (
                        <Box
                            sx={{
                                mt: pxToRem(8),
                                display: 'grid',
                                gridTemplateColumns: '40% 1fr',
                                gap: pxToRem(12),
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClose}
                                sx={{
                                    width: '100%',
                                    p: pxToRem(12),
                                    fontSize: pxToRem(14),
                                }}
                            >
                                {editTaskStatusCancelButtonTxt}
                            </Button>
                            <Button
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!selectedValue}
                                sx={{
                                    width: '100%',
                                    p: pxToRem(12),
                                    fontSize: pxToRem(14),
                                    '&:disabled': {
                                        bgcolor: '#C7C8CB',
                                    },
                                }}
                            >
                                {editTaskStatusSubmitButtonTxt}
                            </Button>
                        </Box>
                    )}
                </>
            }
        />
    );
};

export default EditTaskStatusDialog;
