import { Button } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { CustomDate } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomDateRangePicker, CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    dictionary: LanguageStructure;
    disableFuture?: boolean;
    disableMaskedInput?: boolean;
    disablePast?: boolean;
    endDate: CustomDate;
    endDateLabel?: string;
    hideHelper?: boolean;
    hideSeparator?: boolean;
    inputFormat?: string;
    inputMask?: string;
    maxDate?: CustomDate;
    minDate?: CustomDate;
    open: boolean;
    separatorLabel?: string;
    startDate: CustomDate;
    startDateLabel?: string;
    title?: string;
    onClose: () => void;
    onConfirm: (newDateRange: [CustomDate, CustomDate]) => void;
};

const DateRangeDialog = (props: Props) => {
    const {
        dictionary: { shared },
        disableFuture,
        disableMaskedInput,
        disablePast,
        endDate,
        endDateLabel,
        hideHelper,
        hideSeparator,
        inputFormat,
        inputMask,
        maxDate,
        minDate,
        open,
        separatorLabel,
        startDate,
        startDateLabel,
        title,
        onClose,
        onConfirm,
    } = props;
    const newTitle = title || shared.dateRangePicker;
    const [dateRange, setDateRange] = useState<[CustomDate, CustomDate]>([
        startDate,
        endDate,
    ]);
    const [isDateRangeInvalid, setIsDateRangeInvalid] =
        useState<boolean>(false);

    const handleDateRangeChange = (
        newDateRange: [CustomDate, CustomDate],
        rangeHasError: boolean
    ) => {
        setDateRange(newDateRange);
        setIsDateRangeInvalid(rangeHasError);
    };

    const handleDateRangeConfirm = () => {
        onConfirm(dateRange);
        onClose();
    };

    return (
        <CustomDialog
            closeable
            open={open}
            title={newTitle}
            width={pxToRem(400)}
            content={
                <CustomDateRangePicker
                    disableFuture={disableFuture}
                    disableMaskedInput={disableMaskedInput}
                    disablePast={disablePast}
                    endDate={endDate}
                    endDateLabel={endDateLabel}
                    hideHelper={hideHelper}
                    hideSeparator={hideSeparator}
                    inputFormat={inputFormat}
                    inputMask={inputMask}
                    maxDate={maxDate}
                    minDate={minDate}
                    separatorLabel={separatorLabel}
                    startDate={startDate}
                    startDateLabel={startDateLabel}
                    onChange={handleDateRangeChange}
                />
            }
            actions={
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onClose}
                    >
                        {shared.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isDateRangeInvalid}
                        onClick={handleDateRangeConfirm}
                    >
                        {shared.ok}
                    </Button>
                </>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;
    return {
        dictionary,
    };
};

export default connect(mapStateToProps)(DateRangeDialog);
