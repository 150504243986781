import React, { useState } from 'react';

type Props = {
    render: (
        openedResidentId: number | null,
        onResidentToggle: (number) => void
    ) => JSX.Element;
};

// TODO: refactor this and use context instead of render props and passing down props for a simplified data flow
export const MultiSelectProvider = (props: Props) => {
    const { render } = props;

    const [openedResidentId, setOpenedResidentId] = useState<number | null>(
        null
    );

    const onResidentToggle = (residentId) => {
        if (openedResidentId === residentId) {
            setOpenedResidentId(null);
        } else {
            setOpenedResidentId(residentId);
        }
    };

    return render(openedResidentId, onResidentToggle);
};
