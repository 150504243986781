import moment from 'moment';

import { LanguageProperty } from 'types/language';
import { InvitationResponse } from 'types/signupInvitations';
import { Column, Row } from 'types/table';
import { UserResponse } from 'types/users';

import { US_DATE_FORMAT } from 'constants/app';

export const formatUsersListColumns = (
    columns: Column[],
    dictionary: LanguageProperty
): Column[] =>
    columns.map((column) => {
        const label = dictionary[column.id];
        const newColumn = {
            ...column,
            label,
        };
        return newColumn;
    });

export const formatUsersListRows = (
    usersList: UserResponse[],
    dictionary: LanguageProperty
): Row[] =>
    usersList.map((itemData) => ({
        id: itemData.userId,
        action: {
            label: dictionary.seeDetails,
            userId: itemData.userId,
        },
        user: {
            firstName: itemData.firstName,
            lastName: itemData.lastName,
            fullName: `${itemData.firstName} ${itemData.lastName}`,
            photo: itemData.picture || '',
        },
        userFullName: `${itemData.firstName} ${itemData.lastName}`,
        role: itemData.role,
        roleId: itemData.roleId,
        email: itemData.email,
        phoneNumber: itemData.phoneNumber,
        registrationDate: moment(itemData.registrationDate).format(
            US_DATE_FORMAT
        ),
    }));

export const formatInvitationsListColumns = (
    columns: Column[],
    dictionary: LanguageProperty
): Column[] =>
    columns.map((column) => {
        const label = dictionary[column.id];
        const newColumn = {
            ...column,
            label,
        };
        return newColumn;
    });

export const formatInvitationsListRows = (
    invitationsList: InvitationResponse[],
    dictionary: LanguageProperty
): Row[] =>
    invitationsList.map((itemData) => ({
        id: itemData.invitationId,
        action: {
            label: dictionary.seeDetails,
            invitation: itemData,
        },
        invitedTo: `${itemData.firstName} ${itemData.lastName}`,
        email: itemData.email,
        status: itemData.invitationStatusId,
        invitationDate: moment(itemData.creationTimestamp).format(
            US_DATE_FORMAT
        ),
        invitationTimestamp: itemData.creationTimestamp,
    }));
