import React from 'react';

import { TaskCompletionOptions } from 'types/residents';

import { TaskDetails } from '../TaskDetails';
import { TaskRowContainer } from '../TaskRowContainer';

import { TaskOptions } from './TaskOptions';

type Props = {
    taskName: string;
    taskTime: string;
    taskNotes: string;
    taskCompletionOptions?: TaskCompletionOptions;
    completionOptionValue?: number;
    userTaskNotes?: string;
    taskStatusId: number;
    onToggleNotes: () => void;
    onToggleEditAddTaskNotes: () => void;
    onToggleRejectDialog: () => void;
    onCompletionOptionsChange: (number) => void;
};

export const ResidentRowTask = (props: Props) => {
    const {
        taskName,
        taskTime,
        taskNotes,
        taskCompletionOptions,
        completionOptionValue,
        userTaskNotes,
        taskStatusId,
        onToggleNotes,
        onToggleEditAddTaskNotes,
        onToggleRejectDialog,
        onCompletionOptionsChange,
    } = props;

    return (
        <TaskRowContainer>
            <TaskDetails
                taskName={taskName}
                taskTime={taskTime}
                taskNotes={taskNotes}
                userTaskNotes={userTaskNotes}
                taskCompletionOptions={taskCompletionOptions}
                onToggleNotes={onToggleNotes}
                onToggleEditAddTaskNotes={onToggleEditAddTaskNotes}
                onCompletionOptionsChange={onCompletionOptionsChange}
                completionOptionValue={completionOptionValue}
            />
            <TaskOptions
                taskStatusId={taskStatusId}
                onToggleRejectDialog={onToggleRejectDialog}
            />
        </TaskRowContainer>
    );
};
