import { api } from 'api';

import { ServerData } from 'types/common';
import { ErrorProps } from 'types/messages';
import {
    InvitationCreateUpdateParams,
    InvitationResponse,
} from 'types/signupInvitations';
import { UserCreateParams } from 'types/users';

import { formatApiParams, getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { handleError, throwError } from 'redux/actions/messages';
import 'redux/reducers/users';

/**
 * Fetches the list of Sign-up Invitations based on the current user role.
 */
export const readInvitations =
    (actionType = actionTypes.SIGNUP_INVITATION_READ_LIST) =>
    async (dispatch, getState) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert =
                actionType === actionTypes.SIGNUP_INVITATION_READ_LIST;

            // Enable the loading process.
            dispatch(
                loading(
                    actionTypes.SIGNUP_INVITATION_LOADING,
                    true,
                    shouldHideAlert
                )
            );

            // Get the token.
            const token = getToken();

            // Get the list of the Users that match with the provided params.
            const serverResponse: InvitationResponse[] = (
                await api.get('user-invitations', {
                    headers: {
                        authorization: token,
                    },
                })
            ).data.response;

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.SIGNUP_INVITATION_READ_LIST,
                payload: serverResponse,
            });
        } catch (error) {
            // Get the Error dictionary from the language state.
            const {
                language: {
                    dictionary: { error: errorDictionary },
                },
            } = getState();

            const errorMessage = errorDictionary.generalError;
            const errorProps: ErrorProps = {
                error,
                consoleMessage: errorMessage,
                alertMessage: errorMessage,
            };

            dispatch(handleError(errorProps));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, false));
        }
    };

/**
 * Fetches a single Invitation based on the provided user email.
 * @param email the email of the User who received the invitation.
 */
export const readInvitation = (email: string) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, true));

        // Get the token.
        const token = getToken();

        // Get the data for the requested user.
        const serverData: ServerData = (
            await api.get(`user-signups/${email}`, {})
        ).data;

        if (serverData.error) {
            // Updates the State in Redux.
            dispatch({
                type: actionTypes.SIGNUP_INVITATION_READ_FAIL,
                payload: serverData.error,
            });
        } else {
            // Updates the State in Redux.
            dispatch({
                type: actionTypes.SIGNUP_INVITATION_READ,
                payload: serverData.response,
            });
        }
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, false));
    }
};

/**
 * Verifies if an email exists into the Invitations.
 * @param email the email to check if exists or not.
 */
export const verifyInvitation = (email: string) => async (dispatch) => {
    try {
        // Get the token.
        const token = getToken();

        // Get the lconfirmation about if the provided email exists or not.
        const emailExists: boolean = (
            await api.get(`user-invitations/${email}`, {
                headers: {
                    authorization: token,
                },
            })
        ).data.response;

        // Updates the State in Redux.
        dispatch({
            type: actionTypes.SIGNUP_INVITATION_EMAIL_CHECK,
            payload: emailExists,
        });
    } catch (error) {
        dispatch(throwError(error));
    }
};

/**
 * Creates a new Invitation based on the provided params.
 * @param jsonParams the JSON object with the params for creating a new User.
 */
export const createInvitation =
    (jsonParams: InvitationCreateUpdateParams) => async (dispatch) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, true));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Create the new Invitation.
            await api.post('user-invitations', params, {
                headers: {
                    authorization: token,
                },
            });

            // Updates the State in Redux.
            dispatch(readInvitations(actionTypes.SIGNUP_INVITATION_CREATE));
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, false));
        }
    };

/**
 * Updates an existing Invitation based on the provided params.
 * @param jsonParams the JSON object with the params for creating a new User.
 */
export const updateInvitation =
    (invitationId: number, jsonParams: InvitationCreateUpdateParams) =>
    async (dispatch) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, true));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Update the Invitation.
            await api.put(`user-invitations/${invitationId}`, params, {
                headers: {
                    authorization: token,
                },
            });

            // Updates the State in Redux.
            dispatch(readInvitations(actionTypes.SIGNUP_INVITATION_UPDATE));
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, false));
        }
    };

/**
 * Updates an Invitation Status based on the provided params.
 * @param invitationId the ID of the Invitation to be updated.
 * @param statusId the new Status ID for the Invitation.
 */
export const updateInvitationStatus =
    (invitationId: number, statusId: number) => async (dispatch) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, true));

            // Get the token.
            const token = getToken();

            // Update the Invitation Status.
            await api.put(
                `user-invitations/${invitationId}/status/${statusId}`,
                null,
                {
                    headers: {
                        authorization: token,
                    },
                }
            );

            // Updates the State in Redux.
            dispatch(
                readInvitations(actionTypes.SIGNUP_INVITATION_UPDATE_STATUS)
            );
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, false));
        }
    };

/**
 * Creates a new User based on the provided params.
 * @param jsonParams the JSON object with the params for creating a new User.
 */
export const createUserFromInvitation =
    (jsonParams: UserCreateParams) => async (dispatch) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, true));

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Create the new User.
            await api.post('user-signups', params, {});
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SIGNUP_INVITATION_LOADING, false));
        }
    };
