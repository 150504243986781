import { Capacitor } from '@capacitor/core';
import { useGeolocation } from 'contexts/GeolocationContext';
import React, { memo, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';

import Loading from '../components/Shared/Loading';
import { useBranchQuery } from 'api/queries/branch';
import GeolocationInfo from 'components/Navigation/Geolocation/GeolocationInfo';
import OutsideGeofence from 'components/Navigation/Geolocation/OutsideGeofence';
import {
    BASE_PATH,
    HOME_PATH,
    LOGIN_PATH,
    OPERATIONS_SNAPSHOTS_PATH,
} from 'constants/app';
import { PROFILE } from 'constants/localStorage';
import { handleError } from 'redux/actions/messages';

type Props = {
    isSignedIn: boolean;
    pathname: string;
    roleLevelId: number;
    branchId: number;
    userId: number;
    dispatchThrowError: (error: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AccessControl = (WrappedComponent: any) => {
    const SecuredControl = (props: Props) => {
        const {
            isSignedIn,
            pathname,
            roleLevelId,
            branchId,
            dispatchThrowError,
            userId,
        } = props;

        const storedSession: StoredSession = JSON.parse(
            localStorage.getItem(PROFILE) as string
        );
        const storedIsSignedIn = storedSession?.isSignedIn;

        const isUserLogged = isSignedIn || storedIsSignedIn;
        const isCaregiver = roleLevelId === 5;

        const {
            isLoading: branchIsLoading,
            isError: branchIsError,
            data: branchData,
            error: branchError,
        } = useBranchQuery(branchId);

        const {
            permissionStatus,
            position,
            isPositionAccurate,
            updatePosition,
            isUserInsideWorkingArea,
        } = useGeolocation();

        useEffect(() => {
            if (branchIsError && branchError instanceof Error) {
                dispatchThrowError('Please try reloading the page.');
            }
        }, [branchIsError, branchError]);

        // TODO: Get branch data inside the context (with redux)
        const isInsideWorkingArea = useMemo(() => {
            // DEBUG: Force outside community
            // console.log();
            // return false;

            if (!position || !branchData) {
                return null;
            }

            // eslint-disable-next-line prefer-const
            let { geofencingEnabled, locationLatitude, locationLongitude } =
                branchData;

            if (!geofencingEnabled) {
                return true;
            }

            if (!locationLatitude || !locationLongitude) {
                // If geofencing is enabled, the database should always contain the coordinates
                // So this should never happen
                return true;
            }

            return isUserInsideWorkingArea({
                branchLat: locationLatitude,
                branchLon: locationLongitude,
                maxDistance: 0.1,
            });
        }, [position, branchData]);

        if (!pathname) {
            return null;
        }

        const pathIsLoginPage = pathname.includes('/login');
        const pathIsSignupPage = pathname.includes('/signup');
        const pathIsForgotPasswordPage = pathname.includes('/forgot-password');
        const pathIsPasswordResetPage = pathname.includes('/password-reset');
        const pathIsCompanyPage = pathname.includes('/user-company');
        const pathIsProfilePage = pathname.includes('/user-profile');
        const loginPath = `${BASE_PATH}${LOGIN_PATH}`;
        const homePath = `${BASE_PATH}${HOME_PATH}`;
        const dashboardPath = `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}`;

        if (isUserLogged === null || (isUserLogged && branchIsLoading)) {
            return <Loading />;
        }

        // If the user is not signed-in and he/she is tries to access a restricted page, redirect to the Login page.
        if (
            !isUserLogged &&
            !pathIsLoginPage &&
            !pathIsSignupPage &&
            !pathIsPasswordResetPage &&
            !pathIsForgotPasswordPage
        ) {
            return <Navigate to={`${loginPath}?redirect=${pathname}`} />;
        }

        if (branchIsError && branchError instanceof Error) {
            return <></>;
        }

        // Geofencing
        if (
            Capacitor.isNativePlatform() &&
            isCaregiver &&
            branchData &&
            branchData.geofencingEnabled &&
            !pathIsProfilePage &&
            !pathIsCompanyPage
        ) {
            if (
                permissionStatus?.location.includes('prompt') ||
                permissionStatus?.location === 'denied' ||
                isPositionAccurate === false
            ) {
                return <GeolocationInfo />;
            }

            if (isInsideWorkingArea === null) {
                return <Loading label="Verifying you are at the community" />;
            }

            if (!isInsideWorkingArea) {
                return <OutsideGeofence />;
            }
        }

        // If the user is already signed-in and he/she is a Caregiver and tries to go to the Login page, redirect to the Home page.
        if (
            isUserLogged &&
            (pathIsLoginPage ||
                pathIsSignupPage ||
                pathIsPasswordResetPage ||
                pathIsForgotPasswordPage) &&
            isCaregiver
        ) {
            return <Navigate to={homePath} />;
        }

        // If the user is already signed-in and he/she is not a Caregiver and tries to go to the Login page, redirect to the Dashboard page.
        if (
            isUserLogged &&
            (pathIsLoginPage ||
                pathIsSignupPage ||
                pathIsPasswordResetPage ||
                pathIsForgotPasswordPage) &&
            !isCaregiver
        ) {
            return <Navigate to={dashboardPath} />;
        }

        // Render the requested page.
        return <WrappedComponent {...props} />;
    };

    const mapStateToProps = ({ location, session }: ReduxStore) => {
        const { pathname } = location;
        const {
            isSignedIn,
            sessionData: { roleLevelId, branchId, userId },
        } = session;

        return {
            isSignedIn,
            pathname,
            roleLevelId,
            branchId,
            userId,
        };
    };

    const mapDispatchToProps = (dispatch) => ({
        dispatchThrowError: (error) =>
            dispatch(
                handleError({
                    alertMessage: error,
                    consoleMessage: error,
                    error,
                })
            ),
    });

    return connect(mapStateToProps, mapDispatchToProps)(memo(SecuredControl));
};

export default AccessControl;
