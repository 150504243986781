import { Assignment as NotesIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { ResidentsListResponse } from 'types/residents';

import { ActivityIndicator } from 'components/Shared/ActivityIndicator';
import { pxToRem } from 'components/theme/typography';
import { SUB_PAGES_MENU_MAP } from 'constants/app';
import {
    AVATAR_CONTAINER_WIDTH,
    BUILDING_STATUS_WIDTH,
} from 'constants/residents';
import ResidentPhotoContainer from 'pages/Residents/components/ResidentPhotoContainer';
import { updatePriorParentPage } from 'redux/actions/session';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    done: {
        color: theme.palette.app.green.main,
        backgroundColor: alpha(theme.palette.app.green.main, 0.08),
    },
    pending: {
        color: theme.palette.grey[800],
        backgroundColor: alpha(theme.palette.grey[600], 0.08),
    },
    inBuilding: {
        color: theme.palette.app.green.main,
    },
    outOfBuilding: {
        color: theme.palette.grey[500],
    },
}));

const RowStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        py: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        pl: pxToRem(24),
        '&:last-of-type': {
            borderBottom: 'none',
        },
    })
);

const ResidentDetailsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(
            AVATAR_CONTAINER_WIDTH + BUILDING_STATUS_WIDTH
        )})`,
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.5,
        fontSize: { md: pxToRem(17), lg: pxToRem(18) },
    })
);

const NotesIconStyle = styled(NotesIcon)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        color: theme.palette.app.green.main,
        fontSize: pxToRem(14),
    })
);

const TasksAndRoom = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
    })
);

const TasksCounter = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(4),
        px: pxToRem(8),
        py: pxToRem(4),
        fontSize: { xs: pxToRem(11), md: pxToRem(13), lg: pxToRem(15) },
        fontWeight: 700,
        lineHeight: 1.3,
        borderRadius: pxToRem(4),
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.app.orange.main,
        lineHeight: 1.5,
        fontSize: { md: pxToRem(16), lg: pxToRem(18) },
    })
);

const BuildingStatusContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
        width: pxToRem(BUILDING_STATUS_WIDTH),
    })
);

type Props = {
    dictionary: LanguageStructure;
    resident: ResidentsListResponse;
    roleLevelId: number;
    dispatchUpdatePriorParentPage: (parentPage: string) => void;
};

const ResidentRowData = (props: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        dictionary: { residents: residentsDictionary },
        resident: {
            residentId,
            firstName,
            lastName,
            photo,
            roomNumber,
            buildingStatus,
            totalTasks,
            totalTasksDone,
            totalShiftNotes,
            hasShower,
            showerComplete,
            hasLaundry,
            laundryComplete,
            isOnHospice,
        },
        roleLevelId,
        dispatchUpdatePriorParentPage,
    } = props;
    const residentName = `${firstName} ${lastName}`;
    const residentLocation = `${residentsDictionary.room} ${roomNumber}`;

    const areAllTasksCompleted = totalTasks === totalTasksDone;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const goToResidentDetails = (newResidentId: number) => (event) => {
        // Store the current parent page.
        dispatchUpdatePriorParentPage(pathname);

        const url =
            roleLevelId === 4
                ? SUB_PAGES_MENU_MAP.managerResidentDetails
                : SUB_PAGES_MENU_MAP.residentDetails;

        const detailsPage = url.replace(
            ':residentId',
            newResidentId.toString()
        );
        navigate(detailsPage);
    };

    return (
        <RowStyle
            key={residentId}
            onClick={goToResidentDetails(residentId)}
            sx={{
                backgroundColor: areAllTasksCompleted
                    ? 'activityIndicator.tasksCompletedBackground'
                    : undefined,
            }}
        >
            <ResidentPhotoContainer
                residentName={residentName}
                residentPhoto={photo}
                totalTasks={totalTasks}
                totalTasksDone={totalTasksDone}
            />
            <ResidentDetailsContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: pxToRem(8),
                    }}
                >
                    {hasShower && (
                        <ActivityIndicator
                            needsShower={hasShower}
                            showerComplete={showerComplete}
                            isOnHospice={isOnHospice}
                        />
                    )}
                    {hasLaundry && (
                        <ActivityIndicator laundryComplete={laundryComplete} />
                    )}
                </Box>
                <ResidentName variant="h3">
                    {residentName}
                    {totalShiftNotes > 0 && <NotesIconStyle />}
                </ResidentName>
                <TasksAndRoom>
                    <TasksCounter
                        variant="caption"
                        className={
                            areAllTasksCompleted
                                ? classes.done
                                : classes.pending
                        }
                    >
                        {totalTasksDone}/{totalTasks}
                    </TasksCounter>
                    <RoomNumber variant="h5">{residentLocation}</RoomNumber>
                </TasksAndRoom>
            </ResidentDetailsContainer>
            <BuildingStatusContainer>
                <Typography
                    variant="caption"
                    className={
                        buildingStatus === 'In Building'
                            ? classes.inBuilding
                            : classes.outOfBuilding
                    }
                >
                    {buildingStatus}
                </Typography>
            </BuildingStatusContainer>
        </RowStyle>
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const {
        sessionData: { roleLevelId },
    } = session;

    return {
        dictionary,
        roleLevelId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdatePriorParentPage: (parentPage: string) =>
        dispatch(updatePriorParentPage(parentPage)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentRowData: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResidentRowData);

export default ConnectedResidentRowData;
