import { Box, Typography } from '@mui/material';
import { differenceInDays, parse } from 'date-fns';
import Pluralize from 'pluralize';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    amount: string | number;
    icon: React.ReactElement;
    iconBgColor: string;
    subtitle: string;
    timeRange?: string;
    latestMeasurementDate?: string | null;
};

const ICON_SIZE = 48;

export const ModuleHeader = ({
    amount,
    icon,
    iconBgColor,
    subtitle,
    timeRange,
    latestMeasurementDate,
}: Props) => {
    Pluralize.addUncountableRule('Incontinence');

    const pluralizeFirstWord = (
        inputString: string,
        count: number | string
    ) => {
        if (typeof count === 'string' && count.includes('/')) {
            return inputString;
        }
        const words = inputString.split(' ');
        words[0] = Pluralize(words[0], count);

        return words.join(' ');
    };

    let subtitleWithDate = `${pluralizeFirstWord(subtitle, amount) || ''} ${
        timeRange ? `in last ${timeRange}` : ''
    }`;

    // only the weight module has a second line for the subtitle
    let subtitleSecondLine: null | string = null;

    if (latestMeasurementDate) {
        const parsedDate = parse(
            latestMeasurementDate,
            'yyyy-MM-dd',
            new Date()
        );
        const daysAgo = differenceInDays(new Date(), parsedDate);

        if (daysAgo > 0) {
            subtitleSecondLine = `Last measurement ${daysAgo} days ago`;
        }
    }

    if (subtitle === 'Total task') {
        subtitleWithDate = `${Pluralize(subtitle, amount) || ''} ${
            timeRange ? `in last ${timeRange}` : ''
        }`;
    }

    return (
        <Box
            py={3}
            px={pxToRem(20)}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box>
                <Typography variant="h2" lineHeight={1.2}>
                    {amount}
                </Typography>
                <Typography
                    fontSize={{ xs: pxToRem(12), md: pxToRem(14) }}
                    lineHeight={1.7}
                    color="#6F6F79"
                    textAlign="left"
                >
                    {subtitleWithDate}
                    {subtitleSecondLine && (
                        <>
                            <br />
                            {subtitleSecondLine}
                        </>
                    )}
                </Typography>
            </Box>
            <Box
                width={pxToRem(ICON_SIZE)}
                height={pxToRem(ICON_SIZE)}
                bgcolor={iconBgColor}
                p={pxToRem(10)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
            >
                {icon}
            </Box>
        </Box>
    );
};
