import React from 'react';

import { PRIVACY_POLICY } from 'constants/privacyPolicy';

function createMarkup() {
    return { __html: PRIVACY_POLICY };
}

const PrivacyPolicy = () => (
    // eslint-disable-next-line react/no-danger
    <div style={{ padding: '12px' }} dangerouslySetInnerHTML={createMarkup()} />
);

export default PrivacyPolicy;
