import { api } from 'api';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { getToken } from 'lib/common';

export const useResidentDashboardDetailsQuery = (id: number | null) =>
    useErrorHandledQuery(
        ['resident-dashboard-details', id],
        async (): Promise<ResidentDetailsResponse> =>
            (
                await api.get(`/dashboard/resident-details-v2/${id}`, {
                    headers: {
                        authorization: getToken(),
                    },
                })
            ).data.response,
        { enabled: !!id, staleTime: 300000 }
    );
