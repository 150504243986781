import { Box, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { useCaregiverPerformanceDetails } from 'api/queries/dashboard/caregiverPerformance';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

import { OverviewModule } from './OverviewModule';
import { PerformanceTableModule } from './PerformanceTableModule';

const OverviewContainer = ({ children }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: pxToRem(24),
        }}
    >
        {children}
    </Box>
);

const Performance = () => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const { dateRange } = useSelector(
        (state: ReduxStore) => state.dashboards.filters
    );

    const startDate = dateRange?.startDate;
    const endDate = dateRange?.endDate;

    const { branchId } = useSelector(
        (state: ReduxStore) => state.session.sessionData
    );

    const params =
        startDate && endDate && branchId
            ? {
                  branchId,
                  startPeriod:
                      startDate && moment(startDate).format('YYYY-MM-DD'),
                  endPeriod: endDate && moment(endDate).format('YYYY-MM-DD'),
              }
            : {};

    console.log(params, startDate);

    const {
        isLoading: caregiverPerformanceIsLoading,
        isError: caregiverPerformanceIsError,
        data: caregiverPerformanceData,
    } = useCaregiverPerformanceDetails(params);

    if (caregiverPerformanceIsLoading) {
        return <Loading />;
    }

    return (
        <PageStructure>
            {caregiverPerformanceData && (
                <Box
                    sx={{
                        margin: isMobile ? pxToRem(12) : pxToRem(24),
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: pxToRem(24),
                        }}
                    >
                        <OverviewContainer>
                            <OverviewModule
                                title="Assisted Living Overall"
                                residentsRatio={
                                    caregiverPerformanceData.stats.al.overall
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.al.overall
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.al.overall
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.al.overall
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.al.overall
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.al.overall
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.al.overall
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.al.overall
                                        .caregiverShiftsCount
                                }
                            />
                            <OverviewModule
                                title="AL AM"
                                residentsRatio={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.al.shifts.am
                                        .caregiverShiftsCount
                                }
                            />
                            <OverviewModule
                                title="AL PM"
                                residentsRatio={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.al.shifts.mid
                                        .caregiverShiftsCount
                                }
                            />
                            <OverviewModule
                                title="AL NOC"
                                residentsRatio={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.al.shifts.pm
                                        .caregiverShiftsCount
                                }
                            />
                        </OverviewContainer>
                        <OverviewContainer>
                            <OverviewModule
                                title="Memory Care Overall"
                                residentsRatio={
                                    caregiverPerformanceData.stats.mc.overall
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.mc.overall
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.mc.overall
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.mc.overall
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.mc.overall
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.mc.overall
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.mc.overall
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.mc.overall
                                        .caregiverShiftsCount
                                }
                            />
                            <OverviewModule
                                title="MC AM"
                                residentsRatio={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.mc.shifts.am
                                        .caregiverShiftsCount
                                }
                            />
                            <OverviewModule
                                title="MC PM"
                                residentsRatio={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.mc.shifts.mid
                                        .caregiverShiftsCount
                                }
                            />
                            <OverviewModule
                                title="MC NOC"
                                residentsRatio={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .residentsRatio
                                }
                                tasksRatio={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .scheduledTasksRatio
                                }
                                billableMinutesRatio={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .billableMinutesRatio
                                }
                                completedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .completedTaskCount
                                }
                                refusedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .refusedTaskCount
                                }
                                otherIncompleteTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .otherIncompleteTaskCount
                                }
                                undocumentedTaskCount={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .undocumentedTaskCount
                                }
                                caregiverShiftsCount={
                                    caregiverPerformanceData.stats.mc.shifts.pm
                                        .caregiverShiftsCount
                                }
                            />
                        </OverviewContainer>
                    </Box>
                    <PerformanceTableModule
                        rows={caregiverPerformanceData.caregiverPerformance.map(
                            (caregiver) => ({
                                firstName: caregiver.caregiverFirstName,
                                lastName: caregiver.caregiverLastName,
                                shiftName: caregiver.shiftName,
                                shiftCount: caregiver.shiftCount,
                                residentsDocumented:
                                    caregiver.residentsDocumented,
                                tasksDocumented: caregiver.tasksDocumented,
                                billableMinutesDocumented:
                                    caregiver.billableMinutesDocumented,
                                tasksCompleted: caregiver.completedTasksCount,
                                tasksRefused: caregiver.refusedTasksCount,
                                tasksOtherIncomplete:
                                    caregiver.otherIncompleteTaskCount,
                            })
                        )}
                    />
                </Box>
            )}
        </PageStructure>
    );
};

export const PerformanceDashboard = AccessControl(Performance);
