/* eslint-disable dot-notation */
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { AvatarProps } from 'types/common';
import { DropdownStructure } from 'types/inputs';
import { Column, Row } from 'types/table';

import {
    CustomAutocomplete,
    CustomAvatar,
    CustomCheckbox,
} from 'components/Custom';
import { CheckboxColumn } from 'components/Custom/CustomTable/index';
import NoData from 'components/Shared/NoData';
import { pxToRem } from 'components/theme/typography';
import { ALIGNMENT_TYPES } from 'constants/table';
import { FilterContainer } from 'pages/Dashboards/components/Details/Filters';

const useStyles = makeStyles({
    caregiver: {
        width: pxToRem(180),
    },
});

const Cell = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        fontSize: { lg: pxToRem(14) },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
    })
);

const ModeIconGrey = styled(ModeIcon)(({ theme }) =>
    theme.unstable_sx({
        fill: '#637381',
        fontSize: pxToRem(18),
        ml: pxToRem(6),
        cursor: 'pointer',
    })
);

const CloseIconGrey = styled(CloseIcon)(({ theme }) =>
    theme.unstable_sx({
        fill: '#637381',
        fontSize: pxToRem(18),
        ml: pxToRem(6),
        mb: pxToRem(6),
        cursor: 'pointer',
    })
);

type Props = {
    columns: Column[];
    isRowSelected?: boolean;
    row: Row;
    useCheckBoxes?: boolean;
    onRowCheckedChange?: (rowId: number, newCheckedStatus: boolean) => void;
    dashboards?: any;
    usersList?: any;
    dispatchUpdateCaregiver?: (
        dailyTaskRecordId: number,
        jsonParams: { user_id: number },
        callbackSuccess: () => void
    ) => void;
};

const BodyRow = (props: Props) => {
    const classes = useStyles();
    const {
        columns,
        isRowSelected = false,
        row,
        useCheckBoxes,
        onRowCheckedChange,
        dashboards,
        usersList,
        dispatchUpdateCaregiver,
    } = props;
    const [checked, setChecked] = useState<boolean>(false);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const hasDetails = !isUndefined(row.rowDetails);
    const rowDetails = hasDetails ? (row.rowDetails as Row) : null;
    const detailsColumns = rowDetails ? (rowDetails.columns as Column[]) : [];
    const allDetailsRows = rowDetails ? (rowDetails.rows as Row[]) : [];
    const [detailsRows, setDetailsRows] = useState<Row[]>(allDetailsRows);
    const [caregiverOptions, setCaregiverOptions] = useState<
        DropdownStructure[]
    >([]);
    const [tableRow, setTableRow] = useState<Row>(row);

    const handleRowCheckedChange =
        (rowId: number) => (newCheckedStatus: boolean) => {
            setChecked(newCheckedStatus);

            if (onRowCheckedChange) {
                onRowCheckedChange(rowId, newCheckedStatus);
            }
        };

    const handleExpandRowToggle = () => {
        setOpenDetails(!openDetails);
    };

    useEffect(() => {
        setChecked(isRowSelected);
    }, [isRowSelected]);

    const toggleCaregiver = (index) => {
        const tempRows: any = [...detailsRows];
        // eslint-disable-next-line dot-notation
        tempRows[index]['isEdit'] = !tempRows[index]['isEdit'];
        setDetailsRows([...tempRows]);
    };

    useEffect(() => {
        if (usersList?.length) {
            const newOptions: DropdownStructure[] = usersList.map(
                (caregiver) => ({
                    label: `${caregiver.firstName} ${caregiver.lastName}`,
                    value: caregiver.userId,
                    phoneNumber: caregiver.phoneNumber,
                })
            );
            setCaregiverOptions(newOptions);
        }
    }, [JSON.stringify(usersList)]);

    const handleDropdownAllTaskChange =
        (taskIndex) => (optionValue: number) => {
            if (!optionValue) return;
            const getValueIndex: number = caregiverOptions.findIndex(
                (opt) => opt.value === optionValue
            );
            if (getValueIndex >= 0) {
                const tempRows: any = [...detailsRows];
                dispatchUpdateCaregiver &&
                    dispatchUpdateCaregiver(
                        tempRows[taskIndex].id,
                        { user_id: optionValue },
                        () => {
                            tempRows[taskIndex].caregiver.fullName =
                                caregiverOptions[getValueIndex].label;
                            tempRows[taskIndex].caregiver.selectUserId =
                                optionValue;
                            // eslint-disable-next-line dot-notation
                            tempRows[taskIndex].caregiver.phoneNumber =
                                caregiverOptions[getValueIndex]['phoneNumber'];
                            setDetailsRows([...tempRows]);
                        }
                    );
            }
        };

    const overdueIncompleteChange = () => (optionValue: number) => {
        if (!optionValue) return;
        const getValueIndex: number = caregiverOptions.findIndex(
            (opt) => opt.value === optionValue
        );
        dispatchUpdateCaregiver &&
            dispatchUpdateCaregiver(
                tableRow.id as number,
                { user_id: optionValue },
                () => {
                    const tempTask = {
                        ...tableRow,
                        caregiver: {
                            ...(tableRow.caregiver as any),
                            fullName: caregiverOptions[getValueIndex].label,
                            selectUserId: optionValue,
                            // eslint-disable-next-line dot-notation
                            phoneNumber:
                                caregiverOptions[getValueIndex]['phoneNumber'],
                        },
                    };
                    // eslint-disable-next-line dot-notation
                    setTableRow(tempTask);
                }
            );
    };

    useEffect(() => {
        setTableRow({ ...row });
    }, [row]);

    return (
        <>
            <TableRow role="checkbox" tabIndex={-1} hover>
                {useCheckBoxes && (
                    <CheckboxColumn
                        id="checkbox"
                        align={ALIGNMENT_TYPES.center}
                    >
                        <CustomCheckbox
                            checked={checked}
                            onChange={handleRowCheckedChange(
                                tableRow.id as number
                            )}
                        />
                    </CheckboxColumn>
                )}
                {columns.map((column) => {
                    const {
                        align,
                        avatarType,
                        id: columnId,
                        showAvatar,
                    } = column;
                    const avatarProps: AvatarProps = {
                        avatarType,
                        size: 24,
                    } as AvatarProps;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let value: any = tableRow[columnId];

                    if (typeof value === 'object' && value?.firstName) {
                        const fullName = `${value.firstName} ${value.lastName}`;
                        avatarProps.firstName = value.firstName;
                        avatarProps.lastName = value.lastName;
                        avatarProps.fullName = value.fullName || fullName;
                        avatarProps.photo = value.photo;
                        value = avatarProps.fullName || fullName;
                    } else if (
                        !!avatarType &&
                        showAvatar &&
                        typeof value === 'string'
                    ) {
                        avatarProps.fullName = value;
                    } else if (column.content) {
                        value = column.content(tableRow[columnId]);
                    }
                    // eslint-disable-next-line multiline-ternary, dot-notation
                    if (
                        column.id === 'caregiver' &&
                        tableRow.caregiver &&
                        tableRow.caregiver['selectUserId']
                    ) {
                        return (
                            <TableCell key={columnId} align={align}>
                                <Cell>
                                    {
                                        // eslint-disable-next-line multiline-ternary, dot-notation
                                        !tableRow['isEdit'] ? (
                                            <Cell>
                                                {
                                                    // eslint-disable-next-line multiline-ternary, dot-notation
                                                    `${tableRow.caregiver['fullName']} ${tableRow.caregiver['phoneNumber']}`
                                                }
                                            </Cell>
                                        ) : (
                                            <FilterContainer
                                                className={classes.caregiver}
                                            >
                                                <CustomAutocomplete
                                                    label={dashboards.caregiver}
                                                    // eslint-disable-next-line dot-notation
                                                    value={
                                                        tableRow.caregiver[
                                                            'selectUserId'
                                                        ]
                                                    }
                                                    options={caregiverOptions}
                                                    fullWidth
                                                    onChange={overdueIncompleteChange()}
                                                />
                                            </FilterContainer>
                                        )
                                    }
                                    <Cell
                                        onClick={() => {
                                            setTableRow((prev) => ({
                                                ...prev,
                                                isEdit: !prev.isEdit,
                                            }));
                                        }}
                                    >
                                        {
                                            // eslint-disable-next-line dot-notation
                                            !tableRow['isEdit'] ? (
                                                <ModeIconGrey />
                                            ) : (
                                                <CloseIconGrey />
                                            )
                                        }
                                    </Cell>
                                </Cell>
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell key={columnId} align={align}>
                            <Cell>
                                {showAvatar && (
                                    <AvatarContainer>
                                        <CustomAvatar {...avatarProps} />
                                    </AvatarContainer>
                                )}
                                {value}
                            </Cell>
                        </TableCell>
                    );
                })}
                {hasDetails && (
                    <TableCell>
                        <IconButton
                            size="small"
                            onClick={handleExpandRowToggle}
                        >
                            {openDetails ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            {hasDetails && openDetails && (
                <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                        <Collapse in={openDetails} timeout="auto" unmountOnExit>
                            {!detailsRows.length ? (
                                <NoData hideCard useFilters />
                            ) : (
                                <Table>
                                    <TableBody>
                                        {detailsRows.map((detailRow, index) => (
                                            <TableRow
                                                key={detailRow.id as number}
                                            >
                                                {detailsColumns.map(
                                                    (detailColumn) => {
                                                        const {
                                                            align: detailColumnAlign,
                                                            id: detailColumnId,
                                                            minWidth = 'inherit',
                                                            width = 'auto',
                                                        } = detailColumn;
                                                        const cellStyles = {
                                                            width,
                                                            minWidth,
                                                        };

                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        let innerValue: any =
                                                            detailRow[
                                                                detailColumnId
                                                            ];
                                                        if (
                                                            typeof innerValue ===
                                                                'object' &&
                                                            innerValue?.firstName
                                                        ) {
                                                            const fullName = `${innerValue.firstName} ${innerValue.lastName}`;
                                                            innerValue =
                                                                innerValue.fullName ||
                                                                fullName;
                                                        } else if (
                                                            detailColumn.content
                                                        ) {
                                                            innerValue =
                                                                detailColumn.content(
                                                                    detailRow[
                                                                        detailColumnId
                                                                    ]
                                                                );
                                                        }
                                                        // eslint-disable-next-line multiline-ternary, dot-notation
                                                        if (
                                                            detailColumn.id ===
                                                                'caregiver' &&
                                                            detailRow.caregiver &&
                                                            detailRow.caregiver[
                                                                'selectUserId'
                                                            ]
                                                        ) {
                                                            return (
                                                                <TableCell
                                                                    key={
                                                                        detailColumnId
                                                                    }
                                                                    align={
                                                                        detailColumnAlign
                                                                    }
                                                                    sx={
                                                                        cellStyles
                                                                    }
                                                                >
                                                                    <Cell>
                                                                        {
                                                                            // eslint-disable-next-line multiline-ternary, dot-notation
                                                                            !detailRow[
                                                                                'isEdit'
                                                                            ] ? (
                                                                                <Cell>
                                                                                    {
                                                                                        // eslint-disable-next-line multiline-ternary, dot-notation
                                                                                        `${detailRow.caregiver['fullName']} ${detailRow.caregiver['phoneNumber']}`
                                                                                    }
                                                                                </Cell>
                                                                            ) : (
                                                                                <FilterContainer
                                                                                    className={
                                                                                        classes.caregiver
                                                                                    }
                                                                                >
                                                                                    <CustomAutocomplete
                                                                                        label={
                                                                                            dashboards.caregiver
                                                                                        }
                                                                                        // eslint-disable-next-line dot-notation
                                                                                        value={
                                                                                            detailRow
                                                                                                .caregiver[
                                                                                                'selectUserId'
                                                                                            ]
                                                                                        }
                                                                                        options={
                                                                                            caregiverOptions
                                                                                        }
                                                                                        fullWidth
                                                                                        onChange={handleDropdownAllTaskChange(
                                                                                            index
                                                                                        )}
                                                                                    />
                                                                                </FilterContainer>
                                                                            )
                                                                        }
                                                                        <Cell
                                                                            onClick={() =>
                                                                                toggleCaregiver(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                // eslint-disable-next-line dot-notation
                                                                                !detailRow[
                                                                                    'isEdit'
                                                                                ] ? (
                                                                                    <ModeIconGrey />
                                                                                ) : (
                                                                                    <CloseIconGrey />
                                                                                )
                                                                            }
                                                                        </Cell>
                                                                    </Cell>
                                                                </TableCell>
                                                            );
                                                        }
                                                        return (
                                                            <TableCell
                                                                key={
                                                                    detailColumnId
                                                                }
                                                                align={
                                                                    detailColumnAlign
                                                                }
                                                                sx={cellStyles}
                                                            >
                                                                <Cell>
                                                                    {innerValue}
                                                                </Cell>
                                                            </TableCell>
                                                        );
                                                    }
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default BodyRow;
