import {
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import { SelectStructure, SelectValue } from 'types/inputs';

import { pxToRem } from 'components/theme/typography';

const FormControlStyle = styled(FormControl)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        width: 'auto',
        '& .MuiInput-root.MuiInputBase-root': {
            fontSize: { lg: pxToRem(14) },
        },
    })
);

const MenuItemStyle = styled(MenuItem)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(14) },
        justifyContent: 'center',
    })
);

type Props = {
    id: string;
    className?: string;
    options: SelectStructure[];
    value: number;
    onChange: (newValue: number) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomRowsPerPageSelector = (props: Props) => {
    const { id, className, options, value, onChange } = props;
    const [singleValue, setSingleValue] = useState<SelectValue>();

    const handleChange = (event: SelectChangeEvent<SelectValue>) => {
        const { value: selectedValue } = event.target;
        const numericValue = Number(selectedValue);

        setSingleValue(selectedValue);
        onChange(numericValue);
    };

    useEffect(() => {
        setSingleValue(value.toString());
    }, [value]);

    if (!singleValue) {
        return null;
    }

    return (
        <FormControlStyle variant="standard" size="small">
            <Select
                id={id}
                className={className}
                value={singleValue}
                size="small"
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItemStyle key={option.value} value={option.value}>
                        {option.label}
                    </MenuItemStyle>
                ))}
            </Select>
        </FormControlStyle>
    );
};
