import { MoreHorizOutlined as MoreIcon } from '@mui/icons-material';
import {
    Box,
    Card,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react';
import { BiCoinStack as CoinsIcon, BiTask as NumberIcon } from 'react-icons/bi';
import { connect } from 'react-redux';

import {
    DashboardPerformanceResponse,
    DashboardReadParams,
} from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import {
    DASHBOARD_PERFORMANCE_CARD_HEIGHT,
    DASHBOARD_PERFORMANCE_MINI_CARDS_CONTAINER_HEIGHT,
} from 'constants/dashboards';
import CardBaseStructure from 'pages/Dashboards/components/CardBaseStructure';
import { readPerformance } from 'redux/actions/dashboards';

const MiniCardsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
    })
);

const MiniCardValue = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        color: theme.palette.common.black,
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'bold',
    })
);

const CaregiversChartContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        height: `calc(100% - ${pxToRem(
            DASHBOARD_PERFORMANCE_MINI_CARDS_CONTAINER_HEIGHT - 24
        )})`,
    })
);

const ChartRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '&:last-of-type': {
            mb: 0,
        },
    })
);

const ChartItemIndex = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
        justifyContent: 'flex-start',
        width: pxToRem(32),
    })
);

const ChartItemDataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-start',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

const ChartItemData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pb: pxToRem(4),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ChartItemLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'bold',
        flexGrow: 1,
    })
);

const ChartItemValueTasks = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(12) },
        color: 'app.green.main',
        marginRight: pxToRem(8),
        alignItems: 'center',
    })
);

const ChartItemValuePoints = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(12) },
        color: 'app.orange.main',
        alignItems: 'center',
    })
);

const ChartSeparator = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(40),
        position: 'relative',
        display: 'block',
        textAlign: 'center',
        borderBottomWidth: pxToRem(1),
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
        width: '100%',
        height: pxToRem(24),
    })
);

const IconButtonStyle = styled(IconButton)(({ theme }) =>
    theme.unstable_sx({
        borderWidth: pxToRem(1),
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
    })
);

const MiniCard = ({ children }) => {
    const isMobile = useMediaQuery('(max-width:450px)');

    return (
        <Box
            sx={{
                px: pxToRem(16),
                py: pxToRem(8),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: pxToRem(8),
                borderWidth: pxToRem(1),
                borderStyle: 'solid',
                borderColor: 'grey.300',
                width: pxToRem(160),
                boxShadow: 'none',
                '&:first-of-type': {
                    marginLeft: 0,
                },
                marginLeft: pxToRem(4),
                flexDirection: isMobile ? 'column' : 'row',
            }}
        >
            {children}
        </Box>
    );
};

const MiniCardConcept = ({ children, isPoints = false, isTasks = false }) => {
    const isMobile = useMediaQuery('(max-width:450px)');

    let textColor = 'grey.400';
    if (isPoints) {
        textColor = 'app.orange.main';
    } else if (isTasks) {
        textColor = 'app.green.main';
    }

    return (
        <Box
            sx={{
                display: 'flex',
                fontSize: {
                    lg: pxToRem(10),
                    md: pxToRem(10),
                    sm: pxToRem(10),
                    xs: pxToRem(10),
                },
                width: pxToRem(72),
                textAlign: isMobile ? 'center' : 'left',
                color: textColor,
            }}
        >
            {children}
        </Box>
    );
};

type Props = {
    dictionary: LanguageStructure;
    performance: DashboardPerformanceResponse;
    onExpand: () => void;
};

const PerformanceCard = (props: Props) => {
    const {
        dictionary: { dashboards },
        performance: {
            careTasksProvided = 0,
            careTasksPointsProvided = 0,
            caregivers = [],
            totalResidentsServed = 0,
        },
        onExpand,
    } = props;
    const maxTotalCaregiversToShow = 6;
    const maxTotalCaregiversPerBlock = maxTotalCaregiversToShow / 2;
    const totalCaregivers = dashboards.byTotalCaregivers.replace(
        '{XX}',
        caregivers.length.toLocaleString()
    );
    const secondBlockStartIndex =
        caregivers.length - maxTotalCaregiversPerBlock;
    const maxTotalTasks =
        caregivers.length > 0
            ? Math.max(...caregivers.map((caregiver) => caregiver.totalTasks))
            : 0;

    const maxTotalPoints =
        caregivers.length > 0
            ? Math.max(...caregivers.map((caregiver) => caregiver.totalPoints))
            : 0;

    const firstBlock =
        caregivers.length > maxTotalCaregiversToShow
            ? caregivers.slice(0, maxTotalCaregiversPerBlock)
            : caregivers;
    const secondBlock =
        caregivers.length > maxTotalCaregiversToShow
            ? caregivers.slice(secondBlockStartIndex)
            : [];
    const caregiversToShow = {
        firstBlock,
        secondBlock,
    };

    return (
        <CardBaseStructure
            cardType="performance"
            height={pxToRem(DASHBOARD_PERFORMANCE_CARD_HEIGHT)}
            title={dashboards.performance}
            subtitle={totalCaregivers}
        >
            <MiniCardsContainer>
                <MiniCard>
                    <MiniCardConcept isTasks>
                        {dashboards.careTasksProvided}
                    </MiniCardConcept>
                    <MiniCardValue>
                        {careTasksProvided.toLocaleString()}
                    </MiniCardValue>
                </MiniCard>
                <MiniCard>
                    <MiniCardConcept isPoints>Points Completed</MiniCardConcept>
                    <MiniCardValue>
                        {careTasksPointsProvided.toLocaleString()}
                    </MiniCardValue>
                </MiniCard>
                <MiniCard>
                    <MiniCardConcept>
                        {dashboards.residentsServed}
                    </MiniCardConcept>
                    <MiniCardValue>
                        {totalResidentsServed.toLocaleString()}
                    </MiniCardValue>
                </MiniCard>
            </MiniCardsContainer>
            <CaregiversChartContainer>
                {Object.values(caregiversToShow).map(
                    (caregiversBlock, index) => {
                        const startIndex =
                            index > 0 ? secondBlockStartIndex : 0;
                        const key = `caregiverBlock${index}`;

                        return (
                            <Fragment key={key}>
                                {caregiversBlock.map(
                                    (caregiver, caregiverIndex) => {
                                        const {
                                            id,
                                            name,
                                            totalTasks,
                                            totalPoints,
                                        } = caregiver;
                                        const chartWidth = Math.ceil(
                                            (totalTasks * 100) / maxTotalTasks
                                        );
                                        const pointsChartWidth = Math.ceil(
                                            (totalPoints * 100) / maxTotalPoints
                                        );

                                        return (
                                            <Fragment key={id}>
                                                {index > 0 &&
                                                    caregiverIndex === 0 && (
                                                        <ChartSeparator>
                                                            <IconButtonStyle
                                                                size="large"
                                                                aria-haspopup="false"
                                                                color="inherit"
                                                                onClick={
                                                                    onExpand
                                                                }
                                                            >
                                                                <MoreIcon />
                                                            </IconButtonStyle>
                                                        </ChartSeparator>
                                                    )}
                                                <ChartRow>
                                                    <ChartItemIndex>
                                                        #
                                                        {caregiverIndex +
                                                            startIndex +
                                                            1}
                                                    </ChartItemIndex>
                                                    <ChartItemDataContainer>
                                                        <ChartItemData>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <ChartItemLabel>
                                                                    {name}
                                                                </ChartItemLabel>
                                                                <ChartItemValueTasks>
                                                                    <NumberIcon />
                                                                    {totalTasks.toLocaleString()}
                                                                </ChartItemValueTasks>
                                                                <ChartItemValuePoints>
                                                                    <CoinsIcon />
                                                                    {totalPoints.toLocaleString()}
                                                                </ChartItemValuePoints>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    borderBottomWidth:
                                                                        pxToRem(
                                                                            4
                                                                        ),
                                                                    borderBottomStyle:
                                                                        'solid',
                                                                    borderBottomColor:
                                                                        'app.green.main',
                                                                    width: `${chartWidth}%`,
                                                                }}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    borderBottomWidth:
                                                                        pxToRem(
                                                                            4
                                                                        ),
                                                                    borderBottomStyle:
                                                                        'solid',
                                                                    borderBottomColor:
                                                                        'app.orange.main',
                                                                    width: `${pointsChartWidth}%`,
                                                                    marginTop:
                                                                        pxToRem(
                                                                            1
                                                                        ),
                                                                }}
                                                            />
                                                        </ChartItemData>
                                                    </ChartItemDataContainer>
                                                </ChartRow>
                                            </Fragment>
                                        );
                                    }
                                )}
                            </Fragment>
                        );
                    }
                )}
            </CaregiversChartContainer>
        </CardBaseStructure>
    );
};

const mapStateToProps = ({ dashboards, language }: ReduxStore) => {
    const { performance } = dashboards;
    const { dictionary } = language;

    return {
        dictionary,
        performance,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadPerformance: (params: DashboardReadParams) =>
        dispatch(readPerformance(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceCard);
