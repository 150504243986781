import {
    VisibilityOff as HidePasswordIcon,
    Visibility as ShowPasswordIcon,
} from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { decode as base64Decode } from 'base-64';
import { cloneDeep, isEmpty, isNil, omit, pick } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { NotificationDialogProps } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { SessionReadParams } from 'types/session';
import { InvitationResponse } from 'types/signupInvitations';
import { UserCreateParams, UserFormData, UserFormErrors } from 'types/users';

import { CustomDatePicker, CustomTextField } from 'components/Custom';
import AllieHealthLogo from 'components/Shared/AllieHealthLogo';
import Loading from 'components/Shared/Loading';
import NotificationDialog from 'components/Shared/NotificationDialog';
import { pxToRem } from 'components/theme/typography';
import { BASE_PATH, LOGIN_PATH } from 'constants/app';
import { SIGNUP } from 'constants/localStorage';
import {
    ONLY_LETTERS_AND_SPACES_REGEX,
    ONLY_START_WITH_LETTER_REGEX,
    PASSWORD_REGEX,
} from 'constants/regex';
import {
    INITIAL_NOTIFICATION_DIALOG_STATE,
    NOTIFICATION_TYPE,
} from 'constants/shared';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';
import { signIn } from 'redux/actions/session';
import {
    createUserFromInvitation,
    readInvitation,
} from 'redux/actions/signupInvitations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    phone: {
        '& .PhoneInputInput': {
            padding: `${pxToRem(8.5)} ${pxToRem(14)}`,
            color: theme.palette.common.black,
            height: pxToRem(42),
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.border}`,
            fontSize: pxToRem(18),
            outline: 'none',
            '&:focus': {
                marginLeft: '-1px',
                border: `2px solid ${theme.palette.primary.main}`,
            },
        },
    },
    error: {
        '& .PhoneInputInput': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            '&:focus': {
                borderColor: theme.palette.error.main,
            },
            '&::placeholder': {
                color: theme.palette.error.main,
            },
        },
    },
}));

const MainContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    })
);

const ErrorMessageContainer = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(18), lg: pxToRem(16) },
        lineHeight: { xs: pxToRem(18), lg: pxToRem(16) },
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'center',
    })
);

const TopSection = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(48),
        mb: pxToRem(80),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const Title = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(24), lg: pxToRem(20) },
        lineHeight: { xs: pxToRem(24), lg: pxToRem(20) },
        fontWeight: 'bold',
        width: '100%',
    })
);

const Instructions = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        m: 0,
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(14), lg: pxToRem(14) },
        lineHeight: { xs: pxToRem(14), lg: pxToRem(14) },
        color: theme.palette.grey[600],
        fontWeight: 'normal',
        width: '100%',
    })
);

const FormWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const PhoneNumberWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        mb: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const PhoneNumberErrorMessage = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mx: pxToRem(14),
        mt: pxToRem(4),
        mb: 0,
        color: theme.palette.error.main,
        fontSize: { xs: pxToRem(16), lg: pxToRem(16) },
    })
);

const ButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    })
);

const CancelButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
    })
);

type Props = {
    dictionary: LanguageStructure;
    loading: boolean;
    invitation?: InvitationResponse;
    invitationErrorMessage?: string;
    dispatchCreateUserFromInvitation: (jsonParams: UserCreateParams) => void;
    dispatchReadInvitation: (email: string) => void;
    dispatchSignIn: (params: SessionReadParams) => void;
};

const SignupForm = (props: Props) => {
    const navigate = useNavigate();
    const { encodedLink } = useParams();
    const classes = useStyles();
    const {
        dictionary: {
            error: errorDictionary,
            shared,
            signupForm,
            userManagement,
            userProfile,
        },
        loading,
        invitation,
        invitationErrorMessage,
        dispatchCreateUserFromInvitation,
        dispatchReadInvitation,
        dispatchSignIn,
    } = props;
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [form, setForm] = useState<UserFormData>({} as UserFormData);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] =
        useState<boolean>(false);
    const [errors, setErrors] = useState<UserFormErrors>({});
    const [notificationData, setNotificationData] =
        useState<NotificationDialogProps>(INITIAL_NOTIFICATION_DIALOG_STATE);
    const [disableButtons, setDisableButtons] = useState<boolean>(false);

    const togglePasswordView = (field: string) => () => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const validateForm = (hasErrors?: boolean) => {
        const validRequired = !!(
            form.firstName &&
            form.lastName &&
            form.dateOfBirth &&
            form.phoneNumber &&
            form.password &&
            form.confirmPassword
        );
        const validForm: boolean =
            !hasErrors && isEmpty(errors) && validRequired;
        setIsFormValid(validForm);
    };

    const fieldValidation = (field: string, fieldValue: string) => {
        // DON'T REMOVE THESE FIRST THREE LINES.
        // Initialize the last index for each time they need to be validated. Otherwise the validation could fail.
        ONLY_LETTERS_AND_SPACES_REGEX.lastIndex = 0;
        ONLY_START_WITH_LETTER_REGEX.lastIndex = 0;
        PASSWORD_REGEX.lastIndex = 0;

        // Validate the field.
        const onlyLettersCheck = ONLY_LETTERS_AND_SPACES_REGEX.test(fieldValue);
        const startWithLetterCheck =
            ONLY_START_WITH_LETTER_REGEX.test(fieldValue);
        const validPassword = PASSWORD_REGEX.test(fieldValue);

        // Verify the field type.
        const isPassword = field.toLowerCase().includes('password');
        const isConfirmation = field === 'confirmPassword';
        const isPhoneNumber = field === 'phoneNumber';
        const isText = !isPassword && !isPhoneNumber;

        if (isText && !onlyLettersCheck) {
            setErrors({
                ...errors,
                [field]: shared.onlyLettersAndSpaces,
            });
            return;
        }

        if (isText && !startWithLetterCheck) {
            setErrors({
                ...errors,
                [field]: shared.mustStartWithALetter,
            });
            return;
        }

        if (isPhoneNumber) {
            handlePhoneNumberValidate(fieldValue);
            return;
        }

        if (isConfirmation && fieldValue !== form.password) {
            setErrors({
                ...errors,
                [field]: signupForm.passwordMismatch,
            });
            return;
        }

        if (isPassword && !validPassword) {
            setErrors({
                ...errors,
                [field]: signupForm.passwordError,
            });
            return;
        }

        const errorsClone = cloneDeep(errors);
        delete errorsClone[field];
        setErrors(errorsClone);
    };

    const handlePhoneNumberValidate = (newValue: string) => {
        if (!newValue) {
            setErrors({
                ...errors,
                phoneNumber: errorDictionary.emptyValue,
            });
            return errorDictionary.emptyValue;
        }

        if (!isValidPhoneNumber(newValue)) {
            setErrors({
                ...errors,
                phoneNumber: signupForm.invalidPhoneNumber,
            });
            return signupForm.invalidPhoneNumber;
        }

        const errorsClone = cloneDeep(errors);
        delete errorsClone.phoneNumber;
        setErrors(errorsClone);

        return undefined;
    };

    const handlePhoneNumberBlur = () => {
        handlePhoneNumberValidate(form.phoneNumber);
    };

    const handleFormFieldChange =
        (field: string, validate?: boolean) => (newValue: string) => {
            if (validate) {
                fieldValidation(field, newValue as string);
            }

            setForm({
                ...form,
                [field]: newValue,
            });
        };

    const handleDatePickerChange = (newDate: string, dateHasError: boolean) => {
        setForm({
            ...form,
            dateOfBirth: newDate,
        });

        if (dateHasError) {
            setErrors({
                ...errors,
                dateOfBirth: signupForm.invalidDateOfBirth,
            });
        } else {
            const errorsClone = cloneDeep(errors);
            delete errorsClone.dateOfBirth;
            setErrors(errorsClone);
        }
    };

    const handleCancelClick = () => {
        navigate(`${BASE_PATH}${LOGIN_PATH}`);
    };

    const handleSubmitClick = async () => {
        // Do nothing if the button was triggered but the form is invalid.
        if (!isFormValid || !invitation) {
            return;
        }

        // Set the params to send to the API.
        const jsonParams: UserCreateParams = omit(form, ['confirmPassword']);
        const signInParams: SessionReadParams = pick(form, [
            'username',
            'password',
        ]);

        jsonParams.dateOfBirth = moment(form.dateOfBirth).format().slice(0, 10);

        if (
            jsonParams.email !== invitation?.email ||
            jsonParams.username !== invitation.email
        ) {
            setNotificationData({
                isOpen: true,
                title: signupForm.formErrorTitle,
                message: signupForm.formErrorMessage,
                type: NOTIFICATION_TYPE.error,
            });
            return;
        }

        setNotificationData({
            closable: false,
            isOpen: true,
            title: signupForm.processingTitle,
            message: signupForm.processingMessage,
            type: NOTIFICATION_TYPE.info,
        });

        setDisableButtons(true);

        try {
            // Complete the Sign-up process.
            await dispatchCreateUserFromInvitation(jsonParams);

            // Activate a flag requesting for an invitation update.
            localStorage.setItem(SIGNUP, invitation.invitationId.toString());

            setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);

            // Login the user with the provided credenials.
            await dispatchSignIn(signInParams);
        } catch (error) {
            setDisableButtons(false);
        }
    };

    const handleCloseNotification = () => {
        setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);

        const formClone = cloneDeep(form);
        formClone.email = invitation?.email || '';
        formClone.username = invitation?.email || '';
        setForm(formClone);
    };

    useEffect(() => {
        if (!encodedLink) {
            setErrorMessage(
                `${userManagement.signUpThankYou} ${userManagement.inexistentCode}`
            );
        } else if (!invitation && encodedLink) {
            try {
                // Decode the link code.
                const decodedLink = JSON.parse(base64Decode(encodedLink));

                // If the decoded link hasn't a property called "email", throw an error. Otherwise, look for the invitation.
                if (!decodedLink.email) {
                    setErrorMessage(
                        `${userManagement.signUpThankYou} ${userManagement.invalidCode}`
                    );
                } else {
                    dispatchReadInvitation(decodedLink.email);
                }
            } catch (error) {
                // Show an error message if the decoding process fails.
                setErrorMessage(
                    `${userManagement.signUpThankYou} ${userManagement.invalidCode}`
                );
            }
        } else if (invitation) {
            setForm({
                roleId: invitation.roleId,
                companyId: invitation.companyId,
                branchId: invitation.branchId,
                username: invitation.email,
                password: '',
                confirmPassword: '',
                email: invitation.email,
                phoneNumber: '',
                firstName: invitation.firstName,
                lastName: invitation.lastName,
                picture: null,
                dateOfBirth: moment().format(),
            });
        }
    }, [encodedLink, invitation]);

    useEffect(() => {
        let message;

        switch (invitationErrorMessage) {
            case 'The Invitation does not exist!':
                message = userManagement.invitationInvalid;
                break;
            case 'The Invitation has already been completed!':
                message = userManagement.invitationCompleted;
                break;
            case 'The Invitation has been canceled!':
                message = userManagement.invitationCanceled;
                break;
            default:
        }

        if (message) {
            message = `${userManagement.signUpThankYou} ${message}`;
            setErrorMessage(message);
        }
    }, [invitationErrorMessage]);

    if (loading) {
        return <Loading />;
    }

    return (
        <PageStructure>
            <MainContainer>
                <AllieHealthLogo />
                {errorMessage && (
                    <TopSection>
                        <ErrorMessageContainer>
                            {errorMessage}
                        </ErrorMessageContainer>
                        <ErrorMessageContainer>
                            {userManagement.gotoLogin}
                        </ErrorMessageContainer>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelClick}
                        >
                            {shared.goToLoginPage}
                        </Button>
                    </TopSection>
                )}
                {!errorMessage && (
                    <>
                        <TopSection>
                            <Title>{signupForm.title}</Title>
                            <Instructions>
                                {signupForm.instructions}
                            </Instructions>
                        </TopSection>
                        {!isNil(form) && !isEmpty(form) && (
                            <FormWrapper>
                                <CustomTextField
                                    id="firstName"
                                    label={userProfile.firstName}
                                    value={form.firstName}
                                    customErrorMessage={errors.firstName}
                                    fullWidth
                                    required
                                    onBlur={validateForm}
                                    onChange={handleFormFieldChange(
                                        'firstName',
                                        true
                                    )}
                                />
                                <CustomTextField
                                    id="lastName"
                                    label={userProfile.lastName}
                                    value={form.lastName}
                                    customErrorMessage={errors.lastName}
                                    fullWidth
                                    required
                                    onBlur={validateForm}
                                    onChange={handleFormFieldChange(
                                        'lastName',
                                        true
                                    )}
                                />
                                <CustomDatePicker
                                    date={form.dateOfBirth}
                                    dateLabel={userProfile.dateOfBirth}
                                    marginLeft={0}
                                    disableFuture
                                    onBlur={validateForm}
                                    onChange={handleDatePickerChange}
                                />
                                <CustomTextField
                                    id="email"
                                    label={userProfile.email}
                                    value={form.email}
                                    customErrorMessage={errors.email}
                                    fullWidth
                                    disabled
                                    onChange={handleFormFieldChange('email')}
                                />
                                <PhoneNumberWrapper>
                                    <PhoneInput
                                        defaultCountry="US"
                                        value={form.phoneNumber}
                                        placeholder={userProfile.phoneNumber}
                                        className={`${classes.phone} ${
                                            errors.phoneNumber
                                                ? classes.error
                                                : ''
                                        }`}
                                        onBlur={handlePhoneNumberBlur}
                                        onChange={handleFormFieldChange(
                                            'phoneNumber',
                                            true
                                        )}
                                    />
                                    {errors.phoneNumber && (
                                        <PhoneNumberErrorMessage>
                                            {errors.phoneNumber}
                                        </PhoneNumberErrorMessage>
                                    )}
                                </PhoneNumberWrapper>
                                <CustomTextField
                                    id="username"
                                    label={userProfile.username}
                                    value={form.username}
                                    customErrorMessage={errors.username}
                                    fullWidth
                                    disabled
                                    onChange={handleFormFieldChange('username')}
                                />
                                <CustomTextField
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={userProfile.password}
                                    value={form.password}
                                    customErrorMessage={errors.password}
                                    fullWidth
                                    required
                                    endAdornment={
                                        <IconButton
                                            onClick={togglePasswordView(
                                                'password'
                                            )}
                                        >
                                            {showPassword ? (
                                                <HidePasswordIcon />
                                            ) : (
                                                <ShowPasswordIcon />
                                            )}
                                        </IconButton>
                                    }
                                    onBlur={validateForm}
                                    onChange={handleFormFieldChange(
                                        'password',
                                        true
                                    )}
                                />
                                <CustomTextField
                                    id="confirmPassword"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    label={userProfile.confirmPassword}
                                    value={form.confirmPassword}
                                    customErrorMessage={errors.confirmPassword}
                                    fullWidth
                                    required
                                    endAdornment={
                                        <IconButton
                                            onClick={togglePasswordView(
                                                'passwordConfirm'
                                            )}
                                        >
                                            {showConfirmPassword ? (
                                                <HidePasswordIcon />
                                            ) : (
                                                <ShowPasswordIcon />
                                            )}
                                        </IconButton>
                                    }
                                    onBlur={validateForm}
                                    onChange={handleFormFieldChange(
                                        'confirmPassword',
                                        true
                                    )}
                                />
                                <ButtonsWrapper>
                                    <CancelButton
                                        variant="outlined"
                                        color="secondary"
                                        disabled={disableButtons}
                                        onClick={handleCancelClick}
                                    >
                                        {shared.cancel}
                                    </CancelButton>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !isFormValid || disableButtons
                                        }
                                        onClick={handleSubmitClick}
                                    >
                                        {signupForm.signup}
                                    </Button>
                                </ButtonsWrapper>
                                <NotificationDialog
                                    closable={notificationData.closable}
                                    isOpen={notificationData.isOpen}
                                    message={notificationData.message}
                                    title={notificationData.title}
                                    type={notificationData.type}
                                    onClose={handleCloseNotification}
                                />
                            </FormWrapper>
                        )}
                    </>
                )}
            </MainContainer>
        </PageStructure>
    );
};

const mapStateToProps = ({ language, signupInvitations }: ReduxStore) => {
    const { dictionary } = language;
    const { invitation, invitationErrorMessage, loading } = signupInvitations;

    return {
        dictionary,
        invitation,
        invitationErrorMessage,
        loading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateUserFromInvitation: (jsonParams: UserCreateParams) =>
        dispatch(createUserFromInvitation(jsonParams)),
    dispatchReadInvitation: (email: string) => dispatch(readInvitation(email)),
    dispatchSignIn: (params: SessionReadParams) => dispatch(signIn(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedSignupForm: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(SignupForm)
);

export default ConnectedSignupForm;
