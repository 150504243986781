import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';

import celebrateEmoji from 'assets/celebrate_emoji.png';
import { pxToRem } from 'components/theme/typography';

type Props = {
    dictionary: LanguageStructure;
    residentName: string;
};

const AVATAR_SIZE = {
    xs: pxToRem(120),
    lg: pxToRem(150),
};

const AllTasksCompletedIndicator = (props: Props) => {
    const {
        dictionary: { residents: residentsDictionary },
        residentName,
    } = props;

    const personalizedMessage = residentsDictionary.allTasksDone.replace(
        '{name}',
        residentName
    );

    return (
        <Box
            sx={{
                mt: pxToRem(18),
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <Avatar
                alt="Task Done"
                src={celebrateEmoji}
                sx={{
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                    p: {
                        xs: pxToRem(42),
                        lg: pxToRem(50),
                    },
                    backgroundColor: '#EBF3F4',
                    mb: pxToRem(12),
                }}
            />
            <Typography
                variant="h3"
                fontSize={{ xs: pxToRem(20), lg: pxToRem(22) }}
                lineHeight={1.6}
            >
                {residentsDictionary.congratulations}
            </Typography>
            <Typography
                fontWeight={400}
                fontSize={{ xs: pxToRem(18), lg: pxToRem(20) }}
                lineHeight={1.6}
                textAlign="center"
            >
                {personalizedMessage}
            </Typography>
        </Box>
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedAllTasksCompletedIndicator: any = connect(mapStateToProps)(
    AllTasksCompletedIndicator
);

export default ConnectedAllTasksCompletedIndicator;
