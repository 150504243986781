import { useCallback } from 'react';

import useAnalyticsContext from './useAnalyticsContext';

export const useAnalytics = () => {
    const { analytics } = useAnalyticsContext();

    const pageViewed = useCallback(
        (name: string, category = 'App') => {
            analytics.page(category, name);
        },
        [analytics]
    );

    const trackButtonClicked = useCallback(
        (title: string) => {
            analytics.track('Button Clicked', {
                title,
            });
        },
        [analytics]
    );

    const identifyUser = useCallback(
        (id: number, traits: Record<string, any>) => {
            analytics.identify(id.toString(), traits);
        },
        [analytics]
    );

    return {
        pageViewed,
        trackButtonClicked,
        identifyUser,
    };
};
