import { AddTask, AddUnscheduledTask } from 'types/dailyTasks';
import { SelectStructure } from 'types/inputs';

export const TIME_WIDTH = 72;
export const RESIDENT_PHOTO_WIDTH = 56;
export const CHECKBOX_WIDTH = 64;

export const ADD_TASK_INITIAL_VALUES: AddTask = {
    residentId: 0,
    companyCarePlanId: 0,
};

export const ADD_UNSCHEDULED_TASK_INITIAL_VALUES: AddUnscheduledTask = {
    companyCarePlanId: 0,
};

export const SHIFTS_MAP = {
    AM: 'AM',
    MID: 'MID',
    PM: 'PM',
};

export const SHIFTS_TIMES = {
    AM: {
        start: 6,
        end: 13,
    },
    MID: {
        start: 14,
        end: 21,
    },
    PM: {
        start: 22,
        end: 5,
    },
};

export const EARLY_IN_SHIFT_MINUTES = 20;

export const TASKS_STATUSES = ['Completed', 'Dismissed', 'Canceled'];

export const TASKS_STATUSES_OPTIONS: SelectStructure[] = TASKS_STATUSES.map(
    (status) => ({
        label: status,
        value: status,
    })
);
