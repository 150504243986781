import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ResidentTasks } from 'types/dailyTasks';
import { ReduxStore } from 'types/redux';
import {
    ResidentsListReadParams,
    ResidentsListResponse,
    StoredResidentFilters,
} from 'types/residents';
import { StoredSession } from 'types/session';

import { useResidentsQuery } from 'api/queries/residents';
import Loading from 'components/Shared/Loading';
import { PROFILE, RESIDENTS_FILTERS } from 'constants/localStorage';
import AccessControl from 'helpers/AccessControl';
import { getPeriodOfTwoPreviousShifts } from 'lib/dailyTaskRecords';
import PageStructure from 'pages/PageStructure';
import FilterContainer from 'pages/Residents/components/FilterContainer';
import ResidentsContainer from 'pages/Residents/components/ResidentsContainer';

type Props = {
    timezone: string;
    zoneId: number;
    sortBy: string;
    sortOrder: string;
};

const Residents = (props: Props) => {
    const { timezone, zoneId, sortBy, sortOrder } = props;
    const [residentsToShow, setResidentsToShow] = useState<
        ResidentsListResponse[]
    >([]);
    const [totalResidentsToShow, setTotalResidentsToShow] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [statusId, setStatusId] = useState<string>('all');
    const [queryParams, setQueryParams] =
        useState<ResidentsListReadParams | null>(null);
    const { startPeriod, endPeriod } = getPeriodOfTwoPreviousShifts(timezone);

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery(queryParams);

    const handleSort = (
        newResidentsList: ResidentsListResponse[],
        selectedSortBy: keyof ResidentTasks | 'shower&Laundry',
        isAscending: boolean
    ): ResidentsListResponse[] => {
        if (selectedSortBy === 'shower&Laundry') {
            return orderBy(
                newResidentsList,
                ['hasShower', 'hasLaundry'],
                [isAscending ? 'desc' : 'asc', isAscending ? 'desc' : 'asc']
            );
        }

        if (selectedSortBy === 'roomNumber') {
            return orderBy(
                newResidentsList,
                (item) => Number(item[selectedSortBy]),
                isAscending ? 'asc' : 'desc'
            );
        }

        return orderBy(
            newResidentsList,
            selectedSortBy,
            isAscending ? 'asc' : 'desc'
        );
    };

    const handleSearchChange = (newSearchValue: string) => {
        setSearchText(newSearchValue);
        const searchInLowerCase = newSearchValue.toLowerCase();

        if (!residentsList) {
            return;
        }

        const newResidentsList: ResidentsListResponse[] = residentsList.filter(
            (resident) => {
                const statusValidation =
                    statusId !== 'all'
                        ? resident.buildingStatus === statusId
                        : true;
                return (
                    (resident.firstName
                        .toLowerCase()
                        .includes(searchInLowerCase) ||
                        resident.lastName
                            .toLowerCase()
                            .includes(searchInLowerCase) ||
                        resident.roomNumber
                            .toLowerCase()
                            .includes(searchInLowerCase)) &&
                    resident.zoneId === zoneId &&
                    statusValidation
                );
            }
        );

        const defaultResidentsList = orderBy(
            newResidentsList,
            (item) => Number(item.roomNumber),
            sortOrder === 'asc' ? 'asc' : 'desc'
        );

        const residentsListSorted = handleSort(
            defaultResidentsList,
            sortBy as keyof ResidentTasks | 'shower&Laundry',
            sortOrder === 'asc'
        );

        // Update the stored filters.
        const newStoredFilters: StoredResidentFilters = {
            searchText: newSearchValue,
            zoneId,
        };
        localStorage.setItem(
            RESIDENTS_FILTERS,
            JSON.stringify(newStoredFilters)
        );

        setResidentsToShow(residentsListSorted);
        setTotalResidentsToShow(residentsListSorted?.length);
    };

    const handleStatusChange = (newStatusId: string) => {
        setStatusId(newStatusId);
    };

    useEffect(() => {
        // Get the user's Branch ID.
        const storedSession: StoredSession = JSON.parse(
            localStorage.getItem(PROFILE) as string
        );
        const { branchId = 0 } = storedSession?.sessionData || {};
        setQueryParams({ branchId, startPeriod, endPeriod });

        // Get the stored filters.
        const storedFilters: StoredResidentFilters =
            JSON.parse(localStorage.getItem(RESIDENTS_FILTERS) as string) || {};
        const { searchText: storedSearchText = '' } = storedFilters;

        setSearchText(storedSearchText);
    }, []);

    useEffect(() => {
        if (residentsList?.length) {
            handleSearchChange(searchText);
        }
    }, [residentsList, zoneId, sortBy, sortOrder, statusId]);

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <PageStructure>
            <FilterContainer
                totalResidentsToShow={totalResidentsToShow}
                onSearch={handleSearchChange}
                onStatusChange={handleStatusChange}
            />
            <ResidentsContainer residentsToShow={residentsToShow} />
        </PageStructure>
    );
};

const mapStateToProps = ({ session, filters }: ReduxStore) => {
    const { timezone } = session;
    const {
        caregiverApp: { zoneId, sortBy, sortOrder },
    } = filters;

    return {
        timezone,
        zoneId,
        sortBy,
        sortOrder,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidents: any = AccessControl(
    connect(mapStateToProps)(Residents)
);

export default ConnectedResidents;
