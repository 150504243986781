import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { ResidentsListResponse } from 'types/residents';

import { useResidentsQuery } from 'api/queries/residents';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import ResidentRowData from 'pages/Residents/components/ResidentRowData';
import ResidentRowSkeleton from 'pages/Residents/components/ResidentRowSkeleton';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(10),
        position: 'relative',
        width: '100%',
    })
);

type Props = {
    dictionary: LanguageStructure;
    branchId: number;
    residentsToShow: ResidentsListResponse[];
};

const ResidentsContainer = (props: Props) => {
    const {
        dictionary: { residents: residentsDictionary },
        branchId,
        residentsToShow,
    } = props;

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery({ branchId });

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <RootStyle>
            {residentsListIsLoading && <ResidentRowSkeleton totalItems={3} />}
            {!residentsListIsLoading &&
                !residentsList?.length &&
                !residentsToShow.length && (
                    <Typography variant="subtitle1">
                        {residentsDictionary.noResidents}
                    </Typography>
                )}
            {!residentsListIsLoading &&
                residentsList.length > 0 &&
                !residentsToShow.length && (
                    <Typography variant="subtitle1">
                        {residentsDictionary.noMatch}
                    </Typography>
                )}
            {!residentsListIsLoading &&
                residentsToShow.map((resident) => (
                    <ResidentRowData
                        key={resident.residentId}
                        resident={resident}
                    />
                ))}
        </RootStyle>
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const {
        sessionData: { branchId },
    } = session;

    return {
        dictionary,
        branchId,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentsContainer: any =
    connect(mapStateToProps)(ResidentsContainer);

export default ConnectedResidentsContainer;
