import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import { RoleResponse, UserResponse, UsersListFiltersForm } from 'types/users';

import { CustomAutocomplete, CustomDropdownButton } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import { NOTIFICATION_TYPE } from 'constants/shared';
import { USERS_LIST_FILTERS_FORM_INITIAL_VALUES } from 'constants/users';
import InvitationDialog from 'pages/Users/Management/Invitations/InvitationDialog';
import UserCreateEditDialog from 'pages/Users/Management/Users/UserCreateEditDialog';

const useStyles = makeStyles({
    user: {
        width: pxToRem(272),
    },
    role: {
        width: pxToRem(192),
    },
    email: {
        width: pxToRem(272),
    },
    phoneNumber: {
        width: pxToRem(192),
    },
});

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    })
);

const FiltersWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
    })
);

const FilterContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
        '&:last-of-type': {
            mr: 0,
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    rolesList: RoleResponse[];
    usersList: UserResponse[];
    onDialogSubmit: (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => void;
    onFiltersChange: (newFilters: UsersListFiltersForm) => void;
};

const Filters = (props: Props) => {
    const classes = useStyles();
    const {
        dictionary: { userManagementUsersList },
        rolesList,
        usersList,
        onDialogSubmit,
        onFiltersChange,
    } = props;

    // Get the role id from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { roleId } = storedSession?.sessionData || {};

    // Define the tabs to display.
    const showManagers = roleId <= 3;
    const showAdmins = roleId <= 2;

    const [userNameOptions, setUserNameOptions] = useState<DropdownStructure[]>(
        []
    );
    const [roleOptions, setRoleOptions] = useState<DropdownStructure[]>([]);
    const [emailOptions, setEmailOptions] = useState<DropdownStructure[]>([]);
    const [phoneNumberOptions, setPhoneNumberOptions] = useState<
        DropdownStructure[]
    >([]);
    const [dropdownButtonOptionSelected, setDropdownButtonOptionSelected] =
        useState<number>(0);
    const [filtersForm, setFiltersForm] = useState<UsersListFiltersForm>(
        USERS_LIST_FILTERS_FORM_INITIAL_VALUES
    );
    const [openInvitationDialog, setOpenInvitationDialog] =
        useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    // Define the options to show for the Dropdown Button.
    const dropdownButtonOptions = [
        userManagementUsersList.createUser,
        userManagementUsersList.inviteUser,
    ];

    const handleDropdownChange =
        (filterName: string) => (optionValue: DropdownValue) => {
            const newFilters: UsersListFiltersForm = {
                ...filtersForm,
                [filterName]: optionValue as number,
            };
            setFiltersForm(newFilters);
            onFiltersChange(newFilters);
        };

    const handleDropdownButtonClick = (optionIndex: number) => {
        if (optionIndex === 0) {
            handleOpenCreateDialog();
        } else {
            handleOpenInvitationDialog();
        }
    };

    const handleDropdownButtonChange = (optionIndex: number) => {
        if (dropdownButtonOptionSelected !== optionIndex) {
            setDropdownButtonOptionSelected(optionIndex);
            handleDropdownButtonClick(optionIndex);
        }
    };

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleOpenInvitationDialog = () => {
        setOpenInvitationDialog(true);
    };

    const handleCloseInvitationDialog = () => {
        setOpenInvitationDialog(false);
    };

    const handleDialogSubmit = (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => {
        if (openCreateDialog && notification === NOTIFICATION_TYPE.success) {
            handleCloseCreateDialog();
        }

        if (openInvitationDialog) {
            handleCloseInvitationDialog();
        }

        onDialogSubmit(title, message, notification);
    };

    useEffect(() => {
        if (rolesList) {
            const newRoleOptions: DropdownStructure[] = rolesList.map(
                (role) => ({
                    label: role.roleName,
                    value: role.roleId,
                })
            );

            setRoleOptions(newRoleOptions);
        }

        if (usersList) {
            const newUserNameOptions: DropdownStructure[] = usersList.map(
                (user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.userId,
                })
            );

            const newEmailOptions: DropdownStructure[] = usersList
                .map((user, index) => ({
                    label: user.email,
                    value: index + 1,
                }))
                .filter((user) => !isNil(user.label));

            const newPhoneNumberOptions: DropdownStructure[] = usersList
                .map((user, index) => ({
                    label: user.phoneNumber,
                    value: index + 1,
                }))
                .filter((user) => !isNil(user.label));

            setUserNameOptions(newUserNameOptions);
            setEmailOptions(newEmailOptions);
            setPhoneNumberOptions(newPhoneNumberOptions);
        }
    }, [usersList, rolesList]);

    return (
        <RootStyle>
            <FiltersWrapper>
                <FilterContainer className={classes.user}>
                    <CustomAutocomplete
                        label={userManagementUsersList.user}
                        value={filtersForm.userId}
                        options={userNameOptions}
                        fullWidth
                        onChange={handleDropdownChange('userId')}
                    />
                </FilterContainer>
                {(showManagers || showAdmins) && (
                    <FilterContainer className={classes.role}>
                        <CustomAutocomplete
                            label={userManagementUsersList.role}
                            value={filtersForm.roleId}
                            options={roleOptions}
                            fullWidth
                            onChange={handleDropdownChange('roleId')}
                        />
                    </FilterContainer>
                )}
                <FilterContainer className={classes.email}>
                    <CustomAutocomplete
                        label={userManagementUsersList.email}
                        value={filtersForm.emailIndex}
                        options={emailOptions}
                        fullWidth
                        onChange={handleDropdownChange('emailIndex')}
                    />
                </FilterContainer>
                <FilterContainer className={classes.phoneNumber}>
                    <CustomAutocomplete
                        label={userManagementUsersList.phoneNumber}
                        value={filtersForm.phoneNumberIndex}
                        options={phoneNumberOptions}
                        fullWidth
                        onChange={handleDropdownChange('phoneNumberIndex')}
                    />
                </FilterContainer>
            </FiltersWrapper>
            <CustomDropdownButton
                options={dropdownButtonOptions}
                width={pxToRem(192)}
                onChange={handleDropdownButtonChange}
                onClick={handleDropdownButtonClick}
            />
            <UserCreateEditDialog
                id="user_create"
                isOpen={openCreateDialog}
                onSave={handleDialogSubmit}
                onClose={handleCloseCreateDialog}
            />
            <InvitationDialog
                isOpen={openInvitationDialog}
                onSubmit={handleDialogSubmit}
                onClose={handleCloseInvitationDialog}
            />
        </RootStyle>
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { rolesList, usersList } = users;

    return {
        dictionary,
        usersList,
        rolesList,
    };
};

export default connect(mapStateToProps)(Filters);
