import { Box, CardContent, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { DashboardDialogData, DashboardDialogType } from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import { DASHBOARD_CARD_TITLE_HEIGHT } from 'constants/dashboards';
import {
    ActionLabel,
    ActionLabelContainer,
    CardFooter,
    CardStyle,
    SeeDetails,
    SeeDetailsIcon,
} from 'pages/Dashboards/components/SharedComponents';
import { updatePriorParentPage } from 'redux/actions/session';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    titleBlock: {
        width: `calc(100% - ${pxToRem(88)})`,
    },
    titleFull: {
        width: '100%',
    },
    chip: {
        padding: `${pxToRem(4)} ${pxToRem(8)}`,
        borderRadius: pxToRem(4),
        color: theme.palette.app.orange.main,
        backgroundColor: alpha(theme.palette.app.orange.main, 0.16),
    },
}));

export const CardTitle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(24),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.common.white,
        borderBottomWidth: pxToRem(1),
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
        width: '100%',
        height: pxToRem(DASHBOARD_CARD_TITLE_HEIGHT),
    })
);

export const Title = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[900],
        fontSize: { lg: pxToRem(14) },
    })
);

export const Subtitle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(11) },
    })
);

export const CardBody = styled(CardContent)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(24),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: `calc(100% - ${pxToRem(DASHBOARD_CARD_TITLE_HEIGHT)})`,
        overflow: 'hidden',
    })
);

const TitleContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

type Props = {
    cardType: DashboardDialogType;
    children: ReactNode;
    data?: DashboardDialogData;
    detailsUrl?: string;
    dictionary: LanguageStructure;
    displayShowDetailsLink?: boolean;
    showAllModalTitle?: string;
    showAllTotalRecords?: number;
    height?: number | string;
    subtitle?: string;
    title: string;
    dispatchUpdatePriorParentPage: (parentPage: string) => void;
    onShowAllClick?: (
        newData: DashboardDialogData,
        newType: DashboardDialogType,
        newTitle: string
    ) => void;
};

const CardBaseStructure = (props: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        cardType,
        children,
        data = [],
        detailsUrl = '',
        dictionary: { shared },
        displayShowDetailsLink,
        showAllModalTitle = '',
        showAllTotalRecords = 0,
        height = 'auto',
        subtitle,
        title,
        dispatchUpdatePriorParentPage,
        onShowAllClick = () => null,
    } = props;
    const titleClass = displayShowDetailsLink
        ? classes.titleBlock
        : classes.titleFull;

    const handleShowDetails = () => {
        // Store the current parent page.
        dispatchUpdatePriorParentPage(pathname);

        // Navigate to the corresponding Details URL.
        navigate(detailsUrl);
    };

    const handleShowAllClick = () => {
        onShowAllClick(data, cardType, showAllModalTitle);
    };

    return (
        <CardStyle sx={{ height }}>
            <CardTitle>
                <TitleContainer>
                    <Title variant="body2" className={titleClass}>
                        {title}
                    </Title>
                    {/* {displayShowDetailsLink && (
            <SeeDetails variant="caption" onClick={handleShowDetails}>
              {shared.seeDetails}
              <SeeDetailsIcon />
            </SeeDetails>
          )} */}
                </TitleContainer>
                {!!subtitle && <Subtitle variant="body2">{subtitle}</Subtitle>}
            </CardTitle>
            <CardBody>{children}</CardBody>
            {showAllTotalRecords > 0 && (
                <CardFooter>
                    <ActionLabelContainer>
                        <ActionLabel
                            variant="caption"
                            onClick={handleShowAllClick}
                        >
                            {`${shared.showAll} (${showAllTotalRecords})`}
                        </ActionLabel>
                    </ActionLabelContainer>
                </CardFooter>
            )}
        </CardStyle>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdatePriorParentPage: (parentPage: string) =>
        dispatch(updatePriorParentPage(parentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardBaseStructure);
