import { TableSortLabel } from '@mui/material';
import React from 'react';

import { Column, SortDirection } from 'types/table';

type Props = {
    column: Column;
    initialSortDirection: SortDirection;
    isSortable?: boolean;
    sortBy?: string;
    sortDirection?: SortDirection;
    onSortChange: (columnId: string, initialDirection: SortDirection) => void;
};

const ColumnHeader = (props: Props) => {
    const {
        column,
        initialSortDirection,
        isSortable,
        sortBy,
        sortDirection,
        onSortChange,
    } = props;
    const sortByColumn: string = column.sortColumn || column.id;

    if (!isSortable) {
        return <>{column.label}</>;
    }

    return (
        <TableSortLabel
            active={sortBy === sortByColumn}
            direction={
                sortBy === sortByColumn ? sortDirection : initialSortDirection
            }
            onClick={() => onSortChange(sortByColumn, initialSortDirection)}
        >
            {column.label}
        </TableSortLabel>
    );
};

export default ColumnHeader;
