import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { UserRoleNames } from 'types/users';

import { useRewardsSummaryQuery } from 'api/queries/caregiverRewards';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';
import {
    DESKTOP_HEADER_HEIGHT,
    MENU_MAP,
    OPERATIONS_SNAPSHOTS_PATH,
} from 'constants/app';
import { ROLE_VALUES, USER_ROLES } from 'constants/users';
import { getPathTitle, isSubpage } from 'lib/routes';

import DatePickerContainer from './DatePickerContainer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    boxContainer: {
        borderRadius: pxToRem(8),
        borderWidth: pxToRem(1),
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
    },
    topIcon: {
        color: '#006B75',
        fontSize: pxToRem(32),
    },
}));

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(32),
        py: pxToRem(24),
        display: { xs: 'none', lg: 'flex' },
        alignItems: 'center',
        height: DESKTOP_HEADER_HEIGHT,
        width: '100%',
    })
);

const BackIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
    })
);

const Title = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexGrow: 1,
    })
);

const HeaderRewardsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        borderColor: '#006B75',
        borderStyle: 'solid',
        borderWidth: pxToRem(1),
        borderRadius: pxToRem(100),
        paddingTop: pxToRem(2),
        paddingBottom: pxToRem(2),
        paddingLeft: pxToRem(8),
        paddingRight: pxToRem(8),
    })
);

const RewardsAmountStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: pxToRem(22),
        marginLeft: pxToRem(2),
        color: '#006B75',
    })
) as typeof Typography;

type Props = {
    dictionary: LanguageStructure;
    isSignedIn: boolean;
    priorParentPage: string;
    userRole: UserRoleNames;
    userId: number;
};

const Header = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation() || {};
    const { pathname } = location;
    const classes = useStyles();
    const { dictionary, isSignedIn, priorParentPage, userRole, userId } = props;
    const pageTitle = getPathTitle(dictionary, pathname);
    const pagesWithDateRangeSelector = [OPERATIONS_SNAPSHOTS_PATH];
    const shouldShowDateRange = !!pagesWithDateRangeSelector.find(
        (page) =>
            pathname.includes(page) && !pathname.includes('resident-details')
    );

    const handleGoBackClick = () => {
        navigate(priorParentPage);
    };

    const handlePointsClick = () => {
        navigate(MENU_MAP.myRewards);
    };

    const { data: rewardsSummaryData } = useRewardsSummaryQuery(userId);

    if (!isSignedIn || pathname.includes('/privacy-policy')) {
        return null;
    }

    return (
        <RootStyle>
            {isSubpage(pathname) && (
                <BackIconContainer>
                    <IconButton
                        size="large"
                        aria-haspopup="false"
                        onClick={handleGoBackClick}
                        color="inherit"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </BackIconContainer>
            )}
            <Title variant="h3">{pageTitle}</Title>
            {shouldShowDateRange && <DatePickerContainer showFullDateRange />}
            {userRole === USER_ROLES.CAREGIVER && (
                <HeaderRewardsContainer onClick={handlePointsClick}>
                    <GoldCoin
                        className={classes.topIcon}
                        size={20}
                        viewBox="0 0 20 20"
                    />
                    <RewardsAmountStyle variant="caption" component="p">
                        {!rewardsSummaryData
                            ? ''
                            : rewardsSummaryData.totalPoints}
                    </RewardsAmountStyle>
                </HeaderRewardsContainer>
            )}
            {/* <NotificationsContainer className={classes.boxContainer}>
        <NotificationsIcon />
      </NotificationsContainer> */}
        </RootStyle>
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const {
        isSignedIn,
        priorParentPage,
        sessionData: { roleLevelId, userId },
    } = session;

    return {
        dictionary,
        isSignedIn,
        priorParentPage,
        userRole: ROLE_VALUES[roleLevelId],
        userId,
    };
};

export default connect(mapStateToProps)(Header);
