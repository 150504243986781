import {
    Avatar,
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { camelCase } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { GoSignOut } from 'react-icons/go';
import { connect } from 'react-redux';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { LanguageStructure, Languages } from 'types/language';
import { LocationState, ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';

import LogoImage from 'assets/logo_full.png';
import Scrollbar from 'components/Navigation/Scrollbar';
import { pxToRem } from 'components/theme/typography';
import {
    DESKTOP_MENU_OPTIONS,
    MENU_MAP,
    SIDE_MENU_WIDTH,
    SUB_PAGES_MENU_MAP,
} from 'constants/app';
import { NAVIGATION, PROFILE } from 'constants/localStorage';
import { getInitials } from 'lib/common';
import { getParentPage, isSubpage } from 'lib/routes';
import { updateLocation } from 'redux/actions/location';
import { signOut, updatePriorParentPage } from 'redux/actions/session';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    icon: {
        marginRight: pxToRem(4),
        display: 'inline-flex',
        fontSize: pxToRem(20),
    },
    topIcon: {
        color: theme.palette.orange.main,
        fontSize: pxToRem(16),
    },
    privacyText: {
        fontSize: `${pxToRem(14)} !important`,
        textDecoration: 'underline',
        paddingLeft: pxToRem(4),
    },
    supportText: {
        fontSize: `${pxToRem(14)} !important`,
    },
}));

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: { xs: 'none', lg: 'flex' },
        paddingTop: 'env(safe-area-inset-top)',
        flexShrink: 0,
        width: pxToRem(SIDE_MENU_WIDTH),
        zIndex: 1,
    })
);

const ScrollbarStyle = styled(Scrollbar)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        '& .simplebar-content': {
            display: 'table',
            height: '100%',
            width: '100%',
        },
    })
);

const LogoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(24),
        pt: pxToRem(24),
        pb: pxToRem(8),
    })
);

const Logo = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(160),
    })
) as typeof Box;

const ListItemIconStyle = styled(ListItemIcon)(({ theme }) =>
    theme.unstable_sx({
        color: 'inherit',
        minWidth: pxToRem(32),
    })
);

export const ListItemSupportStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        fontSize: pxToRem(14),
        px: pxToRem(16),
        pb: pxToRem(10),
        color: theme.palette.grey[500],
    })
);

export const PrivacyStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        textDecoration: 'underline',
        fontSize: pxToRem(12),
        '&:hover': {
            textDecoration: 'underline',
            fontSize: pxToRem(12),
        },
        '&:active': {
            textDecoration: 'underline',
            fontSize: pxToRem(12),
            fontWeight: 'normal',
        },
    })
) as typeof Typography;

const MenuOptionsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    })
);

const GreetingContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(16),
        py: pxToRem(8),
        color: theme.palette.common.black,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

const AvatarStyle = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        color: theme.palette.common.black,
        backgroundColor: theme.palette.app.grey.main,
        width: pxToRem(40),
        height: pxToRem(40),
    })
);

const GreetingBoxStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    })
);

type Props = {
    dictionary: LanguageStructure;
    isSignedIn: boolean;
    languageId: Languages;
    priorParentPage: string;
    timezone: string;
    totalLanguages: number;
    userFullName: string;
    dispatchSignOut: () => void;
    dispatchUpdateLocation: (location: LocationState) => void;
    dispatchUpdatePriorParentPage: (parentPage: string) => void;
};

const DesktopSideMenu = (props: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation() || {};
    const { pathname } = location;
    const {
        dictionary: { login, navigator },
        isSignedIn,
        languageId,
        priorParentPage,
        timezone,
        totalLanguages,
        userFullName,
        dispatchSignOut,
        dispatchUpdateLocation,
        dispatchUpdatePriorParentPage,
    } = props;

    const { hardShutdown } = useIntercom();

    const [selectedOption, setSelectedOption] = useState<string>();
    const [greetingLabel, setGreetingLabel] = useState<string>();
    const [greetingName, setGreetingName] = useState<string>('');
    const drawerPaperProps = {
        sx: {
            width: SIDE_MENU_WIDTH,
            bgcolor: 'background.default',
            position: 'relative',
        },
    };

    // Get the user name from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const {
        firstName,
        lastName,
        picture: userPicture,
        roleLevelId = 0,
    } = storedSession?.sessionData || {};
    const userInitials = getInitials(firstName, lastName);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleMenuChange = (newOptionSelected: string) => (event) => {
        const fullMenu = {
            ...MENU_MAP,
            ...SUB_PAGES_MENU_MAP,
        };

        // Store the selected menu into the State.
        setSelectedOption(newOptionSelected);

        // Move to the selected page.
        navigate(fullMenu[newOptionSelected]);
    };

    const handleSignOut = () => {
        // reset Intercom settings on web since it is shared across users
        hardShutdown();
        dispatchSignOut();
    };

    useEffect(() => {
        moment.locale(languageId);
        const currentDateTime = moment.tz(new Date(), timezone);
        const currentHour = Number(currentDateTime.format('HH'));
        let newGreeting = navigator.goodAfternoon;

        if (currentHour < 12) {
            newGreeting = navigator.goodMorning;
        } else if (currentHour >= 19) {
            newGreeting = navigator.goodEvening;
        }

        setGreetingLabel(newGreeting);
    }, []);

    useEffect(() => {
        const userName = firstName ? `${firstName} ${lastName}` : userFullName;
        setGreetingName(userName);
    }, [userFullName]);

    useEffect(() => {
        if (isSubpage(pathname) && !priorParentPage) {
            const parentPage = getParentPage(pathname);
            const storedNavigation = JSON.parse(
                localStorage.getItem(NAVIGATION) as string
            );
            const newPriorParentPage =
                storedNavigation?.parentPage || parentPage || MENU_MAP.home;
            dispatchUpdatePriorParentPage(newPriorParentPage);
        } else if (!isSubpage(pathname)) {
            dispatchUpdatePriorParentPage('');
        }

        let newSelectedOption = '';

        if (!isSubpage(pathname)) {
            const pathParts = pathname.split('/');

            newSelectedOption = camelCase(pathParts[pathParts.length - 1]);
        }

        setSelectedOption(newSelectedOption);

        dispatchUpdateLocation(location);
    }, [pathname, priorParentPage]);

    if (!isSignedIn || pathname.includes('/privacy-policy')) {
        return null;
    }

    return (
        <RootStyle>
            <Drawer open variant="persistent" PaperProps={drawerPaperProps}>
                <ScrollbarStyle>
                    <LogoContainer>
                        <Logo component="img" src={LogoImage} />
                    </LogoContainer>
                    <GreetingContainer>
                        <AvatarStyle
                            alt={`${firstName} ${lastName}`}
                            src={userPicture}
                        >
                            {userInitials}
                        </AvatarStyle>
                        <GreetingBoxStyle>
                            <Typography variant="subtitle1">
                                {greetingLabel}
                            </Typography>
                            <Typography variant="body2">
                                {greetingName}
                            </Typography>
                        </GreetingBoxStyle>
                    </GreetingContainer>
                    <MenuOptionsContainer>
                        {DESKTOP_MENU_OPTIONS.map((option) => {
                            const { id, isSeparator, visibleFor } = option;
                            const shouldBeRendered =
                                (id !== 'language' &&
                                    (!visibleFor ||
                                        visibleFor?.includes(roleLevelId))) ||
                                totalLanguages > 1;

                            if (isSeparator) {
                                return <Divider key={id} />;
                            }

                            if (shouldBeRendered) {
                                const isSelected =
                                    selectedOption === id ||
                                    camelCase(priorParentPage) === id;
                                const IconComponent = option.icon
                                    ? option.icon
                                    : option.customIcon;

                                return (
                                    <ListItem
                                        key={id}
                                        selected={isSelected}
                                        disablePadding
                                        onClick={handleMenuChange(id)}
                                    >
                                        <ListItemButton>
                                            <ListItemIconStyle>
                                                {IconComponent && (
                                                    <IconComponent
                                                        className={classes.icon}
                                                    />
                                                )}
                                            </ListItemIconStyle>
                                            <ListItemText
                                                primary={
                                                    id === 'residents' &&
                                                    roleLevelId !== 5
                                                        ? 'Resident Deep Dive'
                                                        : navigator[id]
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            }
                            return null;
                        })}
                    </MenuOptionsContainer>
                    <List>
                        <ListItem disablePadding>
                            <ListItemSupportStyle>
                                {navigator.supportEmail}
                                <PrivacyStyle
                                    className={classes.privacyText}
                                    component="a"
                                    href="/privacy-policy"
                                    target="_blank"
                                >
                                    {navigator.privacy}
                                </PrivacyStyle>
                            </ListItemSupportStyle>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleSignOut}>
                                <ListItemIconStyle>
                                    <GoSignOut className={classes.icon} />
                                </ListItemIconStyle>
                                <ListItemText primary={login.signOut} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </ScrollbarStyle>
            </Drawer>
        </RootStyle>
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { id: languageId, dictionary, totalLanguages } = language;
    const {
        isSignedIn,
        priorParentPage,
        sessionData: { firstName, lastName },
        timezone,
    } = session;

    return {
        dictionary,
        isSignedIn,
        languageId,
        priorParentPage,
        timezone,
        totalLanguages,
        userFullName: `${firstName} ${lastName}`,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSignOut: () => dispatch(signOut()),
    dispatchUpdateLocation: (location: LocationState) =>
        dispatch(updateLocation(location)),
    dispatchUpdatePriorParentPage: (parentPage: string) =>
        dispatch(updatePriorParentPage(parentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopSideMenu);
