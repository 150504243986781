import { Box, Button, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { InvitationResponse } from 'types/signupInvitations';

import { CustomDialog } from 'components/Custom';
import { TrashIcon } from 'components/Svg';
import { pxToRem } from 'components/theme/typography';
import { NOTIFICATION_TYPE } from 'constants/shared';
import { InvitationStatus } from 'constants/users';
import { updateInvitationStatus } from 'redux/actions/signupInvitations';

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    })
);

const IconNotification = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(36),
        mb: pxToRem(24),
        p: pxToRem(40),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        width: pxToRem(120),
        height: pxToRem(120),
        color: theme.palette.app.orange.main,
        backgroundColor: alpha(theme.palette.app.orange.main, 0.1),
    })
);

const Message = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(20) },
    })
);

const BoldText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        display: 'flex',
        fontSize: { lg: pxToRem(20) },
    })
);

const FooterWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    })
);

const MainButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        '& >:not(:first-of-type)': {
            ml: pxToRem(8),
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    isOpen: boolean;
    invitation?: InvitationResponse;
    dispatchUpdateInvitationStatus: (
        invitationId: number,
        statusId: number
    ) => void;
    onClose: () => void;
    onConfirmCancelation: (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => void;
};

const InvitationCancelDialog = (props: Props) => {
    const {
        dictionary: {
            shared,
            userManagementInvitationsList,
            userManagementUsersList,
        },
        isOpen,
        invitation,
        dispatchUpdateInvitationStatus,
        onClose,
        onConfirmCancelation,
    } = props;

    if (!isOpen || !invitation) {
        return null;
    }

    const { invitationId, firstName, lastName, email } = invitation;
    const invitedToFullName = `${firstName} ${lastName}`;
    const message = userManagementInvitationsList.deleteConfirmation
        .replace('{userFullName}', invitedToFullName)
        .replace('{email}', email);

    const handleCancelInvitation = () => {
        dispatchUpdateInvitationStatus(invitationId, InvitationStatus.canceled);
        onConfirmCancelation(
            userManagementUsersList.invitationSuccess,
            userManagementInvitationsList.deleteConfirmationSuccessMessage,
            NOTIFICATION_TYPE.success
        );
        onClose();
    };

    return (
        <CustomDialog
            id="cancel_invitation"
            open={isOpen}
            width={pxToRem(604)}
            showFooterDivider
            content={
                <>
                    <Row>
                        <IconNotification>
                            <TrashIcon size={36} viewBox="0 0 36 36" />
                        </IconNotification>
                    </Row>
                    <Row>
                        <Message variant="subtitle1">{message}</Message>
                    </Row>
                </>
            }
            actions={
                <FooterWrapper>
                    <MainButtonsWrapper>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            {shared.close}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleCancelInvitation}
                        >
                            {shared.confirm}
                        </Button>
                    </MainButtonsWrapper>
                </FooterWrapper>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateInvitationStatus: (invitationId: number, statusId: number) =>
        dispatch(updateInvitationStatus(invitationId, statusId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvitationCancelDialog);
