import { CustomShape } from 'types/theme';

const shape: CustomShape = {
    borderRadius: 8,
    borderRadiusSm: 1,
    borderRadiusMd: 1.5,
    borderRadiusLg: 2,
};

export default shape;
