import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isNull } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { CustomDate } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomDatePicker } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

const DateRangePickerContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'baseline',
        width: '100%',
    })
);

const Separator = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mx: pxToRem(8),
    })
);

type Props = {
    dictionary: LanguageStructure;
    disableFuture?: boolean;
    disableMaskedInput?: boolean;
    disablePast?: boolean;
    endDate: CustomDate;
    endDateLabel?: string;
    hideHelper?: boolean;
    hideSeparator?: boolean;
    inputFormat?: string;
    inputMask?: string;
    maxDate?: CustomDate;
    minDate?: CustomDate;
    separatorLabel?: string;
    startDate: CustomDate;
    startDateLabel?: string;
    onChange: (
        newDateRange: [CustomDate, CustomDate],
        rangeHasError: boolean
    ) => void;
};

const UnconnectedCustomDateRangePicker = (props: Props) => {
    const {
        dictionary: { shared },
        disableFuture,
        disableMaskedInput,
        disablePast,
        endDate,
        endDateLabel,
        hideHelper,
        hideSeparator,
        inputFormat = 'MM/dd/yyyy',
        inputMask = '__/__/____',
        maxDate,
        minDate,
        separatorLabel,
        startDate,
        startDateLabel,
        onChange,
    } = props;
    const [dateRange, setDateRange] = useState<[CustomDate, CustomDate]>([
        startDate,
        endDate,
    ]);

    const newEndDateLabel = endDateLabel || shared.endDate;
    const newSeparatorLabel = separatorLabel || shared.dateRangeSeparatorLabel;
    const newStartDateLabel = startDateLabel || shared.startDate;

    const handleChange =
        (dateType: 'start' | 'end') =>
        (newDate: CustomDate, newDateHasError: boolean) => {
            const rangeStart = dateType === 'start' ? newDate : dateRange[0];
            const rangeEnd = dateType === 'end' ? newDate : dateRange[1];
            const newDateRange: [CustomDate, CustomDate] = [
                rangeStart,
                rangeEnd,
            ];

            if (isNull(rangeStart) && !isNull(rangeEnd)) {
                newDateRange[0] = rangeEnd;
            } else if (!isNull(rangeStart) && isNull(rangeEnd)) {
                newDateRange[1] = rangeStart;
            }

            setDateRange(newDateRange);
            onChange(newDateRange, !!newDateHasError);
        };

    return (
        <DateRangePickerContainer>
            <CustomDatePicker
                date={dateRange[0]}
                dateLabel={newStartDateLabel}
                disableFuture={disableFuture}
                disableMaskedInput={disableMaskedInput}
                disablePast={disablePast}
                hideHelper={hideHelper}
                inputFormat={inputFormat}
                inputMask={inputMask}
                maxDate={dateRange[1] || maxDate}
                minDate={minDate}
                marginLeft={0}
                marginBottom={0}
                onChange={handleChange('start')}
            />
            {!hideSeparator && (
                <Separator>
                    <Typography variant="body2">{newSeparatorLabel}</Typography>
                </Separator>
            )}
            <CustomDatePicker
                date={dateRange[1]}
                dateLabel={newEndDateLabel}
                disableFuture={disableFuture}
                disableMaskedInput={disableMaskedInput}
                disablePast={disablePast}
                hideHelper={hideHelper}
                inputFormat={inputFormat}
                inputMask={inputMask}
                maxDate={maxDate}
                minDate={dateRange[0] || minDate}
                marginRight={0}
                marginBottom={0}
                onChange={handleChange('end')}
            />
        </DateRangePickerContainer>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

export const CustomDateRangePicker = connect(mapStateToProps)(
    UnconnectedCustomDateRangePicker
);
