import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { LanguageStructure, Languages } from 'types/language';
import { Route } from 'types/routes';

import { APP_NAME } from 'constants/app';
import { routesList } from 'lib/routes';

type Props = {
    children: ReactNode;
    dictionary: LanguageStructure;
    languageId: Languages;
};

const PageStructure = (props: Props) => {
    const { pathname } = useLocation() || {};
    const { children, dictionary, languageId } = props;
    const page: Route | undefined = routesList().find((route) => {
        const routePath = route.path.replace('{languageId}', languageId);
        return matchPath(routePath, pathname);
    });
    const { titleId } = page || {};
    const pageTitle = titleId
        ? dictionary[titleId]?.pageTitle
        : dictionary.pageNotFound.pageTitle;

    return (
        <>
            <Helmet>
                <title>{`${pageTitle} | ${APP_NAME}`}</title>
            </Helmet>
            {children}
        </>
    );
};

const mapStateToProps = ({ language }) => {
    const { id: languageId, dictionary } = language;

    return {
        dictionary,
        languageId,
    };
};

export default connect(mapStateToProps)(PageStructure);
