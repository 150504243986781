import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';

import TabPanel from 'components/Shared/TabPanel';
import { pxToRem } from 'components/theme/typography';
import {
    MENU_MAP,
    USERS_MANAGEMENT_PATH,
    USERS_MANAGEMENT_USERS_PATH,
} from 'constants/app';
import { PROFILE } from 'constants/localStorage';
import InvitationsList from 'pages/Users/Management/Invitations/InvitationsList';
import UsersList from 'pages/Users/Management/Users/UsersList';

const TabContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
    })
);

type Props = {
    dictionary: LanguageStructure;
};

const ManagementTabs = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation() || {};
    const { pathname } = location;
    const {
        dictionary: { userManagementUsersList },
    } = props;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const isUsersList =
        pathname === `${USERS_MANAGEMENT_PATH}${USERS_MANAGEMENT_USERS_PATH}`;

    // Get the role id from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { roleId } = storedSession?.sessionData || {};

    // Define the tabs to display.
    const showManagersTab = roleId <= 3;
    const showAdminsTab = roleId <= 2;

    const getTabUrl = (newTabIndex: number) => {
        switch (newTabIndex) {
            case 1:
                return MENU_MAP.userManagementInvitations;
            default:
                return MENU_MAP.userManagementUsersList;
        }
    };

    const handleTabChange = (event: SyntheticEvent, newTabIndex: number) => {
        const url = getTabUrl(newTabIndex);
        setTabIndex(newTabIndex);
        navigate(url);
    };

    useEffect(() => {
        switch (pathname) {
            case MENU_MAP.userManagementInvitations:
                setTabIndex(1);
                break;
            default:
                setTabIndex(0);
        }
    }, [pathname]);

    return (
        <>
            <TabContainer>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab
                        id="allUsers"
                        label={userManagementUsersList.allUsers}
                    />
                    <Tab
                        id="invitations"
                        label={userManagementUsersList.allInvitations}
                    />
                </Tabs>
            </TabContainer>
            <TabPanel value={0} selectedTab={tabIndex}>
                <UsersList />
            </TabPanel>
            <TabPanel value={1} selectedTab={tabIndex}>
                <InvitationsList />
            </TabPanel>
        </>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

export default connect(mapStateToProps)(ManagementTabs);
