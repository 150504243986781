import {
    Box,
    CircularProgress,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { pxToRem } from 'components/theme/typography';
import { SIDE_MENU_WIDTH } from 'constants/app';

type Props = {
    label?: string;
};

const Loading = (props: Props) => {
    const { label } = props;

    const isMobile = useMediaQuery('(max-width:1024px)');

    const SpinnerContainer = styled(Box)(({ theme }) =>
        theme.unstable_sx({
            padding: pxToRem(16),
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            alignItems: 'center',
            ...(isMobile
                ? { left: '50%' }
                : { left: `calc(50% + ${SIDE_MENU_WIDTH / 2}px)` }),
        })
    );

    const Subtitle = styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: '#6F6F79',
            fontSize: {
                xs: pxToRem(14),
                lg: pxToRem(16),
            },
            lineHeight: 1.5,
            fontWeight: 400,
            marginBottom: pxToRem(16),
            textAlign: 'center',
            marginTop: pxToRem(24),
            ...(isMobile ? {} : {}),
        })
    );

    return (
        <SpinnerContainer>
            <CircularProgress />
            {!!label && <Subtitle>{label}</Subtitle>}
        </SpinnerContainer>
    );
};

export default Loading;
