export const NAVIGATION = 'navigation';
export const PROFILE = 'profile';
export const SIGNUP = 'signup';

export const DASHBOARD_FILTERS = 'dashboard-filters';
export const HOME_FILTERS = 'home-filters';
export const RESIDENTS_FILTERS = 'residents-filters';
export const TEMP_USER_DATA = 'temp-user';

export const CAREGIVER_FILTERS = 'caregiver-filters-v1';

export const NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL =
    'no-early-in-shift-confirmation-until';
