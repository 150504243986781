import { api } from 'api';

import { BranchResponse } from 'types/branches';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { getToken } from 'lib/common';

export const useBranchQuery = (branchId) =>
    useErrorHandledQuery(
        ['branch', branchId],
        async (): Promise<BranchResponse> =>
            (
                await api.get(`/branches/${branchId}`, {
                    headers: {
                        authorization: getToken(),
                    },
                })
            ).data.response,
        { enabled: !!branchId, staleTime: 300000 }
    );
