import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { DASHBOARD_TASKS_CARD_HEIGHT } from 'constants/dashboards';
import { AVATAR_TYPES } from 'constants/shared';
import CardBaseStructure from 'pages/Dashboards/components/CardBaseStructure';
import {
    ResidentNameLabel,
    RoomLabel,
} from 'pages/Dashboards/components/SharedComponents';

const DataRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '$:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        width: pxToRem(32),
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

export const RefusedTasksCard = (props) => {
    const { data, onShowAllClick } = props;

    data.sort((a, b) => {
        if (a.tasks.length < b.tasks.length) {
            return 1;
        }

        if (a.tasks.length > b.tasks.length) {
            return -1;
        }

        return 0;
    });

    return (
        <CardBaseStructure
            cardType="keyServices"
            height={pxToRem(350)}
            title="Refused Key Tasks"
            subtitle="Residents with the most refused tasks in key services"
            showAllModalTitle="Resident Refused Tasks"
            showAllTotalRecords={data?.length || 0}
            onShowAllClick={onShowAllClick}
        >
            {data &&
                data.map(
                    (
                        {
                            residentFirstName,
                            residentLastName,
                            residentName,
                            residentPhoto,
                            roomNumber,
                            residentId,
                            tasks,
                        }
                    ) => (
                        <Link to={`resident-details/${residentId}`}>
                            <DataRow key={residentId}>
                                <AvatarContainer>
                                    <CustomAvatar
                                        avatarType={AVATAR_TYPES.resident}
                                        firstName={residentFirstName}
                                        lastName={residentLastName}
                                        photo={residentPhoto}
                                        size={32}
                                    />
                                </AvatarContainer>
                                <DataContainer>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <ResidentNameLabel>
                                                {residentName}
                                            </ResidentNameLabel>
                                            <RoomLabel>
                                                Room {roomNumber}
                                            </RoomLabel>
                                        </Box>
                                        <Box
                                            sx={{
                                                flexGrow: 0,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color:
                                                    tasks.length >= 3
                                                        ? 'error.main'
                                                        : 'app.orange.main',
                                            }}
                                        >
                                            {`${tasks.length} task${
                                                tasks.length > 1 ? 's' : ''
                                            }`}
                                        </Box>
                                    </Box>
                                </DataContainer>
                            </DataRow>
                        </Link>
                    )
                )}
        </CardBaseStructure>
    );
};
