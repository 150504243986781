import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import { pxToRem } from 'components/theme/typography';

const TabPanelContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
    })
);

const TabPanelContent = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pr: pxToRem(10),
        pl: pxToRem(10),
        pt: pxToRem(24),
        pb: pxToRem(24),
    })
);

type Props = {
    index: number;
    value: number;
    children: ReactNode;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomTabPanel = (props: Props) => {
    const { children, value, index, ...other } = props;

    return (
        <TabPanelContainer
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <TabPanelContent>
                    <Typography>{children}</Typography>
                </TabPanelContent>
            )}
        </TabPanelContainer>
    );
};
