import { Action, TaskStatusesState } from 'types/redux';

import { TASK_STATUSES_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const taskStatuses = (
    state: TaskStatusesState = TASK_STATUSES_DEFAULT_STATE,
    action: Action
) => {
    switch (action.type) {
        case actionTypes.TASK_STATUS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case actionTypes.TASK_STATUS_READ:
            return {
                ...state,
                taskStatus: action.payload,
            };
        case actionTypes.TASK_STATUS_READ_LIST:
            return {
                ...state,
                taskStatusesList: action.payload,
            };
        default:
            return state;
    }
};

export default taskStatuses;
