// eslint-disable-next-line import/prefer-default-export
export const downloadCsv = (csvContent: string, filename: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:text/csv; charset=utf-8, ${encodeURI(
        csvContent
    )}`;
    downloadLink.target = '_blank';
    downloadLink.download = filename;
    downloadLink.click();
};
