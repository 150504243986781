export default function Button(theme) {
    const { palette } = theme;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-textPrimary': {
                        color: palette.common.white,
                        backgroundColor: palette.primary.main,
                    },
                    '&.MuiButton-outlinedSecondary': {
                        color: palette.secondary.dark,
                        backgroundColor: 'transparent',
                        borderColor: palette.secondary.dark,
                    },
                },
            },
        },
    };
}
