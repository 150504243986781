import { SignupInvitationsState } from 'types/redux';

import { USERS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const signupInvitations = (
    state: SignupInvitationsState = USERS_DEFAULT_STATE,
    action
) => {
    switch (action.type) {
        case actionTypes.SIGNUP_INVITATION_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.SIGNUP_INVITATION_READ_LIST: {
            return {
                ...state,
                invitationsList: action.payload,
            };
        }
        case actionTypes.SIGNUP_INVITATION_READ: {
            return {
                ...state,
                invitation: action.payload,
            };
        }
        case actionTypes.SIGNUP_INVITATION_READ_FAIL: {
            return {
                ...state,
                invitationErrorMessage: action.payload,
            };
        }
        case actionTypes.SIGNUP_INVITATION_EMAIL_CHECK: {
            return {
                ...state,
                invitationExists: action.payload,
            };
        }
        default:
            return state;
    }
};

export default signupInvitations;
