import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CompanyCarePlanResponse } from 'types/companyCarePlans';
import {
    CheckTaskMode,
    DailyTasksShiftDetail,
    TaskToUpdate,
} from 'types/dailyTasks';
import { SelectStructure, SelectValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { ResidentDailyTasksResponse } from 'types/residents';
import { StoredSession } from 'types/session';
import {
    TaskStatusesReadParams,
    TaskStatusesResponse,
} from 'types/taskStatuses';
import { UserResponse, UsersReadParams } from 'types/users';

import {
    CustomAutocomplete,
    CustomDialog,
    CustomSelect,
    CustomTextField,
} from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import { readTaskStatuses } from 'redux/actions/tasksStatuses';
import { readUsers } from 'redux/actions/users';

const ViewConceptRow = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
    })
) as typeof Typography;

type Props = {
    companyCarePlansList: CompanyCarePlanResponse[];
    dailyTaskRecord: DailyTasksShiftDetail | ResidentDailyTasksResponse;
    dictionary: LanguageStructure;
    isOpen: boolean;
    dialogType: CheckTaskMode;
    taskStatusesList: TaskStatusesResponse[];
    usersList: UserResponse[];
    dispatchReadUsers: (params: UsersReadParams) => void;
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) => void;
    onSubmit: ({ task }: { task: TaskToUpdate }) => void;
    onClose: () => void;
    newCaregiverNotes?: string;
};

const CheckTaskDialog = (props: Props) => {
    const {
        companyCarePlansList,
        dailyTaskRecord = {} as DailyTasksShiftDetail,
        dictionary: { home, shared },
        isOpen,
        dialogType,
        taskStatusesList,
        usersList,
        dispatchReadUsers,
        dispatchReadTaskStatuses,
        onSubmit,
        onClose,
        newCaregiverNotes,
    } = props;
    const {
        dailyTaskRecordId,
        taskType,
        taskStatusId: storedTaskStatusId,
        caregiverId: storedCaregiverId,
        caregiverName: storedCaregiverName,
        caregiverNotes: storedCaregiverNotes,
        companyCarePlanId,
    } = dailyTaskRecord;
    const [caregiverId, setCaregiverId] = useState<SelectValue>();
    const [caregiverOptions, setCaregiverOptions] = useState<SelectStructure[]>(
        []
    );
    const [taskStatusOptions, setTaskStatusOptions] = useState<
        SelectStructure[]
    >([]);
    const [taskStatusId, setTaskStatusId] = useState<SelectValue>();
    const [selectedTaskStatus, setSelectedTaskStatus] = useState<string>();
    const [caregiverNotes, setCaregiverNotes] = useState<string>('');
    const [taskCompletionSelection, setTaskCompletionSelection] =
        useState<number>(-1);

    const companyCarePlan = companyCarePlansList.find(
        (plan) => plan.companyCarePlanId === companyCarePlanId
    );

    // Get the user's ID, Branch ID and Role Level ID.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const {
        userId = 0,
        branchId = 0,
        roleLevelId = 0,
    } = storedSession?.sessionData || {};
    const isDirector = roleLevelId === 4;
    const isCaregiver = roleLevelId === 5;
    const isAllowed = isDirector || isCaregiver;
    const shouldGetValues = dialogType === 'Edit' || dialogType === 'View';
    let dialogTitle = home.completeTaskDialogTitle;

    if (dialogType === 'Reject') {
        dialogTitle = home.rejectTaskDialogTitle;
    } else if (dialogType === 'Edit') {
        dialogTitle = home.editTaskDialogTitle;
    } else if (dialogType === 'View') {
        dialogTitle = home.viewTaskDialogTitle;
    }

    const handleTaskCompletionSelectionChange = (
        newTaskCompletionSelection: number
    ) => {
        setTaskCompletionSelection(newTaskCompletionSelection);
    };

    const handleTaskStatusChange = (newStatus: string) => {
        setTaskStatusId(newStatus);
    };

    const handleCaregiverNotesChange = (newNotes: string) => {
        setCaregiverNotes(newNotes);
    };

    const handleSubmitClick = () => {
        if (!isAllowed) {
            return;
        }

        const task: TaskToUpdate = {
            taskId: dailyTaskRecordId,
            taskStatusId: Number(taskStatusId),
            caregiverNotes,
        };

        onSubmit({ task });
        handleClose();
    };

    const handleClose = () => {
        resetValues();
        onClose();
    };

    const resetValues = () => {
        setCaregiverNotes('');

        if (caregiverOptions.length) {
            const newCaregiverId = caregiverOptions[0].value;
            setCaregiverId(newCaregiverId);
        }

        if (taskStatusOptions.length) {
            const newSelectedTaskStatus =
                dialogType === 'Complete' ? '2' : taskStatusOptions[0].value;
            setTaskStatusId(newSelectedTaskStatus);
        }
    };

    useEffect(() => {
        if (isCaregiver) {
            setCaregiverId(userId.toString());
        } else if (isDirector && !usersList?.length) {
            const params: UsersReadParams = {
                roleId: 5,
                branchId,
            };
            dispatchReadUsers(params);
        }
    }, []);

    useEffect(() => {
        if (isDirector && usersList?.length) {
            const newCaregiverOptions: SelectStructure[] = usersList.map(
                (user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.userId.toString(),
                })
            );
            setCaregiverOptions(newCaregiverOptions);

            const newCaregiverId = shouldGetValues
                ? newCaregiverOptions.find(
                      (caregiver) =>
                          caregiver.value === storedCaregiverId.toString()
                  )?.value
                : newCaregiverOptions[0].value;
            setCaregiverId(newCaregiverId);
        }
    }, [isDirector, JSON.stringify(usersList), storedCaregiverId]);

    useEffect(() => {
        if (!taskStatusesList.length) {
            const params: TaskStatusesReadParams = {
                startingFromId: 1,
            };

            dispatchReadTaskStatuses(params);
        } else {
            const exludedStatuses: number[] = [3];

            if (dialogType === 'Reject') {
                exludedStatuses.push(1);
                exludedStatuses.push(2);
            }

            const filteredStatuses: TaskStatusesResponse[] =
                taskStatusesList.filter(
                    (taskStatus) => !exludedStatuses.includes(taskStatus.id)
                );
            const newTaskStatusOptions: SelectStructure[] =
                filteredStatuses.map((taskStatus) => ({
                    label: taskStatus.name,
                    value: taskStatus.id.toString(),
                }));
            setTaskStatusOptions(newTaskStatusOptions);

            let newTaskStatusId = '2';

            if (dialogType === 'Reject') {
                // default to unknown status for now since people aren't selecting the dropdown
                newTaskStatusId = '8';
            } else if (shouldGetValues) {
                newTaskStatusId =
                    storedTaskStatusId === 3
                        ? '2'
                        : storedTaskStatusId.toString();
            }

            setTaskStatusId(newTaskStatusId);

            const newSelectedTaskStatus = newTaskStatusOptions.find(
                (taskStatusOption) => taskStatusOption.value === newTaskStatusId
            )?.label;
            setSelectedTaskStatus(newSelectedTaskStatus);

            const newTaskNotes = shouldGetValues ? storedCaregiverNotes : '';
            setCaregiverNotes(newTaskNotes);
        }
    }, [
        JSON.stringify(taskStatusesList),
        dialogType,
        storedTaskStatusId,
        storedCaregiverNotes,
    ]);

    if ((isDirector && !usersList) || isUndefined(caregiverId)) {
        return null;
    }

    return (
        <CustomDialog
            closeable
            open={isOpen}
            title={dialogTitle}
            width="100%"
            onClose={handleClose}
            content={
                <>
                    {isDirector && dialogType !== 'View' && (
                        <CustomSelect
                            id="caregiver"
                            label={home.caregiver}
                            value={caregiverId}
                            options={caregiverOptions}
                            disabled={dialogType === 'Edit'}
                            fullWidth
                            onChange={handleTaskStatusChange}
                        />
                    )}
                    {dialogType === 'Edit' && (
                        <CustomSelect
                            id="taskStatus"
                            label={home.taskStatus}
                            value={taskStatusId as string}
                            options={taskStatusOptions}
                            fullWidth
                            onChange={handleTaskStatusChange}
                        />
                    )}
                    {dialogType === 'Reject' && (
                        <CustomSelect
                            id="taskStatus"
                            label={home.taskStatus}
                            value={taskStatusId as string}
                            options={taskStatusOptions}
                            inputProps={{
                                'data-analytics-id':
                                    'resident-reject-task-status-click',
                            }}
                            fullWidth
                            onChange={handleTaskStatusChange}
                        />
                    )}
                    {dialogType !== 'View' && (
                        <>
                            {companyCarePlan?.completionOptions && (
                                <CustomAutocomplete
                                    id="taskCompletionSelection"
                                    label={home.outcome}
                                    value={
                                        taskCompletionSelection === -1
                                            ? null
                                            : taskCompletionSelection
                                    }
                                    required
                                    options={companyCarePlan.completionOptions.map(
                                        (completionOption) => ({
                                            value: parseInt(
                                                completionOption.name,
                                                10
                                            ),
                                            label: completionOption.value,
                                        })
                                    )}
                                    fullWidth
                                    onChange={
                                        handleTaskCompletionSelectionChange
                                    }
                                />
                            )}
                            <CustomTextField
                                label={home.notes}
                                value={newCaregiverNotes || caregiverNotes}
                                fullWidth
                                multiline
                                rows={5}
                                inputProps={{
                                    'data-analytics-id':
                                        'resident-task-reject-notes-change',
                                }}
                                onChange={handleCaregiverNotesChange}
                            />
                        </>
                    )}
                    {dialogType === 'View' && (
                        <>
                            <ViewConceptRow variant="subtitle2">
                                {home.caregiver}:{' '}
                                <Typography variant="body2" component="span">
                                    {storedCaregiverName}
                                </Typography>
                            </ViewConceptRow>
                            <ViewConceptRow variant="subtitle2">
                                {home.taskType}:{' '}
                                <Typography variant="body2" component="span">
                                    {home[taskType.toLowerCase()]}
                                </Typography>
                            </ViewConceptRow>
                            <ViewConceptRow variant="subtitle2">
                                {home.taskStatus}:{' '}
                                <Typography variant="body2" component="span">
                                    {selectedTaskStatus}
                                </Typography>
                            </ViewConceptRow>
                            <ViewConceptRow variant="subtitle2">
                                {home.notes}:
                            </ViewConceptRow>
                            <Typography variant="body2">
                                {newCaregiverNotes || caregiverNotes}
                            </Typography>
                        </>
                    )}
                </>
            }
            actions={
                <>
                    {dialogType === 'View' && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                        >
                            {shared.close}
                        </Button>
                    )}
                    {dialogType !== 'View' && (
                        <>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                            >
                                {shared.cancel}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!caregiverId || !taskStatusId}
                                onClick={handleSubmitClick}
                            >
                                {shared.submit}
                            </Button>
                        </>
                    )}
                </>
            }
        />
    );
};

const mapStateToProps = ({
    language,
    companyCarePlans,
    taskStatuses,
    users,
}: ReduxStore) => {
    const { dictionary } = language;
    const { taskStatusesList } = taskStatuses;
    const { usersList } = users;
    const { companyCarePlansList } = companyCarePlans;

    return {
        dictionary,
        taskStatusesList,
        usersList,
        companyCarePlansList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUsers: (params: UsersReadParams) => dispatch(readUsers(params)),
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) =>
        dispatch(readTaskStatuses(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedCheckTaskDialog: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckTaskDialog);

export default ConnectedCheckTaskDialog;
