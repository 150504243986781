import { Box } from '@mui/material';
import { format, subDays } from 'date-fns';
import React from 'react';
import {
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    XAxis,
    YAxis,
} from 'recharts';

import { pxToRem } from 'components/theme/typography';

type Props = {
    dates: Date[] | [];
    dateRange: number;
};

const TimelineChart = ({ dates, dateRange }: Props) => {
    if (!dates.length) {
        return null;
    }

    const yesterday = subDays(new Date(), 1);

    const endDate = yesterday.getTime();
    const startDate = subDays(endDate, dateRange).getTime();

    const tickInterval = Math.floor(dateRange / 10);

    const dateTickValues = Array.from(
        { length: Math.ceil(dateRange / tickInterval) + 1 },
        (_, index) => {
            const tickDate = new Date(
                startDate + index * tickInterval * (1000 * 60 * 60 * 24)
            );
            return tickDate.getTime();
        }
    );

    const data = dates
        .map((date) => ({
            date: date.getTime(),
            y: 0,
        }))
        .sort((a, b) => a.date - b.date);

    return (
        <Box
            position="relative"
            width={1}
            height={pxToRem(40)}
            mb={pxToRem(24)}
        >
            <Box
                mx={{ xs: pxToRem(24) }}
                height={1}
                position="absolute"
                sx={{
                    inset: 0,
                }}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart>
                        <XAxis
                            type="number"
                            dataKey="date"
                            fontSize={12}
                            tickMargin={11}
                            stroke="#ECECF3"
                            tick={{ fill: '#6F6F79' }}
                            domain={[startDate, endDate]}
                            ticks={dateTickValues}
                            tickFormatter={(value) => format(value, 'M/d')}
                            interval="preserveStartEnd"
                        />
                        <YAxis type="number" dataKey="y" hide />
                        <Scatter data={data} fill="#EC3232" />
                    </ScatterChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default TimelineChart;
