import { Search as SearchIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';

import { CustomTextField } from 'components/Custom';
import { ZoneSelector } from 'components/Filtering';
import SortButton from 'components/Shared/Sorting/SortButton';
import SortDialog from 'components/Shared/Sorting/SortDialog';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import AccessControl from 'helpers/AccessControl';
import { setSortBy, setSortOrder } from 'redux/actions/filters';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(-16),
        pt: pxToRem(8),
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        position: 'sticky',
        zIndex: 10,
        backgroundColor: theme.palette.common.white,
        top: pxToRem(-7),
    })
);

const SortAndZoneRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        columnGap: { xs: 1, md: 2 },
    })
);

const ZoneContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        flex: 1,
        ml: { md: 'auto' },
    })
);

type Props = {
    dictionary: LanguageStructure;
    sortBy: string;
    sortOrder: string;
    onSearch: (newSearchValue: string) => void;
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
};

const FilterContainer = (props: Props) => {
    const {
        dictionary: { residents: residentsDictionary },
        sortBy,
        sortOrder,
        onSearch,
        dispatchSetSortBy,
        dispatchSetSortOrder,
    } = props;
    const [searchValue, setSearchValue] = useState<string>();
    const [isSortDialogOpen, setIsSortDialogOpen] = useState<boolean>(false);

    // Get the user's Branch ID.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { branchId = 0 } = storedSession?.sessionData || {};

    const handleSearchChange = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
        onSearch(newSearchValue);
    };

    const handleSortButtonClick = () => {
        setIsSortDialogOpen(true);
    };

    const handleSortDialogClose = () => {
        setIsSortDialogOpen(false);
    };

    const handleSortOptions = (
        selectedSortBy: string,
        selectedSortOrder: string
    ) => {
        if (sortBy !== selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }
        if (sortOrder !== selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    return (
        <>
            <RootStyle>
                <CustomTextField
                    label={residentsDictionary.searchLabel}
                    placeholder={residentsDictionary.searchPlaceholder}
                    value={searchValue}
                    fullWidth
                    startAdornment={<SearchIcon />}
                    onChange={handleSearchChange}
                />
                <SortAndZoneRow>
                    <ZoneContainer>
                        <ZoneSelector
                            branchId={branchId}
                            width="100%"
                            fullWidth
                        />
                    </ZoneContainer>
                    <Box
                        sx={{
                            mt: pxToRem(8),
                            mb: pxToRem(16),
                        }}
                    >
                        <SortButton onClick={handleSortButtonClick} />
                    </Box>
                </SortAndZoneRow>
            </RootStyle>
            <SortDialog
                isOpen={isSortDialogOpen}
                onClose={handleSortDialogClose}
                onSort={handleSortOptions}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
        </>
    );
};

const mapStateToProps = ({ language, filters }: ReduxStore) => {
    const { dictionary } = language;
    const {
        caregiverApp: { sortBy, sortOrder },
    } = filters;

    return {
        dictionary,
        sortBy,
        sortOrder,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) =>
        dispatch(setSortOrder(sortOrder)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedFilterContainer: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(FilterContainer)
);

export default ConnectedFilterContainer;
