import { Avatar, Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { ResidentResponse } from 'types/residents';

import noProfilePhoto from 'assets/no_profile_photo.png';
import { pxToRem } from 'components/theme/typography';
import { RESIDENTS_PHOTO_PATH } from 'constants/app';

const ResidentContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { xs: pxToRem(16), md: pxToRem(18) },
    })
);

const ResidentRoom = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(24),
        color: theme.palette.app.orange.main,
        fontSize: { xs: pxToRem(14), md: pxToRem(16) },
        fontWeight: 400,
    })
);

const ResidentPhotoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        mx: pxToRem(8),
    })
);

const ResidentAvatar = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        backgroundColor: theme.palette.common.white,
        width: pxToRem(64),
        height: pxToRem(64),
    })
);

const ResidentStatus = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(4),
        display: 'flex',
        borderRadius: pxToRem(4),
        color: '#006B75',
        backgroundColor: '#006B7514',
    })
);

const DividerStyle = styled(Divider)(({ theme }) =>
    theme.unstable_sx({
        my: pxToRem(16),
    })
);

const ResidentDetailsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

const ResidentDetailsBlock = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    })
);

const DetailsConcept = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { xs: pxToRem(11), md: pxToRem(13) },
    })
);

const DetailsDescription = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.common.black,
        fontSize: { xs: pxToRem(12), md: pxToRem(14) },
        fontWeight: 'bold',
        height: pxToRem(15),
        minWidth: pxToRem(30),
    })
);

type Props = {
    dictionary: LanguageStructure;
    residentData: ResidentResponse;
};

const ResidentTopContainer = (props: Props) => {
    const {
        dictionary: { residents: residentsDictionary },
        residentData,
    } = props;
    const [residentPhoto, setResidentPhoto] = useState<string>(noProfilePhoto);

    useEffect(() => {
        if (residentData && residentData?.photo) {
            setResidentPhoto(`${RESIDENTS_PHOTO_PATH}/${photo}`);
        } else {
            setResidentPhoto(noProfilePhoto);
        }
    }, [residentData?.photo]);

    const {
        firstName = '',
        lastName = '',
        photo = null,
        buildingStatus = '',
        careTypeCode = '',
        transferPeople = '',
        zoneName = '',
        roomNumber = '',
        codeStatus = '',
    } = residentData;

    const fullName = `${firstName} ${lastName}`;

    return (
        <>
            <ResidentContainer>
                <ResidentName variant="h2">{fullName}</ResidentName>
                <ResidentRoom variant="h4">
                    {zoneName} / {roomNumber}
                </ResidentRoom>
                <ResidentPhotoContainer>
                    <ResidentAvatar alt={fullName} src={residentPhoto} />
                </ResidentPhotoContainer>
                <ResidentStatus variant="body2">{codeStatus}</ResidentStatus>
            </ResidentContainer>
            <DividerStyle />
            <ResidentDetailsContainer>
                <ResidentDetailsBlock>
                    <DetailsDescription>{buildingStatus}</DetailsDescription>
                    <DetailsConcept variant="caption">
                        {residentsDictionary.status}
                    </DetailsConcept>
                </ResidentDetailsBlock>
                <ResidentDetailsBlock>
                    <DetailsDescription>{careTypeCode}</DetailsDescription>
                    <DetailsConcept variant="caption">
                        {residentsDictionary.focus}
                    </DetailsConcept>
                </ResidentDetailsBlock>
                <ResidentDetailsBlock>
                    <DetailsDescription>
                        {residentsDictionary[transferPeople]}
                    </DetailsDescription>
                    <DetailsConcept variant="caption">
                        {residentsDictionary.transfer}
                    </DetailsConcept>
                </ResidentDetailsBlock>
            </ResidentDetailsContainer>
            <DividerStyle />
        </>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentTopContainer: any =
    connect(mapStateToProps)(ResidentTopContainer);

export default ConnectedResidentTopContainer;
