import { encode as base64Encode } from 'js-base64';
import { capitalize, padStart, snakeCase } from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';

import { DateComparision } from 'types/common';
import { StoredSession } from 'types/session';

import { PASSWORD_GENERATOR_VALID_CHARS } from 'constants/app';
import { SHIFTS_TIMES } from 'constants/home';
import { PROFILE } from 'constants/localStorage';
import {
    ONE_DAY,
    ONE_HOUR,
    ONE_MINUTE,
    ONE_SECOND,
    ONE_WEEK,
} from 'constants/shared';

export const SortArray = (a, b, property) => {
    if (a[property] < b[property]) {
        return -1;
    }

    if (a[property] > b[property]) {
        return 1;
    }

    return 0;
};

export const camelCaseToSnake = (key) => snakeCase(key);

export const formatApiParams = (obj) => {
    const result = {};

    Object.keys(obj).forEach((key) => {
        result[camelCaseToSnake(key)] = obj[key];
    });

    return {
        params: base64Encode(JSON.stringify(result)),
    };
};

export const getToken = () => {
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { token = '' } = storedSession?.sessionData || {};
    return token;
};

export const getInitials = (firstName = ' ', lastName = ' ') =>
    `${capitalize(firstName.charAt(0))}${capitalize(lastName.charAt(0))}`;

export const isTaskOverdue = (curentTime: string, dueTime: string) => {
    // Get the Hour and Minutes from both times.
    const [currentHours, currentMinutes] = curentTime.split(':');
    const [dueHours, dueMinutes] = dueTime.split(':');

    if (Number(currentHours) > Number(dueHours)) {
        return true;
    }

    if (
        Number(currentHours) === Number(dueHours) &&
        Number(currentMinutes) > Number(dueMinutes)
    ) {
        return true;
    }

    return false;
};

export const generatePassword = (maxLength = 8) => {
    let password = '';

    for (let index = 0; index <= maxLength; index++) {
        const randomIndex = Math.floor(
            Math.random() * PASSWORD_GENERATOR_VALID_CHARS.length
        );
        password += PASSWORD_GENERATOR_VALID_CHARS.substring(
            randomIndex,
            randomIndex + 1
        );
    }

    return password;
};

export const getDateTimeTz24 = (timezone: string) =>
    momentTz.tz(new Date(), timezone).format('YYYY-MM-DD HH:mm');

export const getDateTimeTz12 = (timezone: string) =>
    momentTz.tz(new Date(), timezone).format('YYYY-MM-DD hh:mm a');

export const getDateTz = (timezone: string) =>
    momentTz.tz(new Date(), timezone).format('YYYY-MM-DD');

export const getCurrentShiftDate = (timezone: string) => {
    // ASCENT HACK: hardcoding shifts
    const currentDateTime = momentTz.tz(new Date(), timezone);
    const prevDayDateTime = currentDateTime.clone().subtract(1, 'day');
    const currentDayDateStr = currentDateTime.format('YYYY-MM-DD');

    const startDateTimeStr = `${currentDayDateStr}T${SHIFTS_TIMES.AM.start}:00`; // inclusive
    const dateFormatStr = 'YYYY-MM-DDTHH:mm:ss';
    const dayStartDateTime = moment(startDateTimeStr, dateFormatStr);

    if (currentDateTime.isBefore(dayStartDateTime)) {
        return prevDayDateTime;
    }

    return currentDateTime;
};

export const getTimeTz24 = (timezone: string) =>
    momentTz.tz(new Date(), timezone).format('HH:mm');

export const getTimeTz12 = (timezone: string) =>
    momentTz.tz(new Date(), timezone).format('hh:mm a');

export const formatDateTime24hrs = (
    date: string,
    time: string,
    timezone: string
) => momentTz.tz(`${date} ${time}`, timezone).format('HH:mm');

export const format24hrsToAmPm = (
    date: string,
    time: string,
    timezone: string
) => momentTz.tz(`${date} ${time}`, timezone).format('hh:mm a');

export const format24hrsToAmPmWithoutIndicator = (
    date: string,
    time: string,
    timezone: string
) => momentTz.tz(`${date} ${time}`, timezone).format('hh:mm');

export const formatDateTimeTo24hrs = (dateTime: string, timezone: string) =>
    momentTz.tz(`${dateTime}`, timezone).format('HH:mm');

export const formatDateTimeTo12hrs = (dateTime: string, timezone: string) =>
    momentTz.tz(`${dateTime}`, timezone).format('hh:mm a');

export const formatDateTimeTo12hrsWithoutIndicator = (
    dateTime: string,
    timezone: string
) => momentTz.tz(`${dateTime}`, timezone).format('hh:mm');

export const formatDate = (date: Date) => {
    const month = padStart((date.getMonth() + 1).toString(), 2, '0');
    const day = padStart(date.getDate().toString(), 2, '0');
    const year = date.getFullYear().toString();

    return [year, month, day].join('-');
};

export const formatDateTimeTz = (
    date: string,
    time: string,
    timezone: string
) => momentTz.tz(`${date} ${time}`, timezone).format('YYYY-MM-DD HH:mm');

export const formatDateTimeFullUS = (dateTime: string, timezone: string) =>
    momentTz.tz(`${dateTime}`, timezone).format('MMM DD, YYYY [at] hh:mm a');

export const formatDateTimeShortUS = (dateTime: string, timezone: string) =>
    momentTz.tz(`${dateTime}`, timezone).format('MM/DD/YYYY hh:mm a');

export const formatDateTz = (date: string, timezone: string) =>
    momentTz.tz(`${date} 12:00`, timezone).format('YYYY-MM-DD');

export const formatDateTzUS = (date: string, timezone: string) =>
    momentTz.tz(`${date} 12:00`, timezone).format('MMM DD, YYYY');

export const formatDateTzMX = (date: string, timezone: string) =>
    momentTz.tz(`${date} 12:00`, timezone).format('DD / DDD / YYYY');

export const compareDates = (
    dateA: string,
    action: DateComparision,
    dateB: string,
    dateC?: string
) => {
    switch (action as string) {
        case 'isSame':
            return moment(dateA).isSame(dateB);
        case 'isAfter':
            return moment(dateA).isAfter(dateB);
        case 'isSameOrBefore':
            return moment(dateA).isSameOrBefore(dateB);
        case 'isSameOrAfter':
            return moment(dateA).isSameOrAfter(dateB);
        case 'isBetween':
            return dateC ? moment(dateA).isBetween(dateB, dateC) : false;
        default:
            return moment(dateA).isBefore(dateB);
    }
};

export const calculateElapsedTime = (
    dateTimeStart: string,
    dateTimeEnd: string
) => {
    const start = new Date(dateTimeStart);
    const end = new Date(dateTimeEnd);
    let timeDiff = end.getTime() - start.getTime();
    const weeks = Math.floor(timeDiff / ONE_WEEK);
    timeDiff -= weeks * ONE_WEEK;
    const days = Math.floor(timeDiff / ONE_DAY);
    timeDiff -= days * ONE_DAY;
    const hours = Math.floor(timeDiff / ONE_HOUR);
    timeDiff -= hours * ONE_HOUR;
    const minutes = Math.floor(timeDiff / ONE_MINUTE);
    timeDiff -= minutes * ONE_MINUTE;
    const seconds = Math.floor(timeDiff / ONE_SECOND);

    return {
        weeks,
        days,
        hours,
        minutes,
        seconds,
    };
};

export const getRandomBooleanWithProbability = (probability = 0.25) => {
    if (probability < 0 || probability > 1) {
        throw new Error('Probability should be a value between 0 and 1.');
    }
    const randomValue = Math.random();

    return randomValue < probability;
};
