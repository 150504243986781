import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { BiCoinStack as CoinsIcon, BiTask as NumberIcon } from 'react-icons/bi';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { DashboardPerformanceResponse } from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { updatePriorParentPage } from 'redux/actions/session';

const CaregiversChartContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        paddingTop: pxToRem(20),
        paddingBottom: pxToRem(20),
        paddingLeft: pxToRem(10),
        paddingRight: pxToRem(10),
    })
);

const ChartRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '&:last-of-type': {
            mb: 0,
        },
    })
);

const ChartItemIndex = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
        justifyContent: 'flex-start',
        width: pxToRem(32),
    })
);

const ChartItemDataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-start',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

const ChartItemData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pb: pxToRem(4),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ChartItemLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'bold',
        flexGrow: 1,
    })
);

const ChartItemValueTasks = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(12) },
        color: 'app.green.main',
        marginRight: pxToRem(8),
        alignItems: 'center',
    })
);

const ChartItemValuePoints = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        fontSize: { lg: pxToRem(12) },
        color: 'app.orange.main',
        alignItems: 'center',
    })
);

type Props = {
    data: DashboardPerformanceResponse;
    dictionary: LanguageStructure;
    open: boolean;
    onClose: () => void;
};

const PerformanceDetailsDialog = (props: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {
        data: { caregivers = [] },
        open,
        dictionary: { dashboards, shared },
        onClose,
    } = props;

    const maxTotalTasks = Math.max(
        ...caregivers.map(({ totalTasks }) => totalTasks)
    );

    const maxTotalPoints = Math.max(
        ...caregivers.map(({ totalPoints }) => totalPoints)
    );

    return (
        <CustomDialog
            closeable
            open={open}
            title="Performance Details"
            width={pxToRem(600)}
            maxHeight={`calc(100vh - ${pxToRem(160)})`}
            showDividers
            onClose={onClose}
            content={
                <CaregiversChartContainer>
                    {caregivers.map(
                        ({ id, name, totalTasks, totalPoints }, idx) => (
                            <ChartRow key={id}>
                                <ChartItemIndex>#{idx + 1}</ChartItemIndex>
                                <ChartItemDataContainer>
                                    <ChartItemData>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <ChartItemLabel>
                                                {name}
                                            </ChartItemLabel>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <ChartItemValueTasks>
                                                    <NumberIcon />
                                                    {totalTasks.toLocaleString()}
                                                </ChartItemValueTasks>
                                                <ChartItemValuePoints>
                                                    <CoinsIcon />
                                                    {totalPoints.toLocaleString()}
                                                </ChartItemValuePoints>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                borderBottomWidth: pxToRem(4),
                                                borderBottomStyle: 'solid',
                                                borderBottomColor:
                                                    'app.green.main',
                                                width: `${Math.ceil(
                                                    (totalTasks * 100) /
                                                        maxTotalTasks
                                                )}%`,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                borderBottomWidth: pxToRem(4),
                                                borderBottomStyle: 'solid',
                                                borderBottomColor:
                                                    'app.orange.main',
                                                width: `${Math.ceil(
                                                    (totalPoints * 100) /
                                                        maxTotalPoints
                                                )}%`,
                                                marginTop: pxToRem(1),
                                            }}
                                        />
                                    </ChartItemData>
                                </ChartItemDataContainer>
                            </ChartRow>
                        )
                    )}
                </CaregiversChartContainer>
            }
        />
    );
};

const mapStateToProps = ({ language, dashboards }: ReduxStore) => {
    const { dictionary } = language;
    const { performance } = dashboards;

    return {
        dictionary,
        data: performance,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdatePriorParentPage: (parentPage: string) =>
        dispatch(updatePriorParentPage(parentPage)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PerformanceDetailsDialog);
