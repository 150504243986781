import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReduxStore } from 'types/redux';
import { InvitationResponse } from 'types/signupInvitations';
import { RoleResponse, UserResponse, UsersReadParams } from 'types/users';

import Loading from 'components/Shared/Loading';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';
import ManagementTabs from 'pages/Users/Management/ManagementTabs';
import { readInvitations } from 'redux/actions/signupInvitations';
import { readRoles, readUsers } from 'redux/actions/users';

type Props = {
    invitationsList: InvitationResponse[] | undefined;
    loading: boolean;
    rolesList: RoleResponse[];
    usersList: UserResponse[] | undefined;
    dispatchReadInvitations: () => void;
    dispatchReadRoles: () => void;
    dispatchReadUsers: (jsonParams?: UsersReadParams) => void;
};

const UsersList = (props: Props) => {
    const {
        invitationsList,
        loading,
        usersList,
        rolesList,
        dispatchReadInvitations,
        dispatchReadRoles,
        dispatchReadUsers,
    } = props;

    useEffect(() => {
        if (!usersList) {
            dispatchReadUsers();
        }

        if (!invitationsList) {
            dispatchReadInvitations();
        }

        if (!rolesList) {
            dispatchReadRoles();
        }
    }, [invitationsList, rolesList, usersList]);

    if (loading) {
        return <Loading />;
    }

    return (
        <PageStructure>
            <ManagementTabs />
        </PageStructure>
    );
};

const mapStateToProps = ({
    language,
    signupInvitations,
    users,
}: ReduxStore) => {
    const { dictionary } = language;
    const { loading, rolesList, usersList } = users;
    const { invitationsList } = signupInvitations;

    return {
        dictionary,
        invitationsList,
        loading,
        rolesList,
        usersList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadInvitations: () => dispatch(readInvitations()),
    dispatchReadUsers: (jsonParams?: UsersReadParams) =>
        dispatch(readUsers(jsonParams)),
    dispatchReadRoles: () => dispatch(readRoles()),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedUsersList: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(UsersList)
);

export default ConnectedUsersList;
