import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { cloneDeep, isNil, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { connect } from 'react-redux';

import { NotificationDialogProps } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { CheckedRow, Column, Row, SortDirection } from 'types/table';
import {
    UserResponse,
    UsersListFiltersForm,
    UsersReadParams,
} from 'types/users';

import { CustomTable } from 'components/Custom';
import Loading from 'components/Shared/Loading';
import NoData from 'components/Shared/NoData';
import NotificationDialog from 'components/Shared/NotificationDialog';
import { pxToRem } from 'components/theme/typography';
import { ActionButton } from 'constants/dashboards';
import {
    AVATAR_TYPES,
    INITIAL_NOTIFICATION_DIALOG_STATE,
    NOTIFICATION_TYPE,
} from 'constants/shared';
import {
    ALIGNMENT_TYPES,
    ROWS_PER_PAGE_OPTIONS,
    SORT_DIRECTIONS,
} from 'constants/table';
import { SortRows, updateRowsToShow } from 'lib/table';
import { formatUsersListColumns, formatUsersListRows } from 'lib/users';
import Filters from 'pages/Users/Management/Users/Filters';
import UserCreateEditDialog from 'pages/Users/Management/Users/UserCreateEditDialog';
import UserDeleteDialog from 'pages/Users/Management/Users/UserDeleteDialog';
import UserDetailDialog from 'pages/Users/Management/Users/UserDetailDialog';
import {
    clearUserActionStatus,
    clearUserDetails,
    deleteUser,
    readUser,
    readUsers,
} from 'redux/actions/users';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    caregiver: {
        color: theme.palette.app.green.main,
        backgroundColor: alpha(theme.palette.app.green.main, 0.05),
    },
    manager: {
        color: theme.palette.app.orange.main,
        backgroundColor: alpha(theme.palette.app.orange.main, 0.05),
    },
    admin: {
        color: theme.palette.common.black,
        backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
}));

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(16),
        pb: pxToRem(16),
        width: '100%',
    })
);

const RoleChip = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        m: 0,
        px: pxToRem(8),
        display: 'flex',
        borderRadius: theme.shape.borderRadiusSm,
        fontSize: { lg: pxToRem(10) },
        textAlign: 'center',
        textTransform: 'uppercase',
        width: pxToRem(72),
    })
);

type Props = {
    dictionary: LanguageStructure;
    loading: boolean;
    user?: UserResponse;
    usersList: UserResponse[];
    dispatchClearUserActionStatus: () => void;
    dispatchClearUserDetails: () => void;
    dispatchDeleteUser: (userId: number) => void;
    dispatchReadUser: (userId: number) => void;
    dispatchReadUsers: () => void;
};

const UsersList = (props: Props) => {
    const {
        dictionary: { shared, userManagementUsersList },
        loading,
        user,
        usersList,
        dispatchClearUserActionStatus,
        dispatchClearUserDetails,
        dispatchDeleteUser,
        dispatchReadUser,
        dispatchReadUsers,
    } = props;
    const classes = useStyles();
    const [columns, setColumns] = useState<Column[]>([]);
    const [rows, setRows] = useState<Row[]>([]);
    const [page, setPage] = useState<number>();
    const [rowsPerPage, setRowsPerPage] = useState<number>();
    const [rowsToShow, setRowsToShow] = useState<Row[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortDirection, setSortDirection] = useState<
        SortDirection | undefined
    >();
    const [rowsCheckedStatus, setRowsCheckedStatus] = useState<CheckedRow>({});
    const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [notificationData, setNotificationData] =
        useState<NotificationDialogProps>(INITIAL_NOTIFICATION_DIALOG_STATE);
    const initialPage = 1;

    const handleFiltersChange = (newFilters: UsersListFiltersForm) => {
        const { userId, emailIndex, phoneNumberIndex } = newFilters;
        const hasFilters =
            !isNil(userId) || !isNil(emailIndex) || !isNil(phoneNumberIndex);
        const filteredUsers: UserResponse[] = !hasFilters
            ? cloneDeep(usersList)
            : [];

        if (hasFilters) {
            usersList.forEach((userRow, index) => {
                const validUser = !isNil(userId)
                    ? userRow.userId === userId
                    : true;
                const validEmail = !isNil(emailIndex)
                    ? index + 1 === emailIndex
                    : true;
                const validPhoneNumber = !isNil(phoneNumberIndex)
                    ? index + 1 === phoneNumberIndex
                    : true;
                const recordshowBeDisplayed =
                    validUser && validEmail && validPhoneNumber;

                if (recordshowBeDisplayed) {
                    filteredUsers.push(userRow);
                }
            });
        }

        setRows(formatUsersListRows(filteredUsers, shared));
    };

    const renderRoleChip = (roleName: string) => {
        const roleLabel =
            roleName !== 'Caregiver' && roleName !== 'Manager'
                ? 'Admin'
                : roleName;
        const className = classes[roleLabel.toLocaleLowerCase()];
        return <RoleChip className={className}>{roleLabel}</RoleChip>;
    };

    const renderActionButton = (action) => (
        <ActionButton
            color="green"
            onClick={handleOpenDetailsDialog(action.userId)}
        >
            {action.label}
        </ActionButton>
    );

    const getColumns = (): Column[] => [
        {
            avatarType: AVATAR_TYPES.user,
            id: 'user',
            initialSortDirection: SORT_DIRECTIONS.asc,
            isSortable: true,
            label: '',
            showAvatar: true,
            sortColumn: 'userFullName',
        },
        {
            id: 'role',
            initialSortDirection: SORT_DIRECTIONS.asc,
            content: (role: string) => renderRoleChip(role),
            isSortable: true,
            label: '',
            sortColumn: 'roleId',
            width: pxToRem(112),
            align: ALIGNMENT_TYPES.left,
        },
        {
            id: 'email',
            initialSortDirection: SORT_DIRECTIONS.asc,
            isSortable: true,
            label: '',
            width: pxToRem(320),
        },
        {
            id: 'phoneNumber',
            initialSortDirection: SORT_DIRECTIONS.asc,
            content: (phoneNumber: string) =>
                formatPhoneNumberIntl(phoneNumber),
            isSortable: true,
            sortColumn: 'phoneNumber',
            label: '',
            width: pxToRem(192),
        },
        {
            id: 'registrationDate',
            initialSortDirection: SORT_DIRECTIONS.asc,
            label: '',
            width: pxToRem(224),
        },
        {
            id: 'action',
            content: (action) => renderActionButton(action),
            label: '',
            width: pxToRem(192),
        },
    ];

    const handleOpenDetailsDialog = (userId: number) => () => {
        setOpenDetailsDialog(true);
        dispatchReadUser(userId);
    };

    const handleCloseDetailsDialog = () => {
        setOpenDetailsDialog(false);
        dispatchClearUserDetails();
    };

    const handleOpenDeleteUserDialog = () => {
        setOpenDetailsDialog(false);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteUserDialog = () => {
        setOpenDeleteDialog(false);
        dispatchClearUserDetails();
    };

    const handleOpenEditUserDialog = () => {
        setOpenDetailsDialog(false);
        setOpenEditDialog(true);
    };

    const handleCloseEditUserDialog = () => {
        setOpenEditDialog(false);
        dispatchClearUserDetails();
    };

    const handleDialogSubmit = (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => {
        if (openEditDialog && notification === NOTIFICATION_TYPE.success) {
            handleCloseEditUserDialog();
        }

        setNotificationData({
            isOpen: true,
            message,
            title,
            type: notification,
        });
    };

    const handleUserDeleteConfirm = (userId: number) => {
        handleCloseDeleteUserDialog();
        dispatchDeleteUser(userId);
        dispatchReadUsers();
    };

    const handleCloseNotification = () => {
        dispatchClearUserActionStatus();
        dispatchClearUserDetails();

        if (notificationData.type === NOTIFICATION_TYPE.success) {
            dispatchReadUsers();
        }

        setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setPage(initialPage);
        setRowsPerPage(newRowsPerPage);
    };

    const handleRowsSelectChange = (newSelectedRows: number[]) => {
        setSelectedRows(newSelectedRows);
    };

    const handleSortChange = (
        newSortBy?: string,
        newSortDirection?: SortDirection
    ) => {
        const newRows = SortRows(rows, newSortBy, newSortDirection);
        setSortBy(newSortBy);
        setSortDirection(newSortDirection);

        if (!isUndefined(page) && !isUndefined(rowsPerPage)) {
            setRowsToShow(updateRowsToShow(newRows, page, rowsPerPage));
        }
    };

    const updateContent = () => {
        setPage(initialPage);
        setRowsPerPage(ROWS_PER_PAGE_OPTIONS[0]);
    };

    useEffect(() => {
        updateContent();
    }, []);

    useEffect(() => {
        if (usersList) {
            setColumns(
                formatUsersListColumns(getColumns(), userManagementUsersList)
            );
            setRows(formatUsersListRows(usersList, shared));
        }
    }, [usersList]);

    useEffect(() => {
        if (rows.length && !isUndefined(page) && !isUndefined(rowsPerPage)) {
            setRowsToShow(updateRowsToShow(rows, page, rowsPerPage));
        }
    }, [page, rows, rowsPerPage]);

    return (
        <RootStyle>
            <Filters
                onFiltersChange={handleFiltersChange}
                onDialogSubmit={handleDialogSubmit}
            />
            {loading ? (
                <Loading />
            ) : (
                <>
                    {!rows.length ? (
                        <NoData useFilters />
                    ) : (
                        <>
                            <CustomTable
                                rows={rowsToShow}
                                columns={columns}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                sortBy={sortBy}
                                sortDirection={sortDirection}
                                totalRows={rows.length}
                                maxHeight={`calc(100vh - ${pxToRem(368)})`}
                                // useCheckBoxes
                                usePagination
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                onRowsSelectChange={handleRowsSelectChange}
                                onSortChange={handleSortChange}
                                rowsCheckedStatus={rowsCheckedStatus}
                                setRowsCheckedStatus={setRowsCheckedStatus}
                            />
                            <UserDetailDialog
                                isOpen={openDetailsDialog}
                                onClose={handleCloseDetailsDialog}
                                onDelete={handleOpenDeleteUserDialog}
                                onEdit={handleOpenEditUserDialog}
                            />
                            <UserDeleteDialog
                                isOpen={openDeleteDialog}
                                onConfirm={handleUserDeleteConfirm}
                                onCancel={handleCloseDeleteUserDialog}
                            />
                            <UserCreateEditDialog
                                id="user_edit"
                                isOpen={openEditDialog}
                                onReset={handleDialogSubmit}
                                onSave={handleDialogSubmit}
                                onClose={handleCloseEditUserDialog}
                            />
                            <NotificationDialog
                                isOpen={notificationData.isOpen}
                                message={notificationData.message}
                                title={notificationData.title}
                                type={notificationData.type}
                                onClose={handleCloseNotification}
                            />
                        </>
                    )}
                </>
            )}
        </RootStyle>
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { loading, user, usersList } = users;

    const filteredUsersList = usersList
        ? usersList.filter((userData) => userData.isActive)
        : usersList;

    return {
        dictionary,
        loading,
        user,
        usersList: filteredUsersList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchClearUserActionStatus: () => dispatch(clearUserActionStatus()),
    dispatchClearUserDetails: () => dispatch(clearUserDetails()),
    dispatchDeleteUser: (userId: number) => dispatch(deleteUser(userId, true)),
    dispatchReadUser: (userId: number) => dispatch(readUser(userId, true)),
    dispatchReadUsers: () => dispatch(readUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
