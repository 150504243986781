import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { cloneDeep, isNil, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { NotificationDialogProps } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import {
    InvitationResponse,
    InvitationsListFiltersForm,
} from 'types/signupInvitations';
import { CheckedRow, Column, Row, SortDirection } from 'types/table';

import { CustomTable } from 'components/Custom';
import Loading from 'components/Shared/Loading';
import NoData from 'components/Shared/NoData';
import NotificationDialog from 'components/Shared/NotificationDialog';
import { pxToRem } from 'components/theme/typography';
import { ActionButton } from 'constants/dashboards';
import {
    INITIAL_NOTIFICATION_DIALOG_STATE,
    NOTIFICATION_TYPE,
} from 'constants/shared';
import {
    ALIGNMENT_TYPES,
    ROWS_PER_PAGE_OPTIONS,
    SORT_DIRECTIONS,
} from 'constants/table';
import { INVITATION_STATUS_MAP, InvitationStatus } from 'constants/users';
import { SortRows, updateRowsToShow } from 'lib/table';
import {
    formatInvitationsListColumns,
    formatInvitationsListRows,
} from 'lib/users';
import InvitationDetailDialog from 'pages/Users/Management/Invitations/InvitationDetailDialog';
import InvitationFilters from 'pages/Users/Management/Invitations/InvitationFilters';
import {
    readInvitation,
    readInvitations,
} from 'redux/actions/signupInvitations';

import InvitationCancelDialog from './InvitationCancelDialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    completed: {
        color: theme.palette.app.green.main,
        backgroundColor: alpha(theme.palette.app.green.main, 0.05),
    },
    canceled: {
        color: theme.palette.app.orange.main,
        backgroundColor: alpha(theme.palette.app.orange.main, 0.05),
    },
    pending: {
        color: theme.palette.socialMedia.google.yellow,
        backgroundColor: alpha(theme.palette.socialMedia.google.yellow, 0.05),
    },
}));

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(16),
        pb: pxToRem(16),
        width: '100%',
    })
);

const StatusChip = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        m: 0,
        px: pxToRem(8),
        display: 'flex',
        borderRadius: theme.shape.borderRadiusSm,
        fontSize: { lg: pxToRem(10) },
        textAlign: 'center',
        textTransform: 'uppercase',
        width: pxToRem(72),
    })
);

type Props = {
    dictionary: LanguageStructure;
    loading: boolean;
    invitationsList: InvitationResponse[];
    dispatchReadInvitations: () => void;
};

const InvitationsList = (props: Props) => {
    const {
        dictionary: { shared, userManagementInvitationsList },
        loading,
        invitationsList,
        dispatchReadInvitations,
    } = props;
    const classes = useStyles();
    const [columns, setColumns] = useState<Column[]>([]);
    const [rows, setRows] = useState<Row[]>([]);
    const [page, setPage] = useState<number>();
    const [rowsPerPage, setRowsPerPage] = useState<number>();
    const [rowsToShow, setRowsToShow] = useState<Row[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortDirection, setSortDirection] = useState<
        SortDirection | undefined
    >();
    const [invitationSelected, setInvitationSelected] =
        useState<InvitationResponse>();
    const [rowsCheckedStatus, setRowsCheckedStatus] = useState<CheckedRow>({});
    const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);
    const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
    const [notificationData, setNotificationData] =
        useState<NotificationDialogProps>(INITIAL_NOTIFICATION_DIALOG_STATE);
    const initialPage = 1;

    const handleFiltersChange = (newFilters: InvitationsListFiltersForm) => {
        const { invitedById, invitedToName, email, invitationStatusId } =
            newFilters;
        const hasFilters =
            !isNil(invitedById) ||
            !isNil(invitedToName) ||
            !isNil(email) ||
            !isNil(invitationStatusId);
        const filteredInvitations: InvitationResponse[] = !hasFilters
            ? cloneDeep(invitationsList)
            : [];

        if (hasFilters) {
            invitationsList.forEach((invitationRow, index) => {
                const invitedToFullName = `${invitationRow.firstName} ${invitationRow.lastName}`;

                const validInviter = !isNil(invitedById)
                    ? invitationRow.createdById === invitedById
                    : true;
                const validInvitedTo = !isNil(invitedToName)
                    ? invitedToFullName.includes(invitedToName)
                    : true;
                const validEmail = !isNil(email)
                    ? invitationRow.email.includes(email)
                    : true;
                const validStatus = !isNil(invitationStatusId)
                    ? invitationRow.invitationStatusId === invitationStatusId
                    : true;

                const recordshowBeDisplayed =
                    validInviter && validInvitedTo && validEmail && validStatus;

                if (recordshowBeDisplayed) {
                    filteredInvitations.push(invitationRow);
                }
            });
        }

        setRows(formatInvitationsListRows(filteredInvitations, shared));
    };

    const renderStatusChip = (statusId: InvitationStatus) => {
        let className = 'pending';

        if (statusId === InvitationStatus.completed) {
            className = 'completed';
        } else if (statusId === InvitationStatus.canceled) {
            className = 'canceled';
        }

        const statusLabel =
            userManagementInvitationsList[INVITATION_STATUS_MAP[className]];
        return (
            <StatusChip className={classes[className]}>
                {statusLabel}
            </StatusChip>
        );
    };

    const renderActionButton = (action) => (
        <ActionButton
            color="green"
            onClick={handleOpenDetailsDialog(action.invitation)}
        >
            {action.label}
        </ActionButton>
    );

    const getColumns = (): Column[] => [
        {
            id: 'invitedTo',
            initialSortDirection: SORT_DIRECTIONS.asc,
            isSortable: true,
            label: '',
        },
        {
            id: 'email',
            initialSortDirection: SORT_DIRECTIONS.asc,
            isSortable: true,
            label: '',
            width: pxToRem(520),
        },
        {
            id: 'status',
            initialSortDirection: SORT_DIRECTIONS.asc,
            content: (status: InvitationStatus) => renderStatusChip(status),
            isSortable: true,
            label: '',
            sortColumn: 'statusId',
            width: pxToRem(160),
            align: ALIGNMENT_TYPES.left,
        },
        {
            id: 'invitationDate',
            initialSortDirection: SORT_DIRECTIONS.asc,
            isSortable: true,
            label: '',
            sortColumn: 'invitationTimestamp',
            width: pxToRem(192),
        },
        {
            id: 'action',
            content: (action) => renderActionButton(action),
            label: '',
            width: pxToRem(192),
        },
    ];

    const handleOpenDetailsDialog = (invitation: InvitationResponse) => () => {
        setOpenDetailsDialog(true);
        setInvitationSelected(invitation);
    };

    const handleCloseDetailsDialog = () => {
        setOpenDetailsDialog(false);
    };

    const handleOpenCancelInviteDialog = () => {
        handleCloseDetailsDialog();
        setOpenCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setOpenCancelDialog(false);
    };

    const handleReSendInvitation = (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => {
        handleCloseDetailsDialog();
        setNotificationData({
            isOpen: true,
            message,
            title,
            type: notification,
        });
    };

    const handleConfirmCancelInvitation = (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => {
        handleCloseCancelDialog();
        setNotificationData({
            isOpen: true,
            message,
            title,
            type: notification,
        });
    };

    const handleCloseNotification = () => {
        setInvitationSelected(undefined);

        if (notificationData.type === NOTIFICATION_TYPE.success) {
            dispatchReadInvitations();
        }

        setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setPage(initialPage);
        setRowsPerPage(newRowsPerPage);
    };

    const handleRowsSelectChange = (newSelectedRows: number[]) => {
        setSelectedRows(newSelectedRows);
    };

    const handleSortChange = (
        newSortBy?: string,
        newSortDirection?: SortDirection
    ) => {
        const newRows = SortRows(rows, newSortBy, newSortDirection);
        setSortBy(newSortBy);
        setSortDirection(newSortDirection);

        if (!isUndefined(page) && !isUndefined(rowsPerPage)) {
            setRowsToShow(updateRowsToShow(newRows, page, rowsPerPage));
        }
    };

    const updateContent = () => {
        setPage(initialPage);
        setRowsPerPage(ROWS_PER_PAGE_OPTIONS[0]);
    };

    useEffect(() => {
        updateContent();
    }, []);

    useEffect(() => {
        if (invitationsList) {
            setColumns(
                formatInvitationsListColumns(
                    getColumns(),
                    userManagementInvitationsList
                )
            );
            setRows(formatInvitationsListRows(invitationsList, shared));
        }
    }, [invitationsList]);

    useEffect(() => {
        if (rows.length && !isUndefined(page) && !isUndefined(rowsPerPage)) {
            setRowsToShow(updateRowsToShow(rows, page, rowsPerPage));
        }
    }, [page, rows, rowsPerPage]);

    return (
        <RootStyle>
            <InvitationFilters onFiltersChange={handleFiltersChange} />
            {loading ? (
                <Loading />
            ) : (
                <>
                    {!rows.length ? (
                        <NoData useFilters />
                    ) : (
                        <>
                            <CustomTable
                                rows={rowsToShow}
                                columns={columns}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                sortBy={sortBy}
                                sortDirection={sortDirection}
                                totalRows={rows.length}
                                maxHeight={`calc(100vh - ${pxToRem(368)})`}
                                // useCheckBoxes
                                usePagination
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                onRowsSelectChange={handleRowsSelectChange}
                                onSortChange={handleSortChange}
                                rowsCheckedStatus={rowsCheckedStatus}
                                setRowsCheckedStatus={setRowsCheckedStatus}
                            />
                            <InvitationDetailDialog
                                isOpen={openDetailsDialog}
                                invitation={invitationSelected}
                                onClose={handleCloseDetailsDialog}
                                onCancelInvitation={
                                    handleOpenCancelInviteDialog
                                }
                                onReSendInvitation={handleReSendInvitation}
                            />
                            <InvitationCancelDialog
                                isOpen={openCancelDialog}
                                invitation={invitationSelected}
                                onClose={handleCloseCancelDialog}
                                onConfirmCancelation={
                                    handleConfirmCancelInvitation
                                }
                            />
                            <NotificationDialog
                                isOpen={notificationData.isOpen}
                                message={notificationData.message}
                                title={notificationData.title}
                                type={notificationData.type}
                                onClose={handleCloseNotification}
                            />
                        </>
                    )}
                </>
            )}
        </RootStyle>
    );
};

const mapStateToProps = (
    { language, signupInvitations, users }: ReduxStore
) => {
    const { dictionary } = language;
    const { loading, invitationsList } = signupInvitations;

    return {
        dictionary,
        loading,
        invitationsList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadInvitations: () => dispatch(readInvitations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsList);
