import { alpha } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

export default function List(theme) {
    const { palette } = theme;

    return {
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: pxToRem(8),
                    color: palette.grey[500],
                    '& .MuiListItemButton-root': {
                        borderRadius: pxToRem(8),
                    },
                    '&:hover': {
                        '& .MuiListItemButton-root': {
                            backgroundColor: alpha(
                                palette.app.green.main,
                                0.15
                            ),
                        },
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        fontWeight: 'bold',

                        '& .MuiListItemButton-root': {
                            color: palette.common.white,
                            backgroundColor: palette.app.green.main,
                        },
                    },
                },
            },
        },
    };
}
