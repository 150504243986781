import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const FilledCaregiversIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <g id="vuesax/bold/profile-2user">
            <g id="profile-2user">
                <path
                    id="Vector"
                    d="M5.9987 1.33333C4.25203 1.33333 2.83203 2.75333 2.83203 4.49999C2.83203 6.21333 4.17203 7.6 5.9187 7.66C5.97203 7.65333 6.02536 7.65333 6.06536 7.66C6.0787 7.66 6.08536 7.66 6.0987 7.66C6.10536 7.66 6.10536 7.66 6.11203 7.66C7.8187 7.6 9.1587 6.21333 9.16536 4.49999C9.16536 2.75333 7.74536 1.33333 5.9987 1.33333Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_2"
                    d="M9.38859 9.43326C7.52859 8.19326 4.49526 8.19326 2.62193 9.43326C1.77526 9.99992 1.30859 10.7666 1.30859 11.5866C1.30859 12.4066 1.77526 13.1666 2.61526 13.7266C3.54859 14.3533 4.77526 14.6666 6.00193 14.6666C7.22859 14.6666 8.45526 14.3533 9.38859 13.7266C10.2286 13.1599 10.6953 12.3999 10.6953 11.5733C10.6886 10.7533 10.2286 9.99326 9.38859 9.43326Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_3"
                    d="M13.3263 4.89339C13.4329 6.18673 12.5129 7.32006 11.2396 7.47339C11.2329 7.47339 11.2329 7.47339 11.2263 7.47339H11.2062C11.1662 7.47339 11.1262 7.47339 11.0929 7.48673C10.4462 7.52006 9.85292 7.31339 9.40625 6.93339C10.0929 6.32006 10.4863 5.40006 10.4062 4.40006C10.3596 3.86006 10.1729 3.36673 9.89292 2.94673C10.1463 2.82006 10.4396 2.74006 10.7396 2.71339C12.0463 2.60006 13.2129 3.57339 13.3263 4.89339Z"
                    fill="currentColor"
                />
                <path
                    id="Vector_4"
                    d="M14.6576 11.0599C14.6042 11.7066 14.1909 12.2666 13.4976 12.6466C12.8309 13.0133 11.9909 13.1866 11.1576 13.1666C11.6376 12.7333 11.9176 12.1933 11.9709 11.6199C12.0376 10.7933 11.6442 9.99994 10.8576 9.36661C10.4109 9.01327 9.89089 8.73327 9.32422 8.52661C10.7976 8.09994 12.6509 8.38661 13.7909 9.30661C14.4042 9.79994 14.7176 10.4199 14.6576 11.0599Z"
                    fill="currentColor"
                />
            </g>
        </g>
    </Base>
);
