import { Box, Button, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ShiftNotesCategoryResponse } from 'types/ShiftNotesCategories';
import { SelectStructure } from 'types/inputs';
import { LanguageStructure } from 'types/language';

import { CustomMobileDialog, CustomRadioGroupOptions } from 'components/Custom';
import TranscriptionTextField from 'components/Shared/TranscriptionTextField';
import { pxToRem } from 'components/theme/typography';
import { ADD_RESIDENT_NOTE_DIALOG_ANALYTICS } from 'constants/analytics';
import { readShiftNotesCategories } from 'redux/actions/shiftNotesCategories';

type Props = {
    dictionary: LanguageStructure;
    isOpen: boolean;
    loading: boolean;
    reportContent?: string;
    shiftNoteCategoryId?: string;
    shiftNotesCategoriesList: ShiftNotesCategoryResponse[];
    dispatchReadShiftNotesCategories: () => void;
    onClose: () => void;
    onSubmit: (reportContent: string, newShiftNoteCategory: number) => void;
};

const ShiftNoteDialog = (props: Props) => {
    const {
        dictionary: { residents: residentsDictionary, shared },
        isOpen,
        loading,
        reportContent,
        shiftNoteCategoryId,
        shiftNotesCategoriesList,
        dispatchReadShiftNotesCategories,
        onSubmit,
        onClose,
    } = props;
    const [categoryId, setCategoryId] = useState<string>('');
    const [categoriesOptions, setCategoriesOptions] = useState<
        SelectStructure[]
    >([]);
    const [report, setReport] = useState<string>('');

    const [showActivateMicConfirmation, setShowActivateMicConfirmation] =
        useState<boolean>(false);

    const dialogTitle = `${shiftNoteCategoryId ? shared.edit : shared.add} ${
        residentsDictionary.residentNotesTitle
    }`;

    const handleOnClose = () => {
        setCategoryId(categoriesOptions[0]?.value || '');
        setReport('');
        setShowActivateMicConfirmation(false);
        onClose();
    };

    const handleCategoryChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCategoryId(event.target.value);
    };

    const handleReportChange = (newReport: string) => {
        setReport(newReport);
    };

    const handleSubmitClick = () => {
        onSubmit(report, Number(categoryId));
        handleOnClose();
    };

    useEffect(() => {
        if (!shiftNotesCategoriesList.length) {
            dispatchReadShiftNotesCategories();
        }
    }, []);

    useEffect(() => {
        if (shiftNotesCategoriesList.length) {
            const newCategoriesOptions: SelectStructure[] =
                shiftNotesCategoriesList.map((category) => ({
                    label: category.name,
                    value: category.id.toString(),
                }));
            setCategoriesOptions(newCategoriesOptions);
            setCategoryId(newCategoriesOptions[0].value);
        }
    }, [JSON.stringify(shiftNotesCategoriesList)]);

    useEffect(() => {
        if (!isOpen) return;

        setCategoryId(shiftNoteCategoryId || '');
        setReport(reportContent || '');
    }, [isOpen]);

    if (loading) {
        return null;
    }

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={showActivateMicConfirmation ? '' : dialogTitle}
            width="100%"
            onClose={handleOnClose}
            content={
                <>
                    {!showActivateMicConfirmation && (
                        <RadioGroup
                            id="shiftNoteCategory"
                            name="choose-category"
                            defaultValue={shiftNoteCategoryId || ''}
                            onChange={handleCategoryChange}
                            value={categoryId}
                            sx={{
                                display: 'grid',
                                gap: pxToRem(12),
                                m: 0,
                                mb: pxToRem(12),
                            }}
                        >
                            {categoriesOptions.map(({ label, value }) => (
                                <CustomRadioGroupOptions
                                    key={value}
                                    value={value}
                                    label={label}
                                    selectedValue={categoryId}
                                />
                            ))}
                        </RadioGroup>
                    )}
                    <TranscriptionTextField
                        label={shared.note}
                        placeholder={shared.notePlaceholder}
                        showActivateMicConfirmation={
                            showActivateMicConfirmation
                        }
                        text={report}
                        analyticsIdText={
                            ADD_RESIDENT_NOTE_DIALOG_ANALYTICS.TRANSCRIPTION_BUTTON
                        }
                        onChange={handleReportChange}
                        toggleShowActivateMicConfirmation={(show: boolean) =>
                            setShowActivateMicConfirmation(show)
                        }
                    />
                    {!showActivateMicConfirmation && (
                        <Box
                            sx={{
                                mt: pxToRem(8),
                                display: 'grid',
                                gridTemplateColumns: '40% 1fr',
                                gap: pxToRem(12),
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleOnClose}
                                sx={{
                                    width: '100%',
                                    p: pxToRem(12),
                                    fontSize: pxToRem(14),
                                }}
                            >
                                {shared.cancel}
                            </Button>
                            <Button
                                color="primary"
                                disabled={!report || !categoryId}
                                onClick={handleSubmitClick}
                                sx={{
                                    width: '100%',
                                    p: pxToRem(12),
                                    fontSize: pxToRem(14),
                                    '&:disabled': {
                                        bgcolor: '#C7C8CB',
                                    },
                                }}
                            >
                                {shared.submit}
                            </Button>
                        </Box>
                    )}
                </>
            }
        />
    );
};

const mapStateToProps = ({ language, shiftNotesCategories }) => {
    const { dictionary } = language;
    const { shiftNotesCategoriesList, loading } = shiftNotesCategories;

    return {
        dictionary,
        loading,
        shiftNotesCategoriesList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadShiftNotesCategories: () =>
        dispatch(readShiftNotesCategories()),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedShiftNoteDialog: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftNoteDialog);

export default ConnectedShiftNoteDialog;
