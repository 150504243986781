import { api } from 'api';

import { MessageProps } from 'types/messages';
import {
    ResidentShiftNotesCreateUpdateParams,
    ResidentShiftNotesReadParams,
    ResidentShiftNotesResponse,
} from 'types/residentShiftNotes';

import { formatApiParams, getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { showAlert, throwError } from 'redux/actions/messages';
import 'redux/reducers/residentShiftNotes';

/**
 * Fetches the list of Resident Info Reports based on a Company Branch.
 * @param jsonParams the JSON object with the params for fetching the Resident Info Reports list.
 */
export const readResidentShiftNotes =
    (jsonParams: ResidentShiftNotesReadParams) => async (dispatch) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, true));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Fetch the Resident Info Reports List.
            const residentShiftNotesList: ResidentShiftNotesResponse[] = (
                await api.get('/resident-shift-notes', {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response;

            dispatch({
                type: actionTypes.RESIDENT_SHIFT_NOTES_READ_LIST,
                payload: residentShiftNotesList,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, false));
        }
    };

/**
 * Creates a Resident Info Report based on the provided params.
 * @param jsonParams the JSON object with the params for creating a new Resident Info Report.
 */
export const createResidentShiftNotes =
    (jsonParams: ResidentShiftNotesCreateUpdateParams) =>
    async (dispatch, getState) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, true));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Create the new Resident Info Report.
            await api.post('/resident-shift-notes', params, {
                headers: {
                    authorization: token,
                },
            });

            // Get the Home dictionary for fetching the success message.
            const {
                language: {
                    dictionary: { residents },
                },
            } = getState();

            // Set the success message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: residents.createShiftNotesSuccess,
                alertSeverity: 'success',
                status: 200,
            };

            dispatch(showAlert(message));
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, false));
        }
    };

/**
 * Updates a Resident Info Report based provided params.
 * @param residentShiftNoteId the ID of the Resident Info Report to update.
 * @param residentId the Resident's ID where to fetch the Resident Info Reports after the update.
 * @param jsonParams the JSON object with the params for updating a Resident Info Report.
 */
export const updateResidentShiftNote =
    (
        residentShiftNoteId: number,
        jsonParams: ResidentShiftNotesCreateUpdateParams
    ) =>
    async (dispatch, getState) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, true));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Create the new Resident Info Report.
            await api.put(
                `/resident-shift-notes/${residentShiftNoteId}`,
                params,
                {
                    headers: {
                        authorization: token,
                    },
                }
            );

            // Get the Home dictionary for fetching the success message.
            const {
                language: {
                    dictionary: { residents },
                },
            } = getState();

            // Set the success message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: residents.updateShiftNotesSuccess,
                alertSeverity: 'success',
                status: 200,
            };

            dispatch(showAlert(message));
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, false));
        }
    };

/**
 * Removes a Resident Shift Note based on the provided Resident Shift Note ID.
 * @param zoneId the ID of the Zone to remvove.
 */
export const deleteResidentShiftNote =
    (residentShiftNoteId: number) => async (dispatch) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, true));

            // Get the token.
            const token = getToken();

            // Delete the requested Zone.
            await api.delete(`/resident-shift-notes/${residentShiftNoteId}`, {
                headers: {
                    authorization: token,
                },
            });

            dispatch({
                type: actionTypes.RESIDENT_SHIFT_NOTES_DELETE,
                payload: residentShiftNoteId,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.RESIDENT_SHIFT_NOTES_LOADING, false));
        }
    };
