import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import {
    InvitationResponse,
    InvitationsListFiltersForm,
} from 'types/signupInvitations';
import { RoleResponse, UserResponse } from 'types/users';

import { CustomAutocomplete, CustomTextField } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import {
    INVITATIONS_LIST_FILTERS_FORM_INITIAL_VALUES,
    INVITATION_STATUS_MAP,
    InvitationStatus,
} from 'constants/users';

const useStyles = makeStyles({
    invitedBy: {
        width: pxToRem(240),
    },
    invitedTo: {
        width: pxToRem(240),
    },
    email: {
        width: pxToRem(272),
    },
    status: {
        width: pxToRem(192),
    },
});

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    })
);

const FiltersWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
    })
);

const FilterContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
        '&:last-of-type': {
            mr: 0,
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    invitationsList: InvitationResponse[];
    rolesList: RoleResponse[];
    usersList: UserResponse[];
    onFiltersChange: (newFilters: InvitationsListFiltersForm) => void;
};

const InvitationFilters = (props: Props) => {
    const classes = useStyles();
    const {
        dictionary: { userManagementInvitationsList },
        invitationsList,
        rolesList,
        usersList,
        onFiltersChange,
    } = props;

    // Get the role id from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { roleId } = storedSession?.sessionData || {};

    // Define the tabs to display.
    const showManagers = roleId <= 3;
    const showAdmins = roleId <= 2;

    const [invitedByOptions, setInvitedByOptions] = useState<
        DropdownStructure[]
    >([]);
    const [emailOptions, setEmailOptions] = useState<DropdownStructure[]>([]);
    const [invitationStatusOptions, setInvitationStatusOptions] = useState<
        DropdownStructure[]
    >([]);
    const [filtersForm, setFiltersForm] = useState<InvitationsListFiltersForm>(
        INVITATIONS_LIST_FILTERS_FORM_INITIAL_VALUES
    );

    // Define the options to show for the Dropdown Button.
    const dropdownButtonOptions = [
        userManagementInvitationsList.createUser,
        userManagementInvitationsList.inviteUser,
    ];

    const handleDropdownChange =
        (filterName: string) => (optionValue: DropdownValue) => {
            const newFilters: InvitationsListFiltersForm = {
                ...filtersForm,
                [filterName]: optionValue as number,
            };
            setFiltersForm(newFilters);
            onFiltersChange(newFilters);
        };

    const handleTextFieldChange =
        (filterName: string) => (newValue: string) => {
            const newFilters: InvitationsListFiltersForm = {
                ...filtersForm,
                [filterName]: newValue,
            };
            setFiltersForm(newFilters);
            onFiltersChange(newFilters);
        };

    useEffect(() => {
        const newInvitationStatusOptions: DropdownStructure[] = [
            {
                label: userManagementInvitationsList[
                    INVITATION_STATUS_MAP.pending
                ],
                value: InvitationStatus.pending,
            },
            {
                label: userManagementInvitationsList[
                    INVITATION_STATUS_MAP.completed
                ],
                value: InvitationStatus.completed,
            },
            {
                label: userManagementInvitationsList[
                    INVITATION_STATUS_MAP.canceled
                ],
                value: InvitationStatus.canceled,
            },
        ];
        setInvitationStatusOptions(newInvitationStatusOptions);

        if (invitationsList) {
            const invitedBySet = new Set<number>();
            const newInvitedByOptions: DropdownStructure[] = [];

            invitationsList.forEach((invitation) => {
                if (!invitedBySet.has(invitation.createdById)) {
                    invitedBySet.add(invitation.createdById);
                    newInvitedByOptions.push({
                        label: invitation.createdByName,
                        value: invitation.createdById,
                    });
                }
            });

            setInvitedByOptions(newInvitedByOptions);
        }
    }, [usersList, rolesList]);

    return (
        <RootStyle>
            <FiltersWrapper>
                <FilterContainer className={classes.invitedBy}>
                    <CustomAutocomplete
                        label={userManagementInvitationsList.invitedBy}
                        value={filtersForm.invitedById}
                        options={invitedByOptions}
                        fullWidth
                        onChange={handleDropdownChange('invitedById')}
                    />
                </FilterContainer>
                <FilterContainer className={classes.invitedTo}>
                    <CustomTextField
                        label={userManagementInvitationsList.invitedTo}
                        value={filtersForm.invitedToName}
                        fullWidth
                        onChange={handleTextFieldChange('invitedToName')}
                    />
                </FilterContainer>
                <FilterContainer className={classes.email}>
                    <CustomTextField
                        label={userManagementInvitationsList.invitationEmail}
                        value={filtersForm.email}
                        fullWidth
                        onChange={handleTextFieldChange('email')}
                    />
                </FilterContainer>
                <FilterContainer className={classes.status}>
                    <CustomAutocomplete
                        label={userManagementInvitationsList.invitationStatus}
                        value={filtersForm.invitationStatusId}
                        options={invitationStatusOptions}
                        fullWidth
                        onChange={handleDropdownChange('invitationStatusId')}
                    />
                </FilterContainer>
            </FiltersWrapper>
        </RootStyle>
    );
};

const mapStateToProps = (
    { language, signupInvitations, users }: ReduxStore
) => {
    const { dictionary } = language;
    const { invitationsList } = signupInvitations;
    const { rolesList, usersList } = users;

    return {
        dictionary,
        invitationsList,
        usersList,
        rolesList,
    };
};

export default connect(mapStateToProps)(InvitationFilters);
