import { Box, Typography } from '@mui/material';
import React from 'react';
import { TooltipProps } from 'recharts';

import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { pxToRem } from 'components/theme/typography';

const CustomTooltip = ({
    active,
    payload,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
        const { tasks } = payload[0].payload;

        const newTasks = tasks.flatMap((task) => task.tasks) as
            | RefusedTask[]
            | PRNTask[];

        const tasksCount = {};

        newTasks.forEach((task) => {
            const { keyService } = task;

            if (keyService) {
                if (!tasksCount[keyService]) {
                    tasksCount[keyService] = 1;
                } else {
                    tasksCount[keyService]++;
                }
            }
        });

        if (!Object.keys(tasksCount).length) {
            return null;
        }

        return (
            <Box
                sx={{
                    border: 'none',
                    backgroundColor: '#FFF',
                    borderRadius: pxToRem(8),
                    boxShadow: '0px 4px 4px rgba(139, 139, 139, 0.25)',
                    fontFamily: 'Open Sans',
                    color: '#6F6F79',
                    px: pxToRem(8),
                    py: pxToRem(4),
                }}
            >
                {Object.entries(tasksCount).map(([keyService, count]) => (
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '12px',
                            },
                            textTransform: 'capitalize',
                        }}
                        key={keyService}
                    >
                        <Typography
                            component="span"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '12px',
                                },
                            }}
                        >
                            {count as number}{' '}
                        </Typography>
                        {keyService}
                    </Typography>
                ))}
            </Box>
        );
    }

    return null;
};

export default CustomTooltip;
