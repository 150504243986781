import { Box, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

import { AvatarWithName } from './AvatarWithName';
import { DateAndTime } from './DateAndTime';

type Props = {
    row: Fall;
};

export const FallRowContent = ({ row }: Props) => {
    const { caregiver, datetime, fallType } = row;

    const parsedDate = parse(datetime, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formattedDate = format(parsedDate, 'MM/dd/yy h:mm a');

    return (
        <Box
            key={datetime.toString()}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={pxToRem(4)}
        >
            <Box
                display="flex"
                alignItems="baseline"
                gap={pxToRem(4)}
                flexWrap="wrap"
            >
                <AvatarWithName caregiver={caregiver} />
                <Typography
                    fontSize={{
                        xs: pxToRem(10),
                        md: pxToRem(11),
                    }}
                    lineHeight={1.3}
                    color="#6F6F79"
                >
                    ({fallType})
                </Typography>
            </Box>
            <DateAndTime timeStamp={formattedDate} />
        </Box>
    );
};
