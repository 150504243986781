import * as Sentry from '@sentry/react';

import { ErrorProps, MessageProps } from 'types/messages';

import actionTypes from 'redux/actionTypes';
import { signOut } from 'redux/actions/session';
import 'redux/reducers/messages';

export const showAlert = (message: MessageProps) => ({
    type: actionTypes.MESSAGE_SHOW,
    payload: message,
});

export const hideAlert = () => (dispatch, getState) => {
    const {
        messages: { ...message },
    } = getState();

    if (message.open) {
        dispatch({
            type: actionTypes.MESSAGE_HIDE,
        });
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throwError = (error: any) => (dispatch) => {
    Sentry.captureException(error);

    const errorCode = error.response?.data?.code || error.response.status;
    const errorMessage = error.response?.data?.error || error.message;

    // Log the error into the console.
    console.error(`${errorMessage}: ${error}`);

    // Set the message to display into the alert.
    const message: MessageProps = {
        open: true,
        message: errorMessage || error.message,
        alertSeverity: 'error',
        status: errorCode,
    };

    dispatch(showAlert(message));

    // If the error is because a required Authorization (possible expired token), sign-out the user.
    if (errorCode === 401) {
        dispatch(signOut());
    }
};

export const handleError = (errorProps: ErrorProps) => (dispatch) => {
    // Extract the error props.
    const { error, consoleMessage, alertMessage } = errorProps;

    Sentry.captureMessage(consoleMessage);

    // Log the error into the console.
    console.error(`${consoleMessage}: ${error}`);

    // Set the message to display into the alert.
    const message: MessageProps = {
        open: true,
        message: alertMessage || error.message,
        alertSeverity: 'error',
        status: error.response?.data?.code,
    };

    dispatch(showAlert(message));
};
