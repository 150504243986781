import { Box, useMediaQuery } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridValueGetterParams,
    gridNumberComparator,
} from '@mui/x-data-grid';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

import { Chart, ChartLegendItem } from './Chart';
import {
    TYPE_COMPLETED,
    TYPE_OTHER_INCOMPLETE,
    TYPE_REFUSED,
} from './constants';

const columns: GridColDef[] = [
    {
        field: 'caregiverName',
        headerName: 'Caregiver',
        width: 180,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.firstName} ${params.row.lastName.charAt(0)}.`,
    },
    {
        field: 'shift',
        headerName: 'Shift (Count)',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.shiftName} (${params.row.shiftCount})`,
    },
    {
        field: 'residentsDocumented',
        headerName: 'Residents',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        valueGetter: (params: GridValueGetterParams) =>
            Math.round(params.row.residentsDocumented),
    },
    {
        field: 'tasksDocumented',
        headerName: 'Documented',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        valueGetter: (params: GridValueGetterParams) =>
            Math.round(params.row.tasksDocumented),
    },
    {
        field: 'billableMinutesDocumented',
        headerName: 'Points',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        valueGetter: (params: GridValueGetterParams) =>
            Math.round(params.row.billableMinutesDocumented),
    },
    {
        field: 'performance',
        headerName: 'Performance',
        width: 300,
        headerAlign: 'center',
        renderCell: (params: GridValueGetterParams) => (
            <Chart
                completed={params.row.tasksCompleted}
                refused={params.row.tasksRefused}
                otherIncomplete={params.row.tasksOtherIncomplete}
                undocumented={0}
                showLegend={false}
                widthThreshold={0.15}
                showPercent
            />
        ),
        sortable: false,
    },
];

type Row = {
    firstName: string;
    lastName: string;
    shiftName: string;
    shiftCount: number;
    residentsDocumented: number;
    tasksDocumented: number;
    billableMinutesDocumented: number;
    tasksCompleted: number;
    tasksRefused: number;
    tasksOtherIncomplete: number;
};

type Props = {
    rows: Row[];
};

export const PerformanceTableModule = ({ rows = [] }: Props) => {
    const isMobile = useMediaQuery('(max-width:500px)');

    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                borderRadius: pxToRem(12),
                padding: pxToRem(16),
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                marginTop: pxToRem(16),
                gap: pxToRem(12),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        fontSize: pxToRem(20),
                        textAlign: 'left',
                        color: '#2C2D3B',
                        fontWeight: 700,
                    }}
                >
                    Caregiver Performance
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: pxToRem(12),
                    }}
                >
                    <ChartLegendItem type={TYPE_COMPLETED} text="Completed" />
                    <ChartLegendItem type={TYPE_REFUSED} text="Refused" />
                    <ChartLegendItem
                        type={TYPE_OTHER_INCOMPLETE}
                        text="Other Incomplete"
                    />
                </Box>
            </Box>
            <DataGrid
                rows={rows}
                getRowId={(row) =>
                    `${row.firstName} - ${row.lastName} - ${row.shiftName}`
                }
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                disableColumnMenu
                checkboxSelection={false}
                isRowSelectable={() => false}
            />
        </Box>
    );
};
