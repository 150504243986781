import {
    CheckOutlined as CheckIcon,
    StickyNote2Outlined as NoteIcon,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import {
    DashboardsFiltersForm,
    IncompletedOverdueTasksCaregiverData,
    IncompletedOverdueTasksTaskData,
} from 'types/dashboard';
import { Column } from 'types/table';

import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';
import { SORT_DIRECTIONS } from 'constants/table';

const RoomLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.app.orange.main,
        fontSize: { lg: pxToRem(14) },
    })
) as typeof Typography;

const NoteBox = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(8),
        color: theme.palette.green.main,
        backgroundColor: alpha(theme.palette.green.main, 0.08),
        borderRadius: theme.shape.borderRadiusSm,
        width: '100%',
    })
);

const TasksChipsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    })
);

export const OverdueTasksChip = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        px: pxToRem(8),
        py: pxToRem(4),
        display: 'flex',
        borderRadius: theme.shape.borderRadiusSm,
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.05),
        fontSize: { lg: pxToRem(10) },
        textTransform: 'uppercase',
        width: pxToRem(96),
    })
);

export const IncompletedTasksChip = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        px: pxToRem(8),
        py: pxToRem(4),
        display: 'flex',
        borderRadius: theme.shape.borderRadiusSm,
        color: theme.palette.orange.main,
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
        fontSize: { lg: pxToRem(10) },
        textTransform: 'uppercase',
        width: pxToRem(128),
    })
);

export const DoneTasksChip = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        py: pxToRem(4),
        display: 'flex',
        borderRadius: theme.shape.borderRadiusSm,
        color: theme.palette.green.main,
        backgroundColor: alpha(theme.palette.green.main, 0.05),
        fontSize: { lg: pxToRem(10) },
        textTransform: 'uppercase',
        width: pxToRem(80),
    })
);

export const TaskNameContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    })
);

export const TaskName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(14) },
    })
);

export const TaskNotesContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
    })
);

export const TaskNotesIcon = styled(NoteIcon)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
    })
);

export const TaskNotes = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(12) },
    })
);

export const IncompletedLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(4),
        color: theme.palette.error.main,
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'bold',
    })
);

export const CompletedLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'normal',
    })
);

export const CompletedIcon = styled(CheckIcon)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(16) },
    })
);

export const ActionButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        p: 0,
        color: theme.palette.app.green.main,
        backgroundColor: 'none',
        fontSize: { lg: pxToRem(14) },
        fontWeight: 'normal',
        textDecoration: 'underline',
    })
);

export const DASHBOARD_FIRST_BLOCK_COLUMN_WIDTH = 336;
export const DASHBOARD_FIRST_BLOCK_COLUMN_SPACE_WIDTH = 24;
export const DASHBOARD_SECOND_BLOCK_COLUMN_SPACE_WIDTH = 24;
export const DASHBOARD_MORE_TASKS_CHIP_WIDTH = 40;
export const DASHBOARD_RESIDENTS_CARD_HEIGHT = 352;
export const DASHBOARD_PERFORMANCE_CARD_HEIGHT = 616;
export const DASHBOARD_PERFORMANCE_MINI_CARDS_CONTAINER_HEIGHT = 48;
export const DASHBOARD_TASKS_CARD_HEIGHT = 336;
export const DASHBOARD_CARD_TITLE_HEIGHT = 88;

export const DASHBOARD_CATEGORIES = ['overdue', 'incompleted', 'done'];

export const DASHBOARD_FILTERS_FORM_INITIAL_VALUES: DashboardsFiltersForm = {
    caregiverId: null,
    residentId: null,
    shiftId: null,
    taskCategoryId: null,
    taskId: null,
    taskStatusId: null,
    zoneId: null,
};

// eslint-disable-next-line no-shadow
export const enum DATE_RANGE_ERROR_REASONS {
    invalidDate = 'invalidDate',
    invalidRange = 'invalidRange',
    shouldDisableDate = 'shouldDisableDate',
    disableFuture = 'disableFuture',
    disablePast = 'disablePast',
    minDate = 'minDate',
    maxDate = 'maxDate',
}

// eslint-disable-next-line no-shadow
export const enum DASHBOARD_TYPES {
    all = 'all',
    overdue = 'overdue',
    incompleted = 'incompleted',
}

export const DASHBOARD_NOTES_COLUMNS: Column[] = [
    {
        avatarType: AVATAR_TYPES.resident,
        id: 'resident',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        showAvatar: true,
        width: pxToRem(192),
    },
    {
        id: 'room',
        content: (room) => <RoomLabel component="span">{room}</RoomLabel>,
        label: '',
        width: pxToRem(96),
    },
    {
        id: 'caregiver',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(192),
    },
    {
        id: 'time',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(240),
    },
    {
        id: 'submissionCategory',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(224),
    },
    {
        id: 'note',
        content: (note) => <NoteBox component="span">{note}</NoteBox>,
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
    },
];

export const DASHBOARD_UNSCHEDULED_TASKS_COLUMNS: Column[] = [
    {
        avatarType: AVATAR_TYPES.resident,
        id: 'resident',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        showAvatar: true,
        width: pxToRem(192),
    },
    {
        id: 'room',
        content: (room) => <RoomLabel component="span">{room}</RoomLabel>,
        label: '',
        width: pxToRem(96),
    },
    {
        id: 'caregiver',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(192),
    },
    {
        id: 'time',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(240),
    },
    {
        id: 'companyCareplanName',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(224),
    },
];

export const DASHBOARD_ALL_TASKS_COLUMNS: Column[] = [
    {
        avatarType: AVATAR_TYPES.resident,
        id: 'resident',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        sortColumn: 'residentName',
        label: '',
        showAvatar: true,
    },
    {
        id: 'zone',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(112),
    },
    {
        id: 'room',
        content: (room) => <RoomLabel component="span">{room}</RoomLabel>,
        label: '',
        width: pxToRem(112),
    },
    {
        id: 'taskCategory',
        content: (taskCategory) => (
            <TasksChipsContainer>
                <OverdueTasksChip>
                    {taskCategory?.overdue?.total}{' '}
                    {taskCategory?.overdue?.label}
                </OverdueTasksChip>
                <IncompletedTasksChip>
                    {taskCategory?.incompleted?.total}{' '}
                    {taskCategory?.incompleted?.label}
                </IncompletedTasksChip>
                <DoneTasksChip>
                    {taskCategory?.done?.total} {taskCategory?.done?.label}
                </DoneTasksChip>
            </TasksChipsContainer>
        ),
        label: '',
        width: pxToRem(352),
    },
];

export const DASHBOARD_ALL_TASKS_SUB_COLUMNS: Column[] = [
    {
        id: 'taskType',
        content: (taskType) => {
            if (taskType.id === 'overdue') {
                return <OverdueTasksChip>{taskType.label}</OverdueTasksChip>;
            }

            if (taskType.id === 'incompleted') {
                return (
                    <IncompletedTasksChip>
                        {taskType?.label}
                    </IncompletedTasksChip>
                );
            }

            return <DoneTasksChip>{taskType?.label}</DoneTasksChip>;
        },
        label: '',
        width: pxToRem(80),
    },
    {
        id: 'task',
        content: (task) => {
            if (task.type === 'incompleted') {
                return (
                    <TaskNameContainer>
                        <IncompletedLabel variant="caption">
                            {task.status}
                        </IncompletedLabel>
                        <TaskName>{task.name}</TaskName>
                        {task.notes && (
                            <TaskNotesContainer>
                                <TaskNotesIcon />
                                <TaskNotes>{task.notes}</TaskNotes>
                            </TaskNotesContainer>
                        )}
                    </TaskNameContainer>
                );
            }

            if (task.type === 'done') {
                return (
                    <TaskNameContainer>
                        <TaskName>{task.name}</TaskName>
                        {task.notes && (
                            <TaskNotesContainer>
                                <TaskNotesIcon />
                                <TaskNotes>{task.notes}</TaskNotes>
                            </TaskNotesContainer>
                        )}
                    </TaskNameContainer>
                );
            }

            return task.name;
        },
        label: '',
    },
    {
        id: 'shift',
        label: '',
        width: pxToRem(48),
    },
    {
        id: 'caregiver',
        content: (caregiver) => {
            if (!caregiver.phoneNumber) {
                return caregiver.fullName;
            }

            return `${caregiver.fullName} (${formatPhoneNumberIntl(
                caregiver.phoneNumber
            )})`;
        },
        label: '',
        width: pxToRem(272),
    },
    {
        id: 'action',
        content: (action) => {
            if (action.taskType !== 'done') {
                return (
                    <ActionButton color="green">{action.label}</ActionButton>
                );
            }

            return (
                <CompletedLabel>
                    {action.label}
                    <CompletedIcon />
                </CompletedLabel>
            );
        },
        label: '',
        width: pxToRem(192),
    },
];

export const DASHBOARD_INCOMPLETED_TASKS_COLUMNS: Column[] = [
    {
        id: 'task',
        initialSortDirection: SORT_DIRECTIONS.asc,
        content: (task: IncompletedOverdueTasksTaskData) => task.name,
        isSortable: true,
        sortColumn: 'taskName',
        label: '',
    },
    {
        avatarType: AVATAR_TYPES.resident,
        id: 'resident',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        sortColumn: 'residentName',
        label: '',
        showAvatar: true,
        width: pxToRem(256),
    },
    {
        id: 'zone',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(112),
    },
    {
        id: 'caregiver',
        initialSortDirection: SORT_DIRECTIONS.asc,
        content: (caregiver: IncompletedOverdueTasksCaregiverData) =>
            `${caregiver.fullName} (${formatPhoneNumberIntl(
                caregiver.phoneNumber
            )})`,
        isSortable: true,
        sortColumn: 'caregiverName',
        label: '',
        width: pxToRem(272),
    },
    {
        id: 'taskStatus',
        initialSortDirection: SORT_DIRECTIONS.asc,
        content: (taskStatus) => (
            <IncompletedTasksChip>{taskStatus}</IncompletedTasksChip>
        ),
        isSortable: true,
        label: '',
        width: pxToRem(160),
    },
    {
        id: 'shiftName',
        initialSortDirection: SORT_DIRECTIONS.asc,
        isSortable: true,
        label: '',
        width: pxToRem(72),
    },
    {
        id: 'action',
        content: (action) => (
            <ActionButton color="green">{action.label}</ActionButton>
        ),
        label: '',
        width: pxToRem(192),
    },
];

export const DASHBOARD_OVERDUE_TASKS_COLUMNS: Column[] =
    DASHBOARD_INCOMPLETED_TASKS_COLUMNS.filter(
        (item) => item.id !== 'taskStatus'
    );
