import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import { UserResponse } from 'types/users';

import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';
import { readUser } from 'redux/actions/users';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const RowStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pb: pxToRem(8),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
    })
);

const ConceptStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        width: pxToRem(120),
    })
);

const DescriptionStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% - ${pxToRem(120)})`,
    })
);

type Props = {
    dictionary: LanguageStructure;
    loading: boolean;
    user: UserResponse;
    dispatchReadUser: (userId: number) => void;
};

const UserCompany = (props: Props) => {
    const {
        dictionary: { userCompany },
        loading,
        user,
        dispatchReadUser,
    } = props;
    const { company, branch } = user || {};

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Get the user id from the stored data.
            const storedSession: StoredSession = JSON.parse(
                localStorage.getItem(PROFILE) as string
            );
            const { userId } = storedSession?.sessionData || {};

            // Fetch the information from the user.
            dispatchReadUser(userId);
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <PageStructure>
            <RootStyle>
                <RowStyle>
                    <ConceptStyle variant="subtitle1">
                        {userCompany.companyName}:
                    </ConceptStyle>
                    <DescriptionStyle variant="body1">
                        {company}
                    </DescriptionStyle>
                </RowStyle>
                <RowStyle>
                    <ConceptStyle variant="subtitle1">
                        {userCompany.branchName}:
                    </ConceptStyle>
                    <DescriptionStyle variant="body1">
                        {branch}
                    </DescriptionStyle>
                </RowStyle>
            </RootStyle>
        </PageStructure>
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { loading, user } = users;

    return {
        dictionary,
        loading,
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUser: (userId: number) => dispatch(readUser(userId)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedCompany: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(UserCompany)
);

export default ConnectedCompany;
