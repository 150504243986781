import { FormControl, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    InputProps as InputPropsType,
    InputSize,
    InputVariant,
} from 'types/inputs';
import { LanguageStructure } from 'types/language';

import { pxToRem } from 'components/theme/typography';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    autoWidth: {
        width: 'auto',
    },
});

const FormControlStyle = styled(FormControl)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        mb: pxToRem(16),
    })
);

type Props = {
    autoFocus?: boolean;
    id?: string;
    className?: string;
    customErrorMessage?: string;
    dictionary: LanguageStructure;
    disabled?: boolean;
    endAdornment?: ReactNode;
    fullWidth?: boolean;
    focused?: boolean;
    label: string;
    rows?: number;
    maxRows?: number;
    multiline?: boolean;
    placeholder?: string;
    required?: boolean;
    size?: InputSize;
    startAdornment?: ReactNode;
    type?: string;
    value?: string;
    variant?: InputVariant;
    inputProps?: { [key: string]: string };
    onBlur?: (hasErrors?: boolean) => void;
    onChange: (value: string) => void;
    onKeyDown?: (event) => void;
    onKeyUpCapture?: (event) => void;
};

const UnconnectedTextField = (props: Props) => {
    const classes = useStyles();
    const {
        autoFocus = false,
        id,
        className,
        customErrorMessage,
        dictionary: { error },
        disabled,
        endAdornment,
        focused,
        fullWidth,
        label,
        rows = 1,
        maxRows = 1,
        multiline,
        placeholder,
        required,
        size = 'small',
        startAdornment,
        type = 'text',
        value,
        variant = 'outlined',
        inputProps = {},
        onBlur = () => null,
        onChange,
        onKeyDown,
        onKeyUpCapture,
    } = props;
    const [inputValue, setInputValue] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isDirty, setIsDirty] = useState<boolean>(false);
    // For TextField there are inputProps and InputProps in MUI which is really confusing
    const InputProps: InputPropsType = {};

    if (startAdornment) {
        InputProps.startAdornment = (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
        );
    }

    if (endAdornment) {
        InputProps.endAdornment = (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
        );
    }

    const validateMissingValue = (isInputDirty: boolean) => {
        if (!required) {
            return false;
        }

        return isEmpty(inputValue) && isInputDirty;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const handleBlur = () => {
        const isValueMissing = validateMissingValue(true);

        if (!isValueMissing) {
            onBlur();
            return;
        }

        setIsDirty(true);
        setErrorMessage(error.emptyValue);
        onBlur(true);
    };

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }

        const isValueMissing = validateMissingValue(isDirty);

        if (isValueMissing) {
            setErrorMessage(error.emptyValue);
        } else {
            setErrorMessage('');
        }
    }, [value]);

    return (
        <FormControlStyle
            className={fullWidth ? classes.fullWidth : classes.autoWidth}
        >
            <TextField
                id={id}
                type={type}
                className={className}
                placeholder={placeholder}
                label={label}
                value={inputValue}
                multiline={multiline}
                rows={rows}
                maxRows={maxRows}
                size={size}
                variant={variant}
                InputProps={InputProps}
                inputProps={{ 'data-hj-allow': true, ...inputProps }} // eslint-disable-line react/jsx-no-duplicate-props
                required={required}
                disabled={disabled}
                error={!!customErrorMessage || errorMessage !== ''}
                helperText={errorMessage || customErrorMessage}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={onKeyDown}
                onKeyUpCapture={onKeyUpCapture}
                focused={focused}
                autoFocus={autoFocus}
            />
        </FormControlStyle>
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line import/prefer-default-export
export const CustomTextField = connect(mapStateToProps)(UnconnectedTextField);
