import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useResidentQuery } from 'api/queries/residents';
import { CustomMobileDialog } from 'components/Custom/CustomMobileDialog';
import Loading from 'components/Shared/Loading';
import { getRandomBooleanWithProbability } from 'lib/common';

import NurseCallsCheckbox from './NurseCallsCheckbox';
import NurseCallsCheckboxPrompt from './NurseCallsCheckboxPrompt';

// the user_ids of caregivers who were abusing nurse calls and we don't want them to be prompted
const BANNED_NURSE_CALL_USERS = [435, 432];
const DISABLED_BRANCHES = [2];

type Props = {
    isOpen: boolean;
    residentId: number;
    userId: number;
    branchId: number;
    selectedDate: string;
    onClose: () => void;
};

const NurseCallsDialog = (props: Props) => {
    const { branchId, isOpen, onClose, residentId, selectedDate, userId } =
        props;

    const [showPrompt, setShowPrompt] = useState(true);
    const [showDialog, setShowDialog] = useState(false);

    const isNotAllowedToSeeNurseCallsUser = (currentUserId) =>
        BANNED_NURSE_CALL_USERS.includes(currentUserId);

    const isNotAllowedToSeeNurseCallsBranch = (currentBranchId: number) =>
        DISABLED_BRANCHES.includes(currentBranchId);

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(residentId);

    useEffect(() => {
        if (residentId) {
            // ASCENT HACK: Hardcode jessie.lucci user to always see the nurse call flow
            setShowDialog(
                !isNotAllowedToSeeNurseCallsUser(userId) &&
                    !isNotAllowedToSeeNurseCallsBranch(branchId) &&
                    (getRandomBooleanWithProbability() || userId === 178)
            );
        }
    }, [residentId]);

    if (!showDialog) {
        return null;
    }

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const residentName = `${residentData?.firstName} ${residentData?.lastName}`;

    const promptTitle = `Great job completing all ${residentName}'s tasks!`;
    const checkboxTitle = `Select ${residentName}'s call light requests this shift`;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={showPrompt ? promptTitle : checkboxTitle}
            onClose={onClose}
            width="100%"
            content={
                showPrompt ? (
                    <NurseCallsCheckboxPrompt
                        residentName={residentName}
                        onAccept={() => setShowPrompt(false)}
                    />
                ) : (
                    <NurseCallsCheckbox
                        residentId={residentId}
                        onClose={onClose}
                        selectedDate={selectedDate}
                    />
                )
            }
        />
    );
};

const mapStateToProps = ({ session }) => {
    const {
        sessionData: { userId, branchId },
    } = session;

    return {
        userId,
        branchId,
    };
};

export const ConnectedNurseCallsDialog =
    connect(mapStateToProps)(NurseCallsDialog);

export default ConnectedNurseCallsDialog;
