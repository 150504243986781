import emailjs from '@emailjs/browser';
import { Info } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { encode as base64Encode } from 'base-64';
import { cloneDeep, isEmpty, isNil, omit } from 'lodash';
import moment from 'moment';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';

import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import {
    RoleResponse,
    UserCreateParams,
    UserFormData,
    UserFormErrors,
    UserResponse,
    UserUpdateParams,
} from 'types/users';

import {
    CustomAutocomplete,
    CustomAvatar,
    CustomDatePicker,
    CustomDialog,
    CustomTextField,
} from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import {
    EMAIL_JS_PASSWORD_TEMPLATE_ID,
    EMAIL_JS_PUBLIC_KEY,
    EMAIL_JS_SERVICE_ID,
} from 'constants/app';
import { PROFILE } from 'constants/localStorage';
import {
    ONLY_LETTERS_AND_SPACES_REGEX,
    ONLY_START_WITH_LETTER_REGEX,
} from 'constants/regex';
import { AVATAR_TYPES, NOTIFICATION_TYPE } from 'constants/shared';
import { generatePassword } from 'lib/common';
import { createUser, updateUser, verifyEmail } from 'redux/actions/users';

const AVATAR_DETAILS_SIZE = 100;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    phone: {
        '& .PhoneInputInput': {
            padding: `${pxToRem(8.5)} ${pxToRem(14)}`,
            color: theme.palette.common.black,
            height: pxToRem(42),
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.border}`,
            fontSize: pxToRem(18),
            outline: 'none',
            '&:focus': {
                marginLeft: '-1px',
                border: `2px solid ${theme.palette.primary.main}`,
            },
        },
    },
    error: {
        '& .PhoneInputInput': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            '&:focus': {
                borderColor: theme.palette.error.main,
            },
            '&::placeholder': {
                color: theme.palette.error.main,
            },
        },
    },
}));

const AvatarRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    })
);

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    })
);

const InfoWrapper = styled(Row)(({ theme }) =>
    theme.unstable_sx({
        justifyContent: 'flex-start',
    })
);

const InfoIcon = styled(Info)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.info.main,
    })
);

const UserSingleDataWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '49%',
    })
);

const PhoneNumberWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        mb: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const PhoneNumberErrorMessage = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mx: pxToRem(14),
        mt: pxToRem(4),
        mb: 0,
        color: theme.palette.error.main,
        fontSize: { xs: pxToRem(16), lg: pxToRem(16) },
    })
);

const FooterWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    })
);

const TernaryButtonWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(32),
        display: 'flex',
    })
);

const MainButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        '& >:not(:first-of-type)': {
            ml: pxToRem(8),
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    emailExists?: boolean;
    id: string;
    isOpen: boolean;
    rolesList: RoleResponse[];
    user?: UserResponse;
    userActionSuccess?: boolean;
    dispatchCreateUser: (jsonParams: UserCreateParams) => void;
    dispatchUpdateUser: (userId: number, jsonParams: UserUpdateParams) => void;
    dispatchVerifyEmail: (email: string) => void;
    onReset?: (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => void;
    onSave: (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => void;
    onClose: () => void;
};

const UserDetailDialog = (props: Props) => {
    const classes = useStyles();
    const formRef = useRef() as MutableRefObject<HTMLFormElement>;
    const { register, setValue } = useForm();
    const {
        dictionary: {
            error: errorDictionary,
            shared,
            signupForm,
            userManagementUsersList,
            userProfile,
        },
        emailExists,
        id,
        isOpen,
        rolesList,
        user,
        userActionSuccess,
        dispatchCreateUser,
        dispatchUpdateUser,
        dispatchVerifyEmail,
        onReset = () => null,
        onSave,
        onClose,
    } = props;
    const [form, setForm] = useState<UserFormData>({} as UserFormData);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [errors, setErrors] = useState<UserFormErrors>({});
    const [roleOptions, setRoleOptions] = useState<DropdownStructure[]>([]);
    const isCreate = !user;
    const title = isCreate
        ? userManagementUsersList.createUser
        : userManagementUsersList.editUser;
    const saveButtonLabel = isCreate ? shared.create : shared.saveChanges;

    // Get the role id from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { branchId, company, companyId, roleId } =
        storedSession?.sessionData || {};

    const validateForm =
        (formValues: UserFormData, formErrors: UserFormErrors) =>
        (hasErrors?: boolean) => {
            const validRequired = !!(
                formValues.roleId &&
                formValues.email &&
                formValues.firstName &&
                formValues.lastName &&
                formValues.phoneNumber &&
                formValues.dateOfBirth
            );
            const validForm: boolean =
                !hasErrors &&
                isEmpty(formErrors) &&
                validRequired &&
                (isNil(emailExists) || !emailExists);
            setIsFormValid(validForm);
        };

    const fieldValidation = (field: string, fieldValue: string) => {
        // DON'T REMOVE THESE FIRST TWO LINES.
        // Initialize the last index for each time they need to be validated. Otherwise the validation could fail.
        ONLY_LETTERS_AND_SPACES_REGEX.lastIndex = 0;
        ONLY_START_WITH_LETTER_REGEX.lastIndex = 0;

        // Validate the field.
        const onlyLettersCheck = ONLY_LETTERS_AND_SPACES_REGEX.test(fieldValue);
        const startWithLetterCheck =
            ONLY_START_WITH_LETTER_REGEX.test(fieldValue);

        // Verify the field type.
        const isPhoneNumber = field === 'phoneNumber';
        const isText = !isPhoneNumber;

        if (isText && !onlyLettersCheck) {
            setErrors({
                ...errors,
                [field]: shared.onlyLettersAndSpaces,
            });
            return;
        }

        if (isText && !startWithLetterCheck) {
            setErrors({
                ...errors,
                [field]: shared.mustStartWithALetter,
            });
            return;
        }

        if (isPhoneNumber) {
            handlePhoneNumberValidate(fieldValue);
            return;
        }

        const errorsClone = cloneDeep(errors);
        delete errorsClone[field];
        setErrors(errorsClone);
    };

    const triggerMessage = (notificationType: NOTIFICATION_TYPE) => {
        if (notificationType === NOTIFICATION_TYPE.success) {
            // Define the notification message.
            const notificationMessage = isCreate
                ? userManagementUsersList.userCreationSuccessMessage
                : userManagementUsersList.userUpdateSuccessMessage;

            // Trigger the onSave method with a success message.
            onSave(
                shared.congratulations,
                notificationMessage,
                notificationType
            );
        } else {
            // Define the notification title and message.
            const notificationTitle = isCreate
                ? userManagementUsersList.userCreationErrorTitle
                : userManagementUsersList.userUpdateErrorTitle;
            const notificationMessage = isCreate
                ? userManagementUsersList.userCreationErrorMessage
                : userManagementUsersList.userUpdateErrorMessage;

            // Trigger the onSave method with an error message.
            onSave(
                notificationTitle,
                notificationMessage,
                NOTIFICATION_TYPE.error
            );
        }
    };

    const handleValidateUniqueEmail = () => {
        if (form.email) {
            dispatchVerifyEmail(form.email);
        }
    };

    const handlePhoneNumberValidate = (newValue: string) => {
        if (!newValue) {
            setErrors({
                ...errors,
                phoneNumber: errorDictionary.emptyValue,
            });
            return errorDictionary.emptyValue;
        }

        if (!isValidPhoneNumber(newValue)) {
            setErrors({
                ...errors,
                phoneNumber: signupForm.invalidPhoneNumber,
            });
            return signupForm.invalidPhoneNumber;
        }

        const errorsClone = cloneDeep(errors);
        delete errorsClone.phoneNumber;
        setErrors(errorsClone);

        return undefined;
    };

    const handlePhoneNumberBlur = () => {
        handlePhoneNumberValidate(form.phoneNumber);
    };

    const handleFormFieldChange =
        (field: string, validate?: boolean) => (newValue: string) => {
            if (validate) {
                fieldValidation(field, newValue as string);
            }

            setForm({
                ...form,
                [field]: newValue,
            });
        };

    const handleDropdownChange =
        (field: string) => (optionValue: DropdownValue) => {
            setForm({
                ...form,
                [field]: optionValue as number,
            });
        };

    const handleDatePickerChange = (newDate: string, dateHasError: boolean) => {
        const newformValues: UserFormData = {
            ...form,
            dateOfBirth: newDate,
        };

        setForm(newformValues);

        if (dateHasError) {
            const newFormErrors: UserFormErrors = {
                ...errors,
                dateOfBirth: signupForm.invalidDateOfBirth,
            };

            setErrors(newFormErrors);
            validateForm(newformValues, newFormErrors)(true);
        } else {
            const errorsClone = cloneDeep(errors);
            delete errorsClone.dateOfBirth;
            setErrors(errorsClone);
            validateForm(newformValues, errorsClone)();
        }
    };

    const handleResetPassword = (userData?: UserResponse) => {
        if (!userData) {
            return;
        }

        const { email, firstName, lastName, userId } = userData;

        const userFullName = `${firstName} ${lastName}`;
        const passwordLinkParams = {
            email,
            timestamp: moment(),
        };
        const encodedLink = base64Encode(JSON.stringify(passwordLinkParams));

        // Add the Link Code to the Form that will be submitted through emailjs and update the additional params.
        setValue('action', userManagementUsersList.passwordResetAction);
        setValue('link_code', encodedLink);
        setValue('message', userManagementUsersList.passwordResetMessage);

        // Send the Password Reset email to the user.
        emailjs
            .sendForm(
                EMAIL_JS_SERVICE_ID,
                EMAIL_JS_PASSWORD_TEMPLATE_ID,
                formRef.current,
                EMAIL_JS_PUBLIC_KEY
            )
            .then(
                (_result) => {
                    // Define the notification message.
                    const notificationMessage =
                        userManagementUsersList.passwordResetSuccessMessage
                            .replace('{userFullName}', userFullName)
                            .replace('{userEmail}', email);

                    // Trigger the onSave method with a success message.
                    onReset(
                        shared.congratulations,
                        notificationMessage,
                        NOTIFICATION_TYPE.success
                    );
                },
                (_error) => {
                    // Define the notification message.
                    const notificationMessage =
                        userManagementUsersList.passwordResetErrorMessage
                            .replace('{userFullName}', userFullName)
                            .replace('{userEmail}', email);

                    // Trigger the onSave method with a success message.
                    onReset(
                        userManagementUsersList.passwordResetErrorTitle,
                        notificationMessage,
                        NOTIFICATION_TYPE.error
                    );
                }
            );
    };

    const handleSaveClick =
        (
            isCreateDialog: boolean,
            formValues: UserFormData,
            userData?: UserResponse
        ) =>
        () => {
            if (!isFormValid || (!isCreateDialog && !userData)) {
                return;
            }

            const { email } = formValues;

            if (isCreateDialog) {
                const passwordLinkParams = {
                    email,
                    timestamp: moment(),
                };
                const encodedLink = base64Encode(
                    JSON.stringify(passwordLinkParams)
                );

                // Add the Link Code to the Form that will be submitted through emailjs and update the additional params.
                setValue('action', userManagementUsersList.passwordSetAction);
                setValue('link_code', encodedLink);
                setValue('message', userManagementUsersList.passwordSetMessage);

                // Send the Password Set email to the new user.
                emailjs
                    .sendForm(
                        EMAIL_JS_SERVICE_ID,
                        EMAIL_JS_PASSWORD_TEMPLATE_ID,
                        formRef.current,
                        EMAIL_JS_PUBLIC_KEY
                    )
                    .then(
                        (_result) => {
                            const jsonParams: UserCreateParams = omit(
                                formValues,
                                ['confirmPassword']
                            );
                            jsonParams.username = jsonParams.email;
                            jsonParams.password = generatePassword();
                            dispatchCreateUser(jsonParams);
                        },
                        (_error) => {
                            triggerMessage(NOTIFICATION_TYPE.error);
                        }
                    );
            } else {
                const userId = userData?.userId || 0;
                const jsonParams: UserUpdateParams = omit(formValues, [
                    'username',
                    'password',
                    'confirmPassword',
                ]);
                dispatchUpdateUser(userId, jsonParams);
            }
        };

    useEffect(() => {
        if (!isNil(userActionSuccess) && userActionSuccess) {
            triggerMessage(NOTIFICATION_TYPE.success);
        } else if (!isNil(userActionSuccess) && !userActionSuccess) {
            triggerMessage(NOTIFICATION_TYPE.error);
        }
    }, [form, isCreate, userActionSuccess]);

    useEffect(() => {
        if (rolesList && !roleOptions.length) {
            const newRoleOptions: DropdownStructure[] = rolesList.map(
                (role) => ({
                    label: role.roleName,
                    value: role.roleId,
                })
            );

            setRoleOptions(newRoleOptions);
        }
    }, [rolesList]);

    useEffect(() => {
        // Show en error for the email field if the email already exists for a valid user.
        if (emailExists && (!user || form.email !== user?.email)) {
            const newErrors: UserFormErrors = {
                ...errors,
                email: userManagementUsersList.emailExists,
            };
            setErrors(newErrors);

            validateForm(form, newErrors)(true);
        } else {
            const errorsClone = cloneDeep(errors);
            delete errorsClone.email;
            setErrors(errorsClone);
            validateForm(form, errorsClone)();
        }
    }, [emailExists]);

    useEffect(() => {
        if (!isOpen) {
            const initialFormValues: UserFormData = {
                roleId: roleId + 1,
                companyId,
                branchId,
                username: '',
                password: '',
                confirmPassword: '',
                email: '',
                phoneNumber: '',
                firstName: '',
                lastName: '',
                dateOfBirth: moment().format(),
                picture: '',
            };

            setErrors({});
            setForm(initialFormValues);
        } else if (isOpen && user) {
            const newFormValues: UserFormData = {
                roleId: user.roleId,
                companyId: user.companyId,
                branchId: user.branchId,
                username: '',
                password: '',
                confirmPassword: '',
                email: user.email,
                phoneNumber: user.phoneNumber,
                firstName: user.firstName,
                lastName: user.lastName,
                dateOfBirth: user.dateOfBirth || moment().format(),
                picture: user.picture,
            };

            setForm(newFormValues);
            validateForm(newFormValues, errors)();
        }
    }, [isOpen, user]);

    if (!isOpen) {
        return null;
    }

    return (
        <CustomDialog
            id={id}
            closeable
            open={isOpen}
            title={title}
            width={pxToRem(604)}
            showFooterDivider
            content={
                <>
                    <AvatarRow>
                        <CustomAvatar
                            avatarType={AVATAR_TYPES.user}
                            firstName={form.firstName}
                            lastName={form.lastName}
                            photo={form.picture}
                            size={AVATAR_DETAILS_SIZE}
                        />
                    </AvatarRow>
                    {isCreate && (
                        <InfoWrapper>
                            <InfoIcon />
                            <Typography variant="body1">
                                {userManagementUsersList.userNameHint}
                            </Typography>
                        </InfoWrapper>
                    )}
                    <Row>
                        <UserSingleDataWrapper>
                            <CustomAutocomplete
                                label={userManagementUsersList.role}
                                value={form.roleId}
                                options={roleOptions}
                                fullWidth
                                required
                                onChange={handleDropdownChange('roleId')}
                            />
                        </UserSingleDataWrapper>
                        <UserSingleDataWrapper>
                            <CustomTextField
                                id="email"
                                label={userProfile.email}
                                value={form.email}
                                customErrorMessage={errors.email}
                                fullWidth
                                required
                                onBlur={handleValidateUniqueEmail}
                                onChange={handleFormFieldChange('email')}
                            />
                        </UserSingleDataWrapper>
                    </Row>
                    <Row>
                        <UserSingleDataWrapper>
                            <CustomTextField
                                id="firstName"
                                label={userProfile.firstName}
                                value={form.firstName}
                                customErrorMessage={errors.firstName}
                                fullWidth
                                required
                                onBlur={validateForm(form, errors)}
                                onChange={handleFormFieldChange(
                                    'firstName',
                                    true
                                )}
                            />
                        </UserSingleDataWrapper>
                        <UserSingleDataWrapper>
                            <CustomTextField
                                id="lastName"
                                label={userProfile.lastName}
                                value={form.lastName}
                                customErrorMessage={errors.lastName}
                                fullWidth
                                required
                                onBlur={validateForm(form, errors)}
                                onChange={handleFormFieldChange(
                                    'lastName',
                                    true
                                )}
                            />
                        </UserSingleDataWrapper>
                    </Row>
                    <Row>
                        <UserSingleDataWrapper>
                            <PhoneNumberWrapper>
                                <PhoneInput
                                    defaultCountry="US"
                                    value={form.phoneNumber}
                                    placeholder={userProfile.phoneNumber}
                                    className={`${classes.phone} ${
                                        errors.phoneNumber ? classes.error : ''
                                    }`}
                                    onBlur={handlePhoneNumberBlur}
                                    onChange={handleFormFieldChange(
                                        'phoneNumber',
                                        true
                                    )}
                                />
                                {errors.phoneNumber && (
                                    <PhoneNumberErrorMessage>
                                        {errors.phoneNumber}
                                    </PhoneNumberErrorMessage>
                                )}
                            </PhoneNumberWrapper>
                        </UserSingleDataWrapper>
                        <UserSingleDataWrapper>
                            <CustomDatePicker
                                date={form.dateOfBirth}
                                dateLabel={userProfile.dateOfBirth}
                                marginLeft={0}
                                marginRight={0}
                                marginBottom={0}
                                disableFuture
                                fullWidth
                                onBlur={validateForm(form, errors)}
                                onChange={handleDatePickerChange}
                            />
                        </UserSingleDataWrapper>
                    </Row>
                    <form ref={formRef} style={{ display: 'none' }}>
                        <input value={company} {...register('company_name')} />
                        <input
                            value={`${shared.hello} ${form.firstName} ${form.lastName}`}
                            {...register('greeting')}
                        />
                        <input value={form.email} {...register('to_email')} />
                        <input value="" {...register('action')} />
                        <input value="" {...register('message')} />
                        <input value="" {...register('link_code')} />
                        <input type="submit" />
                    </form>
                </>
            }
            actions={
                <FooterWrapper>
                    {!isCreate && (
                        <TernaryButtonWrapper>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleResetPassword(user)}
                            >
                                {userManagementUsersList.resetPassword}
                            </Button>
                        </TernaryButtonWrapper>
                    )}
                    <MainButtonsWrapper>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            {shared.cancel}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!isFormValid}
                            onClick={handleSaveClick(isCreate, form, user)}
                        >
                            {saveButtonLabel}
                        </Button>
                    </MainButtonsWrapper>
                </FooterWrapper>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { actionSuccess, emailExists, rolesList, user } = users;

    return {
        dictionary,
        emailExists,
        rolesList,
        user,
        userActionSuccess: actionSuccess,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateUser: (jsonParams: UserCreateParams) =>
        dispatch(createUser(jsonParams, true)),
    dispatchUpdateUser: (userId: number, jsonParams: UserUpdateParams) =>
        dispatch(updateUser(userId, jsonParams, true)),
    dispatchVerifyEmail: (email: string) => dispatch(verifyEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailDialog);
