export default function TextField(theme) {
    const { palette } = theme;
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: palette.common.white,
                    },
                    '& .MuiInputLabel-root': {
                        '& .MuiInputLabel-asterisk.MuiFormLabel-asterisk': {
                            color: palette.error.main,
                        },
                    },
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        transform: 'translate(14px, 9px) scale(1)',
                        '&.MuiInputLabel-shrink': {
                            transform: 'translate(14px, -9px) scale(0.75)',
                        },
                    },
                },
            },
        },
    };
}
