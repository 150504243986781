import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';

import {
    DailyTasksShiftDetail,
    ResidentTasks,
    TaskToUpdate,
    onConfirmMultipleTasksListInput,
} from 'types/dailyTasks';
import { ReduxStore } from 'types/redux';

import {
    useRewardsDetailsQuery,
    useRewardsHistoryQuery,
    useRewardsSummaryQuery,
} from 'api/queries/caregiverRewards';
import { useDailyTasks } from 'api/queries/tasks/dailyTasks';
import notificationSound from 'assets/notification-sound.mp3';
import EditTaskStatusDialog from 'components/Shared/EditTaskStatusDialog';
import NurseCallsDialog from 'components/Shared/NurseCallsDialog';
import { AddTaskNotesDialog } from 'components/Shared/Task/AddTaskNotesDialog';
import { TaskNotesViewDialog } from 'components/Shared/Task/TaskNotesViewDialog';
import { useAppDispatch } from 'constants/redux';
import { DIALOG_TYPE } from 'constants/shared';
import { updateDailyTasks } from 'redux/actions/dailyTasks';

import { MultiSelectProvider } from './MultiSelectProvider';
import { ResidentRow } from './ResidentRow';

type Props = {
    branchId: number;
    date: string;
    residentTasksList: ResidentTasks[];
    setSelectedResidentId: (id?: number) => void;
};

export const ResidentRows = (props: Props) => {
    const { residentTasksList, branchId, date, setSelectedResidentId } = props;

    const [viewTaskIdNotes, setViewTaskIdNotes] = useState<number | null>(null);

    const [showTaskIdAddNotesDialog, setShowTaskIdAddNotesDialog] = useState<
        number | null
    >(null);
    const [taskIdNotes, setTaskIdNotes] = useState<{ [key: number]: string }>(
        {}
    );

    const [showTaskIdRejectDialog, setShowTaskIdRejectDialog] = useState<
        number | null
    >(null);

    const [isNurseCallsDialogOpen, setIsNurseCallsDialogOpen] =
        useState<boolean>(false);

    const [nurseCallsResidentId, setNurseCallsResidentId] = useState<
        number | null
    >(null);

    const [taskIdCompletionOptions, setTaskIdCompletionOptions] = useState<{
        [key: string]: number;
    }>({});

    const [playCoinSound] = useSound(notificationSound);

    const dispatch = useAppDispatch();
    const { userId: caregiverId } = useSelector(
        (state: ReduxStore) => state.session.sessionData
    );

    const taskIdToTask: Record<number, DailyTasksShiftDetail> = useMemo(
        () =>
            residentTasksList.reduce((acc, { tasks }) => {
                tasks.forEach((task) => {
                    acc[task.dailyTaskRecordId] = task;
                });

                return acc;
            }, {}),
        [residentTasksList]
    );

    const onToggleTaskNotes = (taskId = null) => {
        setViewTaskIdNotes(taskId);
    };

    const onToggleEditAddTaskNotes = (taskId = null) => {
        setShowTaskIdAddNotesDialog(taskId);
    };

    const onSubmitEditAddTaskNotes = (taskNotes) => {
        // To make TS happy that it isn't null
        if (!showTaskIdAddNotesDialog) {
            return;
        }

        const newTaskNotes = {
            ...taskIdNotes,
            [showTaskIdAddNotesDialog]: taskNotes,
        };

        setTaskIdNotes(newTaskNotes);
        setShowTaskIdAddNotesDialog(null);
    };

    const onToggleRejectDialog = (taskId = null) => {
        setShowTaskIdRejectDialog(taskId);
    };

    const toggleNurseCallsDialog = () => {
        setIsNurseCallsDialogOpen((prevState) => !prevState);
    };

    const onCompletionOptionsChange = (taskId, completionValue) => {
        setTaskIdCompletionOptions({
            ...taskIdCompletionOptions,
            [taskId]: completionValue,
        });
    };

    const { refetch: dailyTasksRefetch } = useDailyTasks({
        branchId: Number(branchId),
        date,
    });

    const { refetch: rewardsDetailsRefetch } =
        useRewardsDetailsQuery(caregiverId);
    const { refetch: rewardsSummaryRefetch } =
        useRewardsSummaryQuery(caregiverId);
    const { refetch: rewardsHistoryRefetch } =
        useRewardsHistoryQuery(caregiverId);

    const onConfirmTask = async (
        taskId: number,
        taskStatusId: number,
        notes = ''
    ) => {
        const tasksToUpdate: TaskToUpdate[] = [];

        tasksToUpdate.push({
            taskId,
            taskStatusId,
            caregiverNotes: notes || taskIdNotes[taskId],
        });

        await dispatch(updateDailyTasks({ tasks: tasksToUpdate }));

        playCoinSound();

        dailyTasksRefetch();
        rewardsDetailsRefetch();
        rewardsSummaryRefetch();
        rewardsHistoryRefetch();
    };

    const onConfirmMultipleTasks = async (
        onConfirmMultipleTasksList: onConfirmMultipleTasksListInput,
        notes = ''
    ) => {
        const tasksToUpdate: TaskToUpdate[] = [];

        onConfirmMultipleTasksList.forEach(({ taskId, taskStatusId }) => {
            let notesToUse = notes;
            if (taskIdNotes[taskId]) {
                notesToUse = taskIdNotes[taskId];
            }

            tasksToUpdate.push({
                taskId,
                taskStatusId,
                caregiverNotes: notesToUse,
            });
        });

        await dispatch(updateDailyTasks({ tasks: tasksToUpdate }));
        playCoinSound();

        dailyTasksRefetch();
        rewardsDetailsRefetch();
        rewardsSummaryRefetch();
        rewardsHistoryRefetch();
    };

    return (
        <>
            {residentTasksList.length > 0 && (
                <MultiSelectProvider
                    setSelectedResidentIdForAddedTaskDialog={
                        setSelectedResidentId
                    }
                    residentTasksList={residentTasksList}
                    render={(
                        openedResidentId,
                        selectedResidentId,
                        selectedTaskIds,
                        onResidentToggle,
                        onResidentSelect,
                        onResidentTaskSelect
                    ) => {
                        const confirmSelectedTasks = (
                            taskStatusId: number,
                            notes = ''
                        ) => {
                            const tasks = Array.from(selectedTaskIds).map(
                                (taskId) => ({
                                    taskId,
                                    taskStatusId,
                                })
                            );
                            onConfirmMultipleTasks(tasks, notes);
                        };

                        const onRejectDialogSubmit = (
                            taskStatusId: number,
                            notes = ''
                        ) => {
                            if (showTaskIdRejectDialog === -1) {
                                confirmSelectedTasks(taskStatusId, notes);
                            } else if (showTaskIdRejectDialog) {
                                onConfirmTask(
                                    showTaskIdRejectDialog,
                                    taskStatusId,
                                    notes
                                );
                            }
                            setShowTaskIdRejectDialog(null);

                            const residentTasksCount = residentTasksList.find(
                                (resident) =>
                                    resident.id === nurseCallsResidentId
                            )?.tasks.length;
                            if (residentTasksCount === 1) {
                                setIsNurseCallsDialogOpen(true);
                            } else if (
                                residentTasksCount === selectedTaskIds.size
                            ) {
                                setIsNurseCallsDialogOpen(true);
                            }
                        };

                        return (
                            <>
                                {residentTasksList.map((residentTasks) => (
                                    <ResidentRow
                                        key={residentTasks.id}
                                        isSelected={
                                            selectedResidentId ===
                                            residentTasks.id
                                        }
                                        isOpen={
                                            openedResidentId ===
                                            residentTasks.id
                                        }
                                        residentTasks={residentTasks}
                                        selectedTaskIds={selectedTaskIds}
                                        onResidentToggle={onResidentToggle}
                                        onResidentSelectToggle={
                                            onResidentSelect
                                        }
                                        onResidentTaskSelectToggle={
                                            onResidentTaskSelect
                                        }
                                        onToggleTaskNotes={onToggleTaskNotes}
                                        onToggleEditAddTaskNotes={
                                            onToggleEditAddTaskNotes
                                        }
                                        onToggleRejectDialog={(taskId) => {
                                            setNurseCallsResidentId(
                                                residentTasks.id
                                            );
                                            setShowTaskIdRejectDialog(taskId);
                                        }}
                                        onCompletionOptionsChange={
                                            onCompletionOptionsChange
                                        }
                                        taskIdCompletionOptions={
                                            taskIdCompletionOptions
                                        }
                                        taskIdNotes={taskIdNotes}
                                        onConfirmTask={async (
                                            taskId,
                                            taskStatusId
                                        ) => {
                                            setNurseCallsResidentId(
                                                residentTasks.id
                                            );
                                            onConfirmTask(taskId, taskStatusId);
                                            if (
                                                residentTasks.tasks.length === 1
                                            ) {
                                                setIsNurseCallsDialogOpen(true);
                                            }
                                        }}
                                        confirmSelectedTasks={async (
                                            taskStatusId
                                        ) => {
                                            setNurseCallsResidentId(
                                                residentTasks.id
                                            );
                                            confirmSelectedTasks(taskStatusId);
                                            if (
                                                residentTasks.tasks.length ===
                                                selectedTaskIds.size
                                            ) {
                                                setIsNurseCallsDialogOpen(true);
                                            }
                                        }}
                                        date={date}
                                    />
                                ))}
                                <TaskNotesViewDialog
                                    isOpen={viewTaskIdNotes !== null}
                                    taskNote={
                                        viewTaskIdNotes
                                            ? taskIdToTask[viewTaskIdNotes]
                                                  .taskNotes
                                            : ''
                                    }
                                    onClose={() => onToggleTaskNotes()}
                                />
                                <AddTaskNotesDialog
                                    isOpen={showTaskIdAddNotesDialog !== null}
                                    onClose={() => onToggleEditAddTaskNotes()}
                                    onSubmit={onSubmitEditAddTaskNotes}
                                    taskNote={
                                        showTaskIdAddNotesDialog
                                            ? taskIdNotes[
                                                  showTaskIdAddNotesDialog
                                              ]
                                            : ''
                                    }
                                />
                                <EditTaskStatusDialog
                                    isOpen={showTaskIdRejectDialog !== null}
                                    onClose={onToggleRejectDialog}
                                    onSubmit={(taskStatusId, notes) => {
                                        onRejectDialogSubmit(
                                            taskStatusId,
                                            notes
                                        );
                                    }}
                                    taskNotes={
                                        showTaskIdRejectDialog
                                            ? taskIdNotes[
                                                  showTaskIdRejectDialog
                                              ]
                                            : undefined
                                    }
                                    dialogType={DIALOG_TYPE.reject}
                                />
                            </>
                        );
                    }}
                />
            )}
            {nurseCallsResidentId && (
                <NurseCallsDialog
                    isOpen={isNurseCallsDialogOpen}
                    onClose={() => {
                        toggleNurseCallsDialog();
                        setNurseCallsResidentId(null);
                    }}
                    residentId={nurseCallsResidentId}
                    selectedDate={date}
                />
            )}
        </>
    );
};
