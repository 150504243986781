import { Box, Typography } from '@mui/material';
import React from 'react';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';

type Props = {
    caregiver: CaregiverData;
};

const AVATAR_SIZE = 20;

export const AvatarWithName = ({ caregiver }: Props) => {
    const { firstName, lastName, photo } = caregiver;
    return (
        <Box display="flex" gap={pxToRem(4)} alignItems="baseline">
            <Box>
                <CustomAvatar
                    avatarType={AVATAR_TYPES.caregiver}
                    firstName={firstName}
                    lastName={lastName}
                    photo={photo || ''}
                    size={AVATAR_SIZE}
                />
            </Box>
            <Typography
                fontSize={{
                    xs: pxToRem(12),
                    md: pxToRem(14),
                }}
                lineHeight={1.7}
                minWidth="max-content"
            >
                {firstName} {lastName}
            </Typography>
        </Box>
    );
};
