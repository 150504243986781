import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import { CounterCardType } from 'types/dashboard';

import { pxToRem } from 'components/theme/typography';
import { CardStyle } from 'pages/Dashboards/components/SharedComponents';

const IconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        p: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadiusSm,
        width: pxToRem(48),
        height: pxToRem(48),
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    })
);

const Value = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        lineHeight: { lg: pxToRem(32) },
    })
);

const Description = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[600],
        fontSize: { lg: pxToRem(14) },
        lineHeight: { lg: pxToRem(14) },
        fontWeight: 400,
    })
);

type Props = {
    type: CounterCardType;
    label: string;
    value: number;
    icon: ReactNode;
    iconBackgroundColor: string;
};

const CounterCard = (props: Props) => {
    const { type, label, value, icon, iconBackgroundColor } = props;
    const cardValue = type === 'percentage' ? `${value}%` : value;

    return (
        <CardStyle
            sx={{
                p: pxToRem(24),
                flexDirection: 'row',
            }}
        >
            <IconContainer sx={{ backgroundColor: iconBackgroundColor }}>
                {icon}
            </IconContainer>
            <DataContainer>
                <Value variant="h2">{cardValue}</Value>
                <Description variant="body2">{label}</Description>
            </DataContainer>
        </CardStyle>
    );
};

export default CounterCard;
