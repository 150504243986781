export default function Link() {
    return {
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: ['Open Sans', 'sans-serif'],
                },
            },
        },
    };
}
