import { snakeCase } from 'lodash';
import React from 'react';

import { ClipboardIcon } from 'components/Svg';
import { taskCategoryIconMap } from 'pages/ResidentDashboard/constants';
import { getCaregiversFromTasks } from 'pages/ResidentDashboard/helpers';

import { TasksRowContent } from './TasksRowContent';

type Props = {
    row: PRNTasksPerTaskName | RefusedTasksPerKeyService;
};

const TasksRow = ({ row }: Props) => {
    const { tasks } = row;
    const { keyService } = row as RefusedTasksPerKeyService;
    const { taskName } = row as PRNTasksPerTaskName;

    let title = '';
    let icon = <></>;

    if (taskName) {
        title = taskName;
        icon = <ClipboardIcon />;
    } else if (keyService) {
        title = keyService;
        icon = taskCategoryIconMap[snakeCase(keyService.toLowerCase())];
    }

    const caregivers = getCaregiversFromTasks(tasks);

    return (
        <TasksRowContent
            title={title}
            tasksCount={tasks.length}
            caregivers={caregivers}
            icon={icon}
        />
    );
};

export default TasksRow;
