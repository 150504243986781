import { useMediaQuery } from '@mui/material';
import React from 'react';
import Confetti from 'react-confetti';

import useWindowSize from 'hooks/useWindowSize';

type Props = {
    activate: boolean;
    stop: () => void;
};

const CustomConfetti = (props: Props) => {
    const { activate, stop } = props;
    const [width, height] = useWindowSize();
    const isMobile = useMediaQuery('(max-width:1024px)');

    const amountOfPieces = isMobile ? 500 : 2000;

    return (
        <Confetti
            // In Desktop subtract 15 from width to prevent horizontal scroll bar
            width={isMobile ? width : width - 15}
            height={height}
            numberOfPieces={activate ? amountOfPieces : 0}
            recycle={false}
            onConfettiComplete={(confetti) => {
                confetti?.reset();
                stop();
            }}
            tweenDuration={1500}
            gravity={0.5}
        />
    );
};

export default CustomConfetti;
