import { Button } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    onClick: () => void;
};

export const SeeAllButton = ({ onClick }: Props) => (
    <Button
        variant="outlined"
        onClick={onClick}
        sx={{
            fontSize: { xs: pxToRem(14) },
            lineHeight: 1.7,
            textTransform: 'none',
            border: 'none',
            whiteSpace: 'nowrap',
            ml: 'auto',
            '&:hover': {
                border: 'none',
            },
        }}
    >
        See All
    </Button>
);
