import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const PortfolioInnerCheckIcon = ({ className, size }: Props) => (
    <Base className={className} size={size}>
        <path
            d="M21.09 6.98c-.85-.94-2.26-1.41-4.33-1.41h-.24v-.04c0-1.68 0-3.76-3.76-3.76h-1.52c-3.76 0-3.76 2.08-3.76 3.76v.04h-.24c-2.07 0-3.49.47-4.33 1.41-.99 1.11-.96 2.58-.86 3.59l.01.07.07.806a.5.5 0 0 0 .226.377c.283.184.585.377.874.537.14.09.29.17.44.25 1.13.62 2.34 1.11 3.58 1.45A4.755 4.755 0 0 0 12 18.75c2.62 0 4.75-2.13 4.75-4.75v-.04c1.26-.38 2.47-.91 3.6-1.57.06-.03.1-.06.15-.09.395-.215.804-.495 1.177-.76a.498.498 0 0 0 .207-.353l.066-.607c.01-.06.01-.11.02-.18.08-1 .06-2.38-.88-3.42ZM8.91 5.53c0-1.7 0-2.34 2.33-2.34h1.52c2.33 0 2.33.64 2.33 2.34v.04H8.91v-.04ZM12 17.25a3.25 3.25 0 0 1-3.22-2.84c-.02-.13-.03-.27-.03-.41 0-1.79 1.46-3.25 3.25-3.25s3.25 1.46 3.25 3.25c0 .12-.01.23-.02.34v.01a3.258 3.258 0 0 1-3.23 2.9Z"
            fill="currentColor"
        />
        <path
            d="M11.43 16.03c-.19 0-.38-.07-.53-.22l-.99-.98a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l.48.48 1.61-1.48c.3-.28.78-.26 1.06.04s.26.78-.04 1.06l-2.13 1.97c-.16.12-.34.19-.52.19Z"
            fill="currentColor"
        />
        <path
            d="M21.042 13.731c.248-.138.57.062.545.345l-.355 3.885c-.21 2-1.03 4.04-5.43 4.04h-7.62c-4.4 0-5.22-2.04-5.42-4.03l-.346-3.703c-.027-.288.28-.482.536-.347.78.43 1.59.81 2.42 1.11a1 1 0 0 1 .62.69c.75 2.6 3.17 4.53 6.01 4.53 2.89 0 5.33-1.97 6.04-4.67a1 1 0 0 1 .61-.7c.84-.33 1.64-.72 2.39-1.15Z"
            fill="currentColor"
        />
    </Base>
);
