import { alpha } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

export default function Pagination(theme) {
    const { palette } = theme;

    return {
        MuiPagination: {
            styleOverrides: {
                root: {
                    marginTop: pxToRem(8),
                    padding: pxToRem(8),
                    color: palette.grey[500],
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    '& .Mui-disabled': {
                        color: palette.grey[500],
                    },
                    '& .MuiPaginationItem-root:hover': {
                        color: theme.palette.app.green.main,
                        backgroundColor: alpha(palette.green.main, 0.08),
                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                        color: theme.palette.common.white,
                        backgroundColor: palette.app.green.main,
                    },
                    '& .MuiPaginationItem-root.Mui-selected:hover': {
                        color: theme.palette.common.white,
                        backgroundColor: palette.app.green.main,
                    },
                },
            },
        },
    };
}
