import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { AvatarProps } from 'types/common';

import { pxToRem } from 'components/theme/typography';
import { AVATAR_WIDTH } from 'constants/residents';
import { AVATAR_PATH_MAP } from 'constants/shared';
import { getInitials } from 'lib/common';

export const CustomAvatar = (props: AvatarProps) => {
    const { palette } = useTheme();
    const {
        avatarType,
        backgroundColor = palette.app.green.light,
        fontColor = palette.common.white,
        firstName,
        lastName,
        photo,
        size = AVATAR_WIDTH,
    } = props;
    const fullName = `${firstName} ${lastName}`;
    const initials = getInitials(firstName, lastName);
    const photoBase = AVATAR_PATH_MAP[avatarType];
    let photoSrc;

    if (photoBase && photo && photo.slice(0, 4) !== 'http') {
        photoSrc = `${photoBase}/${photo}`;
    } else if (photo && photo.slice(0, 4) === 'http') {
        photoSrc = photo;
    }

    const avatarStyle = {
        color: fontColor,
        backgroundColor,
        width: pxToRem(size),
        height: pxToRem(size),
        fontSize: pxToRem(size * 0.5),
    };

    return (
        <Avatar alt={fullName} src={photoSrc} sx={avatarStyle}>
            {initials}
        </Avatar>
    );
};
