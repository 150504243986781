import { Box } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';

import { CustomSelect } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    currentShift: string;
    shiftOptionsOrder: string[];
    dictionary: LanguageStructure;
    fullWidth?: boolean;
    maxWidth?: number | string;
    width?: number | string;
    onChange: (newShift: string) => void;
};

const ShiftSelectorFilter = (props: Props) => {
    const {
        currentShift,
        shiftOptionsOrder,
        dictionary: { home },
        fullWidth,
        maxWidth = pxToRem(264),
        width = '48%',
        onChange,
    } = props;
    const [shift, setShift] = useState<string>(currentShift);

    const handleFilterChange = (newValue: string) => {
        setShift(newValue);
        onChange(newValue);
    };

    const shiftOptions = shiftOptionsOrder.map((shiftName) => ({
        label: home[shiftName.toLocaleLowerCase()],
        value: shiftName as string,
    }));

    return (
        <Box
            sx={{
                width,
                maxWidth,
            }}
        >
            <CustomSelect
                id="shift"
                label={home.shift}
                value={shift}
                options={shiftOptions}
                fullWidth={fullWidth}
                onChange={handleFilterChange}
            />
        </Box>
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

export const ShiftSelector = connect(mapStateToProps)(ShiftSelectorFilter);
