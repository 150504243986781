import emailjs from '@emailjs/browser';
import { Email as EmailIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { encode as base64Encode } from 'base-64';
import moment from 'moment';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NotificationDialogProps } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { SessionReadParams } from 'types/session';
import { UserUpdatePasswordParams } from 'types/users';

import { CustomTextField } from 'components/Custom';
import AllieHealthLogo from 'components/Shared/AllieHealthLogo';
import NotificationDialog from 'components/Shared/NotificationDialog';
import { pxToRem } from 'components/theme/typography';
import {
    BASE_PATH,
    EMAIL_JS_PASSWORD_TEMPLATE_ID,
    EMAIL_JS_PUBLIC_KEY,
    EMAIL_JS_SERVICE_ID,
    LOGIN_PATH,
} from 'constants/app';
import { ONLY_EMAIL_REGEX } from 'constants/regex';
import {
    INITIAL_NOTIFICATION_DIALOG_STATE,
    NOTIFICATION_TYPE,
} from 'constants/shared';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';
import { signIn } from 'redux/actions/session';
import { readUserIdByEmail, updatePassword } from 'redux/actions/users';

const MainContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    })
);

const MessageContainer = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(14), lg: pxToRem(14) },
        lineHeight: { xs: pxToRem(14), lg: pxToRem(14) },
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'center',
    })
);

const TopSection = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(48),
        mb: pxToRem(48),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    })
);

const Title = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(24), lg: pxToRem(20) },
        lineHeight: { xs: pxToRem(24), lg: pxToRem(20) },
        fontWeight: 'bold',
    })
);

const Instructions = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        m: 0,
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(14), lg: pxToRem(14) },
        lineHeight: { xs: pxToRem(14), lg: pxToRem(14) },
        color: theme.palette.grey[600],
        fontWeight: 'normal',
    })
);

const FormWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(24),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    })
);

const CancelButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
    })
);

type Props = {
    dictionary: LanguageStructure;
    usernameFromLogin: string;
};

const ForgotPassword = (props: Props) => {
    const navigate = useNavigate();
    const formRef = useRef() as MutableRefObject<HTMLFormElement>;
    const { register, setValue } = useForm();
    const {
        dictionary: { forgotPassword, shared, userManagement, userProfile },
        usernameFromLogin,
    } = props;
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [notificationData, setNotificationData] =
        useState<NotificationDialogProps>(INITIAL_NOTIFICATION_DIALOG_STATE);

    const handleEmailChange = (newValue: string) => {
        ONLY_EMAIL_REGEX.lastIndex = 0;
        const onlyEmailCheck = ONLY_EMAIL_REGEX.test(newValue);

        if (!onlyEmailCheck) {
            setError(shared.onlyEmail);
        } else {
            setError('');
        }

        setEmail(newValue);
    };

    const handleCancelClick = () => {
        navigate(`${BASE_PATH}${LOGIN_PATH}`);
    };

    const handleSubmitClick = async () => {
        // Do nothing if the button was triggered but the form is invalid.
        if (!email) {
            return;
        }

        const passwordLinkParams = {
            email,
            timestamp: moment(),
            self: true,
        };
        const encodedLink = base64Encode(JSON.stringify(passwordLinkParams));

        // Add the Link Code to the Form that will be submitted through emailjs and update the additional params.
        setValue('action', userManagement.passwordResetAction);
        setValue('greeting', forgotPassword.emailGreeting);
        setValue('link_code', encodedLink);
        setValue('message', forgotPassword.emailMessage);
        setValue('to_email', email);

        // Send the Password Set email to the new user.
        emailjs
            .sendForm(
                EMAIL_JS_SERVICE_ID,
                EMAIL_JS_PASSWORD_TEMPLATE_ID,
                formRef.current,
                EMAIL_JS_PUBLIC_KEY
            )
            .then(
                (_result) => {
                    setSuccessMessage(
                        forgotPassword.notificationMessageSuccess
                    );

                    setValue('action', '');
                    setValue('greeting', '');
                    setValue('link_code', '');
                    setValue('message', '');
                    setValue('to_email', '');
                },
                (_error) => {
                    setNotificationData({
                        isOpen: true,
                        title: forgotPassword.pageDetailsTitle,
                        message: forgotPassword.notificationMessageError,
                        type: NOTIFICATION_TYPE.error,
                    });
                }
            );
    };

    const handleCloseNotification = () => {
        setNotificationData(INITIAL_NOTIFICATION_DIALOG_STATE);
    };

    useEffect(() => {
        setEmail(usernameFromLogin);
    }, [usernameFromLogin]);

    return (
        <PageStructure>
            <MainContainer>
                <AllieHealthLogo />
                <TopSection>
                    {!!successMessage && (
                        <>
                            <MessageContainer>
                                {successMessage}
                            </MessageContainer>
                            <MessageContainer>
                                {userManagement.gotoLogin}
                            </MessageContainer>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelClick}
                            >
                                {shared.goToLoginPage}
                            </Button>
                        </>
                    )}
                    {!successMessage && (
                        <>
                            <Title>{forgotPassword.title}</Title>
                            <Instructions>
                                {forgotPassword.instructions}
                            </Instructions>
                            <FormWrapper>
                                <CustomTextField
                                    id="email"
                                    label={userProfile.email as string}
                                    value={email}
                                    customErrorMessage={error}
                                    fullWidth
                                    startAdornment={<EmailIcon />}
                                    onChange={handleEmailChange}
                                />
                                <form ref={formRef} style={{ display: 'none' }}>
                                    <input
                                        value=""
                                        {...register('company_name')}
                                    />
                                    <input value="" {...register('greeting')} />
                                    <input value="" {...register('to_email')} />
                                    <input value="" {...register('action')} />
                                    <input value="" {...register('message')} />
                                    <input
                                        value=""
                                        {...register('link_code')}
                                    />
                                    <input type="submit" />
                                </form>
                                <ButtonsWrapper>
                                    <CancelButton
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancelClick}
                                    >
                                        {shared.cancel}
                                    </CancelButton>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!email}
                                        onClick={handleSubmitClick}
                                    >
                                        {shared.submit}
                                    </Button>
                                </ButtonsWrapper>
                                <NotificationDialog
                                    closable={notificationData.closable}
                                    isOpen={notificationData.isOpen}
                                    message={notificationData.message}
                                    title={notificationData.title}
                                    type={notificationData.type}
                                    onClose={handleCloseNotification}
                                />
                            </FormWrapper>
                        </>
                    )}
                </TopSection>
            </MainContainer>
        </PageStructure>
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { userId } = users;

    return {
        dictionary,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUserIdByEmail: (email: string) =>
        dispatch(readUserIdByEmail(email)),
    dispatchSignIn: (params: SessionReadParams) => dispatch(signIn(params)),
    dispatchUpdatePassword: (
        userId: number,
        jsonParams: UserUpdatePasswordParams
    ) => dispatch(updatePassword(userId, jsonParams, true)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedForgotPassword: any = AccessControl(
    connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);

export default ConnectedForgotPassword;
