import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { KeyServicesDataStructure } from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';
import { formatDateTimeFullUS, formatDateTimeShortUS } from 'lib/common';

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'flex-start',
        '&:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ReportedByLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
        '&:first-of-type': {
            mt: pxToRem(4),
        },
    })
);

const ResidentData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-end',
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        fontSize: { lg: pxToRem(16) },
        fontWeight: 'bold',
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
    })
);

const TaskRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(4),
        mb: pxToRem(8),
        p: pxToRem(8),
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadiusSm,
        width: '100$',
    })
);

const TaskRowInfo = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
    })
);

const TaskRowTimestamp = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
        width: pxToRem(192),
    })
);

const TaskRowShift = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
        width: pxToRem(40),
    })
);

type Props = {
    dictionary: LanguageStructure;
    serviceData: KeyServicesDataStructure[];
    timezone: string;
};

const KeyServicesTabContent = (props: Props) => {
    const {
        dictionary: { dashboards },
        serviceData = [],
        timezone,
    } = props;

    return (
        <>
            {Object.values(serviceData).map((residentGroup) => {
                const {
                    resident: {
                        id: residentId,
                        firstName,
                        lastName,
                        fullName,
                        photo,
                        roomNumber,
                    },
                    tasks,
                } = residentGroup;

                return (
                    <Row key={residentId}>
                        <AvatarContainer>
                            <CustomAvatar
                                avatarType={AVATAR_TYPES.resident}
                                firstName={firstName}
                                lastName={lastName}
                                photo={photo}
                                size={48}
                            />
                        </AvatarContainer>
                        <DataContainer>
                            <DataContainer>
                                <ResidentData>
                                    <ResidentName>{fullName}</ResidentName>
                                    <RoomNumber>
                                        {dashboards.room} {roomNumber}
                                    </RoomNumber>
                                </ResidentData>
                            </DataContainer>
                            {tasks.map((task) => {
                                const {
                                    keyServiceCode,
                                    taskId,
                                    taskName,
                                    taskTimestamp,
                                    taskShift,
                                    caregiverName,
                                } = task;
                                const dateTimeFormatted = formatDateTimeShortUS(
                                    taskTimestamp,
                                    timezone
                                );
                                const dateTimeFullFormatted =
                                    formatDateTimeFullUS(
                                        taskTimestamp,
                                        timezone
                                    );
                                const reportedByOn = caregiverName
                                    ? dashboards.keyServicesReportedBy
                                          .replace('{XX}', caregiverName)
                                          .replace(
                                              '{YY}',
                                              dateTimeFullFormatted
                                          )
                                    : dashboards.keyServicesReportedOn.replace(
                                          '{YY}',
                                          dateTimeFullFormatted
                                      );

                                return (
                                    <>
                                        <ReportedByLabel>
                                            {reportedByOn}
                                        </ReportedByLabel>
                                        <TaskRow key={taskId}>
                                            <TaskRowInfo>
                                                [{dashboards[keyServiceCode]}]{' '}
                                                {taskName}
                                            </TaskRowInfo>
                                            {/* <TaskRowTimestamp>{dateTimeFormatted}</TaskRowTimestamp> */}
                                            <TaskRowShift>
                                                {taskShift}
                                            </TaskRowShift>
                                        </TaskRow>
                                    </>
                                );
                            })}
                        </DataContainer>
                    </Row>
                );
            })}
        </>
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        timezone,
    };
};

export default connect(mapStateToProps)(KeyServicesTabContent);
