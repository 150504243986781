import { Box } from '@mui/material';
import React from 'react';

import { NestedRoutes } from 'types/routes';

import { pxToRem } from 'components/theme/typography';
import {
    BASE_PATH,
    CODE_PATH,
    DESKTOP_HEADER_HEIGHT,
    FORGOT_PASSWORD_PATH,
    HOME_PATH,
    LOGIN_PATH,
    MANAGER_TASK_LIST,
    MOBILE_BOTTOM_MENU_HEIGHT,
    MOBILE_HEADER_HEIGHT,
    MY_REWARDS_HISTORY_PATH,
    MY_REWARDS_PATH,
    OPERATIONS_SNAPSHOTS_PATH,
    PASSWORD_RESET_PATH,
    PERFORMANCE_SNAPSHOTS_PATH,
    PRIVACY_POLICY,
    RESIDENTS_PATH,
    RESIDENT_DASHBOARD_PATH,
    RESIDENT_DETAILS_PATH,
    SIGNUP_PATH,
    USERS_COMPANY_PATH,
    USERS_MANAGEMENT_INVITATIONS_PATH,
    USERS_MANAGEMENT_PATH,
    USERS_MANAGEMENT_USERS_PATH,
    USERS_PROFILE_PATH,
} from 'constants/app';
import Dashboards from 'pages/Dashboards';
import Home from 'pages/Home';
import Login from 'pages/Login';
import MyRewards from 'pages/MyRewards';
import MyRewardsHistory from 'pages/MyRewards/HistoryPage';
import PageNotFound from 'pages/PageNotFound';
import { PerformanceDashboard } from 'pages/PerformanceDashboard';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { ResidentDashboard } from 'pages/ResidentDashboard';
import Residents from 'pages/Residents';
import ResidentDetails from 'pages/Residents/Details';
import UserCompany from 'pages/Users/Company';
import ForgotPassword from 'pages/Users/ForgotPassword';
import UserManagement from 'pages/Users/Management';
import PasswordForm from 'pages/Users/PasswordForm';
import UserProfile from 'pages/Users/Profile';
import SignupForm from 'pages/Users/SignupForm';

const Wrapper = ({ grayStyles = {}, children }) => (
    <Box
        sx={{
            px: { xs: pxToRem(8), md: pxToRem(16) },
            py: { xs: pxToRem(8), md: pxToRem(16) },
            backgroundColor: grayStyles,
            minHeight: {
                xs: `calc(100vh - ${pxToRem(
                    MOBILE_HEADER_HEIGHT + MOBILE_BOTTOM_MENU_HEIGHT
                )})`,
                lg: `calc(100vh - ${pxToRem(DESKTOP_HEADER_HEIGHT)})`,
            },
        }}
    >
        {children}
    </Box>
);

const AllGrayWrapper = ({ children }) => (
    <Wrapper
        grayStyles={{
            xs: 'app.grey.main',
        }}
    >
        {children}
    </Wrapper>
);

const DesktopGrayWrapper = ({ children }) => (
    <Wrapper
        grayStyles={{
            md: 'app.grey.main',
        }}
    >
        {children}
    </Wrapper>
);

const WhiteWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

export const AppRoutes: NestedRoutes = {
    titleId: 'navigator',
    path: BASE_PATH,
    Component: Login,
    Wrapper: WhiteWrapper,
    children: [
        {
            titleId: 'login',
            path: LOGIN_PATH,
            Component: Login,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'privacyPolicy',
            path: PRIVACY_POLICY,
            Component: PrivacyPolicy,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'dashboards',
            path: OPERATIONS_SNAPSHOTS_PATH,
            Component: Dashboards,
            Wrapper: AllGrayWrapper,
            children: [
                {
                    titleId: 'residentDetails',
                    path: RESIDENT_DASHBOARD_PATH,
                    Component: ResidentDashboard,
                    Wrapper: AllGrayWrapper,
                },
                {
                    titleId: 'performanceSnapshots',
                    path: PERFORMANCE_SNAPSHOTS_PATH,
                    Component: PerformanceDashboard,
                    Wrapper: AllGrayWrapper,
                },
            ],
        },
        {
            titleId: 'home',
            path: HOME_PATH,
            Component: Home,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'managerTaskList',
            path: MANAGER_TASK_LIST,
            Component: Home,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'residents',
            path: RESIDENTS_PATH,
            Component: Residents,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'residentDetails',
                    path: RESIDENT_DETAILS_PATH,
                    Component: ResidentDetails,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'myRewards',
            path: MY_REWARDS_PATH,
            Component: MyRewards,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'rewardsHistory',
                    path: MY_REWARDS_HISTORY_PATH,
                    Component: MyRewardsHistory,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'userCompany',
            path: USERS_COMPANY_PATH,
            Component: UserCompany,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'userManagementUsersList',
            path: USERS_MANAGEMENT_PATH,
            Component: UserManagement,
            Wrapper: AllGrayWrapper,
            children: [
                {
                    titleId: 'userManagementUsersList',
                    path: USERS_MANAGEMENT_USERS_PATH,
                    Component: UserManagement,
                    Wrapper: AllGrayWrapper,
                },
                {
                    titleId: 'userManagementInvitationsList',
                    path: USERS_MANAGEMENT_INVITATIONS_PATH,
                    Component: UserManagement,
                    Wrapper: AllGrayWrapper,
                },
            ],
        },
        {
            titleId: 'userProfile',
            path: USERS_PROFILE_PATH,
            Component: UserProfile,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'signupForm',
            path: SIGNUP_PATH,
            Component: SignupForm,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'signupForm',
                    path: CODE_PATH,
                    Component: SignupForm,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'forgotPassword',
            path: FORGOT_PASSWORD_PATH,
            Component: ForgotPassword,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'passwordReset',
            path: PASSWORD_RESET_PATH,
            Component: PasswordForm,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'passwordReset',
                    path: CODE_PATH,
                    Component: PasswordForm,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'pageNotFound',
            path: '*',
            Component: PageNotFound,
            Wrapper: WhiteWrapper,
        },
    ],
};
