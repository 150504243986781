import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import React from 'react';
import {
    BsFillArrowDownCircleFill as DownArrow,
    BsFillArrowUpCircleFill as UpArrow,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { DashboardReadParams } from 'types/dashboard';

import { useResidentWeightQuery } from 'api/queries/dashboard/residentWeight';
import { CustomAvatar, CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'flex-start',
        '&:first-of-type': {
            mt: pxToRem(24),
        },
        '&:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const ResidentData = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-end',
        flexGrow: 1,
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        fontSize: { lg: pxToRem(16) },
        fontWeight: 'bold',
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[500],
        fontSize: { lg: pxToRem(14) },
    })
);

const DataRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        p: pxToRem(8),
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadiusSm,
        width: '100$',
    })
);

const TaskName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
    })
);

const TaskTimestamp = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
    })
);

type Props = {
    open: boolean;
    params: DashboardReadParams;
    onClose: () => void;
};

export const WeightDetailsDialog = (props: Props) => {
    const { params, open, onClose } = props;

    const {
        isLoading: residentWeightIsLoading,
        isError: residentWeightIsError,
        data: residentWeightData,
    } = useResidentWeightQuery(params);

    return (
        <CustomDialog
            closeable
            open={open}
            title="Resident Weight Changes"
            width={pxToRem(600)}
            maxHeight={`calc(100vh - ${pxToRem(160)})`}
            showDividers
            content={
                <>
                    {!residentWeightIsLoading &&
                        !residentWeightIsError &&
                        residentWeightData &&
                        residentWeightData.highVitalChanges.map(
                            (
                                {
                                    residentId,
                                    residentFirstName,
                                    residentLastName,
                                    residentPhoto,
                                    residentRoom,
                                    allWeightValues,
                                    weightChange,
                                    weightChangeDirection,
                                }
                            ) => (
                                <Row key={residentId}>
                                    <AvatarContainer>
                                        <CustomAvatar
                                            avatarType={AVATAR_TYPES.resident}
                                            firstName={residentFirstName}
                                            lastName={residentLastName}
                                            photo={residentPhoto}
                                            size={48}
                                        />
                                    </AvatarContainer>
                                    <DataContainer>
                                        <Link
                                            to={`resident-details/${residentId}`}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                }}
                                            >
                                                <ResidentData>
                                                    <ResidentName>
                                                        {`${residentFirstName} ${residentLastName}`}
                                                    </ResidentName>
                                                    <RoomNumber>
                                                        {`Room ${residentRoom}`}
                                                    </RoomNumber>
                                                </ResidentData>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        color: 'error.main',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box>{`${weightChange} lbs`}</Box>
                                                    <Box
                                                        sx={{
                                                            marginLeft:
                                                                pxToRem(4),
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        {weightChangeDirection ===
                                                        'Increase' ? (
                                                            <UpArrow />
                                                        ) : (
                                                            <DownArrow />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Link>
                                        {allWeightValues.map(
                                            ({ value, date }) => (
                                                <DataRow key={date}>
                                                    <TaskName>
                                                        {`${value} lbs`}
                                                    </TaskName>
                                                    <TaskTimestamp>
                                                        {moment(date).format(
                                                            'MM/DD/YYYY h:mm A'
                                                        )}
                                                    </TaskTimestamp>
                                                </DataRow>
                                            )
                                        )}
                                    </DataContainer>
                                </Row>
                            )
                        )}
                </>
            }
            onClose={onClose}
        />
    );
};
