import { Close as CloseIcon } from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';

import { pxToRem } from 'components/theme/typography';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    footerDivider: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
}));

const DialogTitleStyle = styled(DialogTitle)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: `calc(100% - ${pxToRem(48)})`,
    })
);

const IconButtonStyle = styled(IconButton)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        p: pxToRem(8),
        position: 'absolute',
        right: pxToRem(8),
        color: theme.palette.grey[600],
        '& .MuiSvgIcon-root': {
            fontSize: pxToRem(16),
        },
    })
);

type Props = {
    actions?: ReactNode;
    className?: string;
    closeable?: boolean;
    content: ReactNode;
    height?: number;
    id?: string;
    maxHeight?: number | string;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    open: boolean;
    showDividers?: boolean;
    showFooterDivider?: boolean;
    subtitle?: ReactNode;
    title?: string;
    width?: number | string;
    onClose: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomDialog = (props: Props) => {
    const classes = useStyles();
    const {
        actions,
        className,
        closeable,
        content,
        height,
        id,
        maxHeight,
        maxWidth = 'md',
        open,
        showDividers,
        showFooterDivider,
        subtitle,
        title,
        width,
        onClose,
    } = props;
    const isMobile = useMediaQuery('(max-width:1024px)');

    const dialogStyles = {
        marginTop: 'env(safe-area-inset-top)',
        '& .MuiDialog-paperScrollPaper': {
            height: height || 'auto',
            width: width || 'auto',
            maxHeight: maxHeight || 'auto',
        },
    };

    // on mobile the autocompletes are hard to work with when the dialog is in the middle of the screen
    // this puts it at the top of the screen
    if (isMobile) {
        dialogStyles['& .MuiDialog-container'] = {
            alignItems: 'flex-start',
        };
    }

    const handleOnClose = (_event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog
            id={id}
            className={className}
            maxWidth={maxWidth}
            open={open}
            onClose={handleOnClose}
            sx={dialogStyles}
            scroll="paper"
        >
            {!!title && (
                <DialogTitleStyle>
                    {title}
                    {closeable && (
                        <IconButtonStyle onClick={onClose}>
                            <CloseIcon />
                        </IconButtonStyle>
                    )}
                </DialogTitleStyle>
            )}
            {!!subtitle && <>{subtitle}</>}
            <DialogContent dividers={showDividers}>{content}</DialogContent>
            {!!actions && (
                <DialogActions
                    className={showFooterDivider ? classes.footerDivider : ''}
                >
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};
