import { Box, Card, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

import { ModuleContent } from './ModuleContent';

type Props = {
    rowData: ResidentNote[] | TaskNote[];
    title: string;
    isNotesModule?: boolean;
    order: number;
};

export const NotesModule = ({
    rowData,
    title,
    isNotesModule,
    order,
}: Props) => (
    <Box
        order={order}
        display={{ lg: 'flex' }}
        flexDirection={{ lg: 'column' }}
    >
        <Typography
            fontSize={{ xs: pxToRem(18), md: pxToRem(20) }}
            lineHeight={1.6}
            mb={1}
        >
            {title}
        </Typography>
        <Card
            variant="outlined"
            sx={{
                flex: { lg: 1 },
            }}
        >
            <ModuleContent rowData={rowData} isNotesModule={isNotesModule} />
        </Card>
    </Box>
);
