import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { pxToRem } from 'components/theme/typography';

export const TaskRowContainer = ({ children }: { children: ReactNode }) => (
    <Box
        sx={{
            display: 'flex',
            border: 1,
            borderColor: 'grey.200',
            padding: pxToRem(16),
            backgroundColor: '#F8F8F9',
            borderRadius: pxToRem(8),
            marginBottom: pxToRem(8),
        }}
    >
        {children}
    </Box>
);
