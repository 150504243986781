import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { SUB_PAGES_MENU_MAP } from 'constants/app';
import { AVATAR_TYPES } from 'constants/shared';
import { updatePriorParentPage } from 'redux/actions/session';

const PHOTO_WIDTH = 64;

type Props = {
    photo: string;
    firstName: string;
    lastName: string;
    residentId: number;
};

export const ResidentPhoto = (props: Props) => {
    const { firstName, lastName, photo, residentId } = props;

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goToResidentDetails = () => {
        dispatch(updatePriorParentPage(pathname));
        const detailsPage = SUB_PAGES_MENU_MAP.residentDetails.replace(
            ':residentId',
            residentId.toString()
        );
        navigate(detailsPage);
    };

    return (
        <Box
            onClick={goToResidentDetails}
            sx={{
                marginRight: pxToRem(12),
                marginLeft: pxToRem(12),
                width: pxToRem(PHOTO_WIDTH),
            }}
        >
            <CustomAvatar
                avatarType={AVATAR_TYPES.resident}
                firstName={firstName}
                lastName={lastName}
                photo={photo}
                size={PHOTO_WIDTH}
            />
        </Box>
    );
};
