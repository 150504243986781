import { LanguageDictionary, LanguageOptions } from 'types/language';

import en from 'languages/en';
import es from 'languages/es';

export const dictionaryList: LanguageDictionary = {
    en,
    es,
};

export const languageOptions: LanguageOptions[] = [
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'es',
        label: 'Español',
    },
];
