import { DashboardState } from 'types/redux';

import { DASHBOARD_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const dashboard = (state: DashboardState = DASHBOARD_DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.DASHBOARD_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_COUNTERS: {
            return {
                ...state,
                counters: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_INCOMPLETED_TASKS: {
            return {
                ...state,
                incompletedTasks: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_INCOMPLETED_TASKS_BY_RESIDENTS: {
            return {
                ...state,
                incompletedByResidents: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_NOTES: {
            return {
                ...state,
                notes: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_UNSCHEDULED_TASKS: {
            return {
                ...state,
                unscheduledTasks: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_OVERDUE_TASKS: {
            return {
                ...state,
                overdueTasks: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_OVERDUE_TASKS_BY_RESIDENTS: {
            return {
                ...state,
                overdueByResidents: action.payload,
            };
        }
        case actionTypes.DASHBOARD_READ_PERFORMANCE: {
            return {
                ...state,
                performance: action.payload,
            };
        }
        case actionTypes.DASHBOARD_UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            };
        }
        default:
            return state;
    }
};

export default dashboard;
