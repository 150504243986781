import '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { UserResponse } from 'types/users';

import { CustomDialog } from 'components/Custom';
import { TrashIcon } from 'components/Svg';
import { pxToRem } from 'components/theme/typography';

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    })
);

const IconNotification = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(36),
        mb: pxToRem(24),
        p: pxToRem(40),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        width: pxToRem(120),
        height: pxToRem(120),
        color: theme.palette.app.orange.main,
        backgroundColor: alpha(theme.palette.app.orange.main, 0.1),
    })
);

const Message = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(20) },
    })
);

const UserName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        fontSize: { lg: pxToRem(20) },
    })
);

type Props = {
    dictionary: LanguageStructure;
    isOpen: boolean;
    user?: UserResponse;
    onConfirm: (userId: number) => void;
    onCancel: () => void;
};

const UserDetailDialog = (props: Props) => {
    const {
        dictionary: { shared, userManagementUsersList },
        isOpen,
        user,
        onConfirm,
        onCancel,
    } = props;

    if (!isOpen || !user) {
        return null;
    }

    const { userId, firstName, lastName } = user;
    const userFullName = `${firstName} ${lastName}`;

    return (
        <CustomDialog
            open={isOpen}
            width={pxToRem(604)}
            content={
                <>
                    <Row>
                        <IconNotification>
                            <TrashIcon size={36} viewBox="0 0 36 36" />
                        </IconNotification>
                    </Row>
                    <Row>
                        <Message variant="body1">
                            {userManagementUsersList.deleteConfirmation}
                        </Message>
                        <UserName variant="subtitle1">{userFullName}</UserName>
                        <Message variant="body1">?</Message>
                    </Row>
                </>
            }
            actions={
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onCancel}
                    >
                        {shared.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onConfirm(userId)}
                    >
                        {shared.confirm}
                    </Button>
                </>
            }
            onClose={onCancel}
        />
    );
};

const mapStateToProps = ({ language, session, users }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;
    const { user } = users;

    return {
        dictionary,
        timezone,
        user,
    };
};

export default connect(mapStateToProps)(UserDetailDialog);
