import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    DashboardDialogData,
    DashboardDialogType,
    IncompletedOverdueTaskResidentData,
} from 'types/dashboard';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { SUB_PAGES_MENU_MAP } from 'constants/app';
import {
    DASHBOARD_MORE_TASKS_CHIP_WIDTH,
    DASHBOARD_TASKS_CARD_HEIGHT,
} from 'constants/dashboards';
import { AVATAR_TYPES } from 'constants/shared';
import CardBaseStructure from 'pages/Dashboards/components/CardBaseStructure';
import {
    ResidentData,
    ResidentNameLabel,
    RoomLabel,
} from 'pages/Dashboards/components/SharedComponents';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    chip: {
        padding: `${pxToRem(4)} ${pxToRem(8)}`,
        borderRadius: pxToRem(4),
        backgroundColor: alpha(theme.palette.app.orange.main, 0.16),
    },
    overdueChip: {
        color: theme.palette.error.main,
    },
    incompletedChip: {
        color: theme.palette.app.orange.main,
    },
}));

const DataRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '$:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        width: pxToRem(32),
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

const DataContainerRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

const TaskStatusLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.error.main,
        fontSize: { lg: pxToRem(12) },
        fontWeight: 'bold',
    })
);

const MoreTasksChip = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(10) },
        width: pxToRem(DASHBOARD_MORE_TASKS_CHIP_WIDTH),
    })
);

const TaskChip = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(4),
        fontSize: { lg: pxToRem(10) },
        width: `calc((100% - ${pxToRem(
            DASHBOARD_MORE_TASKS_CHIP_WIDTH + 12
        )}) / 3)`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    })
);

type Props = {
    cardType: 'incompleted' | 'overdue';
    data: IncompletedOverdueTaskResidentData[];
    dictionary: LanguageStructure;
    showAllTotalRecords?: number;
    subtitle?: string;
    title: string;
    onShowAllClick?: (
        newData: DashboardDialogData,
        newType: DashboardDialogType,
        newTitle: string
    ) => void;
};

const TasksCard = (props: Props) => {
    const classes = useStyles();
    const {
        cardType,
        data = [],
        dictionary: { dashboards, shared },
        showAllTotalRecords = 0,
        subtitle,
        title,
        onShowAllClick = () => null,
    } = props;
    const detailsUrl =
        cardType === 'overdue'
            ? SUB_PAGES_MENU_MAP.dashboardOverdueTasks
            : SUB_PAGES_MENU_MAP.dashboardIncompletedTasks;

    return (
        <CardBaseStructure
            cardType={cardType}
            height={pxToRem(DASHBOARD_TASKS_CARD_HEIGHT)}
            title={title}
            subtitle={subtitle}
            data={data}
            detailsUrl={detailsUrl}
            displayShowDetailsLink
            showAllModalTitle={title}
            showAllTotalRecords={showAllTotalRecords}
            onShowAllClick={onShowAllClick}
        >
            {data.map((resident) => {
                const {
                    residentId,
                    residentName,
                    residentFirstName,
                    residentLastName,
                    residentPhoto,
                    roomNumber,
                    tasks,
                } = resident;

                return (
                    <DataRow key={residentId}>
                        <AvatarContainer>
                            <CustomAvatar
                                avatarType={AVATAR_TYPES.resident}
                                firstName={residentFirstName}
                                lastName={residentLastName}
                                photo={residentPhoto}
                                size={32}
                            />
                        </AvatarContainer>
                        <DataContainer>
                            <Link to={`resident-details/${residentId}`}>
                                <ResidentData>
                                    <ResidentNameLabel>
                                        {residentName}
                                    </ResidentNameLabel>
                                    <RoomLabel>
                                        {dashboards.room} {roomNumber}
                                    </RoomLabel>
                                </ResidentData>
                            </Link>
                            {Object.keys(tasks).map((taskStatus) => {
                                const tasksToShow = tasks[taskStatus].slice(
                                    0,
                                    3
                                );
                                const remainingTasks =
                                    tasks[taskStatus].length - 3;
                                const chipClass = `${
                                    cardType === 'incompleted'
                                        ? classes.incompletedChip
                                        : classes.overdueChip
                                }
                ${classes.chip}`;

                                return (
                                    <Fragment key={taskStatus}>
                                        {cardType === 'incompleted' && (
                                            <DataContainerRow>
                                                <TaskStatusLabel>
                                                    {taskStatus}
                                                </TaskStatusLabel>
                                            </DataContainerRow>
                                        )}
                                        <DataContainerRow>
                                            {tasksToShow.map((task) => {
                                                const taskChipKey = `${task.taskId}_${task.taskDate}_${task.taskTime}`;

                                                return (
                                                    <TaskChip
                                                        key={taskChipKey}
                                                        className={chipClass}
                                                    >
                                                        {task.taskName}
                                                    </TaskChip>
                                                );
                                            })}
                                            {remainingTasks > 0 && (
                                                <MoreTasksChip
                                                    className={chipClass}
                                                >
                                                    {remainingTasks}+
                                                </MoreTasksChip>
                                            )}
                                        </DataContainerRow>
                                    </Fragment>
                                );
                            })}
                        </DataContainer>
                    </DataRow>
                );
            })}
            {!data.length && (
                <Typography variant="body2">
                    There is no data for the provided date range.
                </Typography>
            )}
        </CardBaseStructure>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

export default connect(mapStateToProps)(TasksCard);
