import { api } from 'api';

import { CaregiverPerformanceResponse } from 'types/caregiverPerformanceDashboard';
import { DashboardReadParams } from 'types/dashboard';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, getToken } from 'lib/common';

export const useCaregiverPerformanceDetails = (
    jsonParams: DashboardReadParams | any
) => {
    const params = formatApiParams(jsonParams);
    return useErrorHandledQuery(
        ['caregiver-performance-details', jsonParams],
        async (): Promise<CaregiverPerformanceResponse> =>
            (
                await api.get(`/dashboard/performance-details`, {
                    headers: {
                        authorization: getToken(),
                    },
                    params,
                })
            ).data.response,
        { enabled: !!params, staleTime: 300000 }
    );
};
