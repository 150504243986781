import { Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';

import { CustomDialog } from 'components/Custom';

type Props = {
    dictionary: LanguageStructure;
    isOpen: boolean;
    taskNote: string;
    onClose: () => void;
};

const TaskNotesDialog = (props: Props) => {
    const {
        dictionary: { home },
        isOpen,
        taskNote,
        onClose,
    } = props;

    return (
        <CustomDialog
            closeable
            open={isOpen}
            title={home.taskNotes}
            width="100%"
            onClose={onClose}
            content={<Typography variant="body2">{taskNote}</Typography>}
        />
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedTaskNotesDialog: any = connect(mapStateToProps)(TaskNotesDialog);

export default ConnectedTaskNotesDialog;
