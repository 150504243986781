import { Button } from '@mui/material';
import React from 'react';
import { FiFilter as SortIcon } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';

type Props = {
    onClick: () => void;
};

const SortButton = ({ onClick }: Props) => {
    const { sortButtonTitle: sortButtonTitleTxt } = useSelector(
        (state: ReduxStore) => state.language.dictionary.shared
    );

    return (
        <Button
            variant="outlined"
            startIcon={<SortIcon />}
            onClick={onClick}
            sx={{
                py: pxToRem(9),
                color: 'grey.700',
                borderColor: 'grey.400',
                width: '100%',
            }}
        >
            {sortButtonTitleTxt}
        </Button>
    );
};

export default SortButton;
