import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import {
    BsFillArrowDownCircleFill as DownArrow,
    BsFillArrowUpCircleFill as UpArrow,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { useResidentWeightQuery } from 'api/queries/dashboard/residentWeight';
import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { DASHBOARD_TASKS_CARD_HEIGHT } from 'constants/dashboards';
import { AVATAR_TYPES } from 'constants/shared';
import CardBaseStructure from 'pages/Dashboards/components/CardBaseStructure';
import {
    ResidentNameLabel,
    RoomLabel,
} from 'pages/Dashboards/components/SharedComponents';

const DataRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '$:last-of-type': {
            mb: 0,
        },
    })
);

const AvatarContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        width: pxToRem(32),
    })
);

const DataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

export const ResidentWeights = (props) => {
    const { params, onShowAllClick } = props;
    const {
        isLoading: residentWeightIsLoading,
        isError: residentWeightIsError,
        data: residentWeightData,
    } = useResidentWeightQuery(params);

    const startDateFormatted = moment(params.endPeriod)
        .subtract(60, 'day')
        .format('M/DD/YY');
    const endDateFormatted = moment(params.endPeriod).format('M/DD/YY');

    return (
        <CardBaseStructure
            cardType="keyServices"
            height={pxToRem(DASHBOARD_TASKS_CARD_HEIGHT)}
            title="Resident Weight Changes"
            subtitle={`Weight changed >= 5 lbs between ${startDateFormatted} and ${endDateFormatted}`}
            showAllModalTitle="Resident Weights"
            showAllTotalRecords={
                residentWeightData?.highVitalChanges?.length || 0
            }
            onShowAllClick={onShowAllClick}
        >
            {!residentWeightIsLoading &&
                !residentWeightIsError &&
                residentWeightData &&
                residentWeightData.highVitalChanges &&
                residentWeightData.highVitalChanges.map(
                    (
                        {
                            residentId,
                            residentFirstName,
                            residentLastName,
                            residentPhoto,
                            residentRoom,
                            weightChange,
                            weightChangeDirection,
                            allWeightValues,
                        }
                    ) => (
                        <Link to={`resident-details/${residentId}`}>
                            <DataRow key={residentId}>
                                <AvatarContainer>
                                    <CustomAvatar
                                        avatarType={AVATAR_TYPES.resident}
                                        firstName={residentFirstName}
                                        lastName={residentLastName}
                                        photo={residentPhoto}
                                        size={32}
                                    />
                                </AvatarContainer>
                                <DataContainer>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <ResidentNameLabel>{`${residentFirstName} ${residentLastName}`}</ResidentNameLabel>
                                            <RoomLabel>
                                                Room {residentRoom}
                                            </RoomLabel>
                                        </Box>
                                        <Box
                                            sx={{
                                                flexGrow: 0,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: 'error.main',
                                            }}
                                        >
                                            {weightChange} lbs
                                            <Box
                                                sx={{
                                                    marginLeft: pxToRem(4),
                                                    display: 'flex',
                                                }}
                                            >
                                                {weightChangeDirection ===
                                                'Increase' ? (
                                                    <UpArrow />
                                                ) : (
                                                    <DownArrow />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* <DataContainerRow>
                                        {allWeightValues.map(({ value, date }) => (
                                            <TaskChip key={date}>
                                                {value} lbs
                                            </TaskChip>
                                        ))}
                                    </DataContainerRow> */}
                                </DataContainer>
                            </DataRow>
                        </Link>
                    )
                )}
        </CardBaseStructure>
    );
};
