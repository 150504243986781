import {
    CAREGIVERS_PHOTO_PATH,
    COMPANY_MANAGERS_PHOTO_PATH,
    EXECUTIVE_DIRECTORS_PHOTO_PATH,
    FAMILY_MEMBERS_PHOTO_PATH,
    MANAGERS_PHOTO_PATH,
    RESIDENTS_PHOTO_PATH,
} from 'constants/app';

export const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const ONE_HOUR = 1000 * 60 * 60;
export const ONE_MINUTE = 1000 * 60;
export const ONE_SECOND = 1000 * 60;

// eslint-disable-next-line no-shadow
export const enum AVATAR_TYPES {
    company = 'company',
    director = 'director',
    manager = 'manager',
    caregiver = 'caregiver',
    familyMember = 'familyMember',
    resident = 'resident',
    user = 'user',
}

export const AVATAR_PATH_MAP = {
    company: COMPANY_MANAGERS_PHOTO_PATH,
    director: EXECUTIVE_DIRECTORS_PHOTO_PATH,
    manager: MANAGERS_PHOTO_PATH,
    caregiver: CAREGIVERS_PHOTO_PATH,
    familyMember: FAMILY_MEMBERS_PHOTO_PATH,
    resident: RESIDENTS_PHOTO_PATH,
};

// eslint-disable-next-line no-shadow
export const enum SELECT_ALL_STATUSES {
    inactive = 0,
    active = 1,
    partial = 2,
}

// eslint-disable-next-line no-shadow
export enum NOTIFICATION_TYPE {
    default = 'default',
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning',
}

export const INITIAL_NOTIFICATION_DIALOG_STATE = {
    isOpen: false,
    message: '',
    title: '',
    type: NOTIFICATION_TYPE.default,
};

// eslint-disable-next-line no-shadow
export enum DIALOG_TYPE {
    reject = 'reject',
    edit = 'edit',
}
