import { Languages } from 'types/language';
import { Action } from 'types/redux';

import actionTypes from 'redux/actionTypes';
import 'redux/reducers/language';

/**
 * Changes the language based on the "languageId".
 * @param {Object} languageId
 */
export const toggleLanguage = (languageId: Languages): Action => ({
    type: actionTypes.LANGUAGE_TOGGLE,
    payload: {
        languageId,
    },
});
