import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CalendarPicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { format, min, parseISO } from 'date-fns';
import React from 'react';

import { CheckInsByShiftDate } from 'types/myRewards';

import { pxToRem } from 'components/theme/typography';

import { CheckInCalendarLegend } from './CheckInCalendarLegend';
import { CHECK_IN_ICON_COLOR_MAP } from './constants';

const CustomPickersDay = styled(PickersDay)(() => ({
    color: '#6F6F79',
    fontFamily: 'Open Sans',
    lineHeight: 1.7,
    fontSize: `${pxToRem(14)} !important`,
    pointerEvents: 'none',
    // Styles for current day
    '&.Mui-selected': {
        backgroundColor: 'transparent',
        color: '#FA9C7A',
        // Circle around current day
        '&:focus': {
            backgroundColor: 'transparent',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            inset: 5,
            outline: '1px solid #FA9C7A',
            borderRadius: '50%',
        },
    },
}));

const CustomCalendarPicker = styled(CalendarPicker)(({ theme }) =>
    theme.unstable_sx({
        '& .MuiPickersCalendarHeader-labelContainer': {
            // Prevent opening the dropdown by clicking on the month year label
            pointerEvents: 'none',
            justifyContent: 'center',
            color: '#6F6F79',
            fontFamily: 'Open Sans',
            fontSize: `${pxToRem(16)} !important`,
            textTransform: 'uppercase',
        },
        '& .MuiDayPicker-weekDayLabel': {
            fontFamily: 'Open Sans',
            fontSize: `${pxToRem(14)} !important`,
            fontWeight: 700,
        },
        '& .MuiDayPicker-monthContainer': {
            // Avoid bottom icons from being cut off
            minHeight: '240px',
        },
        // Reorganize elements in the calendar header
        '& .MuiPickersCalendarHeader-root': {
            display: 'block',
            position: 'relative',
            px: 0,
        },
        '& .MuiPickersArrowSwitcher-spacer': {
            width: '220px',
        },
        '& .MuiPickersArrowSwitcher-root': {
            position: 'absolute',
            top: -5,
            left: 18,
        },
    })
);

const renderCustomDay =
    (checkInByShiftDate: CheckInsByShiftDate[]) =>
    (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>
    ) => {
        const formattedDate = format(date, 'yyyy-MM-dd');

        const checkInsForDate =
            checkInByShiftDate.find(
                (item) => item.shiftDayDate === formattedDate
            )?.checkIns ?? [];

        return (
            <Box
                key={date.toString()}
                sx={{
                    position: 'relative',
                }}
            >
                <CustomPickersDay {...pickersDayProps} component="div" />
                <Box
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: pxToRem(2),
                    }}
                >
                    {checkInsForDate.length > 0 &&
                        checkInsForDate.map((checkIn, index) => (
                            <Box
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                sx={{
                                    bgcolor: CHECK_IN_ICON_COLOR_MAP[checkIn],
                                    width: pxToRem(8),
                                    height: pxToRem(8),
                                    borderRadius: '50%',
                                    alignSelf: 'flex-end',
                                }}
                            />
                        ))}
                </Box>
            </Box>
        );
    };

type Props = {
    checkInByShiftDate: CheckInsByShiftDate[];
};

export const CheckInCalendar = ({ checkInByShiftDate }: Props) => {
    let minCheckedInDate = new Date();

    if (checkInByShiftDate.length > 0) {
        const checkedInDates = checkInByShiftDate.map((item) =>
            parseISO(item.shiftDayDate)
        );
        minCheckedInDate = min(checkedInDates);
    }

    return (
        <Box
            sx={{
                mt: pxToRem(16),
                mb: pxToRem(24),
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomCalendarPicker
                    readOnly
                    onChange={() => {
                        // intentionally empty
                    }}
                    renderDay={renderCustomDay(checkInByShiftDate)}
                    views={['day']}
                    date={new Date()}
                    disableFuture
                    minDate={minCheckedInDate}
                />
            </LocalizationProvider>
            <CheckInCalendarLegend />
        </Box>
    );
};
