import { MoreHoriz as MoreOptionsIcon } from '@mui/icons-material';
import {
    Box,
    Card,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { MouseEvent, useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';

import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';

const CardStyle = styled(Card)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        p: pxToRem(8),
        display: 'flex',
        flexWrap: 'wrap',
        color: theme.palette.common.black,
        backgroundColor: theme.palette.app.grey.main,
        fontWeight: 'bold',
        width: { xs: '100%', md: pxToRem(360) },
    })
);

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pb: pxToRem(8),
        display: 'flex',
        width: '100%',
        '&:last-of-type': {
            pb: 0,
        },
    })
);

const FirstRowContent = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        width: `calc(100% - ${pxToRem(24)})`,
    })
);

const GreyLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        display: 'flex',
        color: theme.palette.grey[500],
    })
);

const MenuIcon = styled(IconButton)(({ theme }) =>
    theme.unstable_sx({
        p: 0,
        width: pxToRem(24),
    })
);

const MenuStyle = styled(Menu)(({ theme }) =>
    theme.unstable_sx({
        p: 0,
        display: 'block',
        '& .MuiPaper-root': {
            width: pxToRem(112),
            '& .MuiList-root.MuiMenu-list .MuiMenuItem-root.MuiMenuItem-gutters':
                {
                    justifyContent: 'center',
                },
        },
    })
);

const SecondRowContent = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    })
);

const ReportRow = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    })
);

type Props = {
    caregiver: string;
    caregiverId: number;
    category: string;
    dictionary: LanguageStructure;
    elapsedTimeLabel: string;
    report: string;
    onEdit: () => void;
    onDelete: () => void;
};

const ShiftNoteCard = (props: Props) => {
    const {
        caregiver,
        caregiverId,
        category,
        dictionary: { residents: residentsDictionary, shared },
        elapsedTimeLabel,
        report,
        onEdit,
        onDelete,
    } = props;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(
        null
    );

    // Get the user's ID and his/her Role Level ID.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { userId = 0, roleLevelId = 0 } = storedSession?.sessionData || {};
    const isManager = roleLevelId === 4;
    const isCaregiver = roleLevelId === 5;
    const isAllowed = isManager || isCaregiver;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setMenuOpen(true);
        setAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
        setAnchorElement(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        onEdit();
    };

    const handleDelete = () => {
        handleMenuClose();
        onDelete();
    };

    return (
        <CardStyle>
            <Row>
                <FirstRowContent variant="subtitle2">
                    {caregiver}
                    <GreyLabel variant="body2">{elapsedTimeLabel}</GreyLabel>
                </FirstRowContent>
                {isAllowed && userId === caregiverId && (
                    <MenuIcon onClick={handleClick}>
                        <MoreOptionsIcon />
                    </MenuIcon>
                )}
                <MenuStyle
                    id="shift-note-menu"
                    anchorEl={anchorElement}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={menuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleEdit}>
                        <Typography variant="body2">{shared.edit}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                        <Typography variant="body2">{shared.delete}</Typography>
                    </MenuItem>
                </MenuStyle>
            </Row>
            <Row>
                <SecondRowContent variant="subtitle2">
                    {residentsDictionary.shiftNoteCatetory}
                    <GreyLabel variant="body2">{category}</GreyLabel>
                </SecondRowContent>
            </Row>
            <Row>
                <ReportRow variant="body2">{report}</ReportRow>
            </Row>
        </CardStyle>
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedShiftNoteCard: any = connect(mapStateToProps)(ShiftNoteCard);

export default ConnectedShiftNoteCard;
