import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import SimpleBarReact from 'simplebar-react';

import { pxToRem } from 'components/theme/typography';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
    })
);

const SimpleBar = styled(SimpleBarReact)(({ theme }) =>
    theme.unstable_sx({
        height: '100%',
        '& .simplebar-scrollbar': {
            '&:before': {
                backgroundColor: alpha(theme.palette.grey[600], 0.5),
            },
            '&.simplebar-visible:bofore': {
                opacity: 1,
            },
        },
        '& .simplebar-track.simplebar-vertical': {
            width: pxToRem(10),
        },
        '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
            height: pxToRem(6),
        },
        '& .simplebar-mask': {
            zIndex: 'inherit',
        },
    })
);

const Scrollbar = ({ children, ...other }) => (
    <RootStyle>
        <SimpleBar timeout={500} clickOnTrack={false} {...other}>
            {children}
        </SimpleBar>
    </RootStyle>
);

export default Scrollbar;
