import { FiltersState } from 'types/filters';

import { FILTERS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const filters = (
    state: FiltersState = FILTERS_DEFAULT_STATE as FiltersState,
    action
) => {
    switch (action.type) {
        case actionTypes.FILTER_CAREGIVER_ZONE_UPDATE: {
            return {
                ...state,
                caregiverApp: {
                    ...state.caregiverApp,
                    zoneId: action.payload.zoneId,
                },
            };
        }
        case actionTypes.SORT_BY_UPDATE: {
            return {
                ...state,
                caregiverApp: {
                    ...state.caregiverApp,
                    sortBy: action.payload.sortBy,
                },
            };
        }
        case actionTypes.SORT_ORDER_UPDATE: {
            return {
                ...state,
                caregiverApp: {
                    ...state.caregiverApp,
                    sortOrder: action.payload.sortOrder,
                },
            };
        }
        default:
            return state;
    }
};

export default filters;
