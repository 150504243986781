import emailjs from '@emailjs/browser';
import { Button } from '@mui/material';
import { encode as base64Encode } from 'base-64';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import {
    InvitationCreateUpdateParams,
    InvitationFormErrors,
} from 'types/signupInvitations';
import { RoleResponse, UserInvitation } from 'types/users';

import {
    CustomAutocomplete,
    CustomDialog,
    CustomTextField,
} from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import {
    EMAIL_JS_INVITATION_TEMPLATE_ID,
    EMAIL_JS_PUBLIC_KEY,
    EMAIL_JS_SERVICE_ID,
} from 'constants/app';
import { PROFILE } from 'constants/localStorage';
import {
    ONLY_EMAIL_REGEX,
    ONLY_LETTERS_AND_SPACES_REGEX,
    ONLY_START_WITH_LETTER_REGEX,
} from 'constants/regex';
import { NOTIFICATION_TYPE } from 'constants/shared';
import {
    createInvitation,
    verifyInvitation,
} from 'redux/actions/signupInvitations';
import { verifyEmail } from 'redux/actions/users';

type Props = {
    dictionary: LanguageStructure;
    emailExists?: boolean;
    invitationExists?: boolean;
    isOpen: boolean;
    rolesList?: RoleResponse[];
    dispatchCreateInvitation: (
        jsonParams: InvitationCreateUpdateParams
    ) => void;
    dispatchVerifyEmail: (email: string) => void;
    dispatchVerifyInvitation: (email: string) => void;
    onSubmit: (
        title: string,
        message: string,
        notification: NOTIFICATION_TYPE
    ) => void;
    onClose: () => void;
};

const InvitationDialog = (props: Props) => {
    const formRef = useRef() as MutableRefObject<HTMLFormElement>;
    const { register, setValue } = useForm();
    const {
        dictionary: { shared, userManagementUsersList },
        emailExists,
        invitationExists,
        isOpen,
        rolesList,
        dispatchCreateInvitation,
        dispatchVerifyEmail,
        dispatchVerifyInvitation,
        onSubmit,
        onClose,
    } = props;

    // Get the role id from the stored data.
    const storedSession: StoredSession = JSON.parse(
        localStorage.getItem(PROFILE) as string
    );
    const { company, roleId } = storedSession?.sessionData || {};

    // Define the tabs to display.
    const showManagers = roleId <= 3;
    const showAdmins = roleId <= 2;

    const [invitationForm, setInvitationForm] = useState<UserInvitation>({
        userRoleId: roleId + 1,
        invitationEmail: '',
        invitationFirstName: '',
        invitationLastName: '',
    });
    const [roleOptions, setRoleOptions] = useState<DropdownStructure[]>([]);
    const [errors, setErrors] = useState<InvitationFormErrors>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const fieldValidation = (
        field: string,
        fieldValue: string,
        isEmail: boolean
    ) => {
        ONLY_LETTERS_AND_SPACES_REGEX.lastIndex = 0;
        ONLY_START_WITH_LETTER_REGEX.lastIndex = 0;
        ONLY_EMAIL_REGEX.lastIndex = 0;
        const onlyLettersCheck = ONLY_LETTERS_AND_SPACES_REGEX.test(fieldValue);
        const startWithLetterCheck =
            ONLY_START_WITH_LETTER_REGEX.test(fieldValue);
        const onlyEmailCheck = ONLY_EMAIL_REGEX.test(fieldValue);

        if (!isEmail && !onlyLettersCheck) {
            setErrors({
                ...errors,
                [field]: shared.onlyLettersAndSpaces,
            });
            return;
        }

        if (!isEmail && !startWithLetterCheck) {
            setErrors({
                ...errors,
                [field]: shared.mustStartWithALetter,
            });
            return;
        }

        if (isEmail && !onlyEmailCheck) {
            setErrors({
                ...errors,
                invitationEmail: shared.onlyEmail,
            });
            return;
        }

        const errorsClone = cloneDeep(errors);
        delete errorsClone[field];
        setErrors(errorsClone);
    };

    const handleFormFieldChange =
        (field: string) => (newValue: string | DropdownValue) => {
            fieldValidation(
                field,
                newValue as string,
                field === 'invitationEmail'
            );

            setInvitationForm({
                ...invitationForm,
                [field]: newValue,
            });
        };

    const handleValidateUniqueEmail = () => {
        if (!invitationForm.invitationEmail) {
            return;
        }

        dispatchVerifyEmail(invitationForm.invitationEmail);
        dispatchVerifyInvitation(invitationForm.invitationEmail);
    };

    const validateForm = (hasErrors?: boolean) => {
        const valid: boolean =
            !hasErrors &&
            isEmpty(errors) &&
            !isNil(emailExists) &&
            !emailExists &&
            !isNil(invitationExists) &&
            !invitationExists;
        setIsFormValid(valid);
    };

    const handleSendInvitationClick = () => {
        // Do nothing if the button was triggered but the form is invalid.
        if (!isFormValid) {
            return;
        }

        const signUpLinkParams = {
            email: invitationForm.invitationEmail,
        };
        const encodedLink = base64Encode(JSON.stringify(signUpLinkParams));

        // Add the Link Code to the Form that will be submitted through emailjs.
        setValue('link_code', encodedLink);
        emailjs
            .sendForm(
                EMAIL_JS_SERVICE_ID,
                EMAIL_JS_INVITATION_TEMPLATE_ID,
                formRef.current,
                EMAIL_JS_PUBLIC_KEY
            )
            .then(
                (result) => {
                    // Define the params for creating the new invitation into the DB.
                    const jsonParams: InvitationCreateUpdateParams = {
                        email: invitationForm.invitationEmail,
                        first_name: invitationForm.invitationFirstName,
                        last_name: invitationForm.invitationLastName,
                        role_id: invitationForm.userRoleId,
                    };

                    // Store the new invitation into the DB.
                    dispatchCreateInvitation(jsonParams);

                    // Show a Success notification.
                    onSubmit(
                        userManagementUsersList.invitationSuccess,
                        userManagementUsersList.invitationNotificationSuccess,
                        NOTIFICATION_TYPE.success
                    );
                },
                (error) => {
                    // Show an Error notification.
                    onSubmit(
                        userManagementUsersList.invitationError,
                        userManagementUsersList.invitationNotificationError,
                        NOTIFICATION_TYPE.error
                    );
                }
            );
    };

    useEffect(() => {
        if (rolesList) {
            const newRoleOptions: DropdownStructure[] = rolesList.map(
                (role) => ({
                    label: role.roleName,
                    value: role.roleId,
                })
            );

            setRoleOptions(newRoleOptions);
        }
    }, [rolesList]);

    useEffect(() => {
        // Show en error for the email field if the email already exists for a valid user.
        if (emailExists) {
            setErrors({
                ...errors,
                invitationEmail: userManagementUsersList.emailExists,
            });
        }

        validateForm(true);
    }, [emailExists]);

    useEffect(() => {
        // Show en error for the email field if the email already exists as a Pending Invitation.
        if (invitationExists) {
            setErrors({
                ...errors,
                invitationEmail: userManagementUsersList.invitationExists,
            });
        }

        validateForm(true);
    }, [invitationExists]);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        if (!isOpen) {
            setErrors({});
            setInvitationForm({
                userRoleId: roleId + 1,
                invitationEmail: '',
                invitationFirstName: '',
                invitationLastName: '',
            });
        }
    }, [isOpen]);

    return (
        <CustomDialog
            id="invitation"
            closeable
            open={isOpen}
            title={userManagementUsersList.inviteUser}
            width={pxToRem(400)}
            content={
                <>
                    {(showManagers || showAdmins) && (
                        <CustomAutocomplete
                            label={userManagementUsersList.role}
                            value={invitationForm.userRoleId}
                            options={roleOptions}
                            fullWidth
                            onChange={handleFormFieldChange('userRoleId')}
                        />
                    )}
                    <CustomTextField
                        id="invitationFirstName"
                        label={userManagementUsersList.invitationFirstName}
                        value={invitationForm.invitationFirstName}
                        customErrorMessage={errors.invitationFirstName}
                        fullWidth
                        required
                        onBlur={validateForm}
                        onChange={handleFormFieldChange('invitationFirstName')}
                    />
                    <CustomTextField
                        id="invitationLastName"
                        label={userManagementUsersList.invitationLastName}
                        value={invitationForm.invitationLastName}
                        customErrorMessage={errors.invitationLastName}
                        fullWidth
                        required
                        onBlur={validateForm}
                        onChange={handleFormFieldChange('invitationLastName')}
                    />
                    <CustomTextField
                        id="invitationEmail"
                        label={userManagementUsersList.invitationEmail}
                        value={invitationForm.invitationEmail}
                        customErrorMessage={errors.invitationEmail}
                        fullWidth
                        required
                        onBlur={handleValidateUniqueEmail}
                        onChange={handleFormFieldChange('invitationEmail')}
                    />
                    <form ref={formRef} style={{ display: 'none' }}>
                        <input value={company} {...register('company_name')} />
                        <input
                            value={`${invitationForm.invitationFirstName} ${invitationForm.invitationLastName}`}
                            {...register('to_name')}
                        />
                        <input
                            value={invitationForm.invitationEmail}
                            {...register('to_email')}
                        />
                        <input value="" {...register('link_code')} />
                        <input type="submit" />
                    </form>
                </>
            }
            actions={
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onClose}
                    >
                        {shared.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!isFormValid}
                        onClick={handleSendInvitationClick}
                    >
                        {userManagementUsersList.sendInvitation}
                    </Button>
                </>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({
    language,
    signupInvitations,
    users,
}: ReduxStore) => {
    const { dictionary } = language;
    const { emailExists, rolesList } = users;
    const { invitationExists } = signupInvitations;

    return {
        dictionary,
        emailExists,
        invitationExists,
        rolesList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateInvitation: (jsonParams: InvitationCreateUpdateParams) =>
        dispatch(createInvitation(jsonParams)),
    dispatchVerifyEmail: (email: string) => dispatch(verifyEmail(email)),
    dispatchVerifyInvitation: (email: string) =>
        dispatch(verifyInvitation(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationDialog);
