import { ActionType } from 'types/redux';

const createActionTypes = (types: string[]): ActionType =>
    types.reduce((actionTypes, key) => ({ ...actionTypes, [key]: key }), {});

export default createActionTypes([
    // Messages
    'MESSAGE_SHOW',
    'MESSAGE_HIDE',
    // Location
    'LOCATION_UPDATE',
    // Session
    'SESSION_SIGN_IN',
    'SESSION_SIGN_OUT',
    'SESSION_VALIDATE_SESSION',
    'SESSION_UPDATE_PRIOR_PARENT_PAGE',
    'SESSION_UPDATE_TIMEZONE',
    // Language
    'LANGUAGE_TOGGLE',
    // Users
    'USER_BY_EMAIL',
    'USER_CREATE',
    'USER_READ',
    'USER_READ_LIST',
    'USER_UPDATE_SUCCESS',
    'USER_UPDATE_FAIL',
    'USER_DELETE',
    'USER_LOADING',
    'USER_READ_ROLE',
    'USER_READ_ROLES_LIST',
    'USER_EMAIL_CHECK',
    'USER_CLEAR_DETAILS',
    'USER_CLEAR_ACTION_STATUS',
    'USER_PASSWORD_UPDATE',
    // Zones
    'ZONE_READ_LIST',
    'ZONE_UPDATE',
    'ZONE_LOADING',
    // Tasks Statuses
    'TASK_STATUS_READ_LIST',
    'TASK_STATUS_LOADING',
    // Company Care Plans
    'COMPANY_CARE_PLAN_READ_LIST',
    'COMPANY_CARE_PLAN_LOADING',
    // Dashboard
    'DASHBOARD_READ_COUNTERS',
    'DASHBOARD_READ_INCOMPLETED_TASKS',
    'DASHBOARD_READ_INCOMPLETED_TASKS_BY_RESIDENTS',
    'DASHBOARD_READ_NOTES',
    'DASHBOARD_READ_UNSCHEDULED_TASKS',
    'DASHBOARD_READ_OVERDUE_TASKS',
    'DASHBOARD_READ_OVERDUE_TASKS_BY_RESIDENTS',
    'DASHBOARD_READ_PERFORMANCE',
    'DASHBOARD_UPDATE_FILTERS',
    'DASHBOARD_LOADING',
    // Daily Tasks
    'DAILY_TASKS_CREATE',
    'DAILY_TASKS_READ_LIST',
    'DAILY_TASKS_UPDATE_TASK',
    'DAILY_TASKS_UPDATE_SHIFT_PERIOD',
    'DAILY_TASKS_DOWNLOAD_CSV',
    'DAILY_TASKS_LOADING',
    // Residents
    'RESIDENT_TOGGLE_DISPLAY_PARTY',
    // Shift Notes Categories
    'SHIFT_NOTES_CATEGORY_READ_LIST',
    'SHIFT_NOTES_CATEGORY_LOADING',
    // Resident Shift Notes
    'RESIDENT_SHIFT_NOTES_READ_LIST',
    'RESIDENT_SHIFT_NOTES_DELETE',
    'RESIDENT_SHIFT_NOTES_LOADING',
    // Sign-up Invitations
    'SIGNUP_INVITATION_CREATE',
    'SIGNUP_INVITATION_READ',
    'SIGNUP_INVITATION_READ_FAIL',
    'SIGNUP_INVITATION_READ_LIST',
    'SIGNUP_INVITATION_UPDATE',
    'SIGNUP_INVITATION_UPDATE_STATUS',
    'SIGNUP_INVITATION_USER_CREATE',
    'SIGNUP_INVITATION_EMAIL_CHECK',
    'SIGNUP_INVITATION_LOADING',
    // Filters
    'FILTER_CAREGIVER_ZONE_UPDATE',
    'SORT_BY_UPDATE',
    'SORT_ORDER_UPDATE',
]);
