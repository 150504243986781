import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

export const WarningIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            d="M23.6571 17.5802L14.2879 1.30929C14.0538 0.910658 13.7196 0.580132 13.3183 0.350475C12.9171 0.120817 12.4628 0 12.0005 0C11.5382 0 11.084 0.120817 10.6827 0.350475C10.2815 0.580132 9.94728 0.910658 9.71315 1.30929L0.343989 17.5802C0.118718 17.9658 0 18.4043 0 18.8509C0 19.2974 0.118718 19.736 0.343989 20.1215C0.575117 20.5226 0.908785 20.8549 1.31075 21.0844C1.71272 21.3139 2.16852 21.4324 2.63137 21.4275H21.3697C21.8322 21.432 22.2875 21.3134 22.6891 21.0839C23.0907 20.8544 23.424 20.5223 23.6549 20.1215C23.8805 19.7362 23.9996 19.2977 24 18.8512C24.0004 18.4046 23.882 17.966 23.6571 17.5802ZM11.1434 8.57106C11.1434 8.34374 11.2337 8.12574 11.3945 7.965C11.5552 7.80426 11.7732 7.71396 12.0005 7.71396C12.2279 7.71396 12.4459 7.80426 12.6066 7.965C12.7673 8.12574 12.8576 8.34374 12.8576 8.57106V12.8566C12.8576 13.0839 12.7673 13.3019 12.6066 13.4626C12.4459 13.6234 12.2279 13.7137 12.0005 13.7137C11.7732 13.7137 11.5552 13.6234 11.3945 13.4626C11.2337 13.3019 11.1434 13.0839 11.1434 12.8566V8.57106ZM12.0005 17.9991C11.7463 17.9991 11.4977 17.9237 11.2863 17.7825C11.0748 17.6412 10.9101 17.4404 10.8127 17.2055C10.7154 16.9706 10.69 16.7121 10.7396 16.4627C10.7892 16.2133 10.9116 15.9842 11.0914 15.8044C11.2712 15.6246 11.5003 15.5022 11.7497 15.4526C11.9991 15.4029 12.2576 15.4284 12.4925 15.5257C12.7275 15.623 12.9282 15.7878 13.0695 15.9992C13.2108 16.2107 13.2862 16.4592 13.2862 16.7135C13.2862 17.0545 13.1507 17.3815 12.9096 17.6226C12.6685 17.8637 12.3415 17.9991 12.0005 17.9991Z"
            fill="#EC3232"
        />
    </Base>
);
