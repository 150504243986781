import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { DateRange } from 'types/common';
import {
    CompanyCarePlanResponse,
    CompanyCarePlansListReadParams,
} from 'types/companyCarePlans';
import {
    DailyTaskDownloadParams,
    ResidentTasksDownloadParams,
} from 'types/dailyTasks';
import {
    DashboardFilters,
    DashboardsFilterType,
    DashboardsFiltersForm,
} from 'types/dashboard';
import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { CheckedRow } from 'types/table';
import {
    TaskStatusesReadParams,
    TaskStatusesResponse,
} from 'types/taskStatuses';
import { UserResponse, UsersReadParams } from 'types/users';
import { ZoneResponse, ZonesListReadParams } from 'types/zones';

import { useResidentsQuery } from 'api/queries/residents';
import { CustomAutocomplete } from 'components/Custom';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import { DATE_FORMAT } from 'constants/app';
import {
    DASHBOARD_CATEGORIES,
    DASHBOARD_FILTERS_FORM_INITIAL_VALUES,
} from 'constants/dashboards';
import { SHIFTS_MAP } from 'constants/home';
import { formatApiParams } from 'lib/common';
import { readCompanyCarePlans } from 'redux/actions/companyCarePlans';
import { downloadDailyTaskCsv } from 'redux/actions/dailyTasks';
import { readTaskStatuses } from 'redux/actions/tasksStatuses';
import { readUsers } from 'redux/actions/users';
import { readZones } from 'redux/actions/zones';

const useStyles = makeStyles({
    resident: {
        width: pxToRem(208),
    },
    shift: {
        width: pxToRem(120),
    },
    task: {
        width: pxToRem(272),
    },
    taskCategory: {
        width: pxToRem(192),
    },
    caregiver: {
        width: pxToRem(208),
    },
    zone: {
        width: pxToRem(160),
    },
});

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

export const FilterContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        display: 'flex',
        '&:last-of-type': {
            mr: 0,
        },
    })
);

const DownloadDataBtn = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(215),
        minWidth: pxToRem(160),
        ml: pxToRem(8),
        mb: pxToRem(10),
    })
);

type Props = {
    branchId: number;
    companyCarePlansList: CompanyCarePlanResponse[];
    companyId: number;
    dictionary: LanguageStructure;
    filters: DashboardFilters;
    filtersType: DashboardsFilterType;
    taskStatusesList: TaskStatusesResponse[];
    usersList: UserResponse[];
    zonesList: ZoneResponse[];
    dispatchReadCompanyCarePlans: (
        params: CompanyCarePlansListReadParams
    ) => void;
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) => void;
    dispatchReadUsers: (params: UsersReadParams) => void;
    dispatchReadZones: (params: ZonesListReadParams) => void;
    onFiltersChange: (newFilters: DashboardsFiltersForm) => void;
    dispatchDownloadDailyTaskCsv: (
        downloadUrl: string,
        params: DailyTaskDownloadParams | ResidentTasksDownloadParams
    ) => void;
    rowsCheckedStatus: CheckedRow;
    dateRange?: DateRange;
};

const Filters = (props: Props) => {
    const classes = useStyles();
    const {
        branchId,
        companyCarePlansList,
        companyId,
        dictionary: { dashboards, home },
        filters,
        filtersType,
        taskStatusesList,
        usersList,
        zonesList,
        dispatchReadCompanyCarePlans,
        dispatchReadTaskStatuses,
        dispatchReadUsers,
        dispatchReadZones,
        onFiltersChange,
        dispatchDownloadDailyTaskCsv,
        rowsCheckedStatus,
        dateRange,
    } = props;
    const [assignedResidents, setAssignedResidents] = useState<
        DropdownStructure[]
    >([]);
    const [assignedZones, setAssignedZones] = useState<DropdownStructure[]>([]);
    const [tasksCategories, setTasksCategories] = useState<DropdownStructure[]>(
        []
    );
    const [tasksShifts, setTasksShifts] = useState<DropdownStructure[]>([]);
    const [caregiverOptions, setCaregiverOptions] = useState<
        DropdownStructure[]
    >([]);
    const [tasksOptions, setTasksOptions] = useState<DropdownStructure[]>([]);
    const [statusesOptions, setStatusesOptions] = useState<DropdownStructure[]>(
        []
    );
    const [filtersForm, setFiltersForm] = useState<DashboardsFiltersForm>(
        DASHBOARD_FILTERS_FORM_INITIAL_VALUES
    );

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery({
        branchId: filters?.branchId,
    });

    const handleDropdownChange =
        (filterName: string) => (optionValue: DropdownValue) => {
            const newFilters: DashboardsFiltersForm = {
                ...filtersForm,
                [filterName]: optionValue as number,
            };
            setFiltersForm(newFilters);
            onFiltersChange(newFilters);
        };

    useEffect(() => {
        const newTasksCategories: DropdownStructure[] =
            DASHBOARD_CATEGORIES.map((category, index) => ({
                label: dashboards[category],
                value: index + 1,
            }));
        setTasksCategories(newTasksCategories);

        const newTasksShifts: DropdownStructure[] = Object.values(
            SHIFTS_MAP
        ).map((shift, index) => ({
            label: shift,
            value: index + 1,
        }));
        setTasksShifts(newTasksShifts);

        if (!companyCarePlansList?.length) {
            // Set the params to send to the API.
            const params = { companyId };

            // Trigger the action for fetching the Company Care Plans corresponding to the User's Company ID.
            dispatchReadCompanyCarePlans(params);
        }

        if (!usersList?.length) {
            // Set the params to send to the API.
            const params: UsersReadParams = {
                roleId: 5,
                companyId,
                branchId,
            };

            dispatchReadUsers(params);
        }

        if (!taskStatusesList?.length) {
            const params: TaskStatusesReadParams = {
                startingFromId: 4,
            };

            dispatchReadTaskStatuses(params);
        }
    }, []);

    useEffect(() => {
        if (filters) {
            const zonesParams: ZonesListReadParams = {
                branchId: filters.branchId,
            };
            dispatchReadZones(zonesParams);
        }
    }, [filters]);

    useEffect(() => {
        if (companyCarePlansList?.length) {
            const newOptions: DropdownStructure[] = companyCarePlansList.map(
                (companyCarePlan) => ({
                    label: companyCarePlan.taskName,
                    value: companyCarePlan.companyCarePlanId,
                })
            );
            setTasksOptions(newOptions);
        }
    }, [JSON.stringify(companyCarePlansList)]);

    useEffect(() => {
        if (usersList?.length) {
            const newOptions: DropdownStructure[] = usersList.map(
                (caregiver) => ({
                    label: `${caregiver.firstName} ${caregiver.lastName}`,
                    value: caregiver.userId,
                })
            );
            setCaregiverOptions(newOptions);
        }
    }, [JSON.stringify(usersList)]);

    useEffect(() => {
        if (taskStatusesList?.length) {
            const newTaskStatuses: DropdownStructure[] = taskStatusesList
                .filter((status) => status.id >= 4)
                .map((status) => ({
                    label: status.name,
                    value: status.id,
                }));
            setStatusesOptions(newTaskStatuses);
        }
    }, [JSON.stringify(taskStatusesList)]);

    useEffect(() => {
        if (residentsList?.length) {
            const newAssignedResidents: DropdownStructure[] = residentsList.map(
                (resident) => ({
                    label: `${resident.firstName} ${resident.lastName}`,
                    value: resident.residentId,
                })
            );
            setAssignedResidents(newAssignedResidents);
        }
    }, [JSON.stringify(residentsList)]);

    useEffect(() => {
        if (zonesList.length) {
            const newAssignedZones: DropdownStructure[] = zonesList.map(
                (zone) => ({
                    label: zone.zoneName,
                    value: zone.zoneId,
                })
            );
            setAssignedZones(newAssignedZones);
        }
    }, [JSON.stringify(zonesList)]);

    const downloadData = () => {
        const ids: number[] = [];
        let url = '';
        let fileName = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(rowsCheckedStatus)) {
            if (value) {
                ids.push(Number(key));
            }
        }
        const params: any = {
            branch_id: Number(branchId),
        };
        if (filtersType === 'all') {
            url = 'csv/daily-task-records-by-resident';
            params.resident_ids = ids;
            params.start_period = `${moment(dateRange?.startDate).format(
                DATE_FORMAT
            )} 00:00:00`;
            params.end_period = `${moment(dateRange?.endDate).format(
                DATE_FORMAT
            )} 23:59:59`;
            fileName = 'all-task';
        } else {
            url = 'csv/daily-task-records-by-id';
            params.task_ids = ids;
            fileName =
                filtersType === 'incompleted'
                    ? 'incompleted-tasks'
                    : 'over-due-tasks';
        }
        fileName += moment(new Date()).format('YYYY-MM-DD');
        // Encode the params to be sent to the API.
        const downloadLink = document.createElement('a');
        // downloadLink.href = `data:text/csv; charset=utf-8, ${encodeURI(csvContent)}`;
        const encodedParams = formatApiParams(params);
        downloadLink.href = `${process.env.REACT_APP_BASE_SERVER_URL}${url}?params=${encodedParams.params}`;
        downloadLink.target = '_blank';
        downloadLink.download = fileName;
        downloadLink.click();
        // dispatchDownloadDailyTaskCsv(url, params);
    };

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <RootStyle>
            {filtersType !== 'all' && (
                <FilterContainer className={classes.task}>
                    <CustomAutocomplete
                        label={dashboards.task}
                        value={filtersForm.taskId}
                        options={tasksOptions}
                        fullWidth
                        onChange={handleDropdownChange('taskId')}
                    />
                </FilterContainer>
            )}
            <FilterContainer className={classes.resident}>
                <CustomAutocomplete
                    label={dashboards.resident}
                    value={filtersForm.residentId}
                    options={assignedResidents}
                    fullWidth
                    onChange={handleDropdownChange('residentId')}
                />
            </FilterContainer>
            <FilterContainer className={classes.zone}>
                <CustomAutocomplete
                    label={dashboards.zone}
                    value={filtersForm.zoneId}
                    options={assignedZones}
                    fullWidth
                    onChange={handleDropdownChange('zoneId')}
                />
            </FilterContainer>
            {filtersType === 'all' && (
                <>
                    <FilterContainer className={classes.task}>
                        <CustomAutocomplete
                            label={dashboards.task}
                            value={filtersForm.taskId}
                            options={tasksOptions}
                            fullWidth
                            onChange={handleDropdownChange('taskId')}
                        />
                    </FilterContainer>
                    <FilterContainer className={classes.taskCategory}>
                        <CustomAutocomplete
                            label={dashboards.taskStatus}
                            value={filtersForm.taskCategoryId}
                            options={tasksCategories}
                            fullWidth
                            onChange={handleDropdownChange('taskCategoryId')}
                        />
                    </FilterContainer>
                </>
            )}
            {/* <FilterContainer className={classes.caregiver}>
        <CustomAutocomplete
          label={dashboards.caregiver}
          value={filtersForm.caregiverId}
          options={caregiverOptions}
          fullWidth
          onChange={handleDropdownChange('caregiverId')}
        />
      </FilterContainer> */}
            {filtersType === 'incompleted' && (
                <FilterContainer className={classes.caregiver}>
                    <CustomAutocomplete
                        label={dashboards.taskStatus}
                        value={filtersForm.taskStatusId}
                        options={statusesOptions}
                        fullWidth
                        onChange={handleDropdownChange('taskStatusId')}
                    />
                </FilterContainer>
            )}
            <FilterContainer className={classes.shift}>
                <CustomAutocomplete
                    label={home.shift}
                    value={filtersForm.shiftId}
                    options={tasksShifts}
                    fullWidth
                    onChange={handleDropdownChange('shiftId')}
                />
            </FilterContainer>
            {/* <DownloadDataBtn variant="contained" color="primary" onClick={() => downloadData()} disabled={isEmpty(rowsCheckedStatus)}>Download Data</DownloadDataBtn> */}
        </RootStyle>
    );
};

const mapStateToProps = ({
    companyCarePlans,
    dashboards,
    language,
    session,
    taskStatuses,
    users,
    zones,
}: ReduxStore) => {
    const { companyCarePlansList } = companyCarePlans;
    const { filters } = dashboards;
    const { dictionary } = language;
    const {
        sessionData: { branchId, companyId },
    } = session;
    const { taskStatusesList } = taskStatuses;
    const { usersList } = users;
    const { zonesList } = zones;

    return {
        branchId,
        companyCarePlansList,
        companyId,
        dictionary,
        filters,
        taskStatusesList,
        usersList,
        zonesList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadCompanyCarePlans: (params: CompanyCarePlansListReadParams) =>
        dispatch(readCompanyCarePlans(params)),
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) =>
        dispatch(readTaskStatuses(params)),
    dispatchReadUsers: (params: UsersReadParams) => dispatch(readUsers(params)),
    dispatchReadZones: (params: ZonesListReadParams) =>
        dispatch(readZones(params)),
    dispatchDownloadDailyTaskCsv: (
        downloadUrl: string,
        params: DailyTaskDownloadParams | ResidentTasksDownloadParams
    ) => dispatch(downloadDailyTaskCsv(downloadUrl, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
