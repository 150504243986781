import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';
import { connect, useSelector } from 'react-redux';

import { CompanyCarePlanResponse } from 'types/companyCarePlans';
import { DailyTasksCreateParams } from 'types/dailyTasks';
import { MessageProps } from 'types/messages';
import { ReduxStore } from 'types/redux';

import {
    useRewardsDetailsQuery,
    useRewardsHistoryQuery,
    useRewardsSummaryQuery,
} from 'api/queries/caregiverRewards';
import { useDailyTasks } from 'api/queries/tasks/dailyTasks';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';
import { NURSE_CALLS_DIALOG_ANALYTICS } from 'constants/analytics';
import { createDailyTask } from 'redux/actions/dailyTasks';
import { showAlert } from 'redux/actions/messages';

type Props = {
    companyCarePlansList: CompanyCarePlanResponse[];
    residentId: number;
    caregiverId: number;
    branchId: number;
    selectedDate: string;
    onClose: () => void;
    dispatchShowAlert: (message: MessageProps) => void;
    dispatchCreateDailyTask: (params: DailyTasksCreateParams) => void;
};

const NurseCallsCheckbox = (props: Props) => {
    const {
        companyCarePlansList,
        residentId,
        caregiverId,
        branchId,
        selectedDate,
        onClose,
        dispatchShowAlert,
        dispatchCreateDailyTask,
    } = props;

    const { submit: submitTxt, noNurseCallsButton: noNurseCallsButtonTxt } =
        useSelector((state: ReduxStore) => state.language.dictionary.shared);

    const [selectedValues, setSelectedValues] = useState<number[]>([]);

    const taskOptions = companyCarePlansList
        .filter((companyCarePlan) => companyCarePlan.taskPoints !== null)
        .map((task) => ({
            label: task.taskName,
            value: task.companyCarePlanId,
        }));

    const { refetch: rewardsDetailsRefetch } =
        useRewardsDetailsQuery(caregiverId);
    const { refetch: rewardsSummaryRefetch } =
        useRewardsSummaryQuery(caregiverId);
    const { refetch: rewardsHistoryRefetch } =
        useRewardsHistoryQuery(caregiverId);

    const isChecked = (id: number) => selectedValues.includes(id);

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (isChecked(Number(event.target.value))) {
            const filteredValues = selectedValues.filter(
                (value) => value !== Number(event.target.value)
            );
            setSelectedValues(filteredValues);
            return;
        }

        setSelectedValues([...selectedValues, Number(event.target.value)]);
    };

    const { refetch: dailyTasksRefetch } = useDailyTasks({
        branchId: Number(branchId),
        date: selectedDate,
    });

    const handleNurseCallsSubmit = async () => {
        if (selectedValues.length === 0) {
            const message: MessageProps = {
                open: true,
                message: 'You need to select at least one task',
                alertSeverity: 'error',
                status: 400,
            };

            dispatchShowAlert(message);
            return;
        }

        await Promise.all(
            selectedValues.map(async (companyCarePlanId) => {
                const params: DailyTasksCreateParams = {
                    companyCarePlanId,
                    userId: caregiverId,
                    residentId,
                    taskType: 'Added',
                };

                await dispatchCreateDailyTask(params);
            })
        );

        dailyTasksRefetch();

        rewardsDetailsRefetch();
        rewardsSummaryRefetch();
        rewardsHistoryRefetch();

        onClose();
    };

    const isDisabled = selectedValues.length === 0;

    return (
        <>
            <FormGroup
                sx={{
                    display: 'grid',
                    gap: pxToRem(12),
                    pr: { md: pxToRem(12) },
                    mb: pxToRem(16),
                    maxHeight: '70vh',
                    overflowY: 'auto',
                }}
            >
                {taskOptions.map(({ label, value }) => (
                    <FormControlLabel
                        key={value}
                        value={value}
                        label={label}
                        sx={{
                            paddingBlock: pxToRem(12),
                            pl: pxToRem(12),
                            bgcolor: isChecked(value) ? '#F2F8F8' : 'grey.200',
                            borderRadius: pxToRem(8),
                            border: `1px solid ${
                                isChecked(value) ? '#006b75' : 'transparent'
                            }`,
                            m: '0 !important',
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 700,
                                lineHeight: 1.7,
                            },
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    p: 0,
                                    mr: pxToRem(8),
                                }}
                                checked={isChecked(value)}
                                onChange={handleCheckboxChange}
                                icon={
                                    <Box
                                        sx={{
                                            width: pxToRem(16),
                                            height: pxToRem(16),
                                            borderRadius: '50%',
                                            bgcolor: 'selector.deselected',
                                        }}
                                    />
                                }
                                checkedIcon={
                                    <CheckIcon
                                        fill="#F2F8F8"
                                        style={{
                                            backgroundColor: '#006b75',
                                            borderRadius: '50%',
                                        }}
                                    />
                                }
                            />
                        }
                    />
                ))}
            </FormGroup>
            <Box
                sx={{
                    mt: pxToRem(8),
                    display: 'grid',
                    gridTemplateColumns: '40% 1fr',
                    gap: pxToRem(12),
                }}
            >
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                    sx={{
                        p: pxToRem(12),
                        fontSize: pxToRem(14),
                    }}
                    data-analytics-id={
                        NURSE_CALLS_DIALOG_ANALYTICS.NO_NURSE_CALLS_BUTTON
                    }
                >
                    {noNurseCallsButtonTxt}
                </Button>
                <Button
                    color="primary"
                    onClick={handleNurseCallsSubmit}
                    disabled={isDisabled}
                    sx={{
                        fontSize: pxToRem(14),
                        '&:disabled': {
                            bgcolor: '#C7C8CB',
                        },
                    }}
                    startIcon={
                        <Box
                            component="span"
                            sx={{
                                color: isDisabled ? 'currentcolor' : '#FCD7B1',
                                display: 'flex',
                                mt: pxToRem(3),
                            }}
                        >
                            <GoldCoin size={30} />
                        </Box>
                    }
                    data-analytics-id={
                        NURSE_CALLS_DIALOG_ANALYTICS.SUBMIT_BUTTON
                    }
                >
                    {submitTxt}
                </Button>
            </Box>
        </>
    );
};

const mapStateToProps = ({ companyCarePlans, session }) => {
    const { companyCarePlansList } = companyCarePlans;
    const { sessionData } = session;
    const { userId: caregiverId, branchId } = sessionData;

    return {
        companyCarePlansList,
        caregiverId,
        branchId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchShowAlert: (message: MessageProps) => dispatch(showAlert(message)),
    dispatchCreateDailyTask: (params: DailyTasksCreateParams) =>
        dispatch(createDailyTask(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedNurseCallsCheckbox: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(NurseCallsCheckbox);

export default ConnectedNurseCallsCheckbox;
