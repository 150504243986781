import { alpha } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

export default function AppBar(theme) {
    const { breakpoints, palette } = theme;
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.navbar.background,
                    color: palette.navbar.contrastText,
                    [breakpoints.up('lg')]: {
                        padding: `0 calc((100% - ${pxToRem(992)}) / 2)`,
                    },
                    '& .MuiContainer-root': {
                        padding: 0,
                        '& .MuiToolbar-root': {
                            flexDirection: 'row',
                            [breakpoints.up('lg')]: {
                                minHeight: pxToRem(40),
                                '& .MuiList-root.MuiList-padding': {
                                    padding: 0,
                                    display: 'flex',
                                    '& .MuiListItem-root': {
                                        padding: 0,
                                        marginRight: pxToRem(16),
                                        display: 'flex',
                                        color: alpha(palette.common.white, 0.5),
                                        cursor: 'pointer',
                                        '& .MuiTypography-root.MuiLink-root.MuiLink-underlineNone':
                                            {
                                                color: 'inherit',
                                                '&:hover': {
                                                    color: 'inherit',
                                                },
                                            },
                                    },
                                    '& .MuiListItemButton-root': {
                                        padding: 0,
                                        marginRight: pxToRem(16),
                                        display: 'flex',
                                        color: alpha(palette.common.white, 0.5),
                                        cursor: 'pointer',
                                        '&.Mui-selected': {
                                            color: palette.common.white,
                                            backgroundColor: 'transparent',
                                            borderBottom: `1px solid ${palette.common.white}`,
                                        },
                                        '&:hover': {
                                            color: palette.common.white,
                                            backgroundColor: 'transparent',
                                        },
                                        '& .MuiTypography-root.MuiLink-root.MuiLink-underlineNone':
                                            {
                                                color: 'inherit',
                                                '&:hover': {
                                                    color: 'inherit',
                                                },
                                            },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    };
}
