import { Box, Typography } from '@mui/material';
import momentTz from 'moment-timezone';
import React from 'react';
import { AiOutlineEdit as EditIcon } from 'react-icons/ai';
import {
    FaPlus as PlusIcon,
    FaRegStickyNote as StickyNoteIcon,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { SelectStructure } from 'types/inputs';
import { ReduxStore } from 'types/redux';
import { TaskCompletionOptions } from 'types/residents';

import { CustomSelect } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    taskName: string;
    taskTime: string;
    taskNotes: string;
    taskCompletionOptions?: TaskCompletionOptions;
    completionOptionValue?: number;
    userTaskNotes?: string;
    onToggleNotes: () => void;
    onToggleEditAddTaskNotes: () => void;
    onCompletionOptionsChange: (number) => void;
};

export const TaskDetails = (props: Props) => {
    const {
        taskName,
        taskTime,
        taskNotes,
        taskCompletionOptions,
        completionOptionValue,
        userTaskNotes,
        onToggleNotes,
        onToggleEditAddTaskNotes,
        onCompletionOptionsChange,
    } = props;

    const {
        editNotes: editNotesTxt,
        addNotes: addNotesTxt,
        completionOptionsLabel: completionOptionsLabelTxt,
        completionOptionsPlaceholder: completionOptionsPlaceholderTxt,
    } = useSelector((state: ReduxStore) => state.language.dictionary.home);

    const taskTimeHours = parseInt(taskTime.split(':')[0], 10);
    const taskTimeMinutes = parseInt(taskTime.split(':')[1], 10);
    const currentDateTime = momentTz();
    currentDateTime.set({
        hour: taskTimeHours,
        minute: taskTimeMinutes,
        second: 0,
        millisecond: 0,
    });

    const selectCompletionOptions: SelectStructure[] =
        taskCompletionOptions?.map(({ optionId, optionName }) => ({
            value: optionId.toString(),
            label: optionName,
        })) || [];

    selectCompletionOptions.unshift({
        value: '-1',
        label: completionOptionsPlaceholderTxt,
    });

    return (
        <Box
            sx={{
                marginLeft: pxToRem(12),
                marginRight: pxToRem(12),
                minWidth: 0,
                flexGrow: 1,
            }}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    textAlign: 'left',
                    fontSize: pxToRem(16),
                    lineHeight: pxToRem(24),
                }}
            >
                {taskName}
            </Typography>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: pxToRem(14),
                    lineHeight: pxToRem(24),
                }}
            >
                {currentDateTime.format('hh:mm A')}
            </Typography>
            {taskNotes && (
                <Typography
                    sx={{
                        backgroundColor: '#EFEFF1',
                        borderLeft: 2,
                        borderColor: '#DEDEE0',
                        paddingLeft: pxToRem(8),
                        paddingRight: pxToRem(8),
                        paddingTop: pxToRem(10),
                        paddingBottom: pxToRem(10),
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer',
                    }}
                    onClick={onToggleNotes}
                >
                    {taskNotes}
                </Typography>
            )}
            {taskCompletionOptions && (
                <CustomSelect
                    id="taskCompletionValue"
                    label={completionOptionsLabelTxt}
                    value={
                        completionOptionValue
                            ? completionOptionValue.toString()
                            : '-1'
                    }
                    options={selectCompletionOptions}
                    fullWidth
                    onChange={onCompletionOptionsChange}
                />
            )}
            {userTaskNotes && (
                <Typography
                    sx={{
                        backgroundColor: 'grey.200',
                        borderRadius: pxToRem(8),
                        paddingLeft: pxToRem(12),
                        paddingRight: pxToRem(12),
                        paddingTop: pxToRem(8),
                        paddingBottom: pxToRem(8),
                        display: 'flex',
                        gap: pxToRem(4),
                        alignItems: 'center',
                        marginTop: pxToRem(8),
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <StickyNoteIcon />
                    {userTaskNotes}
                </Typography>
            )}
            <Typography
                sx={{
                    color: 'app.green.main',
                    fontSize: pxToRem(14),
                    lineHeight: pxToRem(16),
                    marginTop: pxToRem(7),
                    cursor: 'pointer',
                    display: 'flex',
                    gap: pxToRem(4),
                    alignItems: 'center',
                }}
                onClick={onToggleEditAddTaskNotes}
            >
                {userTaskNotes ? (
                    <>
                        <EditIcon />
                        {editNotesTxt}
                    </>
                ) : (
                    <>
                        <PlusIcon size={10} />
                        {addNotesTxt}
                    </>
                )}
            </Typography>
        </Box>
    );
};
