import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const ModernHomeIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <g id="vuesax/bold/home-2">
            <g id="vuesax/bold/home-2_2">
                <g id="home-2">
                    <path
                        id="Vector"
                        d="M20.5402 6.82018L14.7802 2.79018C13.2102 1.69018 10.8002 1.75018 9.29023 2.92018L4.28023 6.83018C3.28023 7.61018 2.49023 9.21018 2.49023 10.4702V17.3702C2.49023 19.9202 4.56023 22.0002 7.11023 22.0002H17.8902C20.4402 22.0002 22.5102 19.9302 22.5102 17.3802V10.6002C22.5102 9.25018 21.6402 7.59018 20.5402 6.82018ZM13.2502 18.0002C13.2502 18.4102 12.9102 18.7502 12.5002 18.7502C12.0902 18.7502 11.7502 18.4102 11.7502 18.0002V15.0002C11.7502 14.5902 12.0902 14.2502 12.5002 14.2502C12.9102 14.2502 13.2502 14.5902 13.2502 15.0002V18.0002Z"
                        fill="currentColor"
                    />
                </g>
            </g>
        </g>
    </Base>
);
