import { Avatar, AvatarGroup, Box, Tooltip, Typography } from '@mui/material';
import { startCase } from 'lodash';
import Pluralize from 'pluralize';
import React from 'react';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';

type Props = {
    row: AssistLevelChanges;
};

const AVATAR_SIZE = 20;

export const AssistLevelRowContent = ({ row }: Props) => {
    const { keyService, taskCount, caregivers, carePlan, newPrns } = row;

    return (
        <Box display="flex" justifyContent="space-between">
            <Box>
                {keyService && (
                    <Box>
                        <Typography
                            fontSize={{
                                xs: pxToRem(12),
                                md: pxToRem(14),
                            }}
                            fontWeight={700}
                            lineHeight={1.7}
                            color="#EC3232"
                            component="span"
                        >
                            {startCase(keyService.toLowerCase())}
                        </Typography>{' '}
                        <Typography
                            fontSize={{
                                xs: pxToRem(10),
                                md: pxToRem(11),
                            }}
                            lineHeight={1.3}
                            color="#6F6F79"
                            display="inline"
                        >
                            ({`${taskCount} ${Pluralize('Task', taskCount)}`})
                        </Typography>
                    </Box>
                )}
                {carePlan && (
                    <>
                        <Typography
                            fontSize={{
                                xs: pxToRem(12),
                                md: pxToRem(14),
                            }}
                            fontWeight={700}
                            lineHeight={1.7}
                            mb={pxToRem(2)}
                            component="span"
                            textAlign="left"
                        >
                            Care Plan:
                        </Typography>{' '}
                        <Typography
                            fontSize={{
                                xs: pxToRem(12),
                                md: pxToRem(14),
                            }}
                            lineHeight={1.3}
                            color="#6F6F79"
                            component="span"
                            textAlign="left"
                        >
                            {carePlan}
                        </Typography>
                    </>
                )}
                {newPrns && (
                    <Box>
                        <Typography
                            fontSize={{
                                xs: pxToRem(12),
                                md: pxToRem(14),
                            }}
                            fontWeight={700}
                            lineHeight={1.7}
                            mb={pxToRem(2)}
                            component="span"
                            textAlign="left"
                        >
                            New PRN:
                        </Typography>{' '}
                        <Typography
                            fontSize={{
                                xs: pxToRem(12),
                                md: pxToRem(14),
                            }}
                            lineHeight={1.3}
                            color="#6F6F79"
                            component="span"
                            textAlign="left"
                        >
                            {newPrns.join(', ')}
                        </Typography>
                    </Box>
                )}
            </Box>
            {caregivers && (
                <AvatarGroup
                    max={4}
                    sx={[
                        caregivers.length > 4 && {
                            '& .MuiAvatarGroup-avatar:first-of-type': {
                                width: AVATAR_SIZE,
                                height: AVATAR_SIZE,
                                fontSize: pxToRem(14),
                                lineHeight: 1.7,
                                color: '#2C2D3B',
                                backgroundColor: 'transparent',
                                ml: pxToRem(-4),
                            },
                        },
                    ]}
                >
                    {caregivers.map(({ firstName, lastName, photo }, index) => (
                        <Tooltip
                            title={`${firstName} ${lastName}`}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${firstName} ${lastName} ${index}`}
                        >
                            <Avatar
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                alt={`${firstName} ${lastName}`}
                                sx={{
                                    width: AVATAR_SIZE,
                                    height: AVATAR_SIZE,
                                }}
                            >
                                <CustomAvatar
                                    avatarType={AVATAR_TYPES.caregiver}
                                    firstName={firstName}
                                    lastName={lastName}
                                    photo={photo || ''}
                                    size={AVATAR_SIZE}
                                />
                            </Avatar>
                        </Tooltip>
                    ))}
                </AvatarGroup>
            )}
        </Box>
    );
};
