import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { UserResponse } from 'types/users';

import { CustomAvatar, CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';
import { formatDateTimeFullUS } from 'lib/common';

const AVATAR_DETAILS_SIZE = 100;

const Row = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    })
);

const UserMainDataWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(AVATAR_DETAILS_SIZE + 24)})`,
    })
);

const UserRole = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        color: theme.palette.app.orange.main,
        fontSize: { lg: pxToRem(10) },
        fontWeight: 500,
        textTransform: 'uppercase',
    })
);

const UserName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(20) },
    })
);

const UserEmail = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(16) },
    })
);

const UserSingleDataWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '49%',
    })
);

const SingleDataText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: { lg: pxToRem(14) },
    })
);

const FooterWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    })
);

const TernaryButtonWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(32),
        display: 'flex',
    })
);

const MainButtonsWrapper = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        '& >:not(:first-of-type)': {
            ml: pxToRem(8),
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    isOpen: boolean;
    user?: UserResponse;
    timezone: string;
    onEdit: (user: UserResponse) => void;
    onDelete: (user: UserResponse) => void;
    onClose: () => void;
};

const UserDetailDialog = (props: Props) => {
    const {
        dictionary: { shared, userManagementUsersList, userProfile },
        isOpen,
        user,
        timezone,
        onEdit,
        onDelete,
        onClose,
    } = props;

    if (!isOpen || !user) {
        return null;
    }

    const {
        role,
        firstName,
        lastName,
        dateOfBirth,
        email,
        phoneNumber,
        picture,
        invitationDate,
        registrationDate,
    } = user;
    const userFullName = `${firstName} ${lastName}`;
    const userPhoneNumber = phoneNumber
        ? formatPhoneNumberIntl(phoneNumber)
        : shared.notDefined;
    const userBirthDate = dateOfBirth
        ? moment(dateOfBirth).format('MMM D, YYYY')
        : shared.notDefined;
    const userInvitationDate = invitationDate
        ? formatDateTimeFullUS(invitationDate, timezone)
        : shared.notApplicable;

    return (
        <CustomDialog
            id="user_details"
            closeable
            open={isOpen}
            title={userManagementUsersList.userDetails}
            width={pxToRem(604)}
            showFooterDivider
            content={
                <>
                    <Row>
                        <CustomAvatar
                            avatarType={role.toLowerCase() as AVATAR_TYPES}
                            firstName={firstName}
                            lastName={lastName}
                            fullName={userFullName}
                            photo={picture}
                            size={AVATAR_DETAILS_SIZE}
                        />
                        <UserMainDataWrapper>
                            <UserRole variant="body1">{role}</UserRole>
                            <UserName variant="subtitle1">
                                {userFullName}
                            </UserName>
                            <UserEmail variant="body1">{email}</UserEmail>
                        </UserMainDataWrapper>
                    </Row>
                    <Row>
                        <UserSingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userProfile.phoneNumber}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {userPhoneNumber}
                            </SingleDataText>
                        </UserSingleDataWrapper>
                        <UserSingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userProfile.dateOfBirth}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {userBirthDate}
                            </SingleDataText>
                        </UserSingleDataWrapper>
                    </Row>
                    <Row>
                        <UserSingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userManagementUsersList.invitationDate}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {userInvitationDate}
                            </SingleDataText>
                        </UserSingleDataWrapper>
                        <UserSingleDataWrapper>
                            <SingleDataText variant="subtitle1">
                                {userManagementUsersList.registrationDate}
                            </SingleDataText>
                            <SingleDataText variant="body1">
                                {formatDateTimeFullUS(
                                    registrationDate,
                                    timezone
                                )}
                            </SingleDataText>
                        </UserSingleDataWrapper>
                    </Row>
                </>
            }
            actions={
                <FooterWrapper>
                    <TernaryButtonWrapper>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => onDelete(user)}
                        >
                            {shared.delete}
                        </Button>
                    </TernaryButtonWrapper>
                    <MainButtonsWrapper>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            {shared.close}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onEdit(user)}
                        >
                            {shared.edit}
                        </Button>
                    </MainButtonsWrapper>
                </FooterWrapper>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, session, users }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;
    const { user } = users;

    return {
        dictionary,
        timezone,
        user,
    };
};

export default connect(mapStateToProps)(UserDetailDialog);
