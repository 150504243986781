import { Cancel, CheckCircle, Info, ReportProblem } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { NOTIFICATION_TYPE } from 'constants/shared';

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    })
);

const ErrorIcon = styled(Cancel)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.error.main,
    })
);

const InfoIcon = styled(Info)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.info.main,
    })
);

const SuccessIcon = styled(CheckCircle)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.success.main,
    })
);

const WarningIcon = styled(ReportProblem)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.warning.main,
    })
);

const Message = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flex: 1,
    })
);

type Props = {
    closable?: boolean;
    dictionary: LanguageStructure;
    isOpen: boolean;
    message: string;
    title: string;
    type: NOTIFICATION_TYPE;
    onClose: () => void;
};

const NotificationDialog = (props: Props) => {
    const {
        closable = true,
        dictionary: { shared },
        isOpen,
        message,
        title,
        type = NOTIFICATION_TYPE.default,
        onClose,
    } = props;

    return (
        <CustomDialog
            id="notification"
            open={isOpen}
            title={title}
            width={pxToRem(400)}
            content={
                <RootStyle>
                    {type === NOTIFICATION_TYPE.error && <ErrorIcon />}
                    {type === NOTIFICATION_TYPE.info && <InfoIcon />}
                    {type === NOTIFICATION_TYPE.success && <SuccessIcon />}
                    {type === NOTIFICATION_TYPE.warning && <WarningIcon />}
                    <Message variant="body2">{message}</Message>
                </RootStyle>
            }
            actions={
                <>
                    {closable && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onClose}
                        >
                            {shared.ok}
                        </Button>
                    )}
                </>
            }
            onClose={onClose}
        />
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { rolesList } = users;

    return {
        dictionary,
        rolesList,
    };
};

export default connect(mapStateToProps)(NotificationDialog);
