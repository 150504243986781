import { CompanyCarePlansState } from 'types/redux';

import { COMPANY_CARE_PLANS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const companyCarePlans = (
    state: CompanyCarePlansState = COMPANY_CARE_PLANS_DEFAULT_STATE,
    action
) => {
    switch (action.type) {
        case actionTypes.COMPANY_CARE_PLAN_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.COMPANY_CARE_PLAN_READ_LIST: {
            return {
                ...state,
                companyCarePlansList: action.payload,
            };
        }
        case actionTypes.COMPANY_CARE_PLAN_READ: {
            return {
                ...state,
                companyCarePlan: action.payload,
            };
        }
        default:
            return state;
    }
};

export default companyCarePlans;
