export default function Select() {
    return {
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&.MuiSelect-outlined': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
            },
        },
    };
}
