import { cloneDeep } from 'lodash';

import { Row, SortDirection } from 'types/table';

import { SORT_DIRECTIONS } from 'constants/table';

// eslint-disable-next-row import/prefer-default-export
export const SortRows = (
    sourceRows: Row[],
    sortBy?: string,
    sortDirection?: SortDirection
): Row[] => {
    // Return the original values if there is no column specified for sorting.
    if (!sortBy) {
        return sourceRows;
    }

    const isSortAscending = sortDirection === SORT_DIRECTIONS.asc;
    const rowsCopy = cloneDeep(sourceRows);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowsCopy.sort((a: any, b: any) => {
        // Applying sorting for "non-string" values.
        if (typeof a[sortBy] !== 'string') {
            return isSortAscending
                ? a[sortBy] - b[sortBy]
                : b[sortBy] - a[sortBy];
        }

        // Applying sorting for "string" values.
        const stringA = a[sortBy].toLowerCase();
        const stringB = b[sortBy].toLowerCase();

        if (stringA < stringB) {
            return isSortAscending ? -1 : 1;
        }

        if (stringA > stringB) {
            return isSortAscending ? 1 : -1;
        }

        return 0;
    });

    return rowsCopy;
};

export const updateRowsToShow = (
    newRows: Row[],
    newPage: number,
    newRowsPerPage: number
) => {
    const newPageValue = newPage - 1;
    const sliceStartIndex = newPageValue * newRowsPerPage;
    const sliceEndIndex = (newPageValue + 1) * newRowsPerPage;
    const newRowsToShow = newRows.slice(sliceStartIndex, sliceEndIndex);
    return newRowsToShow;
};
