import { Box } from '@mui/material';
import Pluralize from 'pluralize';
import React from 'react';

import { FilledCaregiversIcon } from 'components/Svg/FilledCaregiversIcon';
import { pxToRem } from 'components/theme/typography';

import { Chart } from './Chart';

const Stat = ({ ratio, labelLineOne, labelLineTwo }) => (
    <Box
        sx={{
            borderRadius: pxToRem(4),
            border: '1px solid #F4F4F5',
            display: 'flex',
            flexDirection: 'column',
            padding: pxToRem(12),
            gap: pxToRem(8),
            flex: 1,
        }}
    >
        <Box
            sx={{
                fontSize: pxToRem(20),
                fontWeight: 700,
            }}
        >
            {ratio ? Math.round(ratio) : 0}
        </Box>
        <Box
            sx={{
                textAlign: 'left',
                fontSize: pxToRem(14),
                fontWeight: 400,
                color: '#85858E',
            }}
        >
            {labelLineOne}
            <br />
            {labelLineTwo}
        </Box>
    </Box>
);

type Props = {
    title: string;
    residentsRatio: number;
    tasksRatio: number;
    billableMinutesRatio: number;
    completedTaskCount: number;
    refusedTaskCount: number;
    otherIncompleteTaskCount: number;
    undocumentedTaskCount: number;
    caregiverShiftsCount: number;
};

export const OverviewModule = ({
    title,
    residentsRatio,
    tasksRatio,
    billableMinutesRatio,
    completedTaskCount,
    refusedTaskCount,
    otherIncompleteTaskCount,
    undocumentedTaskCount,
    caregiverShiftsCount,
}: Props) => (
    <Box
        sx={{
            backgroundColor: '#fff',
            borderRadius: pxToRem(12),
            padding: pxToRem(16),
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: pxToRem(16),
        }}
    >
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#2C2D3B',
                fontWeight: 700,
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    fontSize: pxToRem(20),
                    textAlign: 'left',
                }}
            >
                {title}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: pxToRem(2),
                    fontSize: pxToRem(16),
                    textAlign: 'left',
                }}
            >
                <FilledCaregiversIcon size={18} viewBox="0 0 16 16" />
                {caregiverShiftsCount} care{' '}
                {Pluralize('shift', caregiverShiftsCount)}
            </Box>
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: pxToRem(12),
            }}
        >
            <Stat
                ratio={residentsRatio}
                labelLineOne="Residents"
                labelLineTwo="per Caregiver"
            />
            <Stat
                ratio={tasksRatio}
                labelLineOne="Scheduled"
                labelLineTwo="per Caregiver"
            />
            <Stat
                ratio={billableMinutesRatio}
                labelLineOne="Points"
                labelLineTwo="per Caregiver"
            />
        </Box>
        <Chart
            completed={completedTaskCount}
            refused={refusedTaskCount}
            otherIncomplete={otherIncompleteTaskCount}
            undocumented={undocumentedTaskCount}
        />
    </Box>
);
