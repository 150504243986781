import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import LogoImage from 'assets/logo.png';
import { pxToRem } from 'components/theme/typography';

const LogoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
    })
);

const LogoStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(28),
        height: pxToRem(28),
    })
) as typeof Box;

const AllieLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        letterSpacing: pxToRem(1),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(24) },
        fontWeight: 'bold',
        color: theme.palette.app.green.main,
    })
);

const HealthLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        letterSpacing: pxToRem(1),
        fontFamily: 'Raleway',
        fontSize: { xs: pxToRem(24) },
        fontWeight: 'normal',
        color: theme.palette.app.orange.main,
    })
);

const AllieHealthLogo = () => (
    <LogoContainer>
        <LogoStyle component="img" src={LogoImage} />
        <AllieLabel variant="h1">Allie</AllieLabel>
        <HealthLabel variant="h1">Health</HealthLabel>
    </LogoContainer>
);

export default AllieHealthLogo;
